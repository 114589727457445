import { ReactAdminTabbedShowNewAppWrapper } from "../../components/general/ReactAdminTabbedShowNewAppWrapper";
import { Volunteers } from "./Volunteers";

export function AdminVolunteerList() {
  return (
    <ReactAdminTabbedShowNewAppWrapper>
      <Volunteers forAdminView />
    </ReactAdminTabbedShowNewAppWrapper>
  );
}
