import { Fragment } from "react";
import { useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FormApi } from "final-form";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  composeValidators,
  convertFileToB64,
  positiveIntegerOnly,
  requiredField,
  stateOptions,
  twoDecimalsOnly,
} from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  NullableField,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
  WYSIWYGField,
} from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { ClickTooltip } from "../../../components/ui/ClickTooltip";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { PreviewEventEmail } from "../components/PreviewEventEmail";
import { NotificationSchedule } from "../components/NotificationSchedule";

type Props = {
  event_group_id: any;
  onClose: () => void;
  refreshList: () => void;
};

export function AddEvent({
  onClose: _onClose,
  refreshList,
  event_group_id,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  const onSubmit = async (values: any, _form: FormApi, complete: any) => {
    const {
      imageFile,
      use_custom_event_notifications,
      event_group_event_notifications: EN,
      max_tickets_per_sale,
      ...rest
    } = values;
    const event_group_event_notifications =
      !use_custom_event_notifications || !EN
        ? null
        : EN.map((e: GenericObject) => {
            const { id, days_before_event } = e;
            return {
              id: id ? id : null,
              days_before_event: Number(days_before_event),
            };
          });
    const post: GenericObject = {
      active: true,
      event_group_id,
      max_tickets_per_sale: max_tickets_per_sale
        ? Number(max_tickets_per_sale)
        : null,
      ...rest,
      use_custom_event_notifications,
      event_group_event_notifications,
    };
    if (imageFile) post.ticketImageBase64 = await convertFileToB64(imageFile);
    const url = "/event_group_events";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Event added", ToastTypes.success));
    refreshList();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>Add event</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form, pristine, values }) => {
            const { allow_venue_open_seating } = values;
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.mainContent}>
                  <Field
                    component={TextFieldWrapper}
                    name="event_name"
                    label="Event name"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <Field
                    component={TextFieldWrapper}
                    name="org_name"
                    label="Org name"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <div className={classes.inputGroup}>
                    <Field
                      component={TextFieldWrapper}
                      name="date"
                      label="Date"
                      validate={requiredField}
                      className={classes.halfInput}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                    <Field
                      component={TextFieldWrapper}
                      name="time"
                      label="Time"
                      validate={requiredField}
                      className={classes.halfInput}
                      type="time"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <Field
                    component={TextFieldWrapper}
                    name="location_name"
                    label="Location"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <div className={classes.inputGroup}>
                    <Field
                      name="location_address"
                      label="Address"
                      component={TextFieldWrapper}
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                    <Field
                      name="location_city"
                      label="City"
                      component={TextFieldWrapper}
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                  </div>

                  <div className={classes.inputGroup}>
                    <Field
                      select
                      name="location_state"
                      label="State"
                      component={TextFieldWrapper}
                      validate={requiredField}
                      className={classes.halfInput}
                    >
                      {stateOptions.map(({ name, code }) => (
                        <MenuItem key={code} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      name="location_zip"
                      component={TextFieldWrapper}
                      label="Zip"
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <div className={classes.halfInput}>
                      <SwitchField
                        fieldName="allow_venue_open_seating"
                        label="Allow venue open seats"
                      />
                    </div>
                    {allow_venue_open_seating && (
                      <Field
                        name="venue_open_seat_price"
                        component={TextFieldWrapper}
                        label="Venue open seat price"
                        validate={composeValidators(
                          requiredField,
                          twoDecimalsOnly,
                        )}
                        className={classes.halfInput}
                        type="number"
                      />
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <div className={classes.halfInput}>
                      <SwitchField
                        fieldName="require_name_on_ticket"
                        label="Require name on ticket"
                      />
                    </div>
                    <NullableField
                      name="max_tickets_per_sale"
                      component={TextFieldWrapper}
                      label="Max tickets per sale"
                      validate={positiveIntegerOnly}
                      className={classes.halfInput}
                      type="number"
                    />
                  </div>

                  <div className={classes.ticketConfigsHeader}>
                    Ticket configurations
                  </div>
                  <div className={classes.label}>Ticket image</div>
                  <div className={classes.ticketImgWrapper}>
                    <ImageDropzoneField
                      fieldName="imageFile"
                      currImage={values.ticket_image}
                    />
                  </div>
                  <NullableField
                    component={TextFieldWrapper}
                    name="ticket_additional_info"
                    label="Additional display info (optional)"
                    className={classes.input}
                    type="textarea"
                    multiline
                    minRows={2}
                  />

                  <div className={classes.notificationsHeader}>
                    Event notifications
                  </div>

                  <div className={classes.notificationSubheader}>
                    Email notifications will be sent on behalf of participants
                    who have opted in to have this event's notifications sent.
                  </div>

                  <div className={classes.custoMNotificationsContainer}>
                    <SwitchField
                      fieldName="use_custom_event_notifications"
                      label="Use custom event notifications"
                    />
                    <div className={classes.infoIcon}></div>
                    <ClickTooltip content="By default notifications are sent according to the `group's` set schedule. Switch on to customize the notification schedule for this event." />
                  </div>

                  {values.use_custom_event_notifications && (
                    <Fragment>
                      <NotificationSchedule fieldName="event_group_event_notifications" />
                      <div className={classes.scheduleSpacer} />
                    </Fragment>
                  )}

                  <NullableField
                    component={TextFieldWrapper}
                    name="email_subject"
                    label="Email subject"
                    className={classes.input}
                  />
                  <NullableField
                    component={TextFieldWrapper}
                    name="email_link"
                    label="Link to purchase tickets"
                    className={classes.input}
                  />
                  <div className={classes.emailLabel}>Email body</div>
                  <WYSIWYGField
                    fieldName="email_template"
                    msg="Avoid leaving empty lines at the beginning or end of the email body."
                  />

                  <PreviewEventEmail formValues={values} />
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}

                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting || pristine}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    top: 0,
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      width: "100%",
    },
  },
  ticketConfigsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 16,
    paddingTop: 16,
  },
  label: {
    color: theme.palette.text.secondary,
    paddingBottom: 8,
  },
  ticketImgWrapper: {
    width: "100%",
    marginBottom: 24,
  },
  custoMNotificationsContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    position: "relative",
  },
  infoIcon: {
    marginLeft: 8,
  },
  notificationsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
    paddingTop: 12,
  },
  notificationSubheader: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
    },
  },
  scheduleSpacer: {
    marginBottom: 24,
  },
  emailLabel: {
    marginTop: 8,
    color: theme.palette.text.secondary,
  },
  bottom: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
