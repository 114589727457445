import { Fragment, ReactNode, useEffect, useState } from "react";
import { Avatar, makeStyles, Popover, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import {
  getCampaignId,
  getUserName,
  getUserNameInitials,
  setToast,
} from "../state";
import { isVolunteer, nameToInitials } from "../../lib";
import {
  APIAuthClient,
  changeVolunteerToken,
  getIsImpersonating,
} from "../lib";
import { APIRes, paths } from "../types";
import { VariableHeightDrawer } from "../components/ui/VariableHeightDrawer";
import { RootState } from "../types/state";
import {
  useImpersonationFields,
  useIsDesktop,
  useIsMobile,
  useLeaveImpersonation,
  useLogout,
} from "../hooks/ui";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
};
export function Account({ ...props }: Props) {
  const { onClose } = props;
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const logout = useLogout();
  const isImpersonating = getIsImpersonating();
  const leaveImpersonation = useLeaveImpersonation();
  const [showAdditionalAccounts, setShowAdditionalAccounts] = useState(false);
  const {
    role,
    volunteerId,
    allow_associated_volunteer_accounts,
    associatedVolunteers,
  } = useSelector((state: RootState) => state.user);
  const campaignId = useSelector(getCampaignId);
  const { impersonationLabel, impersonationName } = useImpersonationFields();
  const userName = useSelector(getUserName);
  const userInitials = useSelector(getUserNameInitials);

  useEffect(() => {
    if (isVolunteer(role) && allow_associated_volunteer_accounts) {
      setShowAdditionalAccounts(true);
    }
  }, [role, allow_associated_volunteer_accounts]);

  const switchVolunteerAccount = async (volunteer_id: number) => {
    onClose();
    const url = `/volunteers/login_volunteer_associated_volunteer?volunteer_id=${volunteer_id}&campaign_id=${campaignId}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { token, onCurrentCampaign } = data;
    changeVolunteerToken(token);
    if (!onCurrentCampaign) {
      localStorage.removeItem("selectedCampaignId");
      window.location.replace(paths.ORG_CAMPAIGNS);
    } else {
      window.location.replace(paths.HOME);
    }
  };

  const addAssociatedVolunteer = () => {
    if (!campaignId) return;
    onClose();
    history.push(
      `${paths.ADD_ASSOC_VOLUNTEER_ACCOUNT}?campaign_id=${campaignId}`,
    );
  };

  const Impersonation = () => (
    <div className={classes.impContainer}>
      <div>
        <div className={classes.imp}>Impersonating {impersonationLabel}</div>
        <div className={classes.impName}>{impersonationName}</div>
      </div>
      <Button
        size="small"
        className={classes.leave}
        onClick={leaveImpersonation}
      >
        Leave
      </Button>
    </div>
  );

  return (
    <Wrapper {...props}>
      <div className={classes.container}>
        {isDesktop && isImpersonating && <Impersonation />}

        <div className={classes.nameContainer}>
          <Avatar className={classes.bigAvatar}>{userInitials}</Avatar>
          <div className={classes.userName}>{userName}</div>
          {isVolunteer(role) && (
            <div className={classes.sellerId}>Seller ID: {volunteerId}</div>
          )}
        </div>

        <div
          className={classes.linkRow}
          onClick={() => {
            onClose();
            history.push(paths.PROFILE);
          }}
        >
          <AccountIcon /> <div className={classes.linkTxt}>Profile</div>
        </div>
        <div className={classes.linkRow} onClick={logout}>
          <ExitToAppIcon /> <div className={classes.linkTxt}>Logout</div>
        </div>
        <div className={classes.sectionSpacer} />

        {showAdditionalAccounts && (
          <Fragment>
            <div className={classes.otherAccTop} />
            <div className={classes.otherAcc}>Other accounts</div>

            {Array.isArray(associatedVolunteers) &&
              associatedVolunteers.map(av => {
                const { volunteer_id, first_name, last_name } = av;
                const name = `${first_name} ${last_name}`;
                return (
                  <div
                    key={volunteer_id}
                    className={classes.linkRow}
                    onClick={() => switchVolunteerAccount(volunteer_id)}
                  >
                    <Avatar className={classes.otherAccountAvatar}>
                      {nameToInitials(name)}
                    </Avatar>
                    <div className={classes.otherAccLinkTxt}>
                      {name} #{volunteer_id}
                    </div>
                  </div>
                );
              })}

            <div className={classes.linkRow} onClick={addAssociatedVolunteer}>
              <AddIcon />
              <div className={classes.otherAccLinkTxt}>Add account</div>
            </div>
            <div className={classes.sectionSpacer} />
          </Fragment>
        )}
        {isMobile && isImpersonating && <Impersonation />}
      </div>
    </Wrapper>
  );
}

type WrapperProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
};
function Wrapper({ children, isOpen, onClose, anchorEl }: WrapperProps) {
  const isMobile = useIsMobile();
  const classes = styles();

  if (isMobile) {
    return (
      <VariableHeightDrawer isOpen={isOpen} onClose={onClose}>
        {children}
      </VariableHeightDrawer>
    );
  } else {
    return (
      <Popover
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
        open={isOpen}
        onClose={onClose}
        PaperProps={{ square: true, className: classes.paper }}
      >
        {children}
      </Popover>
    );
  }
}

const styles = makeStyles(theme => ({
  paper: {
    width: 300,
    maxWidth: "100%",
    borderRadius: 8,
  },
  container: {
    width: "100%",
    maxWidth: "100%",
  },
  impContainer: {
    width: "100%",
    padding: 16,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflowWrap: "anywhere",
  },
  imp: {
    fontSize: 16,
    letterSpacing: 0.15,
    marginBottom: 6,
  },
  impName: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  leave: {
    height: 30,
    width: "fit-content",
    minWidth: "fit-content",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.43,
    marginLeft: 10,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#FFFFFF",
      color: theme.palette.primary.main,
    },
  },
  nameContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px 16px",
    borderBottom: "1px solid #DBDEEE",
    marginBottom: 8,
  },
  bigAvatar: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.secondary2.main,
    height: 54,
    width: 54,
    fontSize: 25,
    fontWeight: "bold",
    letterSpacing: 0,
  },
  userName: {
    paddingTop: 16,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "18px",
    textAlign: "center",
  },
  sellerId: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    paddingTop: 6,
    textAlign: "center",
    lineHeight: "22px",
    color: theme.palette.text.secondary2,
  },
  linkRow: {
    minHeight: 48,
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px 12px 8px 18px",
    overflowWrap: "anywhere",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
  linkTxt: {
    fontSize: 16,
    letterSpacing: 0.15,
    marginLeft: 18,
  },
  sectionSpacer: {
    height: 8,
  },
  otherAccTop: {
    borderTop: "1px solid #DDDDDD",
  },
  otherAcc: {
    marginLeft: 18,
    marginTop: 16,
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "16px",
    color: theme.palette.text.secondary2,
  },
  otherAccountAvatar: {
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: 0,
    color: "#FFFFFF",
    backgroundColor: theme.palette.secondary2.main,
    height: 24,
    width: 24,
  },
  otherAccLinkTxt: {
    fontSize: 16,
    letterSpacing: 0.15,
    marginLeft: 18,
    color: theme.palette.text.primary,
    lineHeight: "19px",
  },
}));
