// cSpell:ignore Swipeable
import { ReactNode, useState } from "react";
import { Theme, SwipeableDrawer, Dialog, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../hooks/ui";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../state";
import { RootState } from "../../types/state";
import { nonEmptyArray } from "../../lib";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  keepMounted?: boolean;
  onClose: (e?: any) => void;
  dynamicHeight?: boolean;
  fullScreen?: boolean;
  fullHeight?: boolean;
};

export function ResponsiveModal({
  children,
  isOpen,
  onClose,
  keepMounted = false,
  dynamicHeight = false,
  fullScreen = false,
  fullHeight = false,
}: Props) {
  const classes = styles({ dynamicHeight });
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [identifier, _] = useState(Math.random());
  const modalIdentifierArr = useSelector(
    ({ ui }: RootState) => ui.modalIdentifierArr,
  );

  const onEnter = () => dispatch(uiActions.addModalIdentifier(identifier));
  const onExit = () => dispatch(uiActions.removeLastModalIdentifier());

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
        classes={{ paper: classes.drawer }}
        transitionDuration={500}
        anchor="bottom"
        keepMounted={keepMounted}
        BackdropProps={{
          className:
            nonEmptyArray(modalIdentifierArr) &&
            identifier === modalIdentifierArr.at(-1)
              ? classes.topBackdrop
              : classes.backdrop,
        }}
        SlideProps={{ onEntering: onEnter, onExiting: onExit }}
      >
        <div className={classes.wrapper}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Dialog
        open={isOpen}
        maxWidth={false}
        fullScreen={fullScreen}
        keepMounted={keepMounted}
        classes={{
          paper: classNames(classes.paper, fullHeight && classes.fullHeight),
          paperWidthFalse: classes.paperWidthFalse,
        }}
        BackdropProps={{
          className:
            nonEmptyArray(modalIdentifierArr) &&
            identifier === modalIdentifierArr.at(-1)
              ? classes.topBackdrop
              : classes.backdrop,
        }}
        onClick={e => e.stopPropagation()}
        TransitionProps={{ onEntering: onEnter, onExiting: onExit }}
      >
        {children}
      </Dialog>
    );
  }
}

type StyleProps = {
  dynamicHeight?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(_theme => ({
  drawer: {
    top: ({ dynamicHeight }) => (dynamicHeight ? "auto" : 75),
    borderRadius: "20px 20px 0 0",
    maxHeight: "unset",
  },
  wrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  paper: {
    marginLeft: 0,
    marginRight: 0,
  },
  paperWidthFalse: {
    maxWidth: "100%",
  },
  fullHeight: {
    height: "calc(100% - 64px)",
  },
  topBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  backdrop: {
    backgroundColor: "transparent",
  },
}));
