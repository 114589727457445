import { theme } from "../theme/theme";

type Props = {
  color: "primary" | "secondary" | "white" | "secondaryText";
};
export function AutoTextSVG({ color }: Props) {
  const fill =
    color === "white"
      ? "#FFFFFF"
      : color === "primary"
      ? theme.palette.primary.main
      : color === "secondaryText"
      ? theme.palette.text.secondary
      : theme.palette.secondary.main;

  return (
    <svg width="18" height="14">
      <path
        fill={fill}
        transform="translate(0 3.39756)"
        d="M4.0500636 8.5500641C3.2100127 7.7100129 2.6289775 6.7465 2.3063216 5.6588883C1.9836657 4.5712771 1.9162071 3.4836657 2.1039457 2.3960543L0.89987272 3.6001272L0 2.7002547L2.7002547 0L5.3998728 2.7002547L4.5 3.6001272L3.3862963 2.4864235C3.2061944 3.3862963 3.2456512 4.2957149 3.504667 5.2146797C3.7630463 6.133008 4.244803 6.9450574 4.9499364 7.6501908C5.512516 8.2127705 6.1463723 8.6327963 6.8515062 8.9102669C7.5566397 9.1877384 8.2725925 9.3264742 9 9.3264742C9.7274075 9.3264742 10.443996 9.1877384 11.14913 8.9102669C11.853627 8.6327963 12.487484 8.2127705 13.050064 7.6501908L13.949937 8.5500641C13.267714 9.2322865 12.49703 9.7445908 11.638524 10.085702C10.779381 10.426814 9.8998728 10.597369 9 10.597369C8.1001272 10.597369 7.2206197 10.426814 6.362113 10.085702C5.5029697 9.7445908 4.7322869 9.2322865 4.0500636 8.5500641"
        fillRule="evenodd"
      />
      <path
        fill={fill}
        transform="translate(4.05006 0.000190735)"
        d="M0 2.0473058C0.68222314 1.3650827 1.4529063 0.85277897 2.3120492 0.51166737C3.1705558 0.17055579 4.0500636 0 4.9499364 0C5.8498092 0 6.7293172 0.17055579 7.58846 0.51166737C8.4469662 0.85277897 9.2176495 1.3650827 9.8998728 2.0473058C10.739923 2.8873568 11.321595 3.8508697 11.643615 4.9384813C11.96627 6.0260925 12.03373 7.1137037 11.846627 8.2013149L13.050064 6.9972425L13.949937 7.8971152L11.250319 10.597369L8.5500641 7.8971152L9.4499369 6.9972425L10.563641 8.1109457C10.743742 7.2110734 10.704286 6.3016548 10.445906 5.38269C10.186891 4.4643617 9.7051334 3.6523123 9 2.9471786C8.4374208 2.384599 7.8035641 1.9645736 7.0990667 1.6871023C6.3939328 1.4096309 5.6773443 1.2708952 4.9499364 1.2708952C4.2225285 1.2708952 3.5065761 1.4096309 2.8014426 1.6871023C2.0963089 1.9645736 1.4624523 2.384599 0.89987272 2.9471786L0 2.0473058Z"
        fillRule="evenodd"
      />
      <path
        fill={fill}
        transform="translate(5.88227 3.87951)"
        d="M1.1480696 3.957149L5.0880356 3.957149L5.0880356 1.1480696L1.1480696 1.1480696L1.1480696 3.957149ZM5.9847264 0.25137886C5.8198981 0.086550698 5.6111584 0 5.379508 0L0.85596097 0C0.62431055 0 0.41557065 0.086550698 0.25074247 0.25137886C0.085914299 0.41557065 0 0.62494695 0 0.85659736L0 6.6484938L1.5420026 5.105855L5.379508 5.105855C5.6111584 5.105855 5.8198981 5.0193043 5.9847264 4.854476C6.1495547 4.6896477 6.2354689 4.4802713 6.2354689 4.248621L6.2354689 0.85659736C6.2354689 0.62494695 6.1495547 0.41557065 5.9847264 0.25137886L5.9847264 0.25137886Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
