import { makeStyles } from "@material-ui/core";
import { GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { formatMoneyUnPadded, nonEmptyArray } from "../../../../lib";
import classNames from "classnames";
type DonationTickets = {
  id: number;
  event_name: string;
  ticket_type: string;
  seat: string;
  ticket_price: string;
  attendee: string | null;
}[];

type Props = { donation: GenericObject };

export function EventTicketsCard({ donation }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const eventTickets = donation.eventTickets as DonationTickets;

  if (!nonEmptyArray(eventTickets)) return <></>;
  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Tickets</div>

      {eventTickets.map((ticket, idx) => {
        const { id, event_name, seat, attendee, ticket_price } = ticket;
        const isLast = idx === eventTickets.length - 1;
        return (
          <div
            key={id}
            className={classNames(classes.row, isLast && classes.lastRow)}
          >
            <div className={classes.top}>
              <div>{event_name}</div>
              <div className={classes.price}>
                {formatMoneyUnPadded(ticket_price)}
              </div>
            </div>
            <div className={classes.figure}>{seat}</div>
            <div className={classes.figure}>Name: {attendee}</div>
          </div>
        );
      })}
    </div>
  );
}

const styles = makeStyles(theme => ({
  row: {
    marginBottom: 16,
    paddingBottom: 18,
    borderBottom: "1px solid #EAEBF3",
  },
  lastRow: {
    borderBottom: "none",
    paddingBottom: 0,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontWeight: 500,
    letterSpacing: 0.2,
    color: theme.palette.text.primary,
    paddingTop: 4,
  },
  price: {
    minWidth: "fit-content",
    paddingLeft: 16,
  },
  figure: {
    paddingTop: 6,
  },
}));
