// cSpell:ignore Swipeable
import { makeStyles, SwipeableDrawer, Menu } from "@material-ui/core";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";
// import AddIcon from "@material-ui/icons/Add";
import { Fragment, ReactNode, useState } from "react";
import { useIsMobile } from "../../../hooks/ui";
import {
  campaignDonationsCsv,
  campaignOrdersCsv,
  campaignByProductOrdersCsv,
  campaignTicketCsv,
} from "../../../exports/donation/campaignDonationListCSVs";
import { useSelector } from "react-redux";
import {
  getIsLoadingDownload,
  getIsProductCampaign,
  getIsSchoolathon,
} from "../../../state";
import { RootState } from "../../../types/state";
import { getIsGivvr, getIsVolunteer } from "../../../../customState";
import classNames from "classnames";
import {
  isKidsAreFirstCompany,
  isMrsFieldsCompany,
  isRaffleCampaign,
  isSimplyGoodnessCompany,
} from "../../../../lib";
import { AddOrderForm } from "../../../components";
import { AddDonation } from "./AddDonation";
import { DonationUpload } from "./DonationUpload";
import {
  STHShipstationDownload,
  STSShipstationDownload,
  pullSheetDownload,
  studentLabelsDownload,
  mrsFieldsOrderXmlDownload,
  dinnerReportDownload,
  donationDedicationsDownload,
  STSStudentProductsDownload,
} from "../../../exports/donation/donationDownloads";
import { DownloadStudentPickSlips } from "./DownloadStudentPickSlips";
const MENU_WIDTH = 290;
const BUTTON_WIDTH = 160;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
  refreshData: () => void;
};
export function ActionsMenu(props: Props) {
  const { isOpen, onClose, anchorEl, refreshData } = props;
  const classes = styles();
  const [showAddOrderForms, setShowAddOrderForms] = useState(false);
  const [showAddDonation, setShowAddDonation] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showPickSlipsModal, setShowPickSlipsModal] = useState(false);
  const isLoading = useSelector(getIsLoadingDownload);
  const isVolunteer = useSelector(getIsVolunteer);
  const { campaignId, campaign_type_id, no_cash_donations } = useSelector(
    (state: RootState) => state.campaign,
  );
  const { companyId, canManage } = useSelector(
    (state: RootState) => state.user,
  );
  const isGivvr = useSelector(getIsGivvr);
  const isProduct = useSelector(getIsProductCampaign);
  const isMrsFields = isMrsFieldsCompany(companyId);
  const isSimplyGoodness = isSimplyGoodnessCompany(companyId);
  const isKidsAreFirst = isKidsAreFirstCompany(companyId);
  const isRaffle = isRaffleCampaign(campaign_type_id);
  const isSchoolathon = useSelector(getIsSchoolathon);

  const onAddOrderForm = () => {
    setShowAddOrderForms(true);
    onClose();
  };

  if (isVolunteer) {
    return (
      <Fragment>
        <VolunteerMenuItems {...props} onAddOrderForm={onAddOrderForm} />
        {showAddOrderForms && (
          <AddOrderForm
            onClose={() => setShowAddOrderForms(false)}
            refreshList={refreshData}
          />
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <ResponsiveMenu isOpen={isOpen} onClose={onClose} anchorEl={anchorEl}>
        <div
          className={classNames(
            classes.container,
            isLoading && classes.loading,
          )}
        >
          {isLoading && <div className={classes.loadingOverlay} />}

          {/* ADD DONATIONS/ORDERS */}
          <div className={classes.subHeader}>
            Add {isProduct ? "orders" : "donations"}
          </div>
          <div
            className={classes.row}
            onClick={() => {
              setShowAddDonation(true);
              onClose();
            }}
          >
            Add a {isProduct ? "sale" : "donation"}
          </div>
          {!no_cash_donations && (
            <div
              className={classes.row}
              onClick={() => {
                setShowUpload(true);
                onClose();
              }}
            >
              Upload {isProduct ? "orders" : "donations"}
            </div>
          )}
          {isProduct && canManage && !isSchoolathon && (
            <div className={classes.row} onClick={onAddOrderForm}>
              Add order form
            </div>
          )}

          {/* DONATION/ORDER EXPORTS */}
          <div className={classes.divider} />
          <div className={classes.subHeader}>
            {isProduct ? "Order" : "Donation"} exports
          </div>
          {!isProduct && (
            <Fragment>
              <div
                className={classes.row}
                onClick={() =>
                  campaignDonationsCsv(
                    campaignId,
                    campaign_type_id,
                    isGivvr,
                    onClose,
                  )
                }
              >
                Export all donations
              </div>
              <div
                className={classes.row}
                onClick={() => donationDedicationsDownload(campaignId, onClose)}
              >
                Dedications Export
              </div>
              {isGivvr && (
                <div
                  className={classes.row}
                  onClick={() => dinnerReportDownload(campaignId, onClose)}
                >
                  Dinner export
                </div>
              )}
              {isRaffle && (
                <div
                  className={classes.row}
                  onClick={() =>
                    campaignTicketCsv(campaignId, isGivvr, onClose)
                  }
                >
                  Ticket export
                </div>
              )}
            </Fragment>
          )}
          {isProduct && (
            <Fragment>
              <div
                className={classes.row}
                onClick={() => campaignOrdersCsv(campaignId, onClose)}
              >
                Export all orders
              </div>
              <div
                className={classes.row}
                onClick={() => campaignByProductOrdersCsv(campaignId, onClose)}
              >
                Export orders with products
              </div>
              {(isMrsFields || isSimplyGoodness || isKidsAreFirst) && (
                <div
                  className={classes.row}
                  onClick={() => mrsFieldsOrderXmlDownload(campaignId, onClose)}
                >
                  {isKidsAreFirst ? "KAF-FM" : "XML"} order export
                </div>
              )}
            </Fragment>
          )}

          {/* ORDER FULFILLMENT */}
          {isProduct && (
            <Fragment>
              <div className={classes.divider} />
              <div className={classes.subHeader}>Order fulfillment</div>
              <div
                className={classes.row}
                onClick={() => STHShipstationDownload(campaignId, onClose)}
              >
                STH ShipStation
              </div>
              <div
                className={classes.row}
                onClick={() => STSShipstationDownload(campaignId, onClose)}
              >
                STS ShipStation
              </div>
              <div
                className={classes.row}
                onClick={() => pullSheetDownload(campaignId, onClose)}
              >
                STS pull sheet
              </div>
              <div
                className={classes.row}
                onClick={() => {
                  setShowPickSlipsModal(true);
                  onClose();
                }}
              >
                STS pick slips
              </div>
              <div
                className={classes.row}
                onClick={() => studentLabelsDownload(campaignId, onClose)}
              >
                STS student labels
              </div>
              <div
                className={classes.row}
                onClick={() => STSStudentProductsDownload(campaignId, onClose)}
              >
                STS student product list
              </div>
            </Fragment>
          )}
        </div>
      </ResponsiveMenu>

      {showAddOrderForms && (
        <AddOrderForm
          onClose={() => setShowAddOrderForms(false)}
          refreshList={refreshData}
        />
      )}
      {showAddDonation && (
        <AddDonation onClose={() => setShowAddDonation(false)} />
      )}
      {showUpload && (
        <DonationUpload
          onClose={() => setShowUpload(false)}
          refreshData={refreshData}
        />
      )}
      {showPickSlipsModal && (
        <DownloadStudentPickSlips
          onClose={() => setShowPickSlipsModal(false)}
        />
      )}
    </Fragment>
  );
}

type ResponsiveProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
};
export function ResponsiveMenu({
  children,
  isOpen,
  onClose,
  anchorEl,
}: ResponsiveProps) {
  const classes = styles();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
        classes={{ paper: classes.drawer }}
        transitionDuration={500}
        anchor="bottom"
      >
        <div className={classes.drawerWrapper}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        className={classes.menu}
        open={isOpen}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: MENU_WIDTH - BUTTON_WIDTH,
        }}
      >
        {children}
      </Menu>
    );
  }
}

type VolunteerProps = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
  refreshData: () => void;
  onAddOrderForm: () => void;
};
function VolunteerMenuItems(props: VolunteerProps) {
  const { isOpen, onClose, anchorEl /*onAddOrderForm*/ } = props;
  const classes = styles();
  const isLoading = useSelector(getIsLoadingDownload);
  const { campaignId, campaign_type_id } = useSelector(
    (state: RootState) => state.campaign,
  );
  // const { companyId } = useSelector((state: RootState) => state.user);
  const isGivvr = useSelector(getIsGivvr);
  const isProduct = useSelector(getIsProductCampaign);
  // const isMrsFields = isMrsFieldsCompany(companyId);

  return (
    <Fragment>
      <ResponsiveMenu isOpen={isOpen} onClose={onClose} anchorEl={anchorEl}>
        <div
          className={classNames(
            classes.vContainer,
            isLoading && classes.loading,
          )}
        >
          {isLoading && <div className={classes.loadingOverlay} />}
          {!isProduct && (
            <div
              className={classes.vRow}
              onClick={() =>
                campaignDonationsCsv(
                  campaignId,
                  campaign_type_id,
                  isGivvr,
                  onClose,
                )
              }
            >
              <GetAppSharpIcon fontSize="small" />
              <div className={classes.vText}>Export all donations</div>
            </div>
          )}
          {isProduct && (
            <Fragment>
              {/* {isMrsFields && (
                <div className={classes.vRow} onClick={onAddOrderForm}>
                  <AddIcon fontSize="small" />
                  <div className={classes.vText}>Add order form</div>
                </div>
              )} */}
              <div
                className={classes.vRow}
                onClick={() => campaignOrdersCsv(campaignId, onClose)}
              >
                <GetAppSharpIcon fontSize="small" />
                <div className={classes.vText}>Export all orders</div>
              </div>
              <div
                className={classes.vRow}
                onClick={() => campaignByProductOrdersCsv(campaignId, onClose)}
              >
                <GetAppSharpIcon fontSize="small" />
                <div className={classes.vText}>Export orders with products</div>
              </div>
            </Fragment>
          )}
        </div>
      </ResponsiveMenu>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  drawer: {
    top: "auto",
    maxHeight: "unset",
  },
  drawerWrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  menu: {
    padding: 0,
  },
  menuPaper: {
    borderRadius: 8,
  },
  container: {
    width: MENU_WIDTH,
    position: "relative",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      width: "100%",
    },
  },
  subHeader: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "16px",
    color: theme.palette.text.secondary2,
    padding: "8px 12px",
  },
  divider: {
    margin: "8px 0",
    borderBottom: "1px solid #DBDEEE",
  },
  row: {
    width: "100%",
    height: 36,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 16,
    letterSpacing: 0.15,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
  loading: {
    opacity: 0.4,
  },
  loadingOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 999,
  },
  // volunteer styles
  vContainer: {
    width: MENU_WIDTH,
    position: "relative",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
      width: "100%",
    },
  },
  vRow: {
    minHeight: 48,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "10px 16px 10px 16px",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "19px",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 16px 10px 20px",
    },
  },
  vText: {
    paddingLeft: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
    },
  },
}));
