import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { APIAuthClient, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RootState } from "../../../types/state";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddMaterialBundle({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const materialAutocomplete = useSelector(
    ({ material }: RootState) => material.materialAutocomplete,
  );
  const { repAutocomplete } = useSelector(({ reps }: RootState) => reps);
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const { bundle_name, is_rep_restricted, materials, reps } = values;
    const materialIds = materials.map(({ material }) => material.id);
    const post: GenericObject = {
      active: true,
      bundle_name,
      is_rep_restricted: Boolean(is_rep_restricted),
      materialIds,
    };
    if (is_rep_restricted && Array.isArray(reps) && reps.length) {
      const repIds = reps.map(({ rep }) => rep.id);
      post.repIds = repIds;
    }
    const url = `/material_bundles`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Bundle added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{ materials: [{}] }}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Add material bundle</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                <Field
                  component={TextFieldWrapper}
                  name="bundle_name"
                  label="Bundle name"
                  validate={requiredField}
                  className={classes.input}
                />
                <div className={classes.subheader}>Materials</div>
                <FieldArray name="materials">
                  {({ fields }) => (
                    <div className={classes.arrayField}>
                      {fields.map((row, index) => {
                        const hasOneItem = fields.length === 1;
                        return (
                          <div key={index}>
                            <div className={classes.materialRow}>
                              <Field
                                name={`${row}.material`}
                                fullWidth
                                validate={requiredField}
                                render={({ input, meta, ...rest }) => {
                                  const showError =
                                    ((meta.submitError &&
                                      !meta.dirtySinceLastSubmit) ||
                                      meta.error) &&
                                    meta.touched;
                                  return (
                                    <Autocomplete
                                      {...rest}
                                      {...input}
                                      onChange={(_, value) =>
                                        input.onChange(value)
                                      }
                                      value={input.value ? input.value : null}
                                      options={materialAutocomplete}
                                      getOptionSelected={(option, value) =>
                                        option.id === value.id
                                      }
                                      getOptionLabel={o => (o ? o.name : o)}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          placeholder="Select material"
                                          helperText={
                                            showError
                                              ? meta.error || meta.submitError
                                              : undefined
                                          }
                                          error={showError}
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                              <div className={classes.removeWrapper}>
                                <IconButton
                                  disabled={hasOneItem}
                                  onClick={() => fields.remove(index)}
                                >
                                  <RemoveIcon
                                    className={
                                      !hasOneItem ? classes.remove : ""
                                    }
                                  />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <Button
                        color="primary"
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={() => fields.push("")}
                      >
                        MATERIAL
                      </Button>
                    </div>
                  )}
                </FieldArray>

                <div className={classes.subheader}>Reps</div>
                <SwitchField
                  fieldName="is_rep_restricted"
                  label="Rep restricted"
                />
                <div className={classes.repSpacer} />
                {values.is_rep_restricted && (
                  <FieldArray name="reps">
                    {({ fields }) => (
                      <div className={classes.arrayField}>
                        {fields.map((row, index) => {
                          return (
                            <div key={index}>
                              <div className={classes.materialRow}>
                                <Field
                                  name={`${row}.rep`}
                                  fullWidth
                                  validate={requiredField}
                                  render={({ input, meta, ...rest }) => {
                                    const showError =
                                      ((meta.submitError &&
                                        !meta.dirtySinceLastSubmit) ||
                                        meta.error) &&
                                      meta.touched;
                                    return (
                                      <Autocomplete
                                        {...rest}
                                        {...input}
                                        onChange={(_, value) =>
                                          input.onChange(value)
                                        }
                                        value={input.value ? input.value : null}
                                        options={repAutocomplete}
                                        getOptionLabel={o => (o ? o.name : o)}
                                        getOptionSelected={(option, value) =>
                                          option.id === value.id
                                        }
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            placeholder="Select rep"
                                            helperText={
                                              showError
                                                ? meta.error || meta.submitError
                                                : undefined
                                            }
                                            error={showError}
                                          />
                                        )}
                                      />
                                    );
                                  }}
                                />
                                <div className={classes.removeWrapper}>
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                  >
                                    <RemoveIcon className={classes.remove} />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <Button
                          color="primary"
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={() => fields.push("")}
                        >
                          REP
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                )}
              </div>

              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px 0 16px",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  subheader: {
    padding: "16px 0",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
  },
  arrayField: {
    marginBottom: 24,
  },
  materialRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  removeWrapper: {
    marginLeft: 16,
    width: "fit-content",
    maxWidth: "fit-content",
    color: theme.palette.error.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
  remove: {
    color: theme.palette.error.main,
  },
  repSpacer: {
    height: 12,
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
