import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FormApi } from "final-form";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  composeValidators,
  requiredField,
  twoDecimalsOnly,
} from "../../../lib";
import {
  APIRes,
  GenericObject,
  SeatingMapLevels,
  ToastTypes,
} from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  CircularLoader,
  NullableField,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import classNames from "classnames";
import { Map } from "./Map";

type Props = {
  id: any;
  onClose: () => void;
  refreshList: () => void;
  level: SeatingMapLevels;
};

export function EditSeatingMapSection({
  id,
  onClose: _onClose,
  refreshList,
  level,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  useEffect(() => {
    const fetch = async () => {
      const url = `/seating_map_sections/${id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setInitialValues(data);
      setLoading(false);
    };
    if (id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async (values: any, _form: FormApi, complete: any) => {
    const {
      seating_map_id,
      section_name,
      allow_open_seating,
      open_seating_price,
      open_seating_ticket_msg,
      allow_assigned_seating,
      assigned_seating_price,
      assigned_seating_ticket_msg,
      rows,
      redoRows,
    } = values;
    const update = {
      seating_map_id,
      section_name,
      allow_open_seating,
      open_seating_price,
      open_seating_ticket_msg,
      allow_assigned_seating,
      assigned_seating_price,
      assigned_seating_ticket_msg,
      rows,
      redoRows: Boolean(redoRows),
    };

    const url = `/seating_map_sections/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    refreshList();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div>Edit section</div>
          <IconButton className={classes.close} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {loading && (
          <div className={classes.loadingWrapper}>
            <CircularLoader show />
          </div>
        )}

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form, values, pristine }) => {
            const { allow_open_seating, allow_assigned_seating } = values;
            return (
              <form
                onSubmit={handleSubmit}
                className={classNames(
                  classes.form,
                  loading && classes.hideForm,
                )}
              >
                <div className={classes.mainContent}>
                  <Field
                    component={TextFieldWrapper}
                    name="section_name"
                    label="Section name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  {/* OPEN SEATING */}
                  <div className={classes.toggleNegativeMargin} />
                  <div className={classes.toggle}>
                    <SwitchField
                      fieldName="allow_open_seating"
                      label="Allow open seating"
                    />
                  </div>
                  {allow_open_seating && (
                    <div className={classes.inputGroup}>
                      <Field
                        name="open_seating_price"
                        component={TextFieldWrapper}
                        label="Open seating price"
                        validate={composeValidators(
                          requiredField,
                          twoDecimalsOnly,
                        )}
                        className={classes.halfInput}
                        type="number"
                      />
                      <NullableField
                        name="open_seating_ticket_msg"
                        component={TextFieldWrapper}
                        label="Open seating ticket message"
                        className={classes.halfInput}
                      />
                    </div>
                  )}

                  {/* ASSIGNED SEATING */}
                  <div className={classes.toggle}>
                    <SwitchField
                      fieldName="allow_assigned_seating"
                      label="Allow assigned seating"
                    />
                  </div>
                  {allow_assigned_seating && (
                    <div className={classes.inputGroup}>
                      <Field
                        name="assigned_seating_price"
                        component={TextFieldWrapper}
                        label="Assigned seating price"
                        validate={composeValidators(
                          requiredField,
                          twoDecimalsOnly,
                        )}
                        className={classes.halfInput}
                        type="number"
                      />
                      <NullableField
                        name="assigned_seating_ticket_msg"
                        component={TextFieldWrapper}
                        label="Assigned seating ticket message"
                        className={classes.halfInput}
                      />
                    </div>
                  )}

                  <div className={classes.mapHeader}>Map</div>
                  <Map level={level} />
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}

                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting || pristine}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 900,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    position: "sticky",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    top: 0,
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      borderBottom: "none",
    },
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      marginRight: -4,
      marginTop: -4,
    },
  },
  loadingWrapper: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  hideForm: {
    display: "none",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    padding: "24px 24px 0 24px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 0 16px",
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      width: "100%",
    },
  },
  toggleNegativeMargin: {
    marginTop: -8,
  },
  toggle: {
    marginBottom: 12,
  },
  mapHeader: {
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: "16px 0",
    fontWeight: 500,
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
