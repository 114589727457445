import {
  isCompanyDataEntry,
  isCompanyOrCompanyAdminOrRep,
  isFulfiller,
  isGivvrCompany,
  isOrg,
  isOrgOrOrgEmployee,
  isSuperOrCompanyOrCompanyAdminOrRep,
  isSuperOrCompanyOrCompanyAdminOrRepImpersonator,
  isVolunteer,
  parseUserToken,
  roles,
} from "../../lib";
import { userTypes } from "./actions";

const userReducers = (state = { ...parseUserToken() }, action) => {
  switch (action.type) {
    case userTypes.USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case userTypes.CLEAR_USER_INFO:
      return {};
    case userTypes.ADD_PREVIEWS_TO_USER:
      return {
        ...state,
        ...action.payload,
      };
    case userTypes.SEASON_ID_CHANGE:
      return { ...state, season_id: action.payload };
    case userTypes.SEASON_OPTIONS_SET:
      return { ...state, companySeasonOptions: action.payload };
    case userTypes.ASSOCIATED_VOLUNTEERS_SET:
      return { ...state, associatedVolunteers: action.payload };
    default:
      return state;
  }
};

const getRoleBooleans = state => {
  const role = state.user.role;
  let isSuperAdmin = false;
  let isCompany = false;
  let isRep = false;
  let isCompanyAdmin = false;
  switch (role) {
    case roles.SUPER_ADMIN:
      isSuperAdmin = true;
      break;
    case roles.COMPANY:
      isCompany = true;
      break;
    case roles.REP:
      isRep = true;
      break;
    case roles.COMPANY_ADMIN:
      isCompanyAdmin = true;
      break;
    default:
  }
  const isCompanyOrCompanyAdmin = Boolean(isCompany || isCompanyAdmin);
  return {
    isSuperAdmin,
    isCompany,
    isRep,
    isCompanyAdmin,
    isCompanyOrCompanyAdmin,
  };
};

// super-admin in his company-role mode has owner permissions to do things other company's can't do
const getIsOwner = state => {
  const { isOwner, role } = state.user;

  return Boolean(isOwner || role === roles.SUPER_ADMIN);
};

const getIsOwnerOrGivvr = state => {
  const { isOwner, role, companyId } = state.user;
  return Boolean(
    isOwner ||
      role === roles.SUPER_ADMIN ||
      (isGivvrCompany(companyId) && role === roles.COMPANY),
  );
};

const getIsGivvr = state => {
  return isGivvrCompany(state.user.companyId);
};

const getIsSuperAdminOrSuperAdminImpersonator = state => {
  const { origImpersonatorRole, role, isOwner } = state.user;

  return Boolean(
    role === roles.SUPER_ADMIN ||
      isOwner ||
      origImpersonatorRole === roles.SUPER_ADMIN,
  );
};

const getIsRepOrHigherUser = state => {
  const { isOwner, role, origImpersonatorRole } = state.user;
  return Boolean(
    isOwner ||
      isSuperOrCompanyOrCompanyAdminOrRep(role) ||
      isSuperOrCompanyOrCompanyAdminOrRepImpersonator(origImpersonatorRole),
  );
};

const getIsAnyCompanyPositionOrRepUser = state => {
  const role = state.user.role;
  return Boolean(
    isCompanyOrCompanyAdminOrRep(role) ||
      isFulfiller(role) ||
      isCompanyDataEntry(role),
  );
};

const getSeasonId = state => {
  const { season_id } = state.user;
  return season_id ? season_id : "";
};

const getSupplierHasDistributors = state => {
  return Boolean(state.user.supplierHasDistributors);
};

const getIsSuperAdminImpersonator = state => {
  return state.user.origImpersonatorRole === roles.SUPER_ADMIN;
};

const getVolunteerId = state => {
  return state.user.volunteerId;
};

const getOrgId = state => {
  return state.user.orgId;
};

const getIsVolunteer = state => {
  return isVolunteer(state.user.role);
};

const getIsOrgOrEmp = state => {
  return isOrgOrOrgEmployee(state.user.role);
};

const getIsCoDataEntry = state => {
  return isCompanyDataEntry(state.user.role);
};

const getIsOrgAdmin = state => {
  return isOrg(state.user.role);
};

const getCanManage = state => {
  return Boolean(state.user.canManage);
};

export {
  userReducers,
  getRoleBooleans,
  getIsOwner,
  getIsOwnerOrGivvr,
  getIsGivvr,
  getIsSuperAdminOrSuperAdminImpersonator,
  getIsRepOrHigherUser,
  getSeasonId,
  getIsAnyCompanyPositionOrRepUser,
  getSupplierHasDistributors,
  getIsSuperAdminImpersonator,
  getVolunteerId,
  getOrgId,
  getIsVolunteer,
  getIsOrgOrEmp,
  getIsOrgAdmin,
  getCanManage,
  getIsCoDataEntry,
};
