import { useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import AddIcon from "@material-ui/icons/Add";
import { useIsDesktop, useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { NoEvents } from "../../../assets";
import { EventRows } from "./EventRows";
import {
  eventGroupEventSearchFields,
  eventGroupEventSortFields,
} from "../../../types/events";
import { AddEvent } from "./AddEvent";

type Props = {
  groupId: any;
};
export function Events({ groupId }: Props) {
  const classes = styles();
  const [showSort, setShowSort] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  useScrollToTop();

  const listProps = useList({
    baseUrl: "/event_group_events",
    isReady: true,
    initialState: {
      sort: "created_at",
      order: "desc",
      event_group_id: groupId,
    },
  });
  const { listIsFiltered, total, noResults, showRows, refreshList } = listProps;

  return (
    <ListContainer listProps={listProps} noStyling>
      {isMobile && (
        <div className={classes.topSection}>
          <div className={classes.count}>
            {total} Event
            {total !== 1 ? "s" : ""}
          </div>

          <div>
            <IconButton
              onClick={() => setShowSort(true)}
              className={classes.sort}
            >
              <SwapVertIcon />
            </IconButton>
            <IconButton onClick={() => setShowAdd(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <MultiSearch
          listProps={listProps}
          fields={eventGroupEventSearchFields}
        />
        {isDeskTop && (
          <div>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
            >
              Event
            </Button>
          </div>
        )}
      </div>

      {noResults && (
        <ListNoResults
          SVG={NoEvents}
          listIsFiltered={listIsFiltered}
          label="events"
        />
      )}

      {showRows && <EventRows listProps={listProps} />}

      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={eventGroupEventSortFields}
        />
      )}
      {showAdd && (
        <AddEvent
          event_group_id={groupId}
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  sort: {
    marginRight: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 16px 16px",
    },
  },
}));
