import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { APIRes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { CircularLoader, ResponsiveModal } from "../../../components";
import { useDrawerTransition } from "../../../hooks/ui";
import { Fragment, useEffect, useState } from "react";
import { APIAuthClient } from "../../../lib";
import { getCampaignId, setToast } from "../../../state";

type Props = { onClose: () => void };
export function PreviewAutoTexts({ onClose: _onClose }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const campaignId = useSelector(getCampaignId);
  const [loading, setLoading] = useState(true);
  const [previews, setPreviews] = useState<
    { label: string; preview: string }[]
  >([]);

  useEffect(() => {
    const url = `/communications/campaign_contact_text_communications_preview?campaign_id=${campaignId}`;
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setPreviews(data);
      setLoading(false);
    };
    if (campaignId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.titleRow}>
          <div>Automated messaging details</div>
          <div className={classes.close}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className={classes.msg}>
          {`When you enroll contacts in automated texts we will send them text message(s).`}
        </div>

        {loading && (
          <div className={classes.loader}>
            <CircularLoader show />
          </div>
        )}

        {previews.map(({ label, preview }) => {
          return (
            <Fragment key={label}>
              <div className={classes.subheader}>{label}</div>
              <div className={classes.preview}>{preview}</div>
            </Fragment>
          );
        })}
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    minHeight: 200,
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: 16,
      paddingTop: 42,
    },
  },
  titleRow: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "32px",
  },
  close: {
    marginTop: -12,
    marginRight: -12,
    [theme.breakpoints.down("sm")]: {
      marginTop: -32,
      marginRight: -6,
    },
  },
  msg: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
  loader: {
    margin: "24px 0",
  },
  subheader: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    marginTop: 24,
    marginBottom: 8,
  },
  preview: {
    backgroundColor: "#F5F5F5",
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
}));
