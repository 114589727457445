import {
  FormControlLabel,
  Switch,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import { FieldRenderProps, Field } from "react-final-form";

type Props = {
  fieldName: string;
  label: string | React.ReactNode;
  disabled?: boolean;
};
export function SwitchField({ fieldName, label, disabled }: Props) {
  const classes = styles();
  return (
    <FormControl>
      <FormControlLabel
        className={classes.label}
        control={
          <Field
            component={SwitchWrapper}
            name={fieldName}
            type="checkbox"
            disabled={Boolean(disabled)}
          />
        }
        label={label}
      />
    </FormControl>
  );
}

export function SwitchWrapper(props: FieldRenderProps<any, any>) {
  const {
    input: { checked, name, onChange, ...restInput },
    ...rest
  } = props;

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      {...rest}
      name={name}
      inputProps={restInput}
    />
  );
}

const styles = makeStyles(_theme => ({
  label: {
    marginRight: 0,
  },
}));
