// cSpell:ignore Phonelink, Swipeable, Overridable
import {
  makeStyles,
  SwipeableDrawer,
  IconButton,
  Theme,
  Button,
  SvgIconTypeMap,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link, useHistory } from "react-router-dom";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CampaignSetupIcon from "@material-ui/icons/PersonalVideoOutlined";
import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import LeaderboardIcon from "@material-ui/icons/InsertChartOutlined";
import TeamsIcon from "@material-ui/icons/GroupWorkOutlined";
import StudentsIcon from "@material-ui/icons/PeopleOutlined";
import EventIcon from "@material-ui/icons/EventOutlined";
import EventSeatIcon from "@material-ui/icons/EventSeatOutlined";
import { ReactComponent as EmployeesIcon } from "../assets/EmployeesIcon.svg";
import SubmissionsIcon from "@material-ui/icons/EmailOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import EventTicketIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import MaterialIcon from "@material-ui/icons/PermMediaOutlined";
import GiveawayIcon from "@material-ui/icons/SportsEsportsOutlined";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  fromMenuQueyParam,
  paths,
} from "../types";
import {
  getHasEvents,
  getIsProductCampaign,
  getShowLeaderboard,
  getVolunteerLabel,
  toggleSidebar,
} from "../state";
// import { WebpageQR } from "../components";
import { RootState } from "../types/state";
import { useIsMobile } from "../hooks/ui";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  getCompanyFavicon32Href,
  getCompanyLogoHref,
  getIsImpersonating,
} from "../lib";
import { Fragment, useState } from "react";
import { CampaignSelector } from "./CampaignSelector";
import { Account } from "./Account";
import {
  getIsOrgAdmin,
  getIsOrgOrEmp,
  getIsVolunteer,
} from "../../customState";

export function Sidebar() {
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = useHistory();
  const isMobile = useIsMobile();
  const sidebarOpen = useSelector((state: RootState) => state.ui.sidebarOpen);
  const isProduct = useSelector(getIsProductCampaign);
  const isVolunteer = useSelector(getIsVolunteer);
  const isOrgOrEmp = useSelector(getIsOrgOrEmp);
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const { volunteerId, companyId } = useSelector((state: any) => state.user);
  const showLB = useSelector(getShowLeaderboard);
  const { volunteerLabel } = useSelector(getVolunteerLabel);
  const hasEvents = useSelector(getHasEvents);
  const hasMaterialBundle = useSelector(
    ({ campaign }: RootState) => campaign.hasMaterialBundle,
  );
  const campaignGiveawayId = useSelector(
    ({ campaign }: RootState) => campaign.campaignGiveawayId,
  );
  const isImpersonating = getIsImpersonating();
  const classes = styles({ sidebarOpen });
  const [showAccount, setShowAccount] = useState(false);
  const [accountAnchorEl, setAccountAnchorEl] = useState<undefined | Element>(
    undefined,
  );
  const companyLogoHref = getCompanyLogoHref(companyId);
  const companyFavicon32Href = getCompanyFavicon32Href(companyId);

  const _toggleSidebar = () => dispatch(toggleSidebar());

  const handleAccountClick = (e: any) => {
    setAccountAnchorEl(e.currentTarget);
    setShowAccount(true);
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={isMobile ? sidebarOpen : true}
      onClose={_toggleSidebar}
      onOpen={_toggleSidebar}
      variant={isMobile ? "temporary" : "permanent"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: sidebarOpen,
        [classes.drawerClosed]: !sidebarOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClosed]: !sidebarOpen,
        }),
        paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
      }}
    >
      <div
        className={classes.sidebarToggle}
        onClick={() => dispatch(toggleSidebar())}
      >
        <IconButton size="small">
          {sidebarOpen ? (
            <ChevronLeftIcon color="primary" />
          ) : (
            <ChevronRightIcon color="primary" />
          )}
        </IconButton>
      </div>

      <div className={classes.drawerContent}>
        <div className={classes.mainContent}>
          <div className={classes.topContent}>
            {!sidebarOpen && (
              <img
                alt="logo"
                src={companyFavicon32Href}
                className={classes.favicon}
              />
            )}
            {sidebarOpen && (
              <Fragment>
                <img
                  alt="logo"
                  src={companyLogoHref}
                  className={classes.logo}
                />
                <CampaignSelector />
                {isVolunteer && (
                  <div className={classes.sellerId}>
                    Seller ID {volunteerId}
                  </div>
                )}
              </Fragment>
            )}
          </div>

          <NavButton
            label="Home"
            Icon={HomeOutlinedIcon}
            link={paths.HOME}
            selected={pathname === paths.HOME}
            sidebarOpen={sidebarOpen}
          />
          <NavButton
            label={isProduct ? "Sales" : "Donations"}
            Icon={MonetizationOnOutlinedIcon}
            link={paths.DONATIONS}
            selected={pathname === paths.DONATIONS}
            sidebarOpen={sidebarOpen}
          />
          {isVolunteer && (
            <NavButton
              label="Contacts"
              Icon={ContactsOutlinedIcon}
              link={paths.CONTACTS}
              selected={pathname === paths.CONTACTS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isVolunteer && (
            <NavButton
              label="Campaign Setup"
              Icon={CampaignSetupIcon}
              link={`${paths.VOLUNTEER_GUIDED_SETUP}?${fromMenuQueyParam}=true`}
              selected={pathname === paths.VOLUNTEER_GUIDED_SETUP}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isOrgOrEmp && (
            <NavButton
              label={volunteerLabel}
              Icon={StudentsIcon}
              link={paths.VOLUNTEERS}
              selected={pathname === paths.VOLUNTEERS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isOrgOrEmp && (
            <NavButton
              label="Teams"
              Icon={TeamsIcon}
              link={paths.TEAMS}
              selected={pathname === paths.TEAMS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {hasMaterialBundle && isOrgOrEmp && (
            <NavButton
              label="Media Center"
              Icon={MaterialIcon}
              link={paths.MEDIA_CENTER}
              selected={pathname === paths.MEDIA_CENTER}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isOrgOrEmp && (
            <NavButton
              label="Campaign Settings"
              Icon={SettingsOutlinedIcon}
              link={paths.ORG_CAMPAIGN_SETTINGS}
              selected={pathname === paths.ORG_CAMPAIGN_SETTINGS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isOrgOrEmp && (
            <NavButton
              label="Submissions"
              Icon={SubmissionsIcon}
              link={paths.SUBMISSIONS}
              selected={pathname === paths.SUBMISSIONS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {showLB && (
            <NavButton
              label="Leaderboard"
              Icon={LeaderboardIcon}
              link={paths.LEADERBOARD}
              selected={pathname === paths.LEADERBOARD}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isOrgAdmin && (
            <NavButton
              label="Employees"
              Icon={EmployeesIcon}
              link={paths.ORG_EMPLOYEES}
              selected={pathname === paths.ORG_EMPLOYEES}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isImpersonating && isOrgAdmin && (
            <NavButton
              label="Seating Maps"
              Icon={EventSeatIcon}
              link={paths.EVENT_SEATING}
              selected={pathname === paths.EVENT_SEATING}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isImpersonating && isOrgAdmin && (
            <NavButton
              label="Events"
              Icon={EventIcon}
              link={paths.EVENTS}
              selected={pathname === paths.EVENTS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {hasEvents && isOrgOrEmp && (
            <NavButton
              label="Event Tickets"
              Icon={EventTicketIcon}
              link={paths.EVENT_TICKETS}
              selected={pathname === paths.EVENT_TICKETS}
              sidebarOpen={sidebarOpen}
            />
          )}
          {isOrgOrEmp && (isImpersonating || campaignGiveawayId) && (
            <NavButton
              label="Giveaways"
              Icon={GiveawayIcon}
              link={paths.GIVEAWAYS}
              selected={pathname === paths.GIVEAWAYS}
              sidebarOpen={sidebarOpen}
            />
          )}
          <NavButton
            label="Account"
            Icon={AccountIcon}
            selected={showAccount}
            sidebarOpen={sidebarOpen}
            onClick={handleAccountClick}
          />
        </div>

        {/* <div className={classes.qrContainer}>
          <WebpageQR />
        </div> */}
      </div>
      <Account
        isOpen={showAccount}
        onClose={() => setShowAccount(false)}
        anchorEl={accountAnchorEl}
      />
    </SwipeableDrawer>
  );
}

type NavButtonProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  link?: string;
  selected: boolean;
  sidebarOpen: boolean;
  onClick?: (e: any) => void;
};
function NavButton({
  label,
  Icon,
  link,
  selected,
  sidebarOpen,
  onClick,
}: NavButtonProps) {
  const classes = styles({});
  const navProps: {
    onClick?: (e: any) => void;
    to?: string;
    component?: any;
  } = {};
  if (onClick) navProps.onClick = (e: any) => onClick(e);
  else if (link) {
    navProps.to = link;
    navProps.component = Link;
  }

  if (sidebarOpen) {
    return (
      <Button
        fullWidth
        size="large"
        variant="text"
        className={classNames(
          classes.button,
          selected && classes.selectedButton,
        )}
        startIcon={
          <Icon
            className={classNames(
              classes.startIcon,
              selected && classes.selectedStartIcon,
            )}
          />
        }
        {...navProps}
      >
        {label}
      </Button>
    );
  }
  return (
    <div>
      <IconButton
        {...navProps}
        className={classNames(
          classes.iconButton,
          selected && classes.selectedIcon,
        )}
      >
        <Icon />
      </IconButton>
    </div>
  );
}

type StyleProps = {
  sidebarOpen?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  drawer: {
    width: SIDEBAR_OPEN_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerOpen: {
    width: SIDEBAR_OPEN_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClosed: {
    width: SIDEBAR_CLOSED_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },
  sidebarToggle: {
    position: "absolute",
    height: 32,
    width: 32,
    borderRadius: "100%",
    border: "1px solid #DBDEEE",
    backgroundColor: "#FFFFFF",
    zIndex: theme.zIndex.drawer + 1,
    top: 104,
    right: -16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100%",
    justifyContent: "space-between",
    padding: ({ sidebarOpen }) =>
      sidebarOpen ? "24px 16px 16px 16px" : "24px 0 16px 0",
    overflowX: "hidden",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  paperAnchorDockedLeft: {
    borderRight: "1px solid #DBDEEE",
    overflow: "visible",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topContent: {
    marginBottom: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: ({ sidebarOpen }) => (sidebarOpen ? "flex-start" : "center"),
    width: "100%",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: 60,
    marginBottom: 12,
  },
  sellerId: {
    fontSize: 14,
    letterSpacing: 0.1,
    color: theme.palette.text.secondary2,
    marginTop: 8,
  },
  button: {
    fontSize: 16,
    letterSpacing: 0.15,
    marginBottom: 16,
    color: theme.palette.text.secondary,
    paddingLeft: 18,
    fontWeight: 400,
    backgroundColor: "unset",
    justifyContent: "flex-start",
    borderRadius: 22,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 8,
    fontSize: "24px !important",
    color: theme.palette.text.secondary,
  },
  iconButton: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
  selectedIcon: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: "#E3F2FE !important",
    "&:hover": {
      backgroundColor: "#E3F2FE !important",
      opacity: 0.8,
    },
  },
  selectedButton: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 500,
    backgroundColor: "#E3F2FE !important",
    "&:hover": {
      backgroundColor: "#E3F2FE !important",
      opacity: 0.8,
    },
  },
  selectedStartIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  qrContainer: {
    display: ({ sidebarOpen }) => (sidebarOpen ? "flex" : "none"),
    width: "100%",
  },
  favicon: {
    maxWidth: 40,
    maxHeight: 40,
  },
}));
