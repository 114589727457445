import {
  CircularLoader,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { Form, Field, useField } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { useAppDispatch } from "../../../types/state";
import { getCampaignId, setToast } from "../../../state";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import {
  APIAuthClient,
  composeValidators,
  formatMoneyUnPadded,
  nonEmptyArray,
  requiredField,
  twoDecimalsOnly,
} from "../../../lib";
import { useState, useEffect } from "react";
import { Button, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import classNames from "classnames";
type SchoolathonData = {
  clubMemberProductOptions?: { id: number; product_name: string }[];
  clubMembers?: {
    id: number;
    first_name: string;
    last_name: string;
    product_name: string;
  }[];
  currentPrizePick?: string;
  prizeOptions?: { id: number; name: string }[];
  raised?: number;
};

type Props = { onClose: () => void; record: GenericObject };
export function SchoolathonManage({ onClose: _onClose, record }: Props) {
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const { id: volunteer_id, first_name, last_name } = record;
  const dispatch = useAppDispatch();
  const classes = styles();
  const campaignId = useSelector(getCampaignId);
  const isMobile = useIsMobile();
  const [data, setData] = useState<SchoolathonData>({});
  const [loaded, setLoaded] = useState(false);
  const {
    clubMemberProductOptions = [],
    clubMembers = [],
    currentPrizePick = "",
    prizeOptions = [],
    raised = 0,
  } = data;

  useEffect(() => {
    if (campaignId && volunteer_id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, volunteer_id]);

  const fetchData = async () => {
    const url = `/volunteer_campaigns/schoolathon_student_management_data?campaign_id=${campaignId}&volunteer_id=${volunteer_id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) {
      return dispatch(setToast(errorMessage));
    }
    setData(data);
    setLoaded(true);
  };

  const submit = async (values: GenericObject) => {
    const url = "/volunteer_campaigns/schoolathon_student_management_data";
    const body = { campaign_id: campaignId, volunteer_id, ...values };
    const res = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.top}>
        <div className={classes.title}>Manage Student</div>
        <div className={classes.name}>
          {first_name} {last_name}
        </div>
        <IconButton onClick={onClose} className={classes.cancel}>
          <CloseIcon />
        </IconButton>
      </div>

      {!loaded && (
        <div className={classes.loadingContainer}>
          <CircularLoader show />
        </div>
      )}

      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={submit}
        render={({ handleSubmit, form, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={classNames(
                  classes.content,
                  !loaded && classes.hideContent,
                )}
              >
                {/* DONATIONS */}
                <div className={classes.sectionWrapper}>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      In-hand donations
                    </div>
                    <div className={classes.donationTxt}>
                      Enter in-hand donations separately or as a lump sum
                    </div>

                    <FieldArray name="cashDonations">
                      {({ fields }) => (
                        <div>
                          {fields.map((row, index) => {
                            return (
                              <div key={index} className={classes.inputRow}>
                                <Field
                                  name={`${row}.first_name`}
                                  component={TextFieldWrapper}
                                  placeholder="First name*"
                                  validate={requiredField}
                                  className={classes.input}
                                />
                                <Field
                                  name={`${row}.last_name`}
                                  component={TextFieldWrapper}
                                  placeholder="Last name*"
                                  validate={requiredField}
                                  className={classes.input}
                                />

                                <Field
                                  name={`${row}.amount`}
                                  component={TextFieldWrapper}
                                  placeholder="Amount*"
                                  validate={composeValidators(
                                    requiredField,
                                    twoDecimalsOnly,
                                  )}
                                  className={classes.largeInput}
                                  type="number"
                                />

                                <div className={classes.removeWrapper}>
                                  <CloseIcon
                                    onClick={() => fields.remove(index)}
                                    color="error"
                                    className={classes.remove}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          <div>
                            <Button
                              color="primary"
                              className={classes.add}
                              startIcon={<AddIcon />}
                              onClick={() => fields.push({})}
                            >
                              Donation
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <div className={classes.sectionSpacer} />
                </div>

                {/* CLUB MEMBERS */}
                <div className={classes.sectionWrapper}>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      Color club members
                    </div>
                    <MemberStats prevRaised={raised as number} />

                    <FieldArray name="clubMembers">
                      {({ fields }) => (
                        <div>
                          {fields.map((row, index) => {
                            return (
                              <div key={index} className={classes.inputRow}>
                                <Field
                                  name={`${row}.first_name`}
                                  component={TextFieldWrapper}
                                  placeholder="First name*"
                                  validate={requiredField}
                                  className={classes.input}
                                />
                                <Field
                                  name={`${row}.last_name`}
                                  component={TextFieldWrapper}
                                  placeholder="Last name*"
                                  validate={requiredField}
                                  className={classes.input}
                                />

                                <Field
                                  select
                                  name={`${row}.product_id`}
                                  component={TextFieldWrapper}
                                  placeholder="Select a T-shirt"
                                  validate={requiredField}
                                  className={classes.largeInput}
                                  SelectProps={{
                                    displayEmpty: true,
                                    renderValue: (selected: any) => {
                                      if (selected === "") {
                                        return (
                                          <span className={classes.placeholder}>
                                            T-shirt size*
                                          </span>
                                        );
                                      }
                                      const member = clubMemberProductOptions.find(
                                        ({ id }) => selected === id,
                                      );
                                      if (member) return member.product_name;
                                      return "";
                                    },
                                  }}
                                >
                                  {clubMemberProductOptions.map(
                                    ({ id, product_name }) => (
                                      <MenuItem key={id} value={id}>
                                        {product_name}
                                      </MenuItem>
                                    ),
                                  )}
                                </Field>

                                <div className={classes.removeWrapper}>
                                  <CloseIcon
                                    onClick={() => fields.remove(index)}
                                    color="error"
                                    className={classes.remove}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          <div>
                            <Button
                              color="primary"
                              className={classes.add}
                              startIcon={<AddIcon />}
                              onClick={() => fields.push({})}
                            >
                              Member
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>

                    <div className={classes.currMembers}>
                      Registered color club members
                    </div>
                    {clubMembers.length < 1 && (
                      <div className={classes.noMembers}>No members yet</div>
                    )}
                    {clubMembers.length > 0 && (
                      <div className={classes.currMembersP}>
                        Changes can be made from the corresponding order in the
                        sales list. To update a t-shirt size please delete the
                        order and re-add the member above. To remove a member,
                        delete the order.
                      </div>
                    )}
                    {clubMembers.length > 0 && (
                      <div className={classes.currMembersList}>
                        {!isMobile && (
                          <div
                            className={classNames(
                              classes.currMemberRow,
                              classes.currMemberHeader,
                            )}
                          >
                            <div>First name</div>
                            <div>Last name</div>
                            <div>Order ID</div>
                            <div>T-shirt</div>
                          </div>
                        )}
                        {clubMembers.map((m, index) => {
                          const { first_name, last_name, id, product_name } = m;
                          return (
                            <div key={id} className={classes.currMemberRow}>
                              {isMobile && index > 0 && (
                                <div className={classes.currMemberRowSpacer} />
                              )}
                              <div className={classes.memberName}>
                                {first_name}
                                {isMobile && ` ${last_name}`}
                              </div>
                              {!isMobile && <div>{last_name}</div>}
                              <div className={classes.memberOrderId}>{id}</div>
                              <div>{product_name}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className={classes.sectionSpacer} />
                </div>

                {/* TEE SHIRT */}
                <div className={classes.sectionWrapper}>
                  <div className={classes.section}>
                    <div className={classes.sectionHeader}>
                      Student T-shirt size
                    </div>
                    {currentPrizePick && (
                      <div className={classes.prevPrize}>
                        <span className={classes.prevPrizeLabel}>
                          Previously selected:
                        </span>{" "}
                        {currentPrizePick}
                      </div>
                    )}

                    <Field
                      select
                      name="prize_product_id"
                      component={TextFieldWrapper}
                      className={classes.teeInput}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (selected: any) => {
                          if (selected === "") {
                            return (
                              <span className={classes.placeholder}>
                                {currentPrizePick && "Update "}Student T-shirt
                                size
                              </span>
                            );
                          }
                          const prize = prizeOptions.find(
                            ({ id }) => selected === id,
                          );
                          if (prize) return prize.name;
                          return "";
                        },
                      }}
                    >
                      {prizeOptions.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Field>
                  </div>
                </div>

                {/* ACTIONS */}
                <div className={classes.actions}>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={onClose}
                    disabled={submitting}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color="primary"
                    className={classes.save}
                    onClick={() => form.submit()}
                    disabled={submitting || pristine}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

function MemberStats({ prevRaised }: { prevRaised: number }) {
  const donations = useField("cashDonations").input.value;
  const classes = styles();
  const [raised, setRaised] = useState("");
  const [allowed, setAllowed] = useState(0);

  useEffect(() => {
    let addedAmount = 0;
    if (nonEmptyArray(donations)) {
      (donations as any).forEach(({ amount }) => {
        addedAmount = addedAmount + Number(amount || 0);
      });
    }
    const total = (prevRaised || 0) + addedAmount;
    // 1 member for every $30 raised starting at $60 with a max of 6 members
    let _allowed = Math.floor((total - 30) / 30);
    _allowed = _allowed < 1 ? 0 : _allowed > 6 ? 6 : _allowed;
    setRaised(formatMoneyUnPadded(total));
    setAllowed(_allowed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donations, prevRaised]);

  return (
    <div className={classes.memberStats}>
      <div className={classes.memberStatsRow}>
        <div className={classes.memberStatsLabel}>Total raised</div>
        <div>{raised}</div>
      </div>
      <div className={classes.memberStatsRow}>
        <div className={classes.memberStatsLabel}>Club members allowed</div>
        <div>{allowed}</div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  top: {
    width: 800,
    maxWidth: "100%",
    padding: "16px 40px 24px 24px",
    borderBottom: "1px solid #eaebf3",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 16px 16px 16px",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "32px",
    letterSpacing: 0.15,
  },
  name: {
    fontSize: 16,
    letterSpacing: 0.15,
    paddingTop: 8,
  },
  cancel: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  loadingContainer: {
    width: 800,
    maxWidth: "100%",
    minHeight: 200,
    paddingTop: 40,
  },
  content: {
    width: 800,
    maxWidth: "100%",
    paddingTop: 24,
  },
  hideContent: {
    display: "none",
  },
  sectionWrapper: {
    width: "100%",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  section: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  sectionSpacer: {
    borderBottom: `1px solid #dbdeee`,
    paddingTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
      marginBottom: 24,
    },
  },
  sectionHeader: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: 0.11,
    color: theme.palette.primary.main,
  },
  donationTxt: {
    fontSize: 14,
    lineHeight: "24px",
    letterSpacing: 0.13,
    color: theme.palette.text.secondary,
    paddingTop: 8,
    paddingBottom: 24,
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 18,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      border: "1px solid #dbdeee",
      borderRadius: 8,
      padding: 16,
      marginBottom: 16,
    },
  },
  input: {
    width: 170,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  largeInput: {
    width: 316,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  removeWrapper: {
    height: 40,
    display: "flex",
    alignItems: "center",
    marginLeft: -4,
    marginRight: -4,
    [theme.breakpoints.down("sm")]: {
      height: "unset",
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      justifyContent: "flex-end",
    },
  },
  remove: {
    cursor: "pointer",
  },
  add: {
    width: 125,
  },
  placeholder: {
    color: theme.palette.text.secondary2,
  },
  memberStats: {
    marginTop: 16,
    marginBottom: 16,
  },
  memberStatsRow: {
    display: "flex",
    alignItems: "center",
    letterSpacing: 0.15,
    fontSize: 14,
    lineHeight: "18px",
    paddingBottom: 8,
  },
  memberStatsLabel: {
    width: 184,
    minWidth: 184,
    fontWeight: 500,
    letterSpacing: 0.3,
  },
  currMembers: {
    marginTop: 40,
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "18px",
    letterSpacing: 0.34,
  },
  noMembers: {
    fontSize: 14,
    lineHeight: "24px",
    letterSpacing: 0.13,
    color: theme.palette.text.secondary2,
    paddingTop: 8,
  },
  currMembersP: {
    fontSize: 14,
    lineHeight: "24px",
    letterSpacing: 0.13,
    color: theme.palette.text.secondary,
    marginBottom: 24,
  },
  currMembersList: {
    border: "1px solid #c9cdde",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      border: "none",
      borderRadius: "none",
    },
  },
  currMemberRow: {
    padding: "12px 0 12px 16px",
    borderTop: "1px solid #c9cdde",
    minHeight: 56,
    display: "grid",
    gridTemplateColumns: "160px 160px 100px 1fr",
    alignItems: "center",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.15,
    "& > *": {
      paddingRight: 16,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      lineHeight: "20px",
      borderTop: "none",
      padding: 0,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  currMemberHeader: {
    borderTop: "none",
    fontWeight: 500,
  },
  memberName: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 50,
    },
  },
  memberOrderId: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  currMemberRowSpacer: {
    paddingTop: 16,
    borderTop: "1px solid #eaebf3",
    marginTop: 16,
  },
  prevPrize: {
    marginTop: 16,
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.15,
  },
  prevPrizeLabel: {
    fontWeight: 500,
    letterSpacing: 0.3,
  },
  teeInput: {
    marginTop: 24,
    marginBottom: 40,
    width: 366,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 24,
    paddingRight: 24,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "0 16px",
      marginBottom: 16,
    },
  },
  save: {
    width: 120,
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));
