import { Button, Link, makeStyles } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import InviteIcon from "@material-ui/icons/GroupAddOutlined";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import {
  affiliateLink,
  affiliateLinkWithTS,
  copyLinkWithSubAffiliate,
  downloadQRCode,
  facebookLink,
  linkedinLink,
  textMessageLink,
  twitterLink,
  whatsappLink,
} from "../../../lib";
import { CopyLink } from "../../../components";
import {
  AFFILIATE_SC_PARAM,
  AFFILIATE_SUB_CODES,
  AFFILIATES,
} from "../../../../lib";
import { RootState } from "../../../types/state";
import { Fragment, useState } from "react";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { ShareIconSVG } from "../../../assets/ShareIconSVG";
import { FacebookBlueSmallSVG } from "../../../assets/FacebookBlueSmallSVG";
import { TwitterBlueSmallSVG } from "../../../assets/TwitterBlueSmallSVG";
import { LinkedinBlueSmallSVG } from "../../../assets/LinkedinBlueSmallSVG";
import { WhatsappBlueSmallSVG } from "../../../assets/WhatsappBlueSmallSVG";
import { LinksDrawer } from "./LinksDrawer";
import { ShareCardView } from "../../../types/dashboard";
import { getVolunteerLabel } from "../../../state";
import { InstagramBlueSmallSVG } from "../../../assets";
import { InstagramDrawer } from "../../../components/general/InstagramDrawer";
import { useAddInstagram } from "../../../hooks/misc";

type Props = {
  view: ShareCardView;
};

export function Share({ view }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { webpageLink } = useSelector((state: RootState) => state.campaign);
  const link =
    view === ShareCardView.orgSignUp
      ? affiliateLink(
          `${webpageLink}/volunteers/register`,
          AFFILIATES.DASHBOARD_REGISTRATION_LINK,
        )
      : affiliateLinkWithTS(webpageLink, AFFILIATES.DASHBOARD_SHARING_LINKS);

  return (
    <div className={classes.container}>
      {isDesktop && <DesktopContent link={link} view={view} />}
      {isMobile && <MobileContent link={link} view={view} />}
    </div>
  );
}

type ContentProps = {
  view: ShareCardView;
  link: string;
};

function DesktopContent({ view, link }: ContentProps) {
  const classes = styles();
  const { volunteerLabelLC } = useSelector(getVolunteerLabel);
  const { orgName, volunteerName } = useSelector(
    (state: RootState) => state.campaign,
  );

  return (
    <Fragment>
      <div className={classes.top}>
        <div className={classes.topIconWrapper}>
          {view === ShareCardView.orgSignUp ? (
            <InviteIcon color="primary" />
          ) : (
            <ShareIconSVG />
          )}
        </div>

        <h3 className={classes.title}>
          {view === ShareCardView.orgSignUp
            ? `Invite ${volunteerLabelLC}`
            : "Share campaign"}
        </h3>
      </div>

      <div className={classes.mainContent}>
        {view !== ShareCardView.orgSignUp && (
          <Fragment>
            <p className={classes.subtitle}>
              {view === ShareCardView.volunteer
                ? "Share your link via"
                : "Share link"}
            </p>
            <div className={classes.socialIcons}>
              <Link
                className={classes.socialIconWrapper}
                href={facebookLink(link)}
              >
                <FacebookBlueSmallSVG />
              </Link>
              <Link
                className={classes.socialIconWrapper}
                href={twitterLink(
                  link,
                  orgName,
                  view === ShareCardView.volunteer,
                )}
              >
                <TwitterBlueSmallSVG />
              </Link>
              <Link
                className={classes.socialIconWrapper}
                href={linkedinLink(link)}
              >
                <LinkedinBlueSmallSVG />
              </Link>
              <Link
                className={classes.socialIconWrapper}
                href={whatsappLink(
                  link,
                  orgName,
                  view === ShareCardView.volunteer,
                )}
              >
                <WhatsappBlueSmallSVG />
              </Link>
              <Link
                className={classes.socialIconWrapper}
                href={textMessageLink({
                  link,
                  orgName,
                  volunteerName,
                  isVolunteer: view === ShareCardView.volunteer,
                })}
              >
                <SmsOutlinedIcon fontSize="small" />
              </Link>
            </div>
          </Fragment>
        )}

        <p className={classes.subtitle}>
          Copy link {view === ShareCardView.orgSignUp && "to registration form"}
        </p>
        <div className={classes.linkContainer}>
          <div>{copyLinkWithSubAffiliate(link)}</div>
          <CopyLink link={copyLinkWithSubAffiliate(link)}>
            <Button size="small" color="primary" className={classes.copy}>
              Copy
            </Button>
          </CopyLink>
        </div>

        <p className={classes.subtitle}>
          {view === ShareCardView.volunteer && "Share a QR code to your page"}
          {view === ShareCardView.orgSignUp &&
            "Share QR code for registration form"}
          {view === ShareCardView.orgShare && "Share QR code for campaign"}
        </p>
        <div className={classes.qrWrapper}>
          <QRCode
            id={`${view}-bd-qr`}
            value={`${link}&${AFFILIATE_SC_PARAM}=${AFFILIATE_SUB_CODES.QR}`}
            includeMargin={false}
            size={68}
          />
          <div className={classes.qrShare}>
            <span
              className={classes.qrDownload}
              onClick={() => downloadQRCode(`${view}-bd-qr`)}
            >
              Download
            </span>{" "}
            & share
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function MobileContent({ view, link }: ContentProps) {
  const classes = styles();
  const { orgName, volunteerName } = useSelector(
    (state: RootState) => state.campaign,
  );
  const addInstagram = useAddInstagram();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showInstagram, setShowInstagram] = useState(false);

  return (
    <Fragment>
      <h3 className={classes.title}>Share my campaign</h3>
      <div className={classes.socialIcons}>
        <Link className={classes.socialIconWrapper} href={facebookLink(link)}>
          <FacebookBlueSmallSVG />
        </Link>
        <Link
          className={classes.socialIconWrapper}
          href={twitterLink(link, orgName, true)}
        >
          <TwitterBlueSmallSVG />
        </Link>
        <Link className={classes.socialIconWrapper} href={linkedinLink(link)}>
          <LinkedinBlueSmallSVG />
        </Link>
        <Link
          className={classes.socialIconWrapper}
          href={whatsappLink(link, orgName, view === ShareCardView.volunteer)}
        >
          <WhatsappBlueSmallSVG />
        </Link>
        <Link
          className={classes.socialIconWrapper}
          href={textMessageLink({
            link,
            orgName,
            volunteerName,
            isVolunteer: view === ShareCardView.volunteer,
          })}
        >
          <SmsOutlinedIcon fontSize="small" />
        </Link>
        {addInstagram && (
          <div
            className={classes.socialIconWrapper}
            onClick={() => setShowInstagram(true)}
          >
            <InstagramBlueSmallSVG />
          </div>
        )}
      </div>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<InsertLinkIcon />}
        onClick={() => setShowDrawer(view === ShareCardView.volunteer)}
      >
        Tap for link & QR code
      </Button>

      {showDrawer && (
        <LinksDrawer onClose={() => setShowDrawer(false)} view={view} />
      )}
      {showInstagram && (
        <InstagramDrawer onClose={() => setShowInstagram(false)} link={link} />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    position: "relative", // DON"T REMOVE
    zIndex: 99, // DON"T REMOVE
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 80,
    borderBottom: "1px solid #DBDEEE",
    paddingLeft: 24,
  },
  topIconWrapper: {
    height: 48,
    minWidth: 48,
    borderRadius: 8,
    backgroundColor: "#F1F9FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.12,
    [theme.breakpoints.down("sm")]: {
      letterSpacing: 0.11,
    },
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.11,
    lineHeight: "20px",
  },
  mainContent: {
    padding: 24,
  },
  socialIcons: {
    width: 296,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 360,
      marginBottom: 16,
    },
  },
  socialIconWrapper: {
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  linkContainer: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    padding: "14px 16px 14px 12px",
    margin: "24px 0",
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.11,
    lineHeight: "17px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowWrap: "anywhere",
  },
  copy: {
    width: 80,
    minWidth: 80,
    marginLeft: 16,
    borderRadius: 15,
  },
  qrWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 24,
  },
  qrShare: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    marginLeft: 24,
  },
  qrDownload: {
    lineHeight: "24px",
    color: theme.palette.primary.main,
    marginRight: 6,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
  },
}));
