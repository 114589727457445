import { Fragment, ReactNode, useEffect, useState } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "../theme/quilljs.css";
import { Header } from "./Header";
import { theme } from "../theme/theme";
import { BottomNav } from "./BottomNav";
import { Sidebar } from "./Sidebar";
import { Toast } from "../components";
import {
  APIAuthClient,
  pathAuthCheck,
  scrollToTop,
  validJWTCheck,
} from "../lib";
import {
  APIRes,
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  WHITE_BACKGROUND_PATHS,
  paths,
} from "../types";
import { campaignActions, setToast, toggleSidebar, uiActions } from "../state";
import {
  RA_SUB_PATH,
  isNewAppUser,
  isOrgOrVolunteer,
  isVolunteer,
} from "../../lib";
import { RootState } from "../types/state";
import {
  useIsDesktop,
  useIsMedScreen,
  useIsMobile,
  useShouldRedirectToVolunteerSetup,
} from "../hooks/ui";
import classNames from "classnames";
import { JoinCampaignDialog } from "./JoinCampaignDialog";
import { useCampaignSwitchController } from "./useCampaignSwitchController";
import { setAssociatedVolunteers } from "../../customState";

export function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <_Layout>{children}</_Layout>
    </ThemeProvider>
  );
}

type LayoutProps = {
  children: ReactNode;
};
function _Layout({ children }: LayoutProps) {
  const { role, allow_associated_volunteer_accounts } = useSelector(
    (state: RootState) => state.user,
  );
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { path } = useRouteMatch();
  const {
    location: { pathname, search },
    replace,
  } = useHistory();
  const sidebarOpen = useSelector((state: RootState) => state.ui.sidebarOpen);
  const redirectToVolunteerSetup = useShouldRedirectToVolunteerSetup();
  const isLMedScreen = useIsMedScreen();
  const isDesktop = useIsDesktop();
  const [useCampaignId, setUseCampaignId] = useState<null | number>(null);
  const [showMenu, setShowMenu] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [joinCampaignId, setJoinCampaignId] = useState<null | number>(null);
  const [whiteBG, setWhiteBG] = useState(false);

  useEffect(() => {
    if (!validJWTCheck()) {
      dispatch(
        setToast("Your session has expired, please log back in to continue."),
      );
      replace(`${paths.LOGIN}${search}`, {
        nextPathname: pathname,
        nextSearch: search,
      });
      return;
    }

    // redirect non volunteer-users to old app
    if (!isNewAppUser(role)) {
      window.location.replace(`/${RA_SUB_PATH}`);
    }

    // Due to impersonation capabilities & auth is shared browser wide (across multiple tabs)
    // we don't want to automatically log them out - just redirect them to their home page which every user has access to
    if (!pathAuthCheck(path, role)) {
      return replace(paths.HOME);
    }

    if (
      (isOrgOrVolunteer(role) && !useCampaignId) ||
      redirectToVolunteerSetup ||
      (path === paths.VOLUNTEER_GUIDED_SETUP && isMobile) ||
      path === paths.LEADERBOARD
    ) {
      setShowMenu(false);
      setShowHeader(false);
    } else {
      setShowMenu(true);
      setShowHeader(true);
    }

    if (redirectToVolunteerSetup) replace(paths.VOLUNTEER_GUIDED_SETUP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, path, role, redirectToVolunteerSetup, useCampaignId, isMobile]);

  useEffect(() => {
    if (WHITE_BACKGROUND_PATHS[path]) setWhiteBG(true);
    else setWhiteBG(false);
    scrollToTop();
  }, [path]);

  useEffect(() => {
    if (isLMedScreen && sidebarOpen) dispatch(toggleSidebar());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLMedScreen]);

  useCampaignSwitchController({ setUseCampaignId, setJoinCampaignId });

  useEffect(() => {
    if (useCampaignId && isOrgOrVolunteer(role)) {
      dispatch(campaignActions.getCampaignInfo(useCampaignId));
      dispatch(campaignActions.getOrgCampaignAutocomplete());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, useCampaignId]);

  useEffect(() => {
    if (
      useCampaignId &&
      isVolunteer(role) &&
      allow_associated_volunteer_accounts
    ) {
      getAssociatedVolunteers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, useCampaignId, allow_associated_volunteer_accounts]);

  useEffect(() => {
    const { ao: affiliateOrigin } = queryString.parse(search);
    if (affiliateOrigin && typeof affiliateOrigin === "string") {
      dispatch(uiActions.setAffiliateOrigin(affiliateOrigin));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAssociatedVolunteers = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      "/volunteers/volunteer_associated_volunteers",
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setAssociatedVolunteers(data));
  };

  return (
    <Fragment>
      <div
        className={classNames(
          "newApp",
          classes.wrapper,
          whiteBG && classes.whiteBG,
        )}
      >
        <div className={classes.page}>
          {isDesktop && showMenu && <Sidebar />}
          <div
            className={classes.mainWrapper}
            style={{
              maxWidth:
                isMobile || !showMenu
                  ? "100%"
                  : sidebarOpen
                  ? `calc(100% - ${SIDEBAR_OPEN_WIDTH}px)`
                  : `calc(100% - ${SIDEBAR_CLOSED_WIDTH}px)`,
            }}
          >
            {showHeader && <Header />}
            <main className={classes.main}>{children}</main>
          </div>
        </div>
        {showMenu && <BottomNav />}
      </div>
      <Toast />
      {joinCampaignId && (
        <JoinCampaignDialog
          isOpen={Boolean(joinCampaignId)}
          campaign_id={joinCampaignId}
          onClose={() => setJoinCampaignId(null)}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    color: theme.palette.text.primary,
    backgroundColor: "#F7F7F7",
  },
  whiteBG: {
    backgroundColor: "#FFFFFF",
  },
  page: {
    width: "100%",
    display: "flex",
    flex: 1,
  },
  mainWrapper: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  main: {
    position: "relative",
    flexGrow: 1,
  },
}));
