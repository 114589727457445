// cSpell:ignore Textsms
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  makeStyles,
  Collapse,
  Checkbox,
  IconButton,
  Button,
  Fade,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import { RootState, useAppDispatch } from "../../../types/state";
import {
  Contact,
  DeliveryStatuses,
  GenericObject,
  UpdateEmailRecipients,
} from "../../../types";
import { EditContact } from "../../../components/contacts/EditContact";
import { DeleteContact } from "../../../components/contacts/DeleteContact";
import {
  affiliateLinkWithTS,
  nonEmptyArray,
  textMessageLink,
} from "../../../lib";
import { AFFILIATES, CONTACT_ID_PARAM } from "../../../../lib";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ButtonSpinner,
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
} from "../../../components";
import { contactActions, getCampaignId } from "../../../state";
import { AutoTextSVG } from "../../../assets/AutoTextSVG";

type ContactRowsProps = {
  setShowSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  updateEmailRecipients: UpdateEmailRecipients;
  emailRecipients: GenericObject;
  displayContacts: Contact[];
};
export function ContactRows({
  setShowSendEmail,
  updateEmailRecipients,
  emailRecipients,
  displayContacts,
}: ContactRowsProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [enableSpanish, setEnableSpanish] = useState(false);

  return (
    <Fragment>
      {isMobile && (
        <FormControlLabel
          classes={{ label: classes.spanishSwitchLabel }}
          className={classes.spanishSwitch}
          control={
            <Switch
              checked={enableSpanish}
              onChange={({ target }) => setEnableSpanish(target.checked)}
            />
          }
          label="Enable Spanish messaging"
        />
      )}
      <ListRowsWrapper>
        <ListRows minWidth={1200}>
          {isDesktop && (
            <ListHeaderRowContainer>
              <div className={classes.row}>
                <div />
                <div />
                <div>Name</div>
                <div>Phone</div>
                <div>Email</div>
                <div>Last email sent</div>
                <div>Last text sent</div>
                <div>Compose email</div>
                <div>Compose text</div>
                <div />
              </div>
            </ListHeaderRowContainer>
          )}

          {displayContacts.map((contact, index) => {
            const lastRow = index === displayContacts.length - 1;
            return (
              <Row
                key={contact.id}
                contact={contact}
                lastRow={lastRow}
                setShowSendEmail={setShowSendEmail}
                updateEmailRecipients={updateEmailRecipients}
                emailRecipients={emailRecipients}
                enableSpanish={enableSpanish}
              />
            );
          })}
        </ListRows>
      </ListRowsWrapper>
    </Fragment>
  );
}

type RowProps = {
  contact: Contact;
  lastRow: boolean;
  setShowSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  updateEmailRecipients: UpdateEmailRecipients;
  emailRecipients: GenericObject;
  enableSpanish: boolean;
};
enum TextModes {
  hidden = "hidden",
  conf = "conf",
  confSuccess = "confSuccess",
}
function Row({
  contact,
  lastRow,
  setShowSendEmail,
  updateEmailRecipients,
  emailRecipients,
  enableSpanish,
}: RowProps) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const campaign_id = useSelector(getCampaignId);
  const [expanded, setExpanded] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [txtLang, setTxtLang] = useState<"EN" | "SP">("EN");
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    orgName,
    volunteerName,
    webpageLink,
    hasContactAutoTexting,
  } = useSelector((state: RootState) => state.campaign);
  const {
    id,
    name,
    phone,
    email,
    invalid_email,
    lastEmail,
    lastText,
    lastDripText,
    lastManualText,
    invalid_phone,
    manualTexts,
    hasTextConsent,
    // emailsSentCount,
    manualEmails,
    dripEmails,
    dripTexts,
  } = contact;
  const _link = affiliateLinkWithTS(
    webpageLink,
    AFFILIATES.VOLUNTEER_TO_CONTACT_TEXT,
  );
  const link = `${_link}&${CONTACT_ID_PARAM}=${id}`;
  const hasManualEmails = nonEmptyArray(manualEmails);
  const hasDripEmails = nonEmptyArray(dripEmails);
  const disableEmail = Boolean(!email || invalid_email);
  const hasManualTexts = nonEmptyArray(manualTexts);
  const hasDripTexts = nonEmptyArray(dripTexts);
  const [textMode, setTextMode] = useState<TextModes>(TextModes.hidden);
  const [submitting, setSubmitting] = useState(false);

  const toggleExpand = () => setExpanded(prev => !prev);
  const onCloseEdit = () => setShowEdit(false);
  const onCloseDelete = () => setShowDelete(false);

  const onSendSingleEmail = () => {
    updateEmailRecipients(id, email, true, true);
    setShowSendEmail(true);
  };

  const showTextConf = () => {
    setTimeout(() => {
      setExpanded(false);
      setTextMode(TextModes.conf);
    }, 2000);
  };

  const closeConf = () => setTextMode(TextModes.hidden);
  const markSent = async () => {
    setSubmitting(true);
    const sent = await dispatch(
      contactActions.createManualTextSent({
        contact_id: id,
        campaign_id,
      }),
    );
    if (sent) {
      setTextMode(TextModes.confSuccess);
      setTimeout(closeConf, 2500);
    }
    setSubmitting(false);
  };

  return (
    <ListRowContainer lastRow={lastRow} noBorderLastRowDT>
      <Fade
        in={textMode !== TextModes.hidden}
        timeout={{ appear: 100, exit: 800 }}
      >
        <div className={classes.confirm}>
          <div className={classes.confTxt}>
            {textMode === TextModes.confSuccess && (
              <div>Well done! Keep reaching out and making a difference.</div>
            )}
            {textMode === TextModes.conf && (
              <Fragment>
                <div className={classes.confName}>{name}</div>
                <div>Did you text this contact?</div>
              </Fragment>
            )}
          </div>
          {textMode === TextModes.conf && (
            <div className={classes.confButtons}>
              <Button
                size="small"
                className={classes.confYes}
                onClick={markSent}
                disabled={submitting}
              >
                Yes
                <ButtonSpinner show={submitting} customColor="#5FEA6B" />
              </Button>
              <Button
                size="small"
                className={classes.confNo}
                onClick={() => setTextMode(TextModes.hidden)}
                disabled={submitting}
              >
                No
              </Button>
            </div>
          )}
          {textMode === TextModes.confSuccess && (
            <IconButton onClick={closeConf} className={classes.closeConf}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </Fade>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>
              <IconButton onClick={toggleExpand} size="small">
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
            <div>
              <Checkbox
                checked={Boolean(emailRecipients[id])}
                classes={{ checked: classes.checked }}
                onChange={({ target }) =>
                  updateEmailRecipients(id, email, target.checked)
                }
                disabled={disableEmail}
              />
            </div>
            <div className={classes.name}>{name}</div>
            <div>
              <div className={classes.phoneWrapper}>
                <div>{phone}</div>
                {hasContactAutoTexting && hasTextConsent && (
                  <div className={classes.autoIcon}>
                    <AutoTextSVG color="secondary" />
                  </div>
                )}
              </div>
              {phone && invalid_phone && (
                <div className={classes.invalidWrapper}>
                  <ErrorIcon className={classes.invalidIcon} />
                  Invalid mobile phone
                </div>
              )}
            </div>
            <div>
              <div>{email}</div>
              {email && invalid_email && (
                <div className={classes.invalidWrapper}>
                  <ErrorIcon className={classes.invalidIcon} />
                  Invalid/blocked email
                </div>
              )}
            </div>
            <div>
              {lastEmail && (
                <div
                  className={classNames(
                    classes.dateChip,
                    classes[lastEmail.status],
                  )}
                >
                  {format(new Date(lastEmail.created_at), "M/d/y")}
                </div>
              )}
            </div>
            <div>
              {lastText && (
                <div
                  className={classNames(
                    classes.dateChip,
                    classes[lastText.status],
                  )}
                >
                  {format(new Date(lastText.created_at), "M/d/y")}
                </div>
              )}
            </div>
            <div>
              <Button
                variant="text"
                size="small"
                color="primary"
                startIcon={<MailOutlineIcon />}
                onClick={onSendSingleEmail}
                disabled={disableEmail}
              >
                COMPOSE
              </Button>
            </div>
            <div className={classes.splitBtnWrapper}>
              <IconButton
                size="small"
                color="primary"
                href={textMessageLink({
                  link,
                  phone,
                  orgName,
                  volunteerName,
                  contactName: name,
                  isVolunteer: true,
                  isSpanish: txtLang === "SP",
                })}
                onClick={showTextConf}
                disabled={!phone}
              >
                <TextsmsOutlinedIcon />
              </IconButton>
              <div
                className={classNames(
                  classes.splitBtn,
                  !phone && classes.disabledSB,
                )}
              />
              <TextField
                select
                size="small"
                value={txtLang}
                disabled={!phone}
                className={classes.langSelect}
                onChange={({ target }) => setTxtLang(target.value as any)}
                SelectProps={{
                  renderValue: selected => selected,
                  classes: {
                    root: classes.langSelectRoot,
                    icon: classes.selectIcon,
                    disabled: classes.disabled,
                  },
                }}
              >
                <MenuItem value="EN">English</MenuItem>
                <MenuItem value="SP">Spanish</MenuItem>
              </TextField>
            </div>
            <div className={classes.rightActions}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setShowEdit(true)}
              >
                <EditOutlinedIcon />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                onClick={() => setShowDelete(true)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div className={classes.expandIcon}>
                <IconButton onClick={toggleExpand} size="small">
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <div>
                <div className={classes.name}>{name}</div>
                {lastManualText && (
                  <div className={classes.sent}>
                    You texted on{" "}
                    {format(new Date(lastManualText.created_at), "M/d/yy")}
                  </div>
                )}
                {hasContactAutoTexting && (lastDripText || hasTextConsent) && (
                  <div className={classes.sent}>
                    {lastDripText &&
                      lastDripText.status !== DeliveryStatuses.DELIVERED && (
                        <div
                          className={
                            lastDripText.status == DeliveryStatuses.PENDING
                              ? classes.pendingDot
                              : classes.failedDot
                          }
                        />
                      )}
                    {lastDripText &&
                      `Auto-text on ${format(
                        new Date(lastDripText.created_at),
                        "M/d/yy",
                      )}`}
                    {lastDripText && hasTextConsent && (
                      <div className={classes.iconSpacer} />
                    )}
                    {hasTextConsent && <AutoTextSVG color="secondaryText" />}
                  </div>
                )}
                {lastEmail && (
                  <div className={classes.sent}>
                    {lastEmail.status !== DeliveryStatuses.DELIVERED && (
                      <div
                        className={
                          lastEmail.status == DeliveryStatuses.PENDING
                            ? classes.pendingDot
                            : classes.failedDot
                        }
                      />
                    )}
                    {lastEmail.isManual
                      ? "You emailed on"
                      : "Auto-email sent on"}{" "}
                    {format(new Date(lastEmail.created_at), "M/d/yy")}
                  </div>
                )}
                {phone && invalid_phone && (
                  <div className={classes.invalidWrapper}>
                    <ErrorIcon className={classes.invalidIcon} />
                    Invalid mobile phone
                  </div>
                )}
                {email && invalid_email && (
                  <div className={classes.invalidWrapper}>
                    <ErrorIcon className={classes.invalidIcon} />
                    Invalid/blocked email
                  </div>
                )}
              </div>
            </div>
            <div className={classes.rightActions}>
              <IconButton
                size="small"
                color="primary"
                href={textMessageLink({
                  link,
                  phone,
                  orgName,
                  volunteerName,
                  contactName: name,
                  isVolunteer: true,
                  isSpanish: enableSpanish && txtLang === "SP",
                })}
                disabled={!phone}
                onClick={showTextConf}
              >
                <TextsmsOutlinedIcon />
              </IconButton>
              {enableSpanish && (
                <div
                  className={classNames(
                    classes.splitBtn,
                    !phone && classes.disabledSB,
                  )}
                />
              )}
              {enableSpanish && (
                <TextField
                  select
                  size="small"
                  value={txtLang}
                  disabled={!phone}
                  className={classes.langSelect}
                  onChange={({ target }) => setTxtLang(target.value as any)}
                  SelectProps={{
                    renderValue: selected => selected,
                    classes: {
                      root: classes.langSelectRoot,
                      icon: classes.selectIcon,
                    },
                  }}
                >
                  <MenuItem value="EN">English</MenuItem>
                  <MenuItem value="SP">Spanish</MenuItem>
                </TextField>
              )}
              {!enableSpanish && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={onSendSingleEmail}
                  disabled={disableEmail}
                  className={classes.mobileSendEmail}
                >
                  <MailOutlineIcon />
                </IconButton>
              )}
            </div>
          </Fragment>
        )}
      </div>

      <Collapse in={expanded} timeout="auto">
        <div className={classes.expand}>
          {isMobile && (
            <Fragment>
              <div className={classes.expandSection}>
                <div className={classes.expandInfoSection}>
                  <div>
                    <IconButton size="small">
                      <MailOutlineIcon />
                    </IconButton>
                  </div>
                  <div className={classes.expandInfo}>
                    <div>{email ? email : <span>&#8212;</span>}</div>
                    {email && invalid_email && (
                      <Fragment>
                        <div className={classes.invalidSpacer} />
                        <div className={classes.invalidWrapper}>
                          <ErrorIcon className={classes.invalidIcon} />
                          Invalid/blocked email
                        </div>
                      </Fragment>
                    )}
                    {/* {email && (
                    <div className={classes.sentCount}>
                      {emailsSentCount} emails sent
                    </div>
                  )} */}
                  </div>
                </div>
              </div>
              <div className={classes.expandSection}>
                <div className={classes.expandInfoSection}>
                  <div>
                    <IconButton size="small">
                      <PhoneOutlinedIcon />
                    </IconButton>
                  </div>
                  <div className={classes.expandInfo}>
                    {phone ? phone : <span>&#8212;</span>}
                    {phone && invalid_phone && (
                      <Fragment>
                        <div className={classes.invalidSpacer} />
                        <div className={classes.invalidWrapper}>
                          <ErrorIcon className={classes.invalidIcon} />
                          Invalid mobile phone
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <div className={classes.expandSection}>
            <div className={classes.expandLabel}>User-sent emails</div>
            {!hasManualEmails && <div className={classes.none}>None</div>}
            {hasManualEmails && (
              <div className={classes.dateChips}>
                {manualEmails.map(({ created_at, status }, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(classes.dateChip, classes[status])}
                    >
                      {format(new Date(created_at), "M/d/y")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={classes.expandSection}>
            <div className={classes.expandLabel}>Automated emails</div>
            {!hasDripEmails && <div className={classes.none}>None</div>}
            {hasDripEmails && (
              <div className={classes.dateChips}>
                {dripEmails.map(({ created_at, status }, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(classes.dateChip, classes[status])}
                    >
                      {format(new Date(created_at), "M/d/y")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className={classNames(
              classes.expandSection,
              isDesktop && !hasContactAutoTexting && classes.noBorder,
            )}
          >
            <div className={classes.expandLabel}>User-sent text messages</div>
            {!hasManualTexts && <div className={classes.none}>None</div>}
            {hasManualTexts && (
              <div className={classes.dateChips}>
                {manualTexts.map(({ created_at }, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        classes.dateChip,
                        classes.delivered,
                      )}
                    >
                      {format(new Date(created_at), "M/d/y")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {hasContactAutoTexting && (
            <div
              className={classNames(
                classes.expandSection,
                isDesktop && classes.noBorder,
              )}
            >
              <div className={classes.expandLabel}>Auto-sent text messages</div>
              {!hasDripTexts && <div className={classes.none}>None</div>}
              {hasDripTexts && (
                <div className={classes.dateChips}>
                  {dripTexts.map(({ created_at, status }, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(
                          classes.dateChip,
                          classes[status],
                        )}
                      >
                        {format(new Date(created_at), "M/d/y")}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          {isMobile && (
            <div
              className={classNames(classes.expandSection, classes.noBorder)}
            >
              <div>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  startIcon={<EditOutlinedIcon />}
                  onClick={() => setShowEdit(true)}
                >
                  EDIT
                </Button>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  startIcon={<DeleteOutlineIcon />}
                  className={classes.expandEditButton}
                  onClick={() => setShowDelete(true)}
                >
                  DELETE
                </Button>
              </div>
            </div>
          )}
        </div>
      </Collapse>
      <EditContact isOpen={showEdit} onClose={onCloseEdit} contact={contact} />
      <DeleteContact
        isOpen={showDelete}
        onClose={onCloseDelete}
        contact={contact}
      />
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  spanishSwitch: {
    marginBottom: 6,
  },
  spanishSwitchLabel: {
    fontSize: 14,
    letterSpacing: 0.15,
  },
  confirm: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#FFF4F4",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  confTxt: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  confName: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    marginRight: 24,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 216px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginRight: 0,
      paddingBottom: 8,
    },
  },
  confButtons: {
    minWidth: 175,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      minWidth: 104,
    },
  },
  confYes: {
    width: 80,
    backgroundColor: "#5FEA6B",
    marginRight: 8,
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: "#5FEA6B",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 48,
      maxWidth: 48,
    },
  },
  confNo: {
    width: 80,
    backgroundColor: theme.palette.error.main,
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: theme.palette.error.main,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 48,
      maxWidth: 48,
    },
  },
  closeConf: {
    marginRight: -8,
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns:
      "40px 60px .5fr 162px .6fr 124px 124px 128px 124px 90px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 20,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "9px 2px 9px 12px",
      minHeight: 58,
      display: "flex",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  checked: {
    color: `${theme.palette.secondary2.main} !important`,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "18px",
    },
  },
  invalidSpacer: {
    height: 3,
  },
  invalidWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.main,
    opacity: 1,
    fontSize: 12,
    letterSpacing: 0.13,
    marginTop: 5,
  },
  invalidIcon: {
    fontSize: 15,
    marginRight: 2,
  },
  phoneWrapper: {
    display: "flex",
    alignItems: "center",
  },
  autoIcon: {
    marginLeft: 6,
    display: "flex",
    alignItems: "center",
  },
  iconSpacer: {
    width: 6,
    minWidth: 6,
  },
  pendingDot: {
    width: 8,
    height: 8,
    borderRadius: "100%",
    backgroundColor: "#FFBD20",
    marginRight: 4,
  },
  failedDot: {
    width: 8,
    height: 8,
    borderRadius: "100%",
    backgroundColor: theme.palette.error.main,
    marginRight: 4,
  },
  sent: {
    color: theme.palette.text.secondary2,
    fontSize: 12,
    letterSpacing: 0.4,
    marginTop: 4,
    display: "flex",
    alignItems: "center",
  },
  splitBtnWrapper: {
    display: "flex",
    alignItems: "center",
  },
  splitBtn: {
    height: 30,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 4,
  },
  disabledSB: {
    borderRight: `1px solid ${theme.palette.text.secondary2}`,
  },
  langSelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    color: theme.palette.primary.main,
  },
  langSelectRoot: {
    paddingTop: 5.5,
    paddingBottom: 5.5,
    paddingLeft: 8,
    color: theme.palette.primary.main,
    fontSize: 13,
  },
  disabled: {
    color: theme.palette.text.secondary2,
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  expandIcon: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
    },
  },
  rightActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "fit-content",
  },
  mobileSendEmail: {
    marginRight: 10,
    marginLeft: 6,
  },
  expand: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#EAEBF3",
    padding: "8px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  expandSection: {
    borderBottom: "1px solid #C9CDDE",
    display: "flex",
    maxWidth: "100%",
    alignItems: "center",
    fontSize: 14,
    letterSpacing: 0.15,
    padding: "6px 24px",
    minHeight: 52,
    [theme.breakpoints.down("sm")]: {
      minHeight: 62,
      borderBottom: "1px solid #DBDEEE",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: "6px 15px",
    },
  },
  expandLabel: {
    width: 188,
    minWidth: 188,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      minWidth: "unset",
      padding: "4px 0 12px 0",
      fontWeight: 500,
      letterSpacing: 0.3,
    },
  },
  dateChips: {
    display: "flex",
    flexWrap: "wrap",
  },
  dateChip: {
    height: 32,
    width: 87,
    borderRadius: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    letterSpacing: 0.15,
    margin: "4px 8px 4px 0",
  },
  // IMPORTANT: don't change this className because it's based on the status from the API call
  pending: {
    color: "#683800",
    backgroundColor: "#FFF8E9",
  },
  // IMPORTANT: don't change this className because it's based on the status from the API call
  failed: {
    color: "#5E0808",
    backgroundColor: "#FDEAEA",
  },
  // IMPORTANT: don't change this className because it's based on the status from the API call
  delivered: {
    color: "#083D15",
    backgroundColor: "#EFFCF0",
  },
  noBorder: {
    borderBottom: "none",
  },
  expandEditButton: {
    marginLeft: 20,
    color: "#ED2A2A",
  },
  expandInfoSection: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
  },
  expandInfo: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "15px",
    overflowWrap: "anywhere",
  },
  sentCount: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary2,
    paddingTop: 4,
  },
  none: {
    fontSize: 14,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary2,
  },
}));
