import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import BlankIcon from "@material-ui/icons/BlockOutlined";
import { Form, Field, useField } from "react-final-form";
import { FieldValidator } from "final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import {
  APIAuthClient,
  composeValidators,
  twoDecimalsOnly,
} from "../../../lib";
import {
  APIRes,
  rowCountOptions,
  seatCountOptions,
  ToastTypes,
} from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { Fragment, useEffect, useState } from "react";
const requiredField: FieldValidator<any> = value => (value ? undefined : true);

type Props = {
  seating_map_id: any;
  onClose: () => void;
  refreshList: () => void;
};

export function AddSeatingMapSection({
  seating_map_id,
  onClose: _onClose,
  refreshList,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [rowCount, setRowCount] = useState<number | "">("");
  const [maxSeatsPerRow, setMaxSeatsPerRow] = useState<number | "">("");
  const [countsSelected, setCountsSelected] = useState(false);
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const post = { seating_map_id, ...values };
    const url = "/seating_map_sections";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Section added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{
            allow_open_seating: true,
            allow_assigned_seating: true,
          }}
          render={({ handleSubmit, submitting, form, values }) => {
            const { allow_open_seating, allow_assigned_seating } = values;
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.top}>
                  <h1>Add section</h1>
                  {isMobile && (
                    <IconButton onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>

                <div className={classes.mainContent}>
                  <Field
                    component={TextFieldWrapper}
                    name="section_name"
                    label="Section name"
                    validate={requiredField}
                    className={classes.input}
                  />

                  {/* OPEN SEATING */}
                  <div className={classes.toggleNegativeMargin} />
                  <div className={classes.toggle}>
                    <SwitchField
                      fieldName="allow_open_seating"
                      label="Allow open seating"
                    />
                  </div>
                  {allow_open_seating && (
                    <div className={classes.inputGroup}>
                      <Field
                        name="open_seating_price"
                        component={TextFieldWrapper}
                        label="Open seating price"
                        validate={composeValidators(
                          requiredField,
                          twoDecimalsOnly,
                        )}
                        className={classes.halfInput}
                        type="number"
                      />
                      <Field
                        name="open_seating_ticket_msg"
                        component={TextFieldWrapper}
                        label="Open seating ticket message"
                        className={classes.halfInput}
                      />
                    </div>
                  )}

                  {/* ASSIGNED SEATING */}
                  <div className={classes.toggle}>
                    <SwitchField
                      fieldName="allow_assigned_seating"
                      label="Allow assigned seating"
                    />
                  </div>
                  {allow_assigned_seating && (
                    <div className={classes.inputGroup}>
                      <Field
                        name="assigned_seating_price"
                        component={TextFieldWrapper}
                        label="Assigned seating price"
                        validate={composeValidators(
                          requiredField,
                          twoDecimalsOnly,
                        )}
                        className={classes.halfInput}
                        type="number"
                      />
                      <Field
                        name="assigned_seating_ticket_msg"
                        component={TextFieldWrapper}
                        label="Assigned seating ticket message"
                        className={classes.halfInput}
                      />
                    </div>
                  )}

                  <div className={classes.inputGroup}>
                    <TextField
                      select
                      className={classes.halfInput}
                      disabled={countsSelected}
                      label="Numbers of rows"
                      value={rowCount}
                      onChange={({ target }) =>
                        setRowCount(Number(target.value))
                      }
                    >
                      {rowCountOptions.map(({ name, value }) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      select
                      className={classes.halfInput}
                      disabled={countsSelected}
                      label="Max seats per row"
                      value={maxSeatsPerRow}
                      onChange={({ target }) =>
                        setMaxSeatsPerRow(Number(target.value))
                      }
                    >
                      {seatCountOptions.map(({ name, value }) => {
                        return (
                          <MenuItem key={value} value={value}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>

                  {countsSelected && rowCount && maxSeatsPerRow && (
                    <Map rowCount={rowCount} maxSeatsPerRow={maxSeatsPerRow} />
                  )}
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting || !rowCount || !maxSeatsPerRow}
                    onClick={() => {
                      !countsSelected ? setCountsSelected(true) : form.submit();
                    }}
                  >
                    {!countsSelected ? "Continue" : "Save"}
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

type MapProps = { rowCount: number; maxSeatsPerRow: number };
function Map({ rowCount, maxSeatsPerRow }: MapProps) {
  const classes = styles();
  const rowField = useField("rows").input;

  useEffect(() => {
    const seats = new Array(maxSeatsPerRow).fill({
      seat_identifier: null,
      is_blank: false,
    });
    const rows = new Array(rowCount).fill({
      row_identifier: "",
      seats: [...seats],
    });
    rowField.onChange(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className={classes.mapHeader}>Map</div>
      <div className={classes.map}>
        <FieldArray name="rows">
          {({ fields }) => (
            <div>
              {fields.map((row, index) => {
                return (
                  <div key={index}>
                    <div className={classes.rowSeats}>
                      <Field
                        size="small"
                        component={TextFieldWrapper}
                        placeholder="Row num"
                        name={`${row}.row_identifier`}
                        className={classes.rowIdentifier}
                        validate={requiredField}
                      />
                      <FieldArray name={`${row}.seats`}>
                        {({ fields }) => (
                          <div className={classes.rowSeats}>
                            {fields.map((seat, index) => {
                              const { is_blank } = fields.value[index];
                              return (
                                <div key={index}>
                                  <div>
                                    {!is_blank && (
                                      <div className={classes.seatWrapper}>
                                        <Field
                                          component={TextFieldWrapper}
                                          name={`${seat}.seat_identifier`}
                                          className={classes.seatInputWrapper}
                                          InputProps={{
                                            classes: {
                                              input: classes.seatInput,
                                            },
                                          }}
                                          validate={requiredField}
                                        />
                                        <div
                                          className={classes.makeBlank}
                                          onClick={() => {
                                            fields.update(index, {
                                              ...fields.value[index],
                                              is_blank: true,
                                              seat_identifier: null,
                                            });
                                          }}
                                        >
                                          <CloseIcon fontSize="small" />
                                        </div>
                                      </div>
                                    )}
                                    {is_blank && (
                                      <div
                                        className={classes.blankSeat}
                                        onClick={() => {
                                          fields.update(index, {
                                            ...fields.value[index],
                                            is_blank: false,
                                          });
                                        }}
                                      >
                                        <BlankIcon />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </FieldArray>
      </div>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 800,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  toggleNegativeMargin: {
    marginTop: -8,
  },
  toggle: {
    marginBottom: 12,
  },
  map: {
    overflowY: "auto",
  },
  mapHeader: {
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: "16px 0",
    fontWeight: 500,
  },
  rowIdentifier: {
    width: 100,
    minWidth: 100,
    margin: "2px 16px 2px 0",
  },
  rowSeats: {
    display: "flex",
  },
  seatWrapper: {
    position: "relative",
  },
  seatInputWrapper: {
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    margin: "2px 0 2px 4px",
  },
  seatInput: {
    paddingLeft: 5,
    paddingRight: 4,
    paddingTop: 15,
    paddingBottom: 6,
  },
  makeBlank: {
    position: "absolute",
    top: 2,
    right: 2,
    color: theme.palette.text.secondary2,
    cursor: "pointer",
  },
  undo: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.text.secondary2,
    cursor: "pointer",
  },
  blankSeat: {
    width: 50,
    height: 40,
    maxHeight: 40,
    color: theme.palette.text.secondary2,
    border: `1px solid #C9CDDD`,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2px 0 2px 4px",
    backgroundColor: "rgba(201, 205, 221, .4)",
    cursor: "pointer",
  },
  space: {
    height: 32,
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
