import {
  IconButton,
  Checkbox,
  makeStyles,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { format } from "date-fns";
import { Contact } from "../../../types";
import { useSelector } from "react-redux";
import {
  ButtonSpinner,
  CustomTooltip,
  ResponsiveModal,
  SwitchField,
} from "../../../components";
import { useDrawerTransition, useIsDesktop } from "../../../hooks/ui";
import { Fragment, useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../types/state";
import classNames from "classnames";
import { PreviewAutoTexts } from "./PreviewAutoTexts";
import { contactActions } from "../../../state";

type Props = {
  onClose: () => void;
};
export function ManageAutoTexts({ onClose: _onClose }: Props) {
  const classes = styles();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const { maxAutoTextContacts, campaignId: campaign_id } = useSelector(
    (state: RootState) => state.campaign,
  );
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const isDesktop = useIsDesktop();
  const dispatch = useAppDispatch();
  const [used, setUsed] = useState(0);
  const [max, setMax] = useState(maxAutoTextContacts || 0);
  const [consented, setConsented] = useState(false);
  const [previewTexts, setPreviewTexts] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    updateUsed(contacts);
    const filtered: Contact[] = [];
    contacts.forEach(c => {
      const { phone, invalid_phone, lastDripText } = c;
      if ((phone && !invalid_phone) || lastDripText) filtered.push(c);
    });
    setInitialValues({ contacts: filtered });
  }, [contacts]);

  useEffect(() => {
    setMax(maxAutoTextContacts || 0);
  }, [maxAutoTextContacts]);

  const updateUsed = (contacts: any) => {
    if (!Array.isArray(contacts)) return;
    let count = 0;
    contacts.forEach(c => {
      const { lastDripText, hasTextConsent } = c;
      if (hasTextConsent || lastDripText) count++;
    });
    setUsed(count);
  };

  const onSubmit = async ({ contacts }) => {
    const contactIds: number[] = [];
    contacts.forEach((c: Contact) => {
      const { id, hasTextConsent } = c;
      if (hasTextConsent) contactIds.push(id);
    });
    const update = { contactIds, campaign_id };
    const success = await dispatch(contactActions.updateTextConsents(update));
    if (success) onClose();
  };

  return (
    <Fragment>
      <ResponsiveModal isOpen={isOpen} onClose={onClose}>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, form, pristine }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.container}>
                <div className={classes.top}>
                  <div className={classes.close}>
                    <IconButton onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className={classes.titleRow}>
                    <h2 className={classes.title}>Enable auto-texts</h2>
                    <div className={classes.outOfWrapper}>
                      <div className={classes.outOf}>
                        {used}/{max}
                        {isDesktop && " contacts used"}
                      </div>
                      <CustomTooltip
                        title={`You can enroll up to ${max} contacts in auto-texts. You’ve used ${used}/${max}. Contacts who received auto-texts still count toward your limit, even if you turn off their auto-texts.`}
                      >
                        <div>
                          <InfoIcon className={classes.outOfIcon} />
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                  <div className={classes.learn}>
                    <span
                      className={classes.learnLink}
                      onClick={() => setPreviewTexts(true)}
                    >
                      View
                    </span>{" "}
                    your automated campaign messages
                  </div>
                </div>

                <div className={classes.content}>
                  <FieldArray name="contacts">
                    {({ fields }) => (
                      <div className={classes.rows}>
                        {fields.map((row, index) => {
                          const isFirst = index === 0;
                          const {
                            id,
                            name,
                            phone,
                            alreadySupported,
                            hasTextConsent,
                            lastDripText,
                            invalid_phone,
                          } = fields.value[index] as Contact;
                          const disabled = Boolean(
                            !hasTextConsent &&
                              (!phone ||
                                invalid_phone ||
                                (!lastDripText && used >= max)),
                          );
                          return (
                            <div
                              key={id}
                              className={classNames(
                                classes.row,
                                !isFirst && classes.border,
                              )}
                            >
                              <div>
                                <div className={classes.name}>{name}</div>
                                <div className={classes.phone}>{phone}</div>
                                {lastDripText && (
                                  <div className={classes.lastSent}>
                                    Auto-text sent on{" "}
                                    {format(
                                      new Date(lastDripText.created_at),
                                      "MMM d",
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className={classes.rightCol}>
                                <SwitchField
                                  fieldName={`${row}.hasTextConsent`}
                                  label=""
                                  disabled={disabled}
                                />
                                {lastDripText && !hasTextConsent && (
                                  <div className={classes.towardLimit}>
                                    <InfoIcon className={classes.infoIcon} />
                                    Counts toward limit
                                  </div>
                                )}
                                {lastDripText &&
                                  !hasTextConsent &&
                                  alreadySupported && (
                                    <div className={classes.spacer} />
                                  )}
                                {alreadySupported && (
                                  <div className={classes.checkContainer}>
                                    <CheckCircleIcon
                                      className={classes.checkIcon}
                                    />
                                    Already supported
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>
                  <ChangeListener updateUsed={updateUsed} />
                </div>

                <div className={classes.bottom}>
                  <div className={classes.consent}>
                    <FormControlLabel
                      className={classes.checkboxFC}
                      classes={{ label: classes.checkboxLabel }}
                      control={
                        <Checkbox
                          color="primary"
                          checked={consented}
                          className={classes.checkbox}
                          classes={{
                            root: classes.rootCB,
                            checked: classes.checked,
                          }}
                          onChange={({ target }) =>
                            setConsented(target.checked)
                          }
                        />
                      }
                      label="I confirm that I have obtained consent from the selected
                contacts to receive automated text messages from this
                campaign."
                    />
                  </div>
                  <div className={classes.saveWrapper}>
                    <Button
                      color="primary"
                      className={classes.save}
                      disabled={submitting || !consented || pristine}
                      onClick={() => form.submit()}
                    >
                      Save
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </ResponsiveModal>
      {previewTexts && (
        <PreviewAutoTexts onClose={() => setPreviewTexts(false)} />
      )}
    </Fragment>
  );
}

function ChangeListener({
  updateUsed,
}: {
  updateUsed: (contacts: any) => void;
}) {
  return <OnChange name="contacts">{updateUsed}</OnChange>;
}

const styles = makeStyles(theme => ({
  container: {
    width: 800,
    maxWidth: 800,
    maxHeight: "calc(100vh - 64px)",
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      overflow: "auto",
    },
  },
  close: {
    position: "absolute",
    top: 6,
    right: 6,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      top: 4,
      right: 4,
    },
  },
  top: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    borderBottom: "1px solid #EAEBF3",
    padding: "22px 0 16px 0",
  },
  titleRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 56px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 42px 0 16px",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.12,
    },
  },
  outOfWrapper: {
    display: "flex",
    alignItems: "center",
  },
  outOf: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
  },
  outOfIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
    marginLeft: 6,
  },
  learn: {
    fontSize: 14,
    letterSpacing: 0.1,
    color: theme.palette.text.secondary2,
    padding: "10px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      padding: "10px 16px 0 16px",
    },
  },
  learnLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      overflow: "visible",
    },
  },
  rows: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      overflowY: "inherit",
    },
  },
  row: {
    width: "100%",
    minHeight: 52,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px 8px 24px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 12px 8px 16px",
    },
  },
  border: {
    borderTop: "1px solid #EAEBF3",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "18px",
  },
  phone: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    marginTop: 2,
  },
  lastSent: {
    color: theme.palette.secondary2.main,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
  },
  rightCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    minWidth: "fit-content",
    marginLeft: 16,
  },
  towardLimit: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    fontSize: 12,
    letterSpacing: 0.4,
    minWidth: "fit-content",
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    marginRight: 3,
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
    letterSpacing: 0.15,
    textWrap: "nowrap",
    fontSize: 13,
  },
  checkIcon: {
    fontSize: 17,
    color: "#1AC846",
    marginRight: 4,
  },
  spacer: {
    height: 8,
  },
  bottom: {
    position: "sticky",
    bottom: 0,
    borderTop: "1px solid #EAEBF3",
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    padding: 16,
  },
  consent: {
    backgroundColor: "#E6F5FF",
    width: "100%",
    padding: "16px 16px 16px 6px",
    marginBottom: 16,
  },
  checkboxFC: {
    marginLeft: 0,
    alignItems: "flex-start",
    marginRight: 0,
  },
  checkboxLabel: {
    color: "#003554",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  checkbox: {
    color: "#003554",
  },
  rootCB: {
    fontSize: 14,
    "&$checked": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  checked: {}, // don't remove
  saveWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
}));
