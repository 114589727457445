import { isVolunteer, nameToInitials } from "../../../lib";
import { ToastTypes } from "../../types";
import { RootState } from "../../types/state";
import { uiTypes } from "./actions";

type UiState = {
  toast: undefined | null | string;
  toastType?: ToastTypes;
  sidebarOpen: boolean;
  topBarTitleComponent?: JSX.Element;
  loadingDownload: boolean;
  affiliateOrigin: string | null;
  modalIdentifierArr: number[];
};

const initialState: UiState = {
  toast: null,
  toastType: undefined,
  sidebarOpen: true,
  loadingDownload: false,
  affiliateOrigin: null,
  modalIdentifierArr: [],
};

const uiReducers = (state = initialState, action: any): UiState => {
  switch (action.type) {
    case uiTypes.UI_TOAST_SET:
      return {
        ...state,
        toast: action.payload.toast,
        toastType: action.payload.toastType
          ? action.payload.toastType
          : ToastTypes.warn,
      };
    case uiTypes.UI_TOAST_RESET:
      return { ...state, toast: null, toastType: undefined };
    case uiTypes.SIDEBAR_TOGGLE:
      return { ...state, sidebarOpen: !state.sidebarOpen };
    case uiTypes.TOP_BAR_TITLE_COMPONENT_SET:
      return { ...state, topBarTitleComponent: action.payload };
    case uiTypes.LOADING_DOWNLOAD_TOGGLE:
      return { ...state, loadingDownload: action.isLoading };
    case uiTypes.AFFILIATE_ORIGIN_SET:
      return { ...state, affiliateOrigin: action.affiliateOrigin };
    case uiTypes.MODAL_IDENTIFIER_ADD:
      return {
        ...state,
        modalIdentifierArr: [
          ...state.modalIdentifierArr,
          action.modalIdentifier,
        ],
      };
    case uiTypes.MODAL_IDENTIFIER_REMOVE_LAST:
      return {
        ...state,
        modalIdentifierArr: state.modalIdentifierArr.slice(
          0,
          state.modalIdentifierArr.length - 1,
        ),
      };
    default:
      return state;
  }
};

function getUserName(state: RootState) {
  const {
    user: { userName, role },
    campaign: { volunteerName },
  } = state;
  return isVolunteer(role) ? volunteerName || null : userName || null;
}

function getUserNameInitials(state: RootState) {
  const name = getUserName(state);
  return nameToInitials(name);
}

export { uiReducers, getUserName, getUserNameInitials };
