import {
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { useDrawerTransition, useIsMobile } from "../../hooks/ui";
import { APIRes, ToastTypes } from "../../types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions, setToast } from "../../state";
import { RootState } from "../../types/state";
import classNames from "classnames";
import { RedButton } from "../../components/ui/RedButton";
import { APIAuthClient } from "../../lib";
import { ResponsiveModal } from "../ui/ResponsiveModal";
import { ButtonSpinner } from "../ui/ButtonSpinner";
enum Options {
  CLOSEOUT = "is_post_closeout",
  CUTOFF = "is_post_cutoff",
}

type Props = {
  onClose: () => void;
};
export function DonationTagStatusModal({ onClose: _onClose }: Props) {
  const classes = styles();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [option, setOption] = useState<null | Options>(null);
  const [submitting, setSubmitting] = useState(false);
  const { campaignId, is_post_cutoff, is_post_closeout } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const onConfirm = async () => {
    setSubmitting(true);
    const res = await APIAuthClient.put<any, APIRes>(
      "/campaign_management/update_donation_tags",
      { campaign_id: campaignId, tag_name: option },
    );

    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setSubmitting(false);
    } else {
      dispatch(setToast("Tag updated", ToastTypes.success));
      // refresh current tags
      dispatch(campaignActions.getCampaignInfo(campaignId));
      onClose();
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        <div className={classes.subTitle}>Current tags</div>
        {!is_post_cutoff && !is_post_closeout && (
          <div className={classes.noTags}>No tags yet</div>
        )}
        {is_post_closeout && (
          <div className={classes.currTagWrapper}>
            <CheckCircleIcon className={classes.checkIcon} />
            <div className={classes.currTag}>Post-closeout</div>
          </div>
        )}
        {is_post_cutoff && (
          <div className={classes.currTagWrapper}>
            <CheckCircleIcon className={classes.checkIcon} />
            <div className={classes.currTag}>Post-cutoff</div>
          </div>
        )}

        <div className={classes.sectionSpacer} />

        <div className={classes.subTitle}>Add tag</div>
        <RadioGroup
          value={option}
          onChange={({ target }) => setOption(target.value as Options)}
        >
          <FormControlLabel
            value={Options.CLOSEOUT}
            disabled={is_post_closeout}
            control={<Radio className={classes.radio} />}
            label={
              <div>
                Post-closeout
                <div
                  className={classNames(
                    classes.radioSubText,
                    is_post_closeout && classes.disabledSubTxt,
                  )}
                >
                  All sales going forward will be tagged as Post-Closeout for
                  Org Profit accounting purposes
                </div>
                {is_post_closeout && (
                  <div className={classes.disableWrapper}>
                    <ErrorIcon className={classes.errorIcon} />
                    Already tagged
                  </div>
                )}
              </div>
            }
            classes={{ label: classes.radioLabel, root: classes.radioRoot }}
          />
          <FormControlLabel
            value={Options.CUTOFF}
            disabled={is_post_cutoff}
            control={<Radio className={classes.radio} />}
            label={
              <div>
                Post-cutoff
                <div
                  className={classNames(
                    classes.radioSubText,
                    is_post_cutoff && classes.disabledSubTxt,
                  )}
                >
                  All sales going forward will be tagged as Post-Cut-Off but
                  will not be handled differently in Org Profit accounting
                </div>
                {is_post_cutoff && (
                  <div className={classes.disableWrapper}>
                    <ErrorIcon className={classes.errorIcon} />
                    Already tagged
                  </div>
                )}
              </div>
            }
            classes={{ label: classes.radioLabel, root: classes.radioRoot }}
          />
        </RadioGroup>

        <div className={classes.actions}>
          <Button
            variant={isMobile ? "contained" : "text"}
            color={isMobile ? "secondary" : undefined}
            className={classes.cancel}
            disabled={submitting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <RedButton
            variant={isMobile ? "contained" : "text"}
            className={classes.confirm}
            disabled={!option || submitting}
            onClick={onConfirm}
          >
            Confirm
            <ButtonSpinner show={submitting} color="inherit" />
          </RedButton>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: "100%",
    overflowY: "auto",
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    marginBottom: 12,
  },
  noTags: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    paddingBottom: 16,
  },
  currTagWrapper: {
    paddingBottom: 16,
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    color: "#1AC846",
    fontSize: 19,
  },
  currTag: {
    fontSize: 14,
    letterSpacing: 0.15,
    marginLeft: 4,
  },
  sectionSpacer: {
    height: 24,
  },
  radioLabel: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  radioRoot: {
    alignItems: "flex-start",
    marginBottom: 16,
    marginRight: 0,
  },
  radio: {
    marginTop: -8,
  },
  radioSubText: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.13,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginTop: 2,
  },
  disabledSubTxt: {
    color: theme.palette.text.secondary2,
  },
  disableWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.main,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    marginTop: 8,
    overflowWrap: "anywhere",
    fontWeight: 400,
  },
  errorIcon: {
    marginRight: 8,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 18,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  cancel: {
    marginRight: 8,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 16,
      textTransform: "unset",
    },
  },
  confirm: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
