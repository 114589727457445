import { Button, IconButton, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../../../hooks/ui";
import { GenericObject, VolunteerDetailModes } from "../../../../types";
import { useSelector } from "react-redux";
import {
  getIsProductCampaign,
  getIsSchoolathon,
  getVolunteerLabel,
} from "../../../../state";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/EditOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import MoneyIcon from "@material-ui/icons/MonetizationOnOutlined";
import { Fragment, useState } from "react";
import { hexToRGBA } from "../../../../../lib";
import { RootState } from "../../../../types/state";
import { ImpersonateVolunteer } from "../../../../components";
import { SchoolathonManage } from "../SchoolathonManage";
import { VolunteerDonations } from "../VolunteerDonations";

type Props = {
  mode: VolunteerDetailModes;
  setMode: React.Dispatch<React.SetStateAction<VolunteerDetailModes>>;
  data: GenericObject;
  onClose: () => void;
  setShowDelete: (arg0: boolean) => void;
  refreshList: () => void;
  refreshData: () => void;
  refreshParentRef: React.MutableRefObject<boolean>;
};
export function Top({
  mode,
  data,
  setMode,
  onClose,
  setShowDelete,
  refreshList,
  refreshData,
  refreshParentRef,
}: Props) {
  const { id, first_name = "", last_name = "", slug } = data;
  const isMobile = useIsMobile();
  const classes = styles();
  const isView = mode === VolunteerDetailModes.view;
  const { volunteerLabelSing, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );
  const webpageLink = useSelector(
    ({ campaign }: RootState) => campaign.webpageLink,
  );
  const isProduct = useSelector(getIsProductCampaign);
  const isSchoolathon = useSelector(getIsSchoolathon);
  const [showDonations, setShowDonations] = useState(false);
  const [showSchoolathon, setShowSchoolathon] = useState(false);

  const onDonationsClose = () => {
    if (refreshParentRef.current) refreshData();
    else setShowDonations(false);
  };

  return (
    <div className={classes.container}>
      {/* TOP ROW */}
      <div className={classes.topRow}>
        {/* LEFT */}
        {isView && isMobile && (
          <div className={classes.name}>
            {first_name} {last_name}
            {!isMobile && `${volunteerLabelSing} details`}
          </div>
        )}
        {isView && !isMobile && (
          <div className={classes.title}>{volunteerLabelSing} details</div>
        )}
        {!isView && isMobile && <div />}
        {!isView && !isMobile && (
          <div className={classes.title}>Edit {volunteerLabelLCSingular}</div>
        )}
        {/* RIGHT */}
        <div>
          {!isMobile && (
            <Fragment>
              {isView && (
                <Fragment>
                  {isSchoolathon && (
                    <Button
                      variant="text"
                      color="primary"
                      className={classes.schoolathon}
                      startIcon={<SettingsIcon />}
                      onClick={() => setShowSchoolathon(true)}
                    >
                      manage {volunteerLabelSing}
                    </Button>
                  )}
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.edit}
                    startIcon={<EditIcon />}
                    onClick={() => setMode(VolunteerDetailModes.edit)}
                  >
                    EDIT
                  </Button>
                  <Button
                    variant="text"
                    className={classes.delete}
                    startIcon={<DeleteIcon />}
                    onClick={() => setShowDelete(true)}
                  >
                    DELETE
                  </Button>
                </Fragment>
              )}
              {!isView && (
                <Button
                  variant="text"
                  className={classes.link}
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                  size={isMobile ? "small" : "medium"}
                  href={`${webpageLink}/${slug}`}
                  target="_blank"
                >
                  {volunteerLabelSing} page
                </Button>
              )}
            </Fragment>
          )}

          <IconButton
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      {/* BOTTOM ROW */}
      {!isMobile && isView && (
        <div className={classes.bottomRow}>
          <div className={classes.name}>
            {first_name} {last_name}
          </div>
        </div>
      )}
      {isMobile && (
        <div className={classes.bottomRow}>
          {/* LEFT */}
          {isView && (
            <div className={classes.mobileLeft}>
              <ImpersonateVolunteer volunteerId={id as number} renderButton />
              <div className={classes.btnSpacer} />
              <Button
                variant="text"
                color="primary"
                size="small"
                className={classes.salesLink}
                startIcon={<MoneyIcon />}
                onClick={() => setShowDonations(true)}
              >
                {isProduct ? "VIEW SALES" : "DONATIONS"}
              </Button>
              {isSchoolathon && (
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  className={classes.schoolathon}
                  startIcon={<SettingsIcon />}
                  onClick={() => setShowSchoolathon(true)}
                >
                  manage {volunteerLabelSing}
                </Button>
              )}
            </div>
          )}
          {!isView && (
            <div className={classes.title}>Edit {volunteerLabelLCSingular}</div>
          )}
          {/* RIGHT */}
          <div className={classes.mobileRight}>
            {isView && (
              <Fragment>
                <IconButton
                  onClick={() => setMode(VolunteerDetailModes.edit)}
                  color="primary"
                  className={classes.edit}
                >
                  <EditIcon />
                </IconButton>
              </Fragment>
            )}
            {/* {!isView && (
              <Button
                variant="text"
                className={classes.link}
                color="primary"
                endIcon={<OpenInNewIcon />}
                size={isMobile ? "small" : "medium"}
                href={`${webpageLink}/${slug}`}
                target="_blank"
              >
                {volunteerLabelSing} page
              </Button>
            )} */}
            <IconButton
              className={classes.delete}
              onClick={() => setShowDelete(true)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}

      {showSchoolathon && (
        <SchoolathonManage
          onClose={() => {
            refreshList();
            onClose();
          }}
          record={data}
        />
      )}
      {showDonations && (
        <VolunteerDonations
          onClose={onDonationsClose}
          volunteer_id={id as number}
          volunteerName={`${first_name} ${last_name}`}
          refreshParentRef={refreshParentRef}
        />
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "16px 24px",
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 18,
      position: "sticky",
      top: 0,
      backgroundColor: "#FFFFFF",
      zIndex: 9,
      alignItems: "flex-end",
    },
  },
  topRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  bottomRow: {
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
  },
  close: {
    marginRight: -8,
    [theme.breakpoints.down("sm")]: {
      marginTop: -10,
      marginRight: -8,
    },
  },
  delete: {
    color: theme.palette.error.main,
    margin: "0 16px",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: hexToRGBA(theme.palette.error.main, 0.1),
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginRight: -8,
    },
  },
  link: {
    textTransform: "uppercase",
    marginRight: 16,
  },
  name: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "19px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.15,
      lineHeight: "24px",
      paddingRight: 6,
      marginTop: 6,
    },
  },
  edit: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
    },
  },
  schoolathon: {
    marginRight: 16,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
  mobileLeft: {
    display: "flex",
    flexWrap: "wrap",
  },
  mobileRight: {
    minWidth: "fit-content",
  },
  salesLink: {
    marginRight: 8,
  },
  btnSpacer: {
    width: 8,
  },
}));
