import { useEffect, useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "../../../types/state";
import { useIsMobile } from "../../../hooks/ui";
import { ManageAutoTexts } from "./ManageAutoTexts";
import { AutoTextSVG } from "../../../assets/AutoTextSVG";

export function AutoTextBanner() {
  const classes = styles();
  const isMobile = useIsMobile();
  const { maxAutoTextContacts, hasContactAutoTexting } = useSelector(
    (state: RootState) => state.campaign,
  );
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const [showAutoTexts, setShowAutoTexts] = useState(false);
  const [used, setUsed] = useState(0);
  const [max, setMax] = useState(maxAutoTextContacts || 0);

  useEffect(() => {
    setMax(maxAutoTextContacts || 0);
  }, [maxAutoTextContacts]);

  useEffect(() => {
    if (!Array.isArray(contacts)) return;
    let count = 0;
    contacts.forEach(c => {
      const { lastDripText, hasTextConsent } = c;
      if (hasTextConsent || lastDripText) count++;
    });
    setUsed(count);
  }, [contacts]);

  useEffect(() => {
    if (hasContactAutoTexting && max && used < max) setShow(true);
    else setShow(false);
  }, [max, used, hasContactAutoTexting]);

  if (!show || hide) return <></>;
  return (
    <div className={classes.container}>
      <CloseIcon className={classes.close} onClick={() => setHide(true)} />
      <div>
        <h3 className={classes.title}>Boost your campaign with auto-texts</h3>
        <div className={classes.msg}>
          {used}/{max} contacts enrolled
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          size={isMobile ? "small" : "medium"}
          color="primary"
          onClick={() => setShowAutoTexts(true)}
          startIcon={<AutoTextSVG color="white" />}
        >
          Manage auto-texts
        </Button>
      </div>
      {showAutoTexts && (
        <ManageAutoTexts onClose={() => setShowAutoTexts(false)} />
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#E6F5FF",
    padding: "16px 40px 16px 24px",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#003554",
    marginBottom: 22,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      marginTop: 12,
      marginBottom: 0,
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  close: {
    position: "absolute",
    right: 6,
    top: 6,
    color: "#003554",
    cursor: "pointer",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
      fontSize: 14,
      letterSpacing: 0,
      paddingRight: 16,
    },
  },
  msg: {
    fontSize: 14,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.13,
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 12,
    },
  },
  button: {
    width: 216,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 400,
      maxWidth: "100%",
      marginLeft: 0,
    },
  },
}));
