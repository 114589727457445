import {
  orgProfitStatuses,
  orgProfitPostCloseoutStatuses,
  repCommissionStatuses,
  poStatuses,
  downloadTypes,
  productTypes,
  wholesaleInvoiceStatuses,
  paymentPlans,
  configurableProductOptionDisplayTypes,
  productStructureTypes,
  shippingCalculatedByChoices,
  prizeProgramTierRuleDataPoints,
  campaignFulfillmentStatuses,
} from "./helpers";

const tickets = [];
for (let i = 1; i < 101; i++) {
  tickets.push({ id: i, name: i });
}

const months = [];
for (let i = 1; i < 13; i++) {
  let str = i.toString();
  if (str.length < 2) str = `0${str}`;
  months.push({ id: str, name: str });
}

const currYear = new Date().getFullYear();
const years = [];
for (let i = currYear; i < currYear + 21; i++) {
  const iStr = i.toString();
  years.push({ id: iStr.slice(2), name: i });
}

const hebrewYearOptions = [];
for (let i = currYear; i < currYear + 3; i++) {
  const hebYear = i + 3760;
  hebrewYearOptions.push({ id: hebYear, name: hebYear });
}

const englishYearOptions = [];
for (let i = currYear; i < currYear + 3; i++) {
  englishYearOptions.push({ id: i, name: i });
}

const englishMonthOptions = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const hebrewMonthOptions = [
  { id: 1, name: "Nisan" },
  { id: 2, name: "Iyar" },
  { id: 3, name: "Sivan" },
  { id: 4, name: "Tammuz" },
  { id: 5, name: "Av" },
  { id: 6, name: "Elul" },
  { id: 7, name: "Tishrei" },
  { id: 8, name: "Cheshvan" },
  { id: 9, name: "Kislev" },
  { id: 10, name: "Teves" },
  { id: 11, name: "Shevat" },
  { id: 12, name: "Adar / (I)" },
  { id: 13, name: "Adar II" },
];

const priceArr = [
  10,
  18,
  20,
  24,
  25,
  26,
  30,
  36,
  50,
  54,
  60,
  72,
  90,
  100,
  125,
  136,
  150,
  172,
  180,
  200,
  300,
  360,
  500,
];
const prices = priceArr.map(p => {
  return { id: p, name: `$${p}` };
});

const volunteerLabelOptions = [
  { id: "fundraiser", name: "fundraiser" },
  { id: "student", name: "student" },
  { id: "participant", name: "participant" },
  { id: "volunteer", name: "volunteer" },
  { id: "seller", name: "seller" },
  { id: "ambassador", name: "ambassador" },
  { id: "player", name: "player" },
  { id: "athlete", name: "athlete" },
];

const matchingOptions = [
  { id: 2, name: "2 X" },
  { id: 3, name: "3 X" },
  { id: 4, name: "4 X" },
  { id: 5, name: "5 X" },
  { id: 6, name: "6 X" },
];

const bonusMatchingOptions = [
  { id: 0, name: "None" },
  { id: 2, name: "2 X" },
  { id: 3, name: "3 X" },
  { id: 4, name: "4 X" },
  { id: 5, name: "5 X" },
  { id: 6, name: "6 X" },
];

const ageLevelOptions = [
  { id: "Elementary", name: "Elementary" },
  { id: "Middle School", name: "Middle School" },
  { id: "High School", name: "High School" },
  { id: "Other", name: "Other" },
];

const productTypeOptions = [
  { id: productTypes.PHYSICAL, name: "Physical (Regular)" },
  { id: productTypes.PRIZE, name: "Prize" },
  { id: productTypes.DONATION, name: "Donation" },
];

const ownerProductTypeOptions = [
  { id: productTypes.PHYSICAL, name: "Physical" },
  { id: productTypes.DIGITAL, name: "Digital" },
  { id: productTypes.PRIZE, name: "Prize" },
  { id: productTypes.DONATION, name: "Donation" },
];

const orgProfitStatusOptions = [
  {
    id: orgProfitStatuses.PENDING_CONFIRMATION,
    name: orgProfitStatuses.PENDING_CONFIRMATION,
  },
  {
    id: orgProfitStatuses.REP_CONFIRMED,
    name: orgProfitStatuses.REP_CONFIRMED,
  },
  {
    id: orgProfitStatuses.PROFIT_CONFIRMED,
    name: orgProfitStatuses.PROFIT_CONFIRMED,
  },
  {
    id: orgProfitStatuses.READY_FOR_PAYMENT,
    name: orgProfitStatuses.READY_FOR_PAYMENT,
  },
  { id: orgProfitStatuses.PROFIT_PAID, name: orgProfitStatuses.PROFIT_PAID },
  { id: orgProfitStatuses.INVOICE_SENT, name: orgProfitStatuses.INVOICE_SENT },
  { id: orgProfitStatuses.INVOICE_PAID, name: orgProfitStatuses.INVOICE_PAID },
];

const orgProfitPostCloseoutStatusOptions = [
  {
    id: orgProfitPostCloseoutStatuses.INVOICE_SENT,
    name: orgProfitPostCloseoutStatuses.INVOICE_SENT,
  },
  {
    id: orgProfitPostCloseoutStatuses.INVOICE_PAID,
    name: orgProfitPostCloseoutStatuses.INVOICE_PAID,
  },
];

const repCommissionStatusOptions = [
  {
    id: repCommissionStatuses.PENDING_CLOSEOUT,
    name: repCommissionStatuses.PENDING_CLOSEOUT,
  },
  {
    id: repCommissionStatuses.PENDING_PAYMENT,
    name: repCommissionStatuses.PENDING_PAYMENT,
  },
  { id: repCommissionStatuses.PAID, name: repCommissionStatuses.PAID },
];

const poStatusOptions = [
  { id: poStatuses.AWAITING, name: poStatuses.AWAITING },
  { id: poStatuses.RECEIVING, name: poStatuses.RECEIVING },
  { id: poStatuses.CLOSED, name: poStatuses.CLOSED },
];

const companyRoleOptions = [
  { id: "company_admin", name: "Admin" },
  { id: "fulfiller", name: "Order Fulfiller" },
  { id: "company_data_entry", name: "Data Entry" },
  { id: "rep_manager", name: "Rep Manager" },
];

const launchPacketSectionOptions = [
  { id: "header", name: "header" },
  { id: "material", name: "material" },
  { id: "internal", name: "internal" },
];

const wholesaleInvoiceStatusOptions = [
  {
    id: wholesaleInvoiceStatuses.PENDING,
    name: wholesaleInvoiceStatuses.PENDING,
  },
  {
    id: wholesaleInvoiceStatuses.INVOICE_SENT,
    name: wholesaleInvoiceStatuses.INVOICE_SENT,
  },
  { id: wholesaleInvoiceStatuses.PAID, name: wholesaleInvoiceStatuses.PAID },
];

const stateNames = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const shippingRateTypeOptions = [
  { id: "item count", name: "By Item Count" },
  { id: "weight", name: "By Weight" },
];

const shippingCalculatedByOptions = [
  { id: shippingCalculatedByChoices.RATE_TABLE, name: "Rate Table" },
  { id: shippingCalculatedByChoices.LIVE_RATES, name: "Live Rates" },
];

const campaignFulfillmentStatusOptions = [
  {
    id: campaignFulfillmentStatuses.FULFILLED,
    name: campaignFulfillmentStatuses.FULFILLED,
  },
  {
    id: campaignFulfillmentStatuses.READY_FOR_FULFILLMENT,
    name: campaignFulfillmentStatuses.READY_FOR_FULFILLMENT,
  },
  {
    id: campaignFulfillmentStatuses.ENDED,
    name: campaignFulfillmentStatuses.ENDED,
  },
  {
    id: campaignFulfillmentStatuses.OPEN,
    name: campaignFulfillmentStatuses.OPEN,
  },
  {
    id: campaignFulfillmentStatuses.NO_ITEMS,
    name: campaignFulfillmentStatuses.NO_ITEMS,
  },
];

const campaignShippingOptions = [
  { id: "home", name: "STH" },
  { id: "school", name: "STS" },
  { id: "both", name: "BOTH" },
];

const tipPercentOptions = [];
for (let i = 1; i <= 20; i++) {
  tipPercentOptions.push({ id: i, name: `${i}%` });
}

function downloadCodeOptions() {
  return Object.keys(downloadTypes).map(code => {
    return { id: code, name: downloadTypes[code] };
  });
}

const recurringMonthOptions = [];
for (let i = 3; i <= 36; i++) {
  recurringMonthOptions.push({ id: i, name: `${i} Months` });
}

const paymentPlanOptions = [
  { id: paymentPlans.INSTALLMENTS, name: paymentPlans.INSTALLMENTS },
  {
    id: paymentPlans.INSTALLMENTS_DEFAULTED,
    name: paymentPlans.INSTALLMENTS_DEFAULTED,
  },
  { id: paymentPlans.RECURRING, name: paymentPlans.RECURRING },
  {
    id: paymentPlans.RECURRING_DEFAULTED,
    name: paymentPlans.RECURRING_DEFAULTED,
  },
];

const configurableProductOptionDisplayTypeOptions = [
  { id: configurableProductOptionDisplayTypes.DROPDOWN, name: "Dropdown" },
  {
    id: configurableProductOptionDisplayTypes.TEXT_SWATCH,
    name: "Text Swatch",
  },
  {
    id: configurableProductOptionDisplayTypes.COLOR_SWATCH,
    name: "Color Swatch",
  },
];

const productStructureTypeOptions = [
  { id: productStructureTypes.STANDARD, name: productStructureTypes.STANDARD },
  {
    id: productStructureTypes.CONFIGURABLE,
    name: productStructureTypes.CONFIGURABLE,
  },
];

const prizeProgramTierRuleDataPointOptions = Object.values(
  prizeProgramTierRuleDataPoints,
);

const STSLabelOptions = [
  { id: "school", name: "School" },
  { id: "group", name: "Group" },
  { id: "team", name: "Team" },
  { id: "seller", name: "Seller" },
  { id: "email receipt", name: "Email receipt" },
  { id: "in hand sale", name: "In hand sale" },
];

const daysBeforeEventOptions = [];
for (let i = 1; i < 31; i++) {
  daysBeforeEventOptions.push({ id: i, name: i });
}

export {
  tickets,
  prices,
  months,
  years,
  hebrewYearOptions,
  englishYearOptions,
  volunteerLabelOptions,
  matchingOptions,
  bonusMatchingOptions,
  ageLevelOptions,
  productTypeOptions,
  ownerProductTypeOptions,
  orgProfitStatusOptions,
  repCommissionStatusOptions,
  poStatusOptions,
  companyRoleOptions,
  launchPacketSectionOptions,
  stateNames,
  shippingRateTypeOptions,
  shippingCalculatedByOptions,
  campaignFulfillmentStatusOptions,
  orgProfitPostCloseoutStatusOptions,
  campaignShippingOptions,
  downloadCodeOptions,
  englishMonthOptions,
  hebrewMonthOptions,
  tipPercentOptions,
  wholesaleInvoiceStatusOptions,
  recurringMonthOptions,
  paymentPlanOptions,
  configurableProductOptionDisplayTypeOptions,
  productStructureTypeOptions,
  prizeProgramTierRuleDataPointOptions,
  STSLabelOptions,
  daysBeforeEventOptions,
};
