import { FieldValidator } from "final-form";
import { TWO_DECIMAL_REGEX } from "../../lib";

export const requiredField: FieldValidator<any> = value =>
  value ? undefined : "Required";

export function requiredFieldGenerator(message: string) {
  const validator: FieldValidator<any> = value => (value ? undefined : message);
  return validator;
}

export const requiredCheckbox: FieldValidator<any> = value => {
  return value !== true ? "Required" : undefined;
};

export const positiveIntegerOnly: FieldValidator<any> = value =>
  value && (value === "0" || !/^[0-9]+$/.test(value))
    ? "Must be a valid integer"
    : undefined;

export const requiredPositiveInteger: FieldValidator<any> = value =>
  !value || value === "0" || !/^[0-9]+$/.test(value)
    ? "Must be a valid integer"
    : undefined;

export const requiredInteger: FieldValidator<any> = value =>
  (!value && value !== 0) || !/^[-]?[0-9]+$/.test(value)
    ? "Must be a valid integer"
    : undefined;

export const convertDonorToContactEmailOrPhoneValidation: FieldValidator<any> = (
  _value,
  allValues: any,
  meta,
) => {
  if (!meta) return null;
  const contactSlot = meta.name.replace(/\D/g, "");
  const contact = allValues.convertibleDonors[contactSlot];
  if (!contact) return null;
  const { email, phone } = contact;
  if (!email && !phone) {
    return "email or phone is required";
  }
  return null;
};

export const contactEmailOrPhoneValidation: FieldValidator<any> = (
  _value,
  allValues: any,
  meta,
) => {
  if (!meta) return null;
  const contactSlot = meta.name.replace(/\D/g, "");
  const contact = allValues.contacts[contactSlot];
  if (!contact) return null;
  const { email, phone } = contact;
  if (!email && !phone) {
    return "email or phone is required";
  }
  return null;
};

export const emailValidation: FieldValidator<any> = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export function regexValidatorGenerator(pattern: RegExp, message: string) {
  const validator: FieldValidator<any> = value =>
    value && typeof value === "string" && !pattern.test(value)
      ? message
      : undefined;
  return validator;
}

export const composeValidators = (...validators: FieldValidator<any>[]) => (
  value: any,
  allValues: object,
  fieldState: any,
) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues, fieldState),
    undefined,
  );

export const confirmPWValidation: FieldValidator<any> = (
  value,
  allValues: any,
) => {
  if (value !== allValues.newPassword) {
    return "Passwords Must Match";
  }
  return null;
};

export const twoDecimalsOnly: FieldValidator<any> = value =>
  value && !TWO_DECIMAL_REGEX.test(value) ? "Up to 2 decimals only" : undefined;
