import { useEffect, useState, useRef, ChangeEvent, Fragment } from "react";
import {
  makeStyles,
  Button,
  IconButton,
  TextField as MuiTextField,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Form, Field, FormSpy } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import {
  APIAuthClient,
  convertFileToB64,
  formatMoney,
  nonEmptyArray,
  twoDecimalsOnly,
} from "../../lib";
import { APIRes, Base64File, GenericObject, ToastTypes } from "../../types";
import { setToast } from "../../state";
import { RootState } from "../../types/state";
import { ResponsiveModal } from "../ui/ResponsiveModal";
import { MultiFileDropzoneField } from "../ui/MultiFileDropzoneField";
import { useIsMobile } from "../../hooks/ui";
import { NullableField } from "../form/NullableField";
import { TextFieldWrapper } from "../form/TextFieldWrapper";
import { CircularLoader } from "../ui/CircularLoader";

type Props = {
  donation_id: number;
  onClose: () => void;
  refreshData: () => void;
};

export function EditOrderForm({ donation_id, onClose, refreshData }: Props) {
  const { campaignId, allow_donations } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const productRef = useRef<undefined | HTMLInputElement>();
  const qtyRef = useRef<undefined | HTMLInputElement>();
  const [fetching, setFetching] = useState(true);
  const [grandTotal, setGrandTotal] = useState<0 | string>(0);
  const [itemTotal, setItemTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState<null | GenericObject>(
    null,
  );
  const [activeProductQty, setActiveProductQty] = useState<string | number>("");
  const [initialValues, setInitialValues] = useState({ selectedProducts: [] });

  useEffect(() => {
    fetchBrochure();
    fetchCampaignProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeProduct && qtyRef.current) {
      setActiveProductQty(1);
      setTimeout(() => {
        qtyRef.current && qtyRef.current.select();
      }, 20);
    }
  }, [activeProduct]);

  const fetchCampaignProducts = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      `/campaign_products/campaigns/${campaignId}/autocomplete`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setProducts(data);
  };

  const fetchBrochure = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      `/donations/get_brochure/${donation_id}`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { images, ...rest } = data;
    setInitialValues({ ...rest, images: getCurrentImages(images) });
    setFetching(false);
  };

  const isValidForm = (values: any) => {
    let isValid = true;
    const { selectedProducts, additional_donation } = values;
    if (
      !nonEmptyArray(selectedProducts) &&
      Number(additional_donation || 0) <= 0
    ) {
      dispatch(setToast("There are no items OR donation added"));
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = async (values: any, _: any, complete: any) => {
    if (!isValidForm(values)) return complete();
    const {
      selectedProducts: SP,
      additional_donation,
      brochure_student_entered_amount,
      images,
      brochures,
    } = values;
    let selectedProducts: GenericObject[] = [];
    if (nonEmptyArray(SP)) {
      selectedProducts = (SP as GenericObject[]).map(p => {
        const { id, qty, product_id } = p;
        return { id: id ? id : null, product_id, qty: Number(qty) };
      });
    }

    const update: GenericObject = {
      donation_id,
      additional_donation,
      brochure_student_entered_amount,
      images,
      selectedProducts,
      noPersonalizations: true, // don't remove
    };
    if (Array.isArray(brochures) && brochures.length) {
      const convertedBrochures: Base64File[] = [];
      for (const i in brochures) {
        const converted = await convertFileToB64(brochures[i]);
        if (converted) convertedBrochures.push(converted);
      }
      update.addedBrochures = convertedBrochures;
    }

    const url = "/donations/brochure_edit";
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Order form updated", ToastTypes.success));
    handleCloseModal();
  };

  const handleQtyChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    input?: any,
  ) => {
    const value = e.target.value;
    if ((!input && !value) || (value && /^[1-9][0-9]*$/.test(value))) {
      // if they are editing an added product there is an input, if it's a new product then set activeProductQty
      if (input) input.onChange(value);
      else setActiveProductQty(value);
    }
  };

  const handleQtyEnter = (e: any, fields: any) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && activeProduct && activeProductQty) handleAddItem(fields);
  };

  const handleAddItem = (fields: any) => {
    if (!activeProduct) return;
    const exists =
      fields.value &&
      fields.value.some((e: any) => e.product_id === activeProduct?.id);
    if (exists) {
      return dispatch(
        setToast("Item already added, please adjust it's quantity"),
      );
    }
    const {
      id: product_id,
      product_name,
      price,
      thumbnail: thumbnail_image,
      sku,
    } = activeProduct;
    fields.push({
      product_id,
      product_name,
      price,
      thumbnail_image,
      sku,
      qty: activeProductQty,
    });
    setActiveProduct(null);
    setActiveProductQty("");
    productRef.current && productRef.current.focus();
  };

  const handleCloseModal = () => {
    refreshData();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={true} onClose={onClose}>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.mobileStickyTop}>
            <IconButton size="small" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.top}>
          <div>Edit order form</div>
          {!isMobile && (
            <IconButton size="small" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          )}
        </div>

        {fetching && (
          <div className={classes.loadingWrapper}>
            <CircularLoader show />
          </div>
        )}

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form, pristine }) => {
            return (
              <form
                className={classnames(classes.form, fetching && classes.hidden)}
                onSubmit={handleSubmit}
              >
                <FormSpy
                  onChange={({ values, dirtyFieldsSinceLastSubmit }) => {
                    if (
                      Array.isArray(values.selectedProducts) &&
                      Object.keys(dirtyFieldsSinceLastSubmit).length
                    ) {
                      let ttl = 0;
                      let items = 0;
                      values.selectedProducts.forEach(({ price, qty }) => {
                        ttl = ttl + Number(price) * Number(qty);
                        items = items + Number(qty);
                      });
                      setGrandTotal(formatMoney(ttl));
                      setItemTotal(items);
                    }
                  }}
                />

                <div className={classes.subheader}>Products</div>
                <FieldArray name="selectedProducts">
                  {({ fields }) => {
                    const itemsAdded = Boolean(fields.length);
                    return (
                      <Fragment>
                        {(itemsAdded || !isMobile) && (
                          <div className={classes.products}>
                            {!isMobile && (
                              <div
                                className={classnames(
                                  classes.row,
                                  classes.headerRow,
                                )}
                              >
                                <div>Image</div>
                                <div>Sku</div>
                                <div>Name</div>
                                <div>Price</div>
                                <div>Quantity</div>
                                <div>Total</div>
                                <div />
                              </div>
                            )}

                            {fields.map((row, index) => {
                              const values = fields.value[index];
                              const length = fields.length ? fields.length : 0;
                              const lastRow = index === length - 1;
                              const {
                                sku,
                                product_name,
                                price,
                                thumbnail_image,
                                qty,
                              } = values;
                              return (
                                <div key={index} className={classes.row}>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Image
                                      </div>
                                    )}
                                    <div className={classes.imgAndDelete}>
                                      <img
                                        className={classes.thumbnail}
                                        alt="product"
                                        src={thumbnail_image}
                                      />
                                      {isMobile && (
                                        <IconButton
                                          onClick={() => fields.remove(index)}
                                          size="small"
                                        >
                                          <DeleteIcon color="primary" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Sku
                                      </div>
                                    )}
                                    <div>{sku}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Name
                                      </div>
                                    )}
                                    <div>{product_name}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Price
                                      </div>
                                    )}
                                    <div>{`$${price}`}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Quantity
                                      </div>
                                    )}
                                    <div>
                                      <Field
                                        variant="standard"
                                        type="number"
                                        name={`${row}.qty`}
                                        render={({
                                          input,
                                          meta: _rm,
                                          ...rest
                                        }) => {
                                          return (
                                            <MuiTextField
                                              {...rest}
                                              {...input}
                                              value={input.value}
                                              onFocus={e => e.target.select()}
                                              onChange={e =>
                                                handleQtyChange(e, input)
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={classnames(
                                      classes.productLineItem,
                                      classes.lastLineItem,
                                      lastRow && classes.lastRow,
                                    )}
                                  >
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Total
                                      </div>
                                    )}
                                    <div>{formatMoney(price * qty)}</div>
                                  </div>

                                  {!isMobile && (
                                    <div>
                                      <IconButton
                                        onClick={() => fields.remove(index)}
                                        size="small"
                                      >
                                        <DeleteIcon color="primary" />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                              );
                            })}

                            {!isMobile && itemsAdded && (
                              <div
                                className={classnames(
                                  classes.row,
                                  classes.totalsRow,
                                )}
                              >
                                <div>Totals</div>
                                <div />
                                <div />
                                <div />
                                <div>{itemTotal}</div>
                                <div>{grandTotal}</div>
                                <div />
                              </div>
                            )}
                            {!isMobile && !itemsAdded && (
                              <div className={classes.noItems}>
                                No products added yet. Add products below.
                              </div>
                            )}
                          </div>
                        )}

                        {isMobile && itemsAdded && (
                          <Fragment>
                            <div className={classes.ttlRow}>
                              <div>Number of items</div>
                              <div>{itemTotal}</div>
                            </div>
                            <div className={classes.ttlRow}>
                              <div>Total</div>
                              <div>{grandTotal}</div>
                            </div>
                          </Fragment>
                        )}

                        <div className={classes.selectorContainer}>
                          <Autocomplete
                            className={classes.selector}
                            value={activeProduct}
                            onChange={(_, newValue) =>
                              setActiveProduct(newValue)
                            }
                            getOptionLabel={(option: GenericObject) =>
                              `${option.sku} | ${option.product_name}`
                            }
                            options={products}
                            autoHighlight
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            filterOptions={(options, { inputValue }) => {
                              if (!inputValue) return options;
                              return options.filter(
                                ({ sku = "", product_name = "" }) =>
                                  sku === inputValue ||
                                  (product_name as string).includes(inputValue),
                              );
                            }}
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                label=""
                                placeholder="Enter or select a product to add to order"
                                inputRef={productRef}
                              />
                            )}
                          />

                          <div className={classes.qtyAndAdd}>
                            <MuiTextField
                              className={classes.qtyInput}
                              placeholder="Qty"
                              value={activeProductQty}
                              onChange={e => handleQtyChange(e)}
                              onKeyDown={e => handleQtyEnter(e, fields)}
                              type="number"
                              inputProps={{ step: 0.5 }}
                              inputRef={qtyRef}
                            />

                            <Button
                              color="primary"
                              disabled={!activeProduct || !activeProductQty}
                              onClick={() => handleAddItem(fields)}
                              className={classes.add}
                              size="large"
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </Fragment>
                    );
                  }}
                </FieldArray>

                {allow_donations && (
                  <Fragment>
                    <div className={classes.donationSpacer} />
                    <div className={classes.subheader}>Donation</div>
                    <NullableField
                      name="additional_donation"
                      placeholder="Donation amount"
                      component={TextFieldWrapper}
                      className={classes.donation}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      type="number"
                      validate={twoDecimalsOnly}
                    />
                  </Fragment>
                )}

                <div className={classes.subheader}>Upload Order Forms(s)</div>
                <MultiFileDropzoneField
                  fieldName="brochures"
                  currentFilesFieldName="images"
                />

                <NullableField
                  name="brochure_student_entered_amount"
                  label="Student entered amount collected"
                  component={TextFieldWrapper}
                  className={classes.studentEntered}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  type="number"
                  validate={twoDecimalsOnly}
                />

                <div className={classes.saveButtons}>
                  <Button
                    type="button"
                    disabled={submitting || pristine}
                    className={classes.btn}
                    color="primary"
                    onClick={() => form.submit()}
                  >
                    Save
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

function getCurrentImages(images: any) {
  if (!nonEmptyArray(images)) return [];
  const nameArr = (images as any[]).map((i: any) => i.raw as string);
  return nameArr;
}

const styles = makeStyles(theme => ({
  container: {
    width: 800,
    maxWidth: "100%",
    position: "relative",
    minHeight: 400,
  },
  top: {
    borderBottom: "1px solid #DBDEEE",
    height: 64,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 12,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      height: 36,
      alignItems: "flex-start",
      paddingLeft: 16,
    },
  },
  mobileStickyTop: {
    position: "sticky",
    top: 0,
    height: 48,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 16,
  },
  loadingWrapper: {
    marginTop: 56,
  },
  form: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
    },
  },
  hidden: {
    display: "none",
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  subheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    color: theme.palette.primary.main,
    marginBottom: 16,
  },
  products: {
    width: "100%",
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "68px .4fr 1fr 92px 90px 92px 54px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0 8px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    borderBottom: "1px solid #C9CDDE",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18px",
      height: "unset",
      display: "block",
      padding: "8px 0 0 0",
      borderBottom: "none",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  headerRow: {
    minHeight: 56,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  totalsRow: {
    borderBottom: "none",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  productLineItem: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      minHeight: 32,
      padding: "8px 16px",
      borderBottom: "1px solid #EAEBF3",
    },
  },
  lastLineItem: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 16,
      borderBottom: "1px solid #C9CDDE",
    },
  },
  lineItemLabel: {
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.15,
    width: 85,
    minWidth: 85,
  },
  imgAndDelete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  ttlRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  lastRow: {
    borderBottom: "none !important",
  },
  selectorContainer: {
    width: "100%",
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
      paddingBottom: 16,
      paddingTop: 8,
    },
  },
  selector: {
    width: "calc(100% - 202px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  qtyAndAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  add: {
    width: 90,
    height: 40,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
    },
  },
  qtyInput: {
    width: 82,
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
      margin: 0,
    },
  },
  thumbnail: {
    maxHeight: 36,
    maxWidth: 36,
  },
  noItems: {
    height: 52,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    color: theme.palette.text.secondary2,
  },
  donationSpacer: {
    height: 8,
    [theme.breakpoints.down("sm")]: {
      height: 24,
    },
  },
  donation: {
    width: "50%",
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  studentEntered: {
    width: "50%",
    marginBottom: 40,
    marginTop: 32,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 32,
    },
  },
  saveButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  btn: {
    minWidth: 120,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
