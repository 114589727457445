import { Fragment, useState } from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles, IconButton } from "@material-ui/core";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { formatTimeString } from "../../../lib";
import { formatDateOnlyString } from "../../../../lib";
import classNames from "classnames";
import { EventDetails } from "./EventDetails";
const MIN_WIDTH = 900;

type Props = {
  listProps: ListProps;
};
export function EventRows({ listProps }: Props) {
  const { rows, params, setParams, nextPage, paginationProps } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Event ID"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Event name"
                fieldName="event_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Active"
                fieldName="active"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Location"
                fieldName="location_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Date"
                fieldName="date"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Time"
                fieldName="time"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map(row => {
          return <Row key={row.id as number} row={row} listProps={listProps} />;
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Events per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  listProps: ListProps;
};
function Row({ row, listProps }: RowProps) {
  const { id, active, date, event_name, location_name, time } = row;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);

  return (
    <ListRowContainer>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{event_name}</div>
            <div>
              {active ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>{location_name}</div>
            <div>{formatDateOnlyString(date)}</div>
            <div>{formatTimeString(time as string)}</div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div
                className={classNames(
                  classes.status,
                  !active && classes.inactiveStatus,
                )}
              />
              <div className={classes.mobileContent}>
                <div className={classes.eventName}>{event_name}</div>
                <div className={classes.mobileTxt}>{location_name}</div>
                <div className={classes.mobileTxt}>
                  {formatDateOnlyString(date)}{" "}
                  {formatTimeString(time as string)}
                </div>
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EventDetails
          onClose={() => setShowEdit(false)}
          refreshList={listProps.refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "106px 1fr 92px 1fr 116px 94px 48px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: 16,
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  mobileContent: {
    overflow: "hidden",
    paddingRight: 12,
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  eventName: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
      lineHeight: "22px",
    },
  },
  mobileTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  status: {
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: "100%",
    backgroundColor: "#5FEA6B",
    marginRight: 16,
  },
  inactiveStatus: {
    backgroundColor: "#ED2A2A",
  },
}));
