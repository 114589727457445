import { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import SyncIcon from "@material-ui/icons/Sync";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "../../../types/state";
import { useIsMobile } from "../../../hooks/ui";
import { ConvertSupportersToContacts } from "../../../components/contacts/ConvertSupportersToContacts";

export function ConvertSupportersBanner() {
  const classes = styles();
  const isMobile = useIsMobile();
  const convertibleDonors = useSelector(
    (state: RootState) => state.contacts.convertibleDonors,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [hide, setHide] = useState(false);

  if (hide || !convertibleDonors.length) return <></>;
  return (
    <div className={classes.container}>
      <CloseIcon className={classes.close} onClick={() => setHide(true)} />

      <div>
        <h3 className={classes.title}>Add past supporters to your contacts</h3>
        <div className={classes.msg}>
          Save past campaign donors to your contacts for convenient and
          streamlined future engagement.
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          size={isMobile ? "small" : "medium"}
          color="primary"
          onClick={() => setShowDialog(true)}
          startIcon={<SyncIcon />}
        >
          Convert donors to contacts
        </Button>
      </div>

      <ConvertSupportersToContacts
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#E6F5FF",
    padding: "16px 40px 16px 24px",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#003554",
    marginBottom: 22,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      marginTop: 12,
      marginBottom: 0,
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  close: {
    position: "absolute",
    right: 6,
    top: 6,
    color: "#003554",
    cursor: "pointer",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
      fontSize: 14,
      letterSpacing: 0,
      paddingRight: 16,
    },
  },
  msg: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.13,
      lineHeight: "18px",
      paddingRight: 16,
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 12,
    },
  },
  button: {
    width: 252,
    minWidth: 252,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 400,
      maxWidth: "100%",
      marginLeft: 0,
    },
  },
}));
