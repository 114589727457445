import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, TextField } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { APIAuthClient, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  CircularLoader,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RootState } from "../../../types/state";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  id: any;
};

export function EditCommunicationBundle({
  onClose: _onClose,
  refreshList,
  id,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const {
    communicationAutocomplete,
    communicationAutocompleteIdMap,
  } = useSelector(({ communication }: RootState) => communication);
  const { repAutocomplete, repAutocompleteIdMap } = useSelector(
    ({ reps }: RootState) => reps,
  );
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const url = `communication_bundles/${id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setInitialValues(data);
      setFetching(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      active,
      bundle_name,
      is_rep_restricted,
      communicationIds,
      repIds,
    } = values;
    const post: GenericObject = {
      active,
      bundle_name,
      is_rep_restricted,
      communicationIds,
    };
    if (is_rep_restricted && Array.isArray(repIds) && repIds.length) {
      post.repIds = repIds;
    }
    const url = `/communication_bundles/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Edit communication bundle</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              {fetching && (
                <div className={classes.fetching}>
                  <CircularLoader show />
                </div>
              )}

              {!fetching && (
                <div className={classes.mainContent}>
                  <div className={classes.activeWrapper}>
                    <SwitchField fieldName="active" label="Active" />
                  </div>
                  <Field
                    component={TextFieldWrapper}
                    name="bundle_name"
                    label="Bundle name"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <div className={classes.subheader}>Communications</div>
                  <FieldArray name="communicationIds">
                    {({ fields }) => (
                      <div className={classes.arrayField}>
                        {fields.map((row, index) => {
                          const hasOneItem = fields.length === 1;
                          return (
                            <div key={index}>
                              <div className={classes.arrayRow}>
                                <Field
                                  name={row}
                                  fullWidth
                                  validate={requiredField}
                                  render={({ input, meta, ...rest }) => {
                                    const showError =
                                      ((meta.submitError &&
                                        !meta.dirtySinceLastSubmit) ||
                                        meta.error) &&
                                      meta.touched;
                                    return (
                                      <Autocomplete
                                        {...rest}
                                        {...input}
                                        onChange={(_, value: any) => {
                                          input.onChange(
                                            value ? value.id : null,
                                          );
                                        }}
                                        value={
                                          communicationAutocompleteIdMap[
                                            input.value
                                          ] || null
                                        }
                                        options={communicationAutocomplete}
                                        getOptionSelected={(option, value) => {
                                          return option.id === value.id;
                                        }}
                                        getOptionLabel={o => (o ? o.name : o)}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            placeholder="Select communication"
                                            helperText={
                                              showError
                                                ? meta.error || meta.submitError
                                                : undefined
                                            }
                                            error={showError}
                                          />
                                        )}
                                      />
                                    );
                                  }}
                                />
                                <div className={classes.removeWrapper}>
                                  <IconButton
                                    disabled={hasOneItem}
                                    onClick={() => fields.remove(index)}
                                  >
                                    <RemoveIcon
                                      className={
                                        !hasOneItem ? classes.remove : ""
                                      }
                                    />
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <Button
                          color="primary"
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={() => fields.push("")}
                        >
                          COMMUNICATION
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <div className={classes.subheader}>Reps</div>
                  <SwitchField
                    fieldName="is_rep_restricted"
                    label="Rep restricted"
                  />
                  <div className={classes.repSpacer} />
                  {values.is_rep_restricted && (
                    <FieldArray name="repIds">
                      {({ fields }) => (
                        <div className={classes.arrayField}>
                          {fields.map((row, index) => {
                            return (
                              <div key={index}>
                                <div className={classes.arrayRow}>
                                  <Field
                                    name={row}
                                    fullWidth
                                    validate={requiredField}
                                    render={({ input, meta, ...rest }) => {
                                      const showError =
                                        ((meta.submitError &&
                                          !meta.dirtySinceLastSubmit) ||
                                          meta.error) &&
                                        meta.touched;
                                      return (
                                        <Autocomplete
                                          {...rest}
                                          {...input}
                                          onChange={(_, value: any) => {
                                            input.onChange(
                                              value ? value.id : null,
                                            );
                                          }}
                                          value={
                                            repAutocompleteIdMap[input.value] ||
                                            null
                                          }
                                          options={repAutocomplete}
                                          getOptionLabel={o => (o ? o.name : o)}
                                          getOptionSelected={(option, value) =>
                                            option.id === value.id
                                          }
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              placeholder="Select rep"
                                              helperText={
                                                showError
                                                  ? meta.error ||
                                                    meta.submitError
                                                  : undefined
                                              }
                                              error={showError}
                                            />
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                  <div className={classes.removeWrapper}>
                                    <IconButton
                                      onClick={() => fields.remove(index)}
                                    >
                                      <RemoveIcon className={classes.remove} />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <Button
                            color="primary"
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={() => fields.push("")}
                          >
                            REP
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  )}
                </div>
              )}

              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={fetching || submitting}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  fetching: {
    padding: "50px 0",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: "8px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  activeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 18,
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  subheader: {
    padding: "16px 0",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
  },
  arrayField: {
    marginBottom: 24,
  },
  arrayRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  removeWrapper: {
    marginLeft: 16,
    width: "fit-content",
    maxWidth: "fit-content",
    color: theme.palette.error.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
  remove: {
    color: theme.palette.error.main,
  },
  repSpacer: {
    height: 12,
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
