import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { format } from "date-fns";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import DonationTagIcon from "@material-ui/icons/ReportProblemOutlined";
import { CampaignDonationQueryParams, GenericObject } from "../../../types";
import { getIsProductCampaign } from "../../../state";
import {
  ListContainer,
  ListHeaderRowContainer,
  ListNoResults,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  ResponsiveModal,
  SortableColumnHeader,
} from "../../../components";
import {
  useDrawerTransition,
  useIsDesktop,
  useIsMobile,
} from "../../../hooks/ui";
import { useList } from "../../../hooks/list";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import { ListPagination } from "../../../components/list/ListPagination";
import { RootState } from "../../../types/state";
import classNames from "classnames";
import { formatMoney } from "../../../lib";
import { DonationDetails } from "../../donations/components/DonationDetails";
import { NoResultsSVG } from "../../../assets";
import { useSelector } from "react-redux";

type Props = {
  onClose: () => void;
  volunteer_id: number;
  volunteerName: string;
  refreshParentRef: React.MutableRefObject<boolean>;
};
export function VolunteerDonations({
  onClose: _onClose,
  volunteer_id,
  volunteerName,
  refreshParentRef,
}: Props) {
  const classes = styles();
  const { isOpen, onClose: onCloseFunc } = useDrawerTransition(_onClose);
  const { campaignId, campaign_type_id } = useSelector(
    (state: RootState) => state.campaign,
  );
  const isDesktop = useIsDesktop();
  const [isReady, setIsReady] = useState(false);
  const isProduct = useSelector(getIsProductCampaign);

  const listProps = useList<CampaignDonationQueryParams>({
    baseUrl: `/donations/campaigns/${campaignId}`,
    isReady,
    initialState: { sort: "id", order: "desc", volunteer_id },
  });
  const {
    params,
    setParams,
    paginationProps,
    nextPage,
    rows,
    refreshList,
    showRows,
    noResults,
  } = listProps;

  useEffect(() => {
    if (volunteer_id && campaignId && campaign_type_id) setIsReady(true);
  }, [volunteer_id, campaignId, campaign_type_id]);

  const onClose = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCloseFunc();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <h2>
            {volunteerName}'s {isProduct ? "Sales" : "Donations"}
          </h2>
          <IconButton onClick={onClose} className={classes.close}>
            <CloseIcon />
          </IconButton>
        </div>

        <ListContainer listProps={listProps} noStyling>
          {noResults && (
            <ListNoResults
              SVG={NoResultsSVG}
              listIsFiltered={false}
              label={isProduct ? "sales" : "donations"}
            />
          )}

          {showRows && (
            <ListRowsWrapper>
              <ListRows minWidth={940}>
                {isDesktop && (
                  <ListHeaderRowContainer>
                    <div
                      className={classNames(
                        classes.row,
                        isProduct && classes.productRow,
                      )}
                    >
                      <SortableColumnHeader
                        columnName={`${isProduct ? "Order" : "Donation"} ID`}
                        fieldName="id"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Name"
                        fieldName="last_name"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Date"
                        fieldName="created_at"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Solicited by"
                        fieldName="solicited_by"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Amount"
                        fieldName="total_amount"
                        params={params}
                        setParams={setParams}
                      />
                      {isProduct && (
                        <SortableColumnHeader
                          columnName="Shipping Method"
                          fieldName="ship_to_school"
                          params={params}
                          setParams={setParams}
                        />
                      )}
                    </div>
                  </ListHeaderRowContainer>
                )}
                {rows.map((row, index) => {
                  const lastRow = index === rows.length - 1;
                  return (
                    <Row
                      key={row.id as number}
                      row={row}
                      lastRow={lastRow}
                      isProduct={isProduct}
                      refreshList={refreshList}
                      refreshParentRef={refreshParentRef}
                    />
                  );
                })}
              </ListRows>
              <ListPagination
                nextPage={nextPage}
                paginationProps={paginationProps}
                label={`${isProduct ? "Orders" : "Donations"} per page:`}
                minWidth={940}
              />
            </ListRowsWrapper>
          )}
        </ListContainer>
      </div>
    </ResponsiveModal>
  );
}
type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  isProduct: boolean;
  refreshList: () => any;
  refreshParentRef: React.MutableRefObject<boolean>;
};
function Row({
  row,
  lastRow,
  isProduct,
  refreshList,
  refreshParentRef,
}: RowProps) {
  const classes = styles();
  const [showDetails, setShowDetails] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    id,
    first_name,
    last_name,
    ship_to_school: STS,
    created_at,
    total_amount,
    solicited_by,
    post_closeout,
    post_cutoff,
  } = row;
  const tagText = getTagText({ post_cutoff, post_closeout });
  const onCloseDetails = () => setShowDetails(false);

  return (
    <ListRowContainer
      clickable
      lastRow={lastRow}
      onClick={isDesktop ? () => setShowDetails(true) : undefined}
    >
      <div className={classNames(classes.row, isProduct && classes.productRow)}>
        {isDesktop && (
          <Fragment>
            <div className={classes.id}>
              {`#${id}`}
              {tagText && (
                <Tooltip title={tagText} onClick={e => e.stopPropagation()}>
                  <DonationTagIcon className={classes.donationTag} />
                </Tooltip>
              )}
            </div>
            <div>{`${first_name} ${last_name}`}</div>
            <div>{format(new Date(created_at as string), "M/d/y, p")}</div>
            <div>{solicited_by}</div>
            <div>{formatMoney(total_amount)}</div>
            {isProduct && (
              <div className={classes.shipping}>
                {STS ? <SchoolIcon /> : <HomeIcon />}
                <div className={classes.shippingMethod}>
                  {STS ? "Ship to school" : "Ship to home"}
                </div>
              </div>
            )}
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileWrapper}>
              <div className={classes.mobileRow}>
                <div className={classes.id}>
                  {`#${id}`}
                  {tagText && (
                    <Tooltip title={tagText}>
                      <DonationTagIcon className={classes.donationTag} />
                    </Tooltip>
                  )}
                </div>
                <div className={classes.date}>
                  {format(new Date(created_at as string), "M/d/y, p")}
                </div>
              </div>
              <div className={classes.mobileRow}>
                <div className={classes.name}>
                  {`${first_name} ${last_name}`}
                </div>
                <div className={classes.amount}>
                  {formatMoney(total_amount)}
                </div>
              </div>
            </div>

            <div className={classes.mobileChevron}>
              <IconButton onClick={() => setShowDetails(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>

      {showDetails && (
        <DonationDetails
          id={id as number}
          onClose={onCloseDetails}
          refreshList={refreshList}
          refreshParentRef={refreshParentRef}
        />
      )}
    </ListRowContainer>
  );
}

function getTagText({ post_cutoff, post_closeout }) {
  if (!post_cutoff && !post_closeout) return null;
  let txt = "";
  if (post_cutoff) txt = "Post-cutoff";
  const divider = post_cutoff ? ", " : "";
  if (post_closeout) txt = `${txt}${divider}Post-closeout`;
  return txt;
}

const styles = makeStyles(theme => ({
  container: {
    width: 960,
    maxWidth: 960,
    maxHeight: "calc(100vh - 64px)",
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      overflow: "auto",
    },
  },
  top: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    borderBottom: "1px solid #C9CDDE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "16px 18px 16px 24px",
    overflowWrap: "anywhere",
    lineHeight: "26px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      padding: "40px 16px 14px 16px",
      borderBottom: "1px solid #eaebf3",
    },
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: 12,
      right: 12,
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "130px 1fr 164px .7fr 100px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 15px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 16,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  productRow: {
    gridTemplateColumns: "130px 1fr 164px .7fr 100px 146px",
  },
  id: {
    display: "flex",
    alignItems: "center",
  },
  donationTag: {
    color: "#ffbd20",
    marginLeft: 6,
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
      fontSize: 18,
    },
  },
  shipping: {
    display: "flex",
    alignItems: "center",
  },
  shippingMethod: {
    paddingLeft: 6,
  },
  mobileWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    letterSpacing: 0.1,
    fontSize: 14,
  },
  mobileRow: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  mobileChevron: {
    display: "flex",
    height: "100%",
    minWidth: 54,
    width: 54,
    padding: "0 8px 0 16px",
    alignItems: "center",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
      paddingTop: 8,
      paddingRight: 12,
    },
  },
  date: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 0.47,
      color: theme.palette.text.secondary2,
    },
  },
  amount: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.13,
      color: theme.palette.primary.main,
      paddingTop: 8,
      minWidth: "fit-content",
    },
  },
}));
