import { useDispatch, useSelector } from "react-redux";
import { APIAuthClient } from "../../lib";
import { APIRes, paths } from "../../types";
import { setToast } from "../../state";
import { Button, makeStyles } from "@material-ui/core";
import { getIsCoDataEntry } from "../../../customState";
import { ImpersonateSVG } from "../../assets/ImpersonateSVG";

export function ImpersonateVolunteer({
  volunteerId,
  renderButton = false,
}: {
  volunteerId: number;
  renderButton?: boolean;
}) {
  const dispatch = useDispatch();
  const classes = styles();
  const isDataEntry = useSelector(getIsCoDataEntry);

  const impersonate = async (e: any) => {
    e.stopPropagation();
    const res = await APIAuthClient.get<any, APIRes>(
      `/volunteers/impersonate/${volunteerId}`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { impersonationToken } = data;
    localStorage.setItem("volunteerImpersonationToken", impersonationToken);
    window.location.replace(paths.HOME);
  };

  if (renderButton) {
    return (
      <Button
        variant="text"
        color="primary"
        startIcon={<ImpersonateSVG disabled={isDataEntry} />}
        onClick={impersonate}
        size="small"
        disabled={isDataEntry}
      >
        IMPERSONATE
      </Button>
    );
  }
  return (
    <span
      onClick={impersonate}
      className={isDataEntry ? classes.disabled : classes.icon}
    >
      <ImpersonateSVG disabled={isDataEntry} />
    </span>
  );
}

const styles = makeStyles(_theme => ({
  icon: {
    cursor: "pointer",
  },
  disabled: {
    cursor: "unset",
  },
}));
