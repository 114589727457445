import { createTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    secondary2: Palette["secondary"];
  }
  interface PaletteOptions {
    secondary2: PaletteOptions["secondary"];
  }
  interface TypeText {
    secondary2: TypeText["secondary"];
  }
}

const defaultTheme = createTheme();

export const theme = createTheme({
  palette: {
    text: {
      primary: "#212121",
      secondary: "#5E5E5E",
      secondary2: "#AAAAAA",
      disabled: "#AAAAAA",
    },
    primary: { main: "#248BEF", contrastText: "#FFFFFF" },
    secondary: { main: "#212121", contrastText: "#FFFFFF" },
    secondary2: { main: "#FD7B6F", contrastText: "#FFFFFF" },
    error: { main: "#ED2A2A", contrastText: "#FFFFFF" },
    background: { default: "#FFFFFF", paper: "#FFFFFF" },
  },
  typography: {
    fontFamily: `${[
      "Graphik Web",
      "sans-serif",
      "Roboto",
      "Helvetica",
      "Arial",
    ].join(",")} !important`,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "unset",
        borderRadius: 18,
        color: "#5E5E5E",
        "&:hover": {
          opacity: 0.8,
        },
      },
      text: {
        borderRadius: 4,
      },
    },
    MuiIconButton: {
      root: {
        color: "#AAAAAA",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#5E5E5E",
        "&$checked": {
          color: "#FD7B6F !important",
        },
        "&$disabled": {
          opacity: 0.6,
        },
      },
    },
    MuiInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
        "&::placeholder": {
          color: "#AAAAAA",
          opacity: 1,
          [defaultTheme.breakpoints.down("xs")]: {
            fontSize: 13,
          },
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
        "&::placeholder": {
          color: "#AAAAAA",
          opacity: 1,
          [defaultTheme.breakpoints.down("xs")]: {
            fontSize: 13,
          },
        },
      },
      notchedOutline: {
        borderColor: "#C9CDDD",
        borderRadius: 4,
      },
    },
    MuiFilledInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
        "&::placeholder": {
          color: "#AAAAAA",
          opacity: 1,
          [defaultTheme.breakpoints.down("xs")]: {
            fontSize: 13,
          },
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 8,
      },
    },
    MuiFormHelperText: {
      root: { lineHeight: 1, color: "#5E5E5E" },
      contained: { marginRight: 0 },
    },
    MuiTablePagination: {
      select: {
        paddingLeft: "2px !important",
      },
    },
    MuiPaper: {
      root: {
        fontFamily: `${[
          "Graphik Web",
          "sans-serif",
          "Roboto",
          "Helvetica",
          "Arial",
        ].join(",")} !important`,
        lineHeight: 1,
        fontWeight: 400,
        letterSpacing: 0,
      },
    },
    MuiSwitch: {
      checked: {}, // don't remove
      track: {}, // don't remove
      switchBase: {
        "&$checked": {
          color: "#FD7B6F !important",
        },
        "&$checked + $track": {
          backgroundColor: "#FD7B6F !important",
        },
      },
    },
    MuiRadio: {
      root: {
        color: "#5E5E5E",
        "&$checked": {
          color: "#FD7B6F !important",
        },
        "&$disabled": {
          opacity: 0.6,
        },
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#5E5E5E",
        fontWeight: 500,
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      size: "small",
    },
    MuiSelect: {
      variant: "outlined",
      MenuProps: { disableScrollLock: true },
    },
    MuiButton: {
      variant: "contained",
      disableElevation: true,
    },
    MuiIconButton: {
      size: "small",
    },
  },
});
