import { Fragment, useEffect, useState } from "react";
import BlankIcon from "@material-ui/icons/BlockOutlined";
import { FieldValidator } from "final-form";
import CloseIcon from "@material-ui/icons/Close";
import { Button, TextField, makeStyles, MenuItem } from "@material-ui/core";
import { Field, useField, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import {
  rowCountOptions,
  seatCountOptions,
  SeatingMapLevels,
} from "../../../types";
import { ConfirmationDialog, TextFieldWrapper } from "../../../components";
import classNames from "classnames";
const requiredField: FieldValidator<any> = value => (value ? undefined : true);

enum Modes {
  View = "view",
  Edit = "edit",
  Conf = "Conf",
  Setup = "Setup",
}

export function Map({ level }: { level: SeatingMapLevels }) {
  const classes = styles();
  const [mode, setMode] = useState<Modes>(Modes.View);
  const [rowCount, setRowCount] = useState<number | "">("");
  const [maxSeatsPerRow, setMaxSeatsPerRow] = useState<number | "">("");
  const isOrg = level === SeatingMapLevels.Org;

  return (
    <Fragment>
      {(mode === Modes.View || mode === Modes.Conf) && <MapView />}
      {mode === Modes.Edit && isOrg && rowCount && maxSeatsPerRow && (
        <MapEdit rowCount={rowCount} maxSeatsPerRow={maxSeatsPerRow} />
      )}
      {mode === Modes.View && isOrg && (
        <div className={classes.redoBtn}>
          <Button color="secondary" onClick={() => setMode(Modes.Conf)}>
            Redo map
          </Button>
        </div>
      )}
      {mode === Modes.Setup && (
        <div>
          <div className={classes.inputGroup}>
            <TextField
              select
              className={classes.halfInput}
              label="Numbers of rows"
              value={rowCount}
              onChange={({ target }) => setRowCount(Number(target.value))}
            >
              {rowCountOptions.map(({ name, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              select
              className={classes.halfInput}
              label="Max seats per row"
              value={maxSeatsPerRow}
              onChange={({ target }) => setMaxSeatsPerRow(Number(target.value))}
            >
              {seatCountOptions.map(({ name, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <Button
            color="secondary"
            onClick={() => setMode(Modes.Edit)}
            disabled={!rowCount || !maxSeatsPerRow}
          >
            Continue
          </Button>
        </div>
      )}
      {mode === Modes.Conf && (
        <ConfirmationDialog
          title="Are you sure you want to redo the map?"
          actionFunc={(_, onClose) => {
            onClose();
            setTimeout(() => setMode(Modes.Setup), 10);
          }}
          onClose={() => setMode(Modes.View)}
          actionLabel="Continue"
        />
      )}
    </Fragment>
  );
}

function MapView() {
  const classes = styles();
  return (
    <div className={classes.map}>
      <FieldArray name="rows">
        {({ fields }) => (
          <div>
            {fields.map((row, rowIdx) => {
              const { row_identifier } = fields.value[rowIdx];
              return (
                <div key={rowIdx}>
                  <div className={classes.row}>
                    <div className={classes.rowId}>{row_identifier}</div>
                    <FieldArray name={`${row}.seats`}>
                      {({ fields }) => (
                        <div className={classes.rowSeats}>
                          {fields.map((seat, seatIdx) => {
                            const { is_blank, seat_identifier } = fields.value[
                              seatIdx
                            ];
                            return (
                              <div key={seatIdx}>
                                <div
                                  className={classNames(
                                    classes.seat,
                                    is_blank && classes.blankSeat,
                                  )}
                                >
                                  {!is_blank && seat_identifier}
                                  {is_blank && <BlankIcon />}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </FieldArray>
    </div>
  );
}

type EditProps = { rowCount: number; maxSeatsPerRow: number };
function MapEdit({ rowCount, maxSeatsPerRow }: EditProps) {
  const classes = styles();
  const rowField = useField("rows").input;
  const form = useForm();

  useEffect(() => {
    const oldArr = rowField.value;
    const newRows = Array.from({ length: rowCount }, (_, rowIdx) => {
      if (!oldArr || !oldArr[rowIdx]) {
        return {
          row_identifier: "",
          seats: new Array(maxSeatsPerRow).fill({
            seat_identifier: null,
            is_blank: false,
          }),
        };
      }

      const { row_identifier, seats } = oldArr[rowIdx];
      const newSeats = Array.from({ length: maxSeatsPerRow }, (_, seatIdx) =>
        seats && seats[seatIdx]
          ? { ...seats[seatIdx] }
          : { seat_identifier: null, is_blank: false },
      );
      return {
        row_identifier,
        seats: newSeats,
      };
    });

    rowField.onChange(newRows);
    form.change("redoRows", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.map}>
      <FieldArray name="rows">
        {({ fields }) => (
          <div>
            {fields.map((row, index) => {
              return (
                <div key={index}>
                  <div className={classes.row}>
                    <Field
                      size="small"
                      component={TextFieldWrapper}
                      placeholder="Row num"
                      name={`${row}.row_identifier`}
                      className={classes.rowIdInput}
                      validate={requiredField}
                    />
                    <FieldArray name={`${row}.seats`}>
                      {({ fields }) => (
                        <div className={classes.rowSeats}>
                          {fields.map((seat, index) => {
                            const { is_blank } = fields.value[index];
                            return (
                              <div key={index}>
                                <div>
                                  {!is_blank && (
                                    <div className={classes.seatWrapper}>
                                      <Field
                                        component={TextFieldWrapper}
                                        name={`${seat}.seat_identifier`}
                                        className={classes.seatInputWrapper}
                                        InputProps={{
                                          classes: {
                                            input: classes.seatInput,
                                          },
                                        }}
                                        validate={requiredField}
                                      />
                                      <div
                                        className={classes.makeBlank}
                                        onClick={() => {
                                          fields.update(index, {
                                            ...fields.value[index],
                                            is_blank: true,
                                          });
                                        }}
                                      >
                                        <CloseIcon fontSize="small" />
                                      </div>
                                    </div>
                                  )}
                                  {is_blank && (
                                    <div
                                      className={classNames(
                                        classes.seat,
                                        classes.blankSeat,
                                        classes.blankEdit,
                                      )}
                                      onClick={() => {
                                        fields.update(index, {
                                          ...fields.value[index],
                                          is_blank: false,
                                        });
                                      }}
                                    >
                                      <BlankIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </FieldArray>
    </div>
  );
}

const styles = makeStyles(theme => ({
  redoBtn: {
    marginTop: 24,
    marginBottom: 8,
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  map: {
    overflowY: "auto",
  },
  row: {
    display: "flex",
  },
  rowSeats: {
    display: "flex",
  },
  rowId: {
    width: 100,
    minWidth: 100,
    margin: "2px 16px 2px 0",
    height: 40,
    maxHeight: 40,
    border: `1px solid #C9CDDD`,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  seat: {
    position: "relative",
    width: 50,
    height: 40,
    maxHeight: 40,
    border: `1px solid #C9CDDD`,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2px 0 2px 4px",
  },
  blankSeat: {
    color: theme.palette.text.secondary2,
    backgroundColor: "rgba(201, 205, 221, .4)",
  },

  // edit
  rowIdInput: {
    width: 100,
    minWidth: 100,
    margin: "2px 16px 2px 0",
  },
  seatWrapper: {
    position: "relative",
  },
  seatInputWrapper: {
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    margin: "2px 0 2px 4px",
  },
  seatInput: {
    paddingLeft: 5,
    paddingRight: 4,
    paddingTop: 15,
    paddingBottom: 6,
  },
  makeBlank: {
    position: "absolute",
    top: 2,
    right: 2,
    color: theme.palette.text.secondary2,
    cursor: "pointer",
  },
  blankEdit: {
    cursor: "pointer",
  },
}));
