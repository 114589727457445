import { Theme, useMediaQuery } from "@material-ui/core";
import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../types/state";
import { Roles, paths } from "../types";
import { clearAuthStorage, getIsImpersonating } from "../lib";
import { RA_SUB_PATH } from "../../lib";
import {
  getCampaignId,
  getShouldRedirectToVolunteerSetup,
  setTopBarTitleComponent,
} from "../state";
import { useRouteMatch } from "react-router-dom";
import { getVolunteerId } from "../../customState";

export function useIsMobile() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  return isMobile;
}

export function useIsDesktop() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  return isDesktop;
}

export function useIsLGScreen() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  return isDesktop;
}

export function useIsMedScreen() {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("md"),
  );
  return isDesktop;
}

export function useScrollToTop() {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 500);
  }, []);
}

export function useLogout(campaignId?: any) {
  const stateCampaignId = useSelector(getCampaignId);
  const cId = campaignId ? campaignId : stateCampaignId;
  const stateVolId = useSelector(getVolunteerId);
  // if it's a campaign from a different org don't add the volunteer id
  const vId = campaignId ? null : stateVolId;
  // only tack on volunteer_id if !impersonating
  const isImp = getIsImpersonating();

  const logout = useCallback(() => {
    let redirect = paths.LOGIN;
    if (cId && vId && !isImp) redirect = `${redirect}?c=${cId}&v=${vId}`;
    else if (cId) redirect = `${redirect}?c=${cId}`;
    else if (vId && !isImp) redirect = `${redirect}?v=${vId}`;
    clearAuthStorage();
    window.location.replace(redirect);
  }, [cId, vId, isImp]);
  return logout;
}

export function useImpersonationFields() {
  let label = "";
  let name = "";
  const orgName = useSelector(({ campaign }: RootState) => campaign.orgName);
  const volunteerName = useSelector(
    ({ campaign }: RootState) => campaign.volunteerName,
  );

  if (localStorage.getItem("volunteerImpersonationToken")) {
    label = "Student";
    name = volunteerName;
  } else if (localStorage.getItem("orgImpersonationToken")) {
    label = "Org";
    name = orgName;
  }

  return { impersonationLabel: label, impersonationName: name };
}

export function useLeaveImpersonation() {
  const role = useSelector(({ user }: RootState) => user.role);
  const campaignId = useSelector(getCampaignId);

  const leave = useCallback(() => {
    switch (role) {
      case Roles.VOLUNTEER:
        localStorage.removeItem("volunteerImpersonationToken");
        // if is org impersonating
        if (localStorage.getItem("orgImpersonationToken")) {
          window.location.replace(paths.VOLUNTEERS);
        } else {
          window.location.replace(
            `/${RA_SUB_PATH}/campaigns/${campaignId}/show/volunteer_list`,
          );
        }
        break;
      case Roles.ORG:
        localStorage.removeItem("orgImpersonationToken");
        localStorage.removeItem("selectedCampaignId");
        const orgImpersonatePrevUrl = localStorage.getItem(
          "orgImpersonatePrevUrl",
        );
        localStorage.removeItem("orgImpersonatePrevUrl");
        if (orgImpersonatePrevUrl) {
          window.location.replace(`/${RA_SUB_PATH}${orgImpersonatePrevUrl}`);
        } else {
          window.location.replace(`/${RA_SUB_PATH}/orgs`);
        }
        break;
      default:
        window.location.replace(paths.LOGIN);
    }
  }, [campaignId, role]);
  return leave;
}

export function useDrawerTransition(
  closeFunc: () => void,
  includeDesktop = false,
) {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isDesktop && !includeDesktop) {
      setIsOpen(true);
    } else if (isMobile || (isDesktop && includeDesktop)) {
      setTimeout(() => setIsOpen(true), 200);
    }
  }, [isMobile, isDesktop, includeDesktop]);

  const onClose = useCallback(() => {
    setIsOpen(false);

    if (isDesktop && !includeDesktop) {
      setTimeout(() => closeFunc(), 10);
      return;
    }

    setTimeout(() => closeFunc(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeFunc, isMobile, isDesktop, includeDesktop]);

  return { isOpen, onClose };
}

export function useSetTopBarTitle(
  component: JSX.Element,
  dependencies: any[] = [],
) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTopBarTitleComponent(component));
    return () => {
      dispatch(setTopBarTitleComponent(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
}

export function useShouldRedirectToVolunteerSetup() {
  const shouldRedirect = useSelector(getShouldRedirectToVolunteerSetup);
  const { path } = useRouteMatch();
  return shouldRedirect && path !== paths.ADD_ASSOC_VOLUNTEER_ACCOUNT;
}
