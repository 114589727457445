// cSpell:ignore Swipeable
import { ReactNode, useEffect, useState } from "react";
import { SwipeableDrawer, makeStyles } from "@material-ui/core";
import { useIsDesktop } from "../../hooks/ui";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export function FlatTopDrawer({ children, isOpen, onClose }: Props) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const [identifier, _] = useState(Math.random());
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDesktop) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const onEnter = () => dispatch(uiActions.addModalIdentifier(identifier));
  const onExit = () => dispatch(uiActions.removeLastModalIdentifier());

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={onClose}
      onOpen={() => {}}
      classes={{ paper: classes.drawer }}
      anchor="bottom"
      transitionDuration={500}
      SlideProps={{ onEntering: onEnter, onExiting: onExit }}
    >
      <div className={classes.wrapper}>{children}</div>
    </SwipeableDrawer>
  );
}

const styles = makeStyles(_theme => ({
  drawer: {
    top: "auto",
    maxHeight: "unset",
  },
  wrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flex: 1,
    overflow: "scroll",
  },
}));
