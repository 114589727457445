import { makeStyles, Button, IconButton } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import InsertLinkOutlinedIcon from "@material-ui/icons/InsertLinkOutlined";
import { useSelector } from "react-redux";
import { NoDonationsSVG } from "../../../assets/NoDonationsSVG";
import {
  affiliateLinkWithTS,
  copyLinkWithSubAffiliate,
  emailLink,
  facebookLink,
  linkedinLink,
  textMessageLink,
  twitterLink,
  whatsappLink,
} from "../../../lib";
import { FacebookSVG } from "../../../assets/FacebookSVG";
import { TwitterSVG } from "../../../assets/TwitterSVG";
import { LinkedinSVG } from "../../../assets/LinkedinSVG";
import { WhatsappSVG } from "../../../assets/WhatsappSVG";
import { RootState } from "../../../types/state";
import { AFFILIATES } from "../../../../lib";
import { CopyLink, ListNoResults } from "../../../components";
import { getIsProductCampaign } from "../../../state";
import { getIsVolunteer } from "../../../../customState";

export function NoDonations({ listIsFiltered }: { listIsFiltered: boolean }) {
  const classes = styles();

  const { orgName, webpageLink, volunteerName } = useSelector(
    (state: RootState) => state.campaign,
  );
  const isProduct = useSelector(getIsProductCampaign);
  const isVolunteer = useSelector(getIsVolunteer);
  const link = affiliateLinkWithTS(webpageLink, AFFILIATES.NO_DONATIONS_LINKS);

  return (
    <ListNoResults
      SVG={NoDonationsSVG}
      listIsFiltered={listIsFiltered}
      label={isProduct ? "sales" : "donations"}
      paragraph={
        isVolunteer
          ? "Start by sharing your personal page with family and friends to drive your goal forward!"
          : null
      }
    >
      <div className={classes.buttons}>
        <Button
          href={textMessageLink({
            link,
            orgName,
            volunteerName,
            isVolunteer: true,
          })}
          color="primary"
          className={classes.button}
          startIcon={<ChatBubbleOutlineOutlinedIcon />}
        >
          Send a text
        </Button>
        <Button
          href={emailLink(link, orgName, isVolunteer, volunteerName)}
          color="primary"
          className={classes.button}
          startIcon={<MailOutlineIcon />}
        >
          Send an email
        </Button>
        <CopyLink link={copyLinkWithSubAffiliate(link)}>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<InsertLinkOutlinedIcon />}
          >
            Copy page link
          </Button>
        </CopyLink>
      </div>
      <div className={classes.socialMediaLinks}>
        <IconButton
          size="small"
          href={facebookLink(link)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <FacebookSVG />
        </IconButton>
        <IconButton
          size="small"
          href={twitterLink(link, orgName, isVolunteer)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <TwitterSVG />
        </IconButton>
        <IconButton
          size="small"
          href={linkedinLink(link)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <LinkedinSVG />
        </IconButton>
        <IconButton
          size="small"
          href={whatsappLink(link, orgName, isVolunteer)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <WhatsappSVG />
        </IconButton>
      </div>
    </ListNoResults>
  );
}

const styles = makeStyles(theme => ({
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 0,
      marginBottom: 24,
    },
  },
  button: {
    width: 170,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      marginBottom: 16,
    },
  },
  socialMediaLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 260,
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
