import { makeStyles, Button, TextField, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CircularLoader } from "../../../components";
import { APIAuthClient } from "../../../lib";
import { useDispatch, useSelector } from "react-redux";
import { getOrgId } from "../../../../customState";
import { setToast } from "../../../state";
import { APIRes, SeatingMapAutocomplete, ToastTypes } from "../../../types";

type Props = {
  event_id: any;
  onComplete: (createdSeatingMapId: any) => void;
};

export function SelectSeatingMap({ event_id, onComplete }: Props) {
  const classes = styles();
  const org_id = useSelector(getOrgId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [maps, setMaps] = useState<SeatingMapAutocomplete>([]);
  const [selectedMapId, setSelectedMapId] = useState<string>("");

  useEffect(() => {
    const fetch = async () => {
      const url = `/seating_maps/autocomplete?org_id=${org_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setMaps(data);
      setLoading(false);
    };
    if (org_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_id]);

  const onSave = async () => {
    setSaving(true);
    const post = { event_id, seating_map_id: selectedMapId };
    const url = "/seating_maps/create_event_map";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setSaving(false);
      return;
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    const { createdSeatingMapId } = data;
    onComplete(createdSeatingMapId);
  };

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularLoader show />
        </div>
      )}

      {!loading && (
        <div>
          <TextField
            select
            label="Select a seating map"
            value={selectedMapId}
            onChange={({ target }) => setSelectedMapId(target.value)}
            className={classes.select}
          >
            {maps.map(({ id, map_name }) => (
              <MenuItem key={id} value={id}>
                {map_name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}

      {selectedMapId && (
        <div>
          <Button
            color="secondary"
            className={classes.save}
            disabled={saving}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    padding: 24,
  },
  loadingWrapper: {
    paddingTop: 50,
  },
  select: {
    width: 300,
    maxWidth: "100%",
  },
  save: {
    width: 120,
    marginTop: 24,
  },
}));
