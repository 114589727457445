import { Fragment, useState } from "react";
import { format } from "date-fns";
import { makeStyles, IconButton, Theme, Tooltip } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import DonationTagIcon from "@material-ui/icons/ReportProblemOutlined";
import classNames from "classnames";
import {
  CampaignDonationQueryParams,
  GenericObject,
  ListProps,
} from "../../../types";
import { formatMoney } from "../../../lib";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { DonationDetails } from "./DonationDetails";
import { getIsProductCampaign, getVolunteerLabel } from "../../../state";
import { useSelector } from "react-redux";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { getIsOrgOrEmp } from "../../../../customState";
import { UpdateVolunteer } from "./UpdateVolunteer";
import { ListPagination } from "../../../components/list/ListPagination";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<CampaignDonationQueryParams>;
};
export function DonationRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const isOrg = useSelector(getIsOrgOrEmp);
  const classes = styles({ isOrg });
  const isDesktop = useIsDesktop();
  const isProduct = useSelector(getIsProductCampaign);
  const { volunteerLabelSing } = useSelector(getVolunteerLabel);

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div
              className={classNames(
                classes.row,
                isProduct && classes.productRow,
              )}
            >
              <SortableColumnHeader
                columnName={`${isProduct ? "Order" : "Donation"} ID`}
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Name"
                fieldName="last_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Date"
                fieldName="created_at"
                params={params}
                setParams={setParams}
              />
              {isOrg && (
                <SortableColumnHeader
                  columnName={volunteerLabelSing}
                  fieldName="volunteerName"
                  params={params}
                  setParams={setParams}
                />
              )}
              {isOrg && (
                <SortableColumnHeader
                  columnName="Solicited by"
                  fieldName="solicited_by"
                  params={params}
                  setParams={setParams}
                />
              )}
              <SortableColumnHeader
                columnName="Amount"
                fieldName="total_amount"
                params={params}
                setParams={setParams}
              />
              {isProduct && (
                <SortableColumnHeader
                  columnName="Shipping Method"
                  fieldName="ship_to_school"
                  params={params}
                  setParams={setParams}
                />
              )}
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              isProduct={isProduct}
              isOrg={isOrg}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label={`${isProduct ? "Orders" : "Donations"} per page:`}
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  isProduct: boolean;
  isOrg: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, isProduct, isOrg, refreshList }: RowProps) {
  const classes = styles({ isOrg });
  const [showDetails, setShowDetails] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    id,
    first_name,
    last_name,
    ship_to_school: STS,
    created_at,
    total_amount,
    volunteer_id,
    solicited_by,
    volunteerName,
    post_closeout,
    post_cutoff,
  } = row;

  const tagText = getTagText({ post_cutoff, post_closeout });
  const onCloseDetails = () => setShowDetails(false);

  return (
    <ListRowContainer
      clickable
      lastRow={lastRow}
      onClick={isDesktop ? () => setShowDetails(true) : undefined}
    >
      <div className={classNames(classes.row, isProduct && classes.productRow)}>
        {isDesktop && (
          <Fragment>
            <div className={classes.id}>
              {`#${id}`}
              {tagText && (
                <Tooltip title={tagText} onClick={e => e.stopPropagation()}>
                  <DonationTagIcon className={classes.donationTag} />
                </Tooltip>
              )}
            </div>
            <div className={classes.name}>{`${first_name} ${last_name}`}</div>
            <div>{format(new Date(created_at as string), "M/d/y, p")}</div>
            {isOrg && (
              <UpdateVolunteer
                donationId={id as number}
                currentVolunteerId={volunteer_id as null | number}
                refreshList={refreshList}
              />
            )}
            {isOrg && (
              <div
                className={classes.solicited}
                onClick={e => e.stopPropagation()}
              >
                {solicited_by}
              </div>
            )}
            <div>{formatMoney(total_amount)}</div>
            {isProduct && (
              <div className={classes.shipping}>
                {STS ? <SchoolIcon /> : <HomeIcon />}
                <div className={classes.shippingMethod}>
                  {STS ? "Ship to school" : "Ship to home"}
                </div>
              </div>
            )}
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileWrapper}>
              <div className={classes.mobileRow}>
                <div className={classes.id}>
                  {`#${id}`}
                  {tagText && (
                    <Tooltip title={tagText}>
                      <DonationTagIcon className={classes.donationTag} />
                    </Tooltip>
                  )}
                </div>
                <div className={classes.date}>
                  {format(new Date(created_at as string), "M/d/y, p")}
                </div>
              </div>
              <div className={classes.mobileRow}>
                <div className={classes.name}>
                  {`${first_name} ${last_name}`}
                </div>
                <div className={classes.amount}>
                  {formatMoney(total_amount)}
                </div>
              </div>

              {isOrg && volunteerName && (
                <div className={classes.volunteerWrapper}>
                  <PersonOutlineIcon fontSize="small" />{" "}
                  <div className={classes.volunteerName}>{volunteerName}</div>
                </div>
              )}
            </div>

            <div className={classes.mobileChevron}>
              <IconButton onClick={() => setShowDetails(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>

      {showDetails && (
        <DonationDetails
          id={id as number}
          onClose={onCloseDetails}
          refreshList={refreshList}
        />
      )}
    </ListRowContainer>
  );
}

function getTagText({ post_cutoff, post_closeout }) {
  if (!post_cutoff && !post_closeout) return null;
  let txt = "";
  if (post_cutoff) txt = "Post-cutoff";
  const divider = post_cutoff ? ", " : "";
  if (post_closeout) txt = `${txt}${divider}Post-closeout`;
  return txt;
}

type StyleProps = {
  isOrg?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: ({ isOrg }) =>
      isOrg ? "130px .35fr 166px 270px .28fr 110px" : "130px .35fr 166px 110px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  productRow: {
    gridTemplateColumns: ({ isOrg }) =>
      isOrg
        ? "130px .35fr 166px 270px .28fr 110px 164px"
        : "130px .35fr 166px 110px 164px",
  },
  mobileWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    letterSpacing: 0.1,
    fontSize: 14,
  },
  mobileRow: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  mobileChevron: {
    display: "flex",
    height: "100%",
    minWidth: 54,
    width: 54,
    padding: "0 8px 0 16px",
    alignItems: "center",
  },
  id: {
    display: "flex",
    alignItems: "center",
  },
  donationTag: {
    color: "#ffbd20",
    marginLeft: 6,
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
      fontSize: 18,
    },
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
      paddingTop: 8,
      paddingRight: 12,
    },
  },
  date: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 0.47,
      color: theme.palette.text.secondary2,
    },
  },
  amount: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.13,
      color: theme.palette.primary.main,
      paddingTop: 8,
      minWidth: "fit-content",
    },
  },
  solicited: {
    height: "100%",
    width: "100%",
    cursor: "auto",
    display: "flex",
    alignItems: "center",
    minHeight: 52,
    marginTop: -6,
    marginBottom: -6,
    paddingTop: 6,
    paddingBottom: 6,
    marginLeft: -18,
    paddingLeft: 18,
  },
  shipping: {
    display: "flex",
    alignItems: "center",
  },
  shippingMethod: {
    paddingLeft: 6,
  },
  volunteerWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    marginTop: 8,
    borderRadius: 12,
    backgroundColor: "#EFEFEF",
    height: 24,
    maxWidth: "fit-content",
    padding: "0 8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  volunteerName: {
    fontSize: 13,
    letterSpacing: 0.15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: 4,
    color: theme.palette.text.secondary,
  },
}));
