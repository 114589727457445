import { useDispatch, useSelector } from "react-redux";
import { FormApi } from "final-form";
import { Button, makeStyles } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, convertFileToB64, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import { ButtonSpinner, TextFieldWrapper } from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { getOrgId } from "../../../../customState";

type Props = {
  record: GenericObject;
  refreshOnCloseRef: React.MutableRefObject<boolean>;
  setMapName: React.Dispatch<React.SetStateAction<string>>;
};

export function EditSeatingMap({
  record,
  refreshOnCloseRef,
  setMapName,
}: Props) {
  const { id, map_name, map_image } = record;
  const classes = styles();
  const dispatch = useDispatch();
  const org_id = useSelector(getOrgId);

  const onSubmit = async (values: any, form: FormApi, complete: any) => {
    refreshOnCloseRef.current = true;
    const { imageFile, map_name } = values;
    const update: GenericObject = { org_id, map_name };
    if (imageFile) update.map_imageBase64 = await convertFileToB64(imageFile);
    const url = `/seating_maps/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    form.restart(data);
    setMapName(data.map_name);
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ map_name, map_image } as GenericObject}
        render={({ handleSubmit, submitting, form, values, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.mainContent}>
                <Field
                  component={TextFieldWrapper}
                  name="map_name"
                  label="Map name"
                  validate={requiredField}
                  className={classes.input}
                />

                <div className={classes.mapHeader}>Map image</div>
                <div className={classes.mapWrapper}>
                  <ImageDropzoneField
                    fieldName="imageFile"
                    noDelete
                    currImage={values.map_image}
                  />
                </div>
              </div>

              <div className={classes.bottom}>
                <Button
                  variant="text"
                  disabled={submitting || pristine}
                  onClick={() => form.restart()}
                >
                  CANCEL
                </Button>

                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting || pristine}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  loadingWrapper: {
    paddingTop: 50,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100vw",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    padding: 24,
    paddingBottom: 16,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  inputGroup: {
    display: "flex",
    width: 500,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: 500,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      width: "100%",
    },
  },
  activeContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 32,
    position: "relative",
  },
  infoIcon: {
    marginLeft: 8,
  },
  mapHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 16,
  },
  mapWrapper: {
    width: 500,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  notificationsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
  },
  notificationSubheader: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
    },
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
      marginBottom: 8,
    },
  },
}));
