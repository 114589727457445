import React, { useState, useEffect, useRef } from "react";
import { ImageInput, ImageField, useNotify, useRefresh } from "react-admin";
import {
  APIClient,
  convertFileToBase64,
  generateAuthHeader,
  isCompanyOrCompanyAdminOrRep,
  isVolunteer,
  requiredPositiveInteger,
  twoDecimalsOnly,
} from "../../lib";
import {
  makeStyles,
  Button,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableFooter,
  InputAdornment,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { TextField } from "final-form-material-ui";
import { Form, Field, useField } from "react-final-form";
import { Personalization } from "./Personalization";
import { NullableField } from "../../newApp/components";

export function BrochureEdit(props) {
  const {
    id,
    campaign_id,
    forExpand,
    associatedDonation,
    allowDonations,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const history = useHistory();
  const productRef = useRef();
  const qtyRef = useRef();
  const [products, setProducts] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeProductQty, setActiveProductQty] = useState("");
  const role = useSelector(state => state.user.role);
  const volunteerUser = isVolunteer(role);
  const coOrRepUser = isCompanyOrCompanyAdminOrRep(role);

  useEffect(() => {
    if (id && campaign_id) {
      fetchDonation();
      fetchCampaignProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, campaign_id]);

  useEffect(() => {
    if (activeProduct && qtyRef.current) qtyRef.current.focus();
  }, [activeProduct]);

  const fetchDonation = async () => {
    const res = await APIClient.get(`/donations/get_brochure/${id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setInitialValues(data);
  };

  const fetchCampaignProducts = async () => {
    const res = await APIClient.get(
      `/campaign_products/campaigns/${campaign_id}/autocomplete`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProducts(data);
  };

  const handleQtyChange = (e, input) => {
    const value = e.target.value;
    if (value && /^[1-9][0-9]*$/.test(value)) {
      if (input) input.onChange(value);
      else setActiveProductQty(value);
    }
  };

  const handleQtyEnter = (e, fields) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && activeProduct && activeProductQty) handleAddItem(fields);
  };

  const handleAddItem = fields => {
    const exists =
      fields.value && fields.value.some(e => e.product_id === activeProduct.id);
    if (exists) {
      return notify(
        "Item already added, please adjust it's quantity",
        "warning",
      );
    }
    const {
      id: product_id,
      product_name,
      thumbnail: thumbnail_image,
      sku,
      can_personalize,
      personalize_max_chars,
    } = activeProduct;
    fields.push({
      product_id,
      product_name,
      thumbnail_image,
      sku,
      qty: activeProductQty,
      can_personalize,
      personalize_max_chars,
      openPersonalization: can_personalize,
    });
    setActiveProduct(null);
    setActiveProductQty("");
    productRef.current && productRef.current.focus();
  };

  const onSubmit = async values => {
    const {
      selectedProducts = [],
      addedBrochures,
      images = [],
      brochure_student_entered_amount,
      internal_notes,
      company_internal_notes,
      additional_donation,
    } = values;

    const formattedSP = selectedProducts.map(sp => {
      const { id, qty, product_id, personalization: p13n } = sp;
      const personalization = Array.isArray(p13n) ? p13n : null;
      return {
        id: id ? id : null,
        product_id,
        qty: Number(qty),
        personalization,
      };
    });

    const data = {
      donation_id: Number(id),
      selectedProducts: formattedSP,
      additional_donation,
      brochure_student_entered_amount: brochure_student_entered_amount
        ? brochure_student_entered_amount
        : null,
      images: images.map(({ raw }) => raw),
    };

    if (!volunteerUser) {
      data.internal_notes = internal_notes ? internal_notes : null;
    }
    if (coOrRepUser) {
      data.company_internal_notes = company_internal_notes
        ? company_internal_notes
        : null;
    }

    if (addedBrochures) {
      try {
        const convertJobs = [];
        addedBrochures.forEach(b => {
          convertJobs.push(convertFileToBase64(b, undefined, true));
        });
        const convertedBrochures = await Promise.all(convertJobs);
        data.addedBrochures = convertedBrochures;
      } catch (error) {
        return notify(
          "There was an error with your attachment, please try again",
          "warning",
        );
      }
    }

    const res = await APIClient.put("/donations/brochure_edit", data, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Order Form has been successfully updated");
    if (forExpand) {
      dispatch({
        type: "RA/TOGGLE_LIST_ITEM_EXPAND",
        payload: id,
        meta: {
          resource: associatedDonation ? "donations/associated" : "donations",
        },
      });
      setTimeout(() => {
        refresh();
      }, 100);
    } else {
      history.push("/donations");
    }
  };

  const EditPersonalization = ({ rowName }) => {
    const openField = useField(`${rowName}.openPersonalization`);
    return (
      <IconButton
        className={classes.editIcon}
        onClick={() => openField.input.onChange(true)}
        size="small"
      >
        <EditIcon />
      </IconButton>
    );
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.title}>Edit Order Form</div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ submitting, form }) => {
            return (
              <form onSubmit={e => e.preventDefault()}>
                <TableContainer component={Paper} className={classes.table}>
                  <FieldArray name="selectedProducts">
                    {({ fields }) => (
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Thumbnail</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields.map((row, index) => {
                            const values = fields.value[index];
                            const {
                              product_name,
                              sku,
                              thumbnail_image,
                              can_personalize,
                              personalize_max_chars,
                            } = values;
                            return (
                              <TableRow key={index}>
                                <TableCell>{sku}</TableCell>
                                <TableCell>{product_name}</TableCell>
                                <TableCell>
                                  <img
                                    className={classes.thumbnail}
                                    alt="thumbnail"
                                    src={thumbnail_image}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Field
                                    className={classes.qtyInput}
                                    variant="standard"
                                    component={TextField}
                                    name={`${row}.qty`}
                                    validate={requiredPositiveInteger}
                                  />
                                </TableCell>
                                <TableCell>
                                  <div className={classes.icons}>
                                    {can_personalize && (
                                      <EditPersonalization rowName={row} />
                                    )}
                                    {can_personalize && (
                                      <Personalization
                                        rowName={row}
                                        maxChars={personalize_max_chars}
                                      />
                                    )}
                                    <IconButton
                                      onClick={() => fields.remove(index)}
                                      size="small"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Autocomplete
                                value={activeProduct}
                                onChange={(_, newValue) =>
                                  setActiveProduct(newValue)
                                }
                                getOptionLabel={option =>
                                  `${option.sku} | ${option.product_name}`
                                }
                                options={products}
                                autoHighlight
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={params => (
                                  <MuiTextField
                                    className={classes.autocomplete}
                                    {...params}
                                    label=""
                                    placeholder="Enter or select an item to add to order"
                                    inputRef={productRef}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <MuiTextField
                                className={classes.qtyInput}
                                label="Qty"
                                value={activeProductQty}
                                onChange={e => handleQtyChange(e)}
                                onKeyDown={e => handleQtyEnter(e, fields)}
                                type="number"
                                step="1"
                                inputRef={qtyRef}
                              />
                            </TableCell>
                            <TableCell className={classes.iconCol}>
                              <IconButton
                                color="primary"
                                disabled={!activeProduct || !activeProductQty}
                                onClick={() => handleAddItem(fields)}
                              >
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )}
                  </FieldArray>
                </TableContainer>

                <TableContainer component={Paper} className={classes.table}>
                  <FieldArray name="images">
                    {({ fields }) => (
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              Current Order Forms
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!fields.length && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                There are no Order Forms!
                              </TableCell>
                            </TableRow>
                          )}
                          {fields.map((_row, index) => {
                            const { full } = fields.value[index];
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  <img
                                    src={full}
                                    alt="order_form"
                                    className={classes.orderForm}
                                  />
                                </TableCell>

                                <TableCell>
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                    size="small"
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </FieldArray>
                </TableContainer>
                <div className={classes.addOF}>
                  <ImageInput
                    source="addedBrochures"
                    label="Add Order Form(s)"
                    accept="image/*, application/pdf"
                    multiple
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.addedOF }}
                    />
                  </ImageInput>
                </div>

                <Field
                  name="brochure_student_entered_amount"
                  variant="standard"
                  label="Student entered amount collected"
                  component={TextField}
                  className={classes.bottomInputs}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  type="number"
                  validate={twoDecimalsOnly}
                />

                {allowDonations && (
                  <NullableField
                    name="additional_donation"
                    variant="standard"
                    label="Donation amount"
                    component={TextField}
                    className={classes.bottomInputs}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    type="number"
                    validate={twoDecimalsOnly}
                  />
                )}

                {!volunteerUser && (
                  <Field
                    className={classes.bottomInputs}
                    variant="standard"
                    component={TextField}
                    name="internal_notes"
                    label="Internal notes"
                    multiline
                    fullWidth
                  />
                )}

                {coOrRepUser && (
                  <Field
                    className={classes.bottomInputs}
                    variant="standard"
                    component={TextField}
                    name="company_internal_notes"
                    label="Company internal notes"
                    multiline
                    fullWidth
                  />
                )}

                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  className={classes.button}
                  onClick={() => form.submit()}
                >
                  save
                </Button>
              </form>
            );
          }}
        />
      </Paper>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    marginTop: 16,
  },
  paper: {
    minHeight: 400,
    padding: 24,
  },
  title: {
    // textAlign: "center",
    fontSize: 30,
    fontWeight: 600,
    paddingBottom: 24,
  },
  table: {
    marginBottom: 24,
    width: "fit-content",
    minWidth: 500,
  },
  thumbnail: {
    maxHeight: 40,
  },
  icons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  editIcon: {
    marginRight: 16,
  },
  qtyInput: {
    width: 82,
  },
  autocomplete: {
    minWidth: 500,
  },
  orderForm: {
    maxHeight: 200,
    maxWidth: 500,
  },
  addOF: {
    maxWidth: 500,
  },
  addedOF: {
    maxHeight: 200,
  },
  bottomInputs: {
    display: "block",
    marginTop: 24,
    marginBottom: 36,
  },
}));
