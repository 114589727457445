import { makeStyles, Tooltip } from "@material-ui/core";
import { ReactElement, ReactNode } from "react";

interface TooltipProps {
  title: NonNullable<ReactNode>;
  children: ReactElement<any, any>;
}

export function CustomTooltip({ title, children }: TooltipProps) {
  const classes = styles();

  return (
    <Tooltip
      title={title}
      disableFocusListener
      classes={{
        tooltip: classes.tooltipContainer,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltip,
      }}
    >
      {children}
    </Tooltip>
  );
}

const styles = makeStyles(_theme => ({
  tooltipContainer: {
    backgroundColor: "#1B47AA",
    maxWidth: 340,
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: 8,
    padding: 12,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  arrow: {
    display: "none",
  },
  tooltip: {
    marginTop: "4px !important",
  },
}));
