import { Fragment } from "react";
import { ListProps } from "../../../types";
import {
  FiltersDrawer,
  FilterWrapper,
  ToggleFilter,
} from "../../../components";
import { EventTicketFilterFields } from "../../../types/eventTickets";
import { makeStyles, TextField, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function Filters({ onClose, listProps }: Props) {
  const { getFilterValue, isActiveFilter, updateTempFilters } = listProps;
  const classes = styles();
  const campaignEventAutocomplete = useSelector(
    ({ campaign }: RootState) => campaign.campaignEventAutocomplete,
  );

  const onEventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target,
      currentTarget: { attributes },
    } = e;
    const event = attributes.getNamedItem("data-event")?.value || "";
    updateTempFilters({
      field: EventTicketFilterFields.event_id,
      value: target.value,
      chipText: `Event - ${event}`,
    });
  };

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter tickets"
    >
      <Fragment>
        <ToggleFilter
          header="Ticket status"
          truthyLabel="Used"
          falsyLabel="Unused"
          truthyChipText="Used tickets"
          falsyChipText="Unused tickets"
          field={EventTicketFilterFields.used}
          listProps={listProps}
        />
        <FilterWrapper
          header="Event"
          active={isActiveFilter(EventTicketFilterFields.event_id)}
        >
          <TextField
            select
            fullWidth
            value={getFilterValue(EventTicketFilterFields.event_id)}
            onChange={onEventChange}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem className={classes.labelOption} value="">
              Select an event
            </MenuItem>
            {campaignEventAutocomplete.map(
              ({ event_id, event_name }, index) => (
                <MenuItem key={index} value={event_id} data-event={event_name}>
                  {event_name}
                </MenuItem>
              ),
            )}
          </TextField>
        </FilterWrapper>
      </Fragment>
    </FiltersDrawer>
  );
}

const styles = makeStyles(theme => ({
  labelOption: {
    color: theme.palette.text.secondary2,
  },
}));
