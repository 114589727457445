import { CircularLoader, ResponsiveModal } from "../../../components";
import { useDrawerTransition } from "../../../hooks/ui";
import { useAppDispatch } from "../../../types/state";
import { getCampaignId, setToast } from "../../../state";
import { APIRes, GenericObject, VolunteerDetailModes } from "../../../types";
import { APIAuthClient } from "../../../lib";
import { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { VolunteerEdit } from "./volunteerDetailComponents/VolunteerEdit";
import { VolunteerView } from "./volunteerDetailComponents/VolunteerView";
import { Top } from "./volunteerDetailComponents/Top";
import { DeleteVolunteer } from "./DeleteVolunteer";
import { SchoolathonManage } from "./SchoolathonManage";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  id: number;
  rowData: GenericObject;
};
export function VolunteerDetails({
  id,
  onClose: _onClose,
  refreshList,
  rowData,
}: Props) {
  const { isOpen, onClose: onCloseFunc } = useDrawerTransition(_onClose);
  const dispatch = useAppDispatch();
  const classes = styles();
  const campaignId = useSelector(getCampaignId);
  const containerRef = useRef<null | HTMLDivElement>(null);
  const refreshParentRef = useRef(false);
  const [data, setData] = useState<GenericObject>({});
  const [loaded, setLoaded] = useState(false);
  const [mode, setMode] = useState<VolunteerDetailModes>(
    VolunteerDetailModes.view,
  );
  const [showDelete, setShowDelete] = useState(false);
  const [showSchoolathon, setShowSchoolathon] = useState(false);

  useEffect(() => {
    if (id) fetchVolunteer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [mode]);

  const onClose = () => {
    if (refreshParentRef.current) refreshList();
    else onCloseFunc();
  };

  const fetchVolunteer = async () => {
    const url = `/volunteers/${id}/details?campaign_id=${campaignId}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return onClose();
    }
    setData(data);
    setLoaded(true);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container} ref={containerRef}>
        <Top
          mode={mode}
          setMode={setMode}
          data={data}
          onClose={onClose}
          setShowDelete={setShowDelete}
          refreshList={refreshList}
          refreshData={fetchVolunteer}
          refreshParentRef={refreshParentRef}
        />
        <div className={classes.content}>
          {!loaded && <CircularLoader show />}
          {loaded && mode === VolunteerDetailModes.view && (
            <VolunteerView data={data} rowData={rowData} />
          )}
          {loaded && mode === VolunteerDetailModes.edit && (
            <VolunteerEdit
              data={data}
              onClose={onClose}
              refreshList={refreshList}
              setMode={setMode}
              setShowDelete={setShowDelete}
            />
          )}
        </div>
      </div>
      {showDelete && (
        <DeleteVolunteer
          onClose={() => setShowDelete(false)}
          setMode={setMode}
          refreshList={refreshList}
          data={data}
          refreshData={fetchVolunteer}
        />
      )}
      {showSchoolathon && (
        <SchoolathonManage
          onClose={() => setShowSchoolathon(false)}
          record={data}
        />
      )}
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 700,
    maxWidth: "100%",
    overflowY: "auto",
  },
  content: {
    width: "100%",
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 24,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
      paddingBottom: 0,
    },
  },
}));
