import { FieldMetaState } from "react-final-form";

export function getErrorFromMeta(meta: FieldMetaState<any>) {
  const { submitError, dirtySinceLastSubmit, error, touched } = meta;
  const showError = Boolean(
    ((submitError && !dirtySinceLastSubmit) || error) && touched,
  );
  const helperText = showError ? error || submitError : undefined;

  return { showError, helperText };
}
