export enum DeliveryStatuses {
  FAILED = "failed",
  DELIVERED = "delivered",
  PENDING = "pending",
}

export type Contact = {
  name: string;
  id: number;
  email: string | null;
  phone: string | null;
  invalid_email?: boolean;
  lastEmail?: null | {
    created_at: Date;
    status: DeliveryStatuses;
    isManual: boolean;
  };
  manualEmails?: null | { created_at: Date; status: DeliveryStatuses }[];
  dripEmails?: null | { created_at: Date; status: DeliveryStatuses }[];
  emailsSentCount?: null | number;
  alreadySupported?: null | boolean;
  invalid_phone?: boolean;
  lastText?: null | {
    created_at: Date;
    status: DeliveryStatuses;
    isManual: boolean;
  };
  lastManualText?: null | { created_at: Date };
  lastDripText?: null | { created_at: Date; status?: DeliveryStatuses };
  manualTexts?: null | { created_at: Date }[];
  dripTexts?: null | { created_at: Date; status: DeliveryStatuses }[];
  hasTextConsent?: boolean;
};

export type UpdateEmailRecipients = (
  id: number,
  email: string | null,
  add: boolean,
  clearOthers?: boolean,
) => void;
