import {
  makeStyles,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { useIsMobile, useDrawerTransition } from "../../../hooks/ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getIsLoadingDownload } from "../../../state";
import { fetchAndDownloadCsv } from "../../../exports/fetchAndDownloadMethods";
import { RootState } from "../../../types/state";

type Props = { onClose: () => void };

export function TicketExport({ onClose: _onClose }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const campaignEventAutocomplete = useSelector(
    ({ campaign }: RootState) => campaign.campaignEventAutocomplete,
  );
  const isLoading = useSelector(getIsLoadingDownload);
  const [eventId, setEventId] = useState("");

  const download = async () => {
    const fileName = `tickets`;
    const url = `/event_tickets/export?event_id=${eventId}`;
    await fetchAndDownloadCsv(url, fileName, onClose);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={() => {}}>
      <div className={classes.container}>
        <div className={classes.top}>
          {isMobile && (
            <div className={classes.close}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.header}>Download tickets</div>
        </div>

        <div className={classes.content}>
          <div className={classes.label}>Select an event</div>
          <TextField
            value={eventId}
            select
            fullWidth
            onChange={({ target }) => setEventId(target.value)}
          >
            {campaignEventAutocomplete.map(({ event_id, event_name }) => {
              return (
                <MenuItem key={event_id} value={event_id}>
                  {event_name}
                </MenuItem>
              );
            })}
          </TextField>
        </div>

        <div className={classes.bottom}>
          <Button
            variant="text"
            color={isMobile ? "primary" : "default"}
            onClick={onClose}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={isLoading || !eventId}
            onClick={download}
          >
            Download
            <ButtonSpinner show={isLoading} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    width: "100%",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    borderBottom: "1px solid #DBDEEE",
  },
  close: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
    paddingBottom: 2,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "24px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  content: {
    flex: 1,
    padding: "24px 24px 0 24px",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
    },
  },
  section: {
    marginBottom: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 8,
  },
  bottom: {
    width: "100%",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    padding: 24,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "16px 16px 8px 16px",
      alignItems: "center",
    },
  },
  actionBtn: {
    minWidth: 120,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));
