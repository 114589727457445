import { SearchFields } from "./list";

export const seatingMapSearchFields: SearchFields = [
  { label: "ID", field: "id", placeholder: "Map ID", isNumber: true },
  { label: "Name", field: "name", placeholder: "Map name" },
];

export const seatingMapSortFields = [
  { field: "id", label: "Map ID" },
  { field: "map_name", label: "Map name" },
  { field: "seat_count", label: "Seats" },
];

export const seatingMapSectionSearchFields: SearchFields = [
  { label: "ID", field: "id", placeholder: "Section ID", isNumber: true },
  { label: "Name", field: "name", placeholder: "Section name" },
];

export const seatingMapSectionSortFields = [
  { field: "id", label: "Section ID" },
  { field: "section_name", label: "Section name" },
  { field: "section_seat_count", label: "Seats" },
  { field: "order_number", label: "Order" },
  { field: "allow_open_seating", label: "Open seating" },
  { field: "allow_assigned_seating", label: "Assigned seating" },
];

export const rowCountOptions: { name: string; value: number }[] = [];
export const seatCountOptions: { name: string; value: number }[] = [];

for (let i = 1; i < 101; i++) {
  let rowName = "";
  let seatName = "";
  if (i === 1) {
    rowName = `${i} Row`;
    seatName = `${i} Seat`;
  } else {
    rowName = `${i} Rows`;
    seatName = `${i} Seats`;
  }
  rowCountOptions.push({ name: rowName, value: i });
  seatCountOptions.push({ name: seatName, value: i });
}

export type SeatingMapAutocomplete = { id: number; map_name: string }[];

export enum SeatingMapLevels {
  Org = "org",
  Event = "event",
}
