// cSpell:ignore Swipeable
import { makeStyles, SwipeableDrawer, Menu } from "@material-ui/core";
import { Fragment, ReactNode, useState } from "react";
import { useIsMobile } from "../../../hooks/ui";
import { useSelector } from "react-redux";
import {
  contactActions,
  getCampaignId,
  getIsLoadingDownload,
  getIsProductCampaign,
  getVolunteerLabel,
} from "../../../state";
import classNames from "classnames";
import { isBigKahunaCompany } from "../../../../lib";
import { ConfirmationDialog } from "../../../components";
import {
  STSStudentInfoReport,
  bigKahunaLargeOrderStudentPhoneNumbers,
  bigKahunaLimoReport,
  campaignVolunteerListDownload,
  orderFormDiscrepancyReport,
  teachersReport,
} from "../../../exports/volunteer/campaignVolunteerDownloads";
import { VolunteerUpload } from "./actionDialogs/VolunteerUpload";
import { ListProps } from "../../../types";
import { EmailVolunteers } from "./actionDialogs/EmailVolunteers";
import { BulkActionUpdates } from "./actionDialogs/BulkActionUpdates";
import { AddVolunteer } from "./AddVolunteer";
import { ActivateOldVolunteers } from "./actionDialogs/ActivateOldVolunteers";
import { StudentDeliveryNumbers } from "./actionDialogs/StudentDeliveryNumbers";
import { VolunteersReport } from "./actionDialogs/VolunteersReport";
import { RootState, useAppDispatch } from "../../../types/state";
import { getIsOrgAdmin, getIsOrgOrEmp } from "../../../../customState";
const MENU_WIDTH = 290;
const BUTTON_WIDTH = 160;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
  listProps: ListProps<any>;
};
export function ActionsMenu(props: Props) {
  const { isOpen, onClose, anchorEl, listProps } = props;
  const {
    refreshList,
    toggleMobileSelectRowsMode,
    setMobileBulkSelectHeaderProps,
  } = listProps;
  const classes = styles();
  const dispatch = useAppDispatch();
  const isOrgOrOrgEmployee = useSelector(getIsOrgOrEmp);
  const [activateOld, setActivateOld] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showSendEmails, setShowSendEmails] = useState(false);
  const [showConfirmContacts, setShowConfirmContacts] = useState(false);
  const [showStudentNums, setShowStudentNums] = useState(false);
  const [showBKSmallOrders, setShowBKSmallOrders] = useState(false);
  const [showVolunteersReport, setShowVolunteersReport] = useState(false);
  const isLoading = useSelector(getIsLoadingDownload);
  const {
    volunteerLabel,
    volunteerLabelLC,
    volunteerLabelLCSingular,
    volunteerLabelSing,
  } = useSelector(getVolunteerLabel);
  const campaignId = useSelector(getCampaignId);
  const { companyId } = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const isProduct = useSelector(getIsProductCampaign);
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const isBK = isBigKahunaCompany(companyId);

  const onClick = (actionFunc: () => void) => {
    actionFunc();
    onClose();
  };

  return (
    <Fragment>
      <ResponsiveMenu isOpen={isOpen} onClose={onClose} anchorEl={anchorEl}>
        <div
          className={classNames(
            classes.container,
            isLoading && classes.loading,
          )}
        >
          {isLoading && <div className={classes.loadingOverlay} />}

          {/* ADD VOLUNTEERS */}
          <div className={classes.subHeader}>Add {volunteerLabelLC}</div>
          <div
            className={classes.row}
            onClick={() => onClick(() => setShowAdd(true))}
          >
            Add a {volunteerLabelLCSingular}
          </div>

          <div
            className={classes.row}
            onClick={() => onClick(() => setShowUpload(true))}
          >
            Upload {volunteerLabelLC}
          </div>

          {isOrgAdmin && (
            <div
              className={classes.row}
              onClick={() => onClick(() => setActivateOld(true))}
            >
              Activate old {volunteerLabelLC}
            </div>
          )}

          {/* VOLUNTEER MANAGEMENT */}
          <div className={classes.divider} />
          <div className={classes.subHeader}>
            {volunteerLabelSing} management
          </div>
          {isMobile && (
            <Fragment>
              {isOrgOrOrgEmployee && (
                <div
                  className={classes.row}
                  onClick={() => {
                    setMobileBulkSelectHeaderProps({
                      buttonLabel: "Send emails",
                      callback: () => setShowSendEmails(true),
                    });
                    toggleMobileSelectRowsMode(true);
                    onClose();
                  }}
                >
                  Bulk send emails
                </div>
              )}
              <BulkActionUpdates
                listProps={listProps}
                className={classes.row}
                onClose={onClose}
              />
            </Fragment>
          )}
          <div
            className={classes.row}
            onClick={() => onClick(() => setShowConfirmContacts(true))}
          >
            Confirm all {volunteerLabelLC} contacts
          </div>

          {/* EXPORTS */}
          <div className={classes.divider} />
          <div className={classes.subHeader}>Exports</div>
          <div
            className={classes.row}
            onClick={() =>
              campaignVolunteerListDownload(campaignId, volunteerLabel, onClose)
            }
          >
            Export all {volunteerLabelLC}
          </div>
          {isProduct && (
            <Fragment>
              <div
                className={classes.row}
                onClick={() => onClick(() => setShowStudentNums(true))}
              >
                Export delivery numbers
              </div>
              <div
                className={classes.row}
                onClick={() => orderFormDiscrepancyReport(campaignId, onClose)}
              >
                Export order form discrepancies
              </div>
              {isBK && (
                <Fragment>
                  <div
                    className={classes.row}
                    onClick={() => onClick(() => setShowBKSmallOrders(true))}
                  >
                    Export small orders
                  </div>
                  <div
                    className={classes.row}
                    onClick={() =>
                      STSStudentInfoReport(
                        campaignId,
                        volunteerLabelSing,
                        onClose,
                      )
                    }
                  >
                    Export STS {volunteerLabelLCSingular} info
                  </div>
                  <div
                    className={classes.row}
                    onClick={() =>
                      bigKahunaLargeOrderStudentPhoneNumbers(
                        campaignId,
                        volunteerLabelSing,
                        onClose,
                      )
                    }
                  >
                    Export large order phone numbers
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}

          {/* REPORTS */}
          <div className={classes.divider} />
          <div className={classes.subHeader}>Reports</div>
          <div
            className={classes.row}
            onClick={() => onClick(() => setShowVolunteersReport(true))}
          >
            {volunteerLabel} report
          </div>
          {isProduct && (
            <Fragment>
              <div
                className={classes.row}
                onClick={() =>
                  onClick(() => teachersReport(campaignId, onClose))
                }
              >
                Teachers report - items
              </div>
              {isBK && (
                <Fragment>
                  <div
                    className={classes.row}
                    onClick={() =>
                      onClick(() => bigKahunaLimoReport(campaignId, onClose))
                    }
                  >
                    Monster limo report
                  </div>
                  <div
                    className={classes.row}
                    onClick={() =>
                      onClick(() =>
                        bigKahunaLimoReport(campaignId, onClose, true),
                      )
                    }
                  >
                    Blast party report
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </ResponsiveMenu>

      {showAdd && (
        <AddVolunteer
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
      {showUpload && (
        <VolunteerUpload
          onClose={() => setShowUpload(false)}
          refreshList={refreshList}
        />
      )}
      {showSendEmails && (
        <EmailVolunteers
          listProps={listProps}
          onClose={() => setShowSendEmails(false)}
        />
      )}
      {showConfirmContacts && (
        <ConfirmationDialog
          onClose={() => setShowConfirmContacts(false)}
          title={`Are you sure you want to confirm all ${volunteerLabelLC} contacts?`}
          subtitle={`confirming ${volunteerLabelLCSingular} contacts will allow emails to be sent to all ${volunteerLabelLCSingular} contacts!`}
          actionLabel="Confirm"
          actionFunc={(_, _onClose) => {
            dispatch(
              contactActions.confirmCampaignContacts(refreshList, onClose),
            );
            _onClose();
          }}
        />
      )}
      {activateOld && (
        <ActivateOldVolunteers
          onClose={() => setActivateOld(false)}
          refreshParentList={refreshList}
        />
      )}
      {showStudentNums && (
        <StudentDeliveryNumbers onClose={() => setShowStudentNums(false)} />
      )}
      {showBKSmallOrders && (
        <StudentDeliveryNumbers
          bigKahunaSmallOrders
          onClose={() => setShowBKSmallOrders(false)}
        />
      )}
      {showVolunteersReport && (
        <VolunteersReport onClose={() => setShowVolunteersReport(false)} />
      )}
    </Fragment>
  );
}

type ResponsiveProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
};
export function ResponsiveMenu({
  children,
  isOpen,
  onClose,
  anchorEl,
}: ResponsiveProps) {
  const classes = styles();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
        classes={{ paper: classes.drawer }}
        transitionDuration={500}
        anchor="bottom"
        keepMounted
      >
        <div className={classes.drawerWrapper}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        className={classes.menu}
        open={isOpen}
        onClose={onClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: MENU_WIDTH - BUTTON_WIDTH,
        }}
      >
        {children}
      </Menu>
    );
  }
}

const styles = makeStyles(theme => ({
  drawer: {
    top: "auto",
    maxHeight: "calc(100vh - 75px)",
  },
  drawerWrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  menu: {
    padding: 0,
  },
  menuPaper: {
    borderRadius: 8,
  },
  container: {
    width: MENU_WIDTH,
    position: "relative",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      width: "100%",
    },
  },
  subHeader: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "16px",
    color: theme.palette.text.secondary2,
    padding: "8px 12px",
  },
  divider: {
    margin: "8px 0",
    borderBottom: "1px solid #DBDEEE",
  },
  row: {
    width: "100%",
    height: 36,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 16,
    letterSpacing: 0.15,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
  loading: {
    opacity: 0.4,
  },
  loadingOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 999,
  },
}));
