import { Fragment, useEffect, useState } from "react";
import {
  Create,
  Edit,
  TextInput,
  NumberInput,
  SimpleForm,
  ImageInput,
  required,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  DateTimeInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  FunctionField,
  BooleanInput,
  SelectArrayInput,
  FileInput,
  FileField,
  Labeled,
  regex,
  useNotify,
  Toolbar,
} from "react-admin";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getIsOwner, getIsOwnerOrGivvr, getSeasonId } from "../../customState";
import { CustomRichText, ThemeColorPickers } from "../../components";
import {
  tickets,
  volunteerLabelOptions,
  matchingOptions,
  bonusMatchingOptions,
  APIClient,
  generateAuthHeader,
  tipPercentOptions,
  TWO_DECIMAL_REGEX,
  paymentPlanOptions,
  recurringMonthOptions,
  isGivvrCompany,
  STSLabelOptions,
} from "../../lib";
import { styles } from "./Campaign.styles";

const DefaultAction = ({ source, forDefaults, ...rest }) => {
  const classes = styles();
  if (!forDefaults) return null;
  return (
    <Fragment>
      <span className={classes.actionSelectSpacer} />
      <SelectInput
        source={source}
        label="action"
        {...rest}
        choices={[
          { id: "remove", name: "remove" },
          { id: "default", name: "default" },
          { id: "default-disabled", name: "default-disabled" },
        ]}
        translateChoice={false}
      />
      <div className={classes.actionSelectSpacer}></div>
    </Fragment>
  );
};

export function CampaignForm(props) {
  const {
    formProps,
    initialValues,
    isDonation,
    isMatching,
    isRaffle,
    isProduct,
    isEdit,
    forDefaults,
    defaultData = {},
    alreadyHasDonations,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const season_id = useSelector(getSeasonId);
  const isOwner = useSelector(state => getIsOwner(state));
  const isOwnerOrGivvr = useSelector(state => getIsOwnerOrGivvr(state));
  const isGivvr = useSelector(state => isGivvrCompany(state.user.companyId));
  const ActionTag = isEdit ? Edit : Create;
  const requiredInput = forDefaults ? [] : [required()];
  const valid2Decimals = [regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only")];
  const requiredValid2Decimals = [
    regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only"),
    required(),
  ];
  const defaultsProps = { formClassName: classes.inline, forDefaults };
  const [digitalContentGroups, setDigitalContentGroups] = useState([]);
  const [seasonOptions, setSeasonOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/company_digital_content_inventory_groups",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setDigitalContentGroups(data);
    };
    if (isProduct && !forDefaults && !isEdit) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduct, forDefaults, isEdit]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/company_seasons/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setSeasonOptions(data);
    };
    if (!forDefaults) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forDefaults]);

  const showField = field => {
    return Boolean(forDefaults || defaultData[`${field}_action`] !== "remove");
  };

  const disableField = (field, disableEditWhenHasDonations = false) => {
    return Boolean(
      (isEdit && disableEditWhenHasDonations && alreadyHasDonations) ||
        (!forDefaults && defaultData[`${field}_action`] === "default-disabled"),
    );
  };

  const transformDataForSubmit = values => {
    // remove uneditable fields to pass backend schema/validation
    const {
      campaign_default: _rm,
      supplier_product_group: _rm2,
      orgName: _rm3,
      campaign_donation_group_id: _rm4,
      dedication_options: _rm5,
      org: _rm6,
      prize_program_supplier_id: _rm7,
      doing_offline_sales,
      ...rest
    } = values;
    const data = rest;
    if (isProduct) data.doing_offline_sales = doing_offline_sales;
    return data;
  };

  return (
    <ActionTag
      {...formProps}
      transform={transformDataForSubmit}
      className={isEdit && classes.form}
    >
      <SimpleForm
        initialValues={initialValues}
        redirect={null}
        toolbar={<Toolbar className={isEdit && classes.toolbar} />}
      >
        {!forDefaults && (
          <FormDataConsumer>
            {({ formData: _rm, ...rest }) => (
              <div className={classes.activateContainer}>
                <BooleanInput
                  {...rest}
                  label="ACTIVATE"
                  source="active"
                  className={classes.fitContent}
                  classes={{ root: classes.activateInput }}
                />
                <div className={classes.activateWarning}>
                  Activating this Campaign will trigger a welcome email to the
                  Org Contact even prior to Site Open Date
                </div>
              </div>
            )}
          </FormDataConsumer>
        )}

        {!forDefaults && (
          <TextInput label="External Identifier" source="external_identifier" />
        )}
        {!forDefaults && seasonOptions.length > 0 && (
          <SelectInput
            source="company_season_id"
            label="Season"
            optionText="season_name"
            initialValue={season_id ? season_id : null}
            choices={seasonOptions}
            translateChoice={false}
            resettable
          />
        )}

        {showField("name") && (
          <TextInput
            source="name"
            label="Campaign Name"
            validate={requiredInput}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("name")}
          />
        )}
        <DefaultAction source="name_action" {...defaultsProps} />

        {showField("campaign_slug") && (
          <TextInput
            source="campaign_slug"
            validate={requiredInput}
            label="Campaign Unique URL"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("campaign_slug")}
          />
        )}
        <DefaultAction source="campaign_slug_action" {...defaultsProps} />

        {!forDefaults && (
          <DateTimeInput
            classes={{ root: classes.picker }}
            source="start_date"
            label="Site Open Date"
            validate={requiredInput}
          />
        )}
        {!forDefaults && (
          <DateTimeInput
            classes={{ root: classes.picker }}
            source="end_date"
            label="Site Close Date"
            validate={requiredInput}
          />
        )}
        {!forDefaults && (
          <NumberInput source="student_count" label="Student Count" step={1} />
        )}

        {showField("goal") && (
          <NumberInput
            source="goal"
            step={1}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("goal")}
          />
        )}
        <DefaultAction source="goal_action" {...defaultsProps} />

        {showField("bonus_goal") && (
          <NumberInput
            source="bonus_goal"
            label="Bonus Goal (goal + bonus)"
            step={1}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("bonus_goal")}
          />
        )}
        <DefaultAction source="bonus_goal_action" {...defaultsProps} />

        {showField("default_volunteer_goal") && (
          <NumberInput
            source="default_volunteer_goal"
            label="Default Student Goal"
            step={1}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("default_volunteer_goal")}
          />
        )}
        <DefaultAction
          source="default_volunteer_goal_action"
          {...defaultsProps}
        />

        {!isProduct && showField("donation_profit_percent") && (
          <TextInput
            type="number"
            source="donation_profit_percent"
            label="Donation Profit Percent"
            validate={valid2Decimals}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("donation_profit_percent", true)}
          />
        )}
        {!isProduct && (
          <DefaultAction
            source="donation_profit_percent_action"
            {...defaultsProps}
          />
        )}

        {/* PRODUCT FIELDS */}
        {isProduct && showField("doing_offline_sales") && (
          <SelectInput
            source="doing_offline_sales"
            validate={requiredInput}
            choices={[
              { id: true, name: "Yes" },
              { id: false, name: "No" },
            ]}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("doing_offline_sales")}
          />
        )}
        {isProduct && (
          <DefaultAction
            source="doing_offline_sales_action"
            {...defaultsProps}
          />
        )}

        {isProduct && showField("shipping_options") && (
          <SelectInput
            validate={requiredInput}
            source="shipping_options"
            formClassName={forDefaults ? classes.inlineBlock : null}
            choices={[
              { id: "school", name: "school" },
              { id: "home", name: "home" },
              { id: "both", name: "both" },
            ]}
            disabled={disableField("shipping_options")}
          />
        )}
        {isProduct && (
          <DefaultAction source="shipping_options_action" {...defaultsProps} />
        )}

        {isProduct && showField("ship_to_school_label") && (
          <SelectInput
            source="ship_to_school_label"
            formClassName={forDefaults ? classes.inlineBlock : null}
            choices={STSLabelOptions}
            disabled={disableField("ship_to_school_label")}
          />
        )}
        {isProduct && (
          <DefaultAction
            source="ship_to_school_label_action"
            {...defaultsProps}
          />
        )}

        {isProduct && (
          <FormDataConsumer>
            {({ formData }) => {
              const shipping_options = formData.shipping_options;
              if (
                !forDefaults &&
                shipping_options !== "school" &&
                shipping_options !== "both"
              ) {
                return <></>;
              }
              return (
                <Fragment>
                  {showField("ship_to_school_delivery_message") && (
                    <TextInput
                      source="ship_to_school_delivery_message"
                      fullWidth
                      label="Ship To School Delivery Msg"
                      disabled={disableField("ship_to_school_delivery_message")}
                    />
                  )}
                  <DefaultAction
                    source="ship_to_school_delivery_message_action"
                    {...defaultsProps}
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>
        )}

        {isProduct && (
          <FormDataConsumer>
            {({ formData: _rm, ...rest }) => (
              <Fragment>
                {/* ONLINE FEE */}
                <div className={classes.flex}>
                  {showField("online_fee_flat") && (
                    <TextInput
                      type="number"
                      {...rest}
                      source="online_fee_flat"
                      disabled={disableField("online_fee_flat", true)}
                    />
                  )}
                  <DefaultAction
                    source="online_fee_flat_action"
                    {...defaultsProps}
                  />
                  {showField("online_fee_flat") &&
                    showField("online_fee_percentage") && (
                      <span>&nbsp;&nbsp;OR&nbsp;&nbsp;</span>
                    )}
                  {showField("online_fee_percentage") && (
                    <TextInput
                      type="number"
                      {...rest}
                      source="online_fee_percentage"
                      disabled={disableField("online_fee_percentage", true)}
                    />
                  )}
                  <DefaultAction
                    source="online_fee_percentage_action"
                    {...defaultsProps}
                  />
                </div>
                {/* CUSTOM FEE */}
                <div className={classes.flex}>
                  {showField("custom_fee") && (
                    <TextInput
                      type="number"
                      {...rest}
                      source="custom_fee"
                      label="Custom Fee (in dollars)"
                      step={1}
                      disabled={disableField("custom_fee")}
                    />
                  )}
                  <DefaultAction
                    source="custom_fee_action"
                    {...defaultsProps}
                  />
                  <div className={classes.space} />
                  {showField("custom_fee_label") && (
                    <TextInput
                      {...rest}
                      source="custom_fee_label"
                      label="Custom Fee Label"
                      disabled={disableField("custom_fee_label")}
                    />
                  )}
                  <DefaultAction
                    source="custom_fee_label_action"
                    {...defaultsProps}
                  />
                </div>
              </Fragment>
            )}
          </FormDataConsumer>
        )}

        {isProduct && showField("allow_donations") && (
          <BooleanInput
            label="Allow Donations"
            source="allow_donations"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("allow_donations")}
          />
        )}
        {isProduct && (
          <DefaultAction source="allow_donations_action" {...defaultsProps} />
        )}

        {isProduct && (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const donationsAllowed = formData.allow_donations;
              if (!donationsAllowed) return <></>;
              return (
                <Fragment>
                  {showField("donation_profit_percent") && (
                    <TextInput
                      {...rest}
                      source="donation_profit_percent"
                      label="Donation Profit Percent"
                      type="number"
                      validate={valid2Decimals}
                      formClassName={forDefaults ? classes.inlineBlock : null}
                      disabled={disableField("donation_profit_percent", true)}
                    />
                  )}
                  <DefaultAction
                    source="donation_profit_percent_action"
                    {...defaultsProps}
                  />
                  {showField("allow_custom_amt") && (
                    <BooleanInput
                      {...rest}
                      label="Allow Custom Amount"
                      source="allow_custom_amt"
                      formClassName={forDefaults ? classes.inlineBlock : null}
                      disabled={disableField("allow_custom_amt")}
                    />
                  )}
                  <DefaultAction
                    source="allow_custom_amt_action"
                    {...defaultsProps}
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>
        )}

        {isProduct && !forDefaults && (
          <BooleanInput
            label="Use Digital Content Addon"
            source="use_digital_content"
            disabled={isEdit}
          />
        )}
        {isProduct && !forDefaults && !isEdit && (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const useDigitalContent = formData.use_digital_content;
              if (!useDigitalContent) return <></>;
              return (
                <SelectInput
                  {...rest}
                  validate={requiredInput}
                  source="digital_content_inventory_group_id"
                  choices={digitalContentGroups}
                />
              );
            }}
          </FormDataConsumer>
        )}
        {/* END PRODUCT FIELDS */}

        {/* RAFFLE FIELDS */}
        {isRaffle && showField("ticket_limit") && (
          <NumberInput
            source="ticket_limit"
            step={1}
            label="Ticket Limit (optional)"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("ticket_limit")}
          />
        )}
        {isRaffle && (
          <DefaultAction source="ticket_limit_action" {...defaultsProps} />
        )}

        {isRaffle && !forDefaults && (
          <ArrayInput
            validate={requiredInput}
            source="campaign_ticket_packages"
            fullWidth={true}
          >
            <SimpleFormIterator>
              <SelectInput
                validate={requiredInput}
                source="num_of_tickets"
                label="number of tickets"
                formClassName={classes.inlineBlock}
                choices={tickets}
                translateChoice={false}
              />
              <NumberInput
                validate={requiredInput}
                source="price"
                label="Price"
                formClassName={classes.inlineBlock}
              />
              <TextInput
                source="label"
                formClassName={classes.inlineBlock}
                multiline
                label="optional display text"
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        {/* END RAFFLE FIELDS */}

        {/* MATCH FIELDS */}
        {isMatching && showField("matching_times") && (
          <SelectInput
            translateChoice={false}
            validate={requiredInput}
            source="matching_times"
            choices={matchingOptions}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("matching_times")}
          />
        )}
        {isMatching && (
          <DefaultAction source="matching_times_action" {...defaultsProps} />
        )}

        {isMatching && showField("bonus_matching_times") && (
          <SelectInput
            translateChoice={false}
            source="bonus_matching_times"
            choices={bonusMatchingOptions}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("bonus_matching_times")}
          />
        )}
        {isMatching && (
          <DefaultAction
            source="bonus_matching_times_action"
            {...defaultsProps}
          />
        )}
        {/* END MATCH FIELDS */}

        {/* DONATION FIELDS */}
        {isDonation && showField("allow_custom_amt") && (
          <BooleanInput
            label="Allow Custom Amount"
            source="allow_custom_amt"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("allow_custom_amt")}
          />
        )}
        {isDonation && (
          <DefaultAction source="allow_custom_amt_action" {...defaultsProps} />
        )}
        {/* END DONATION FIELDS */}

        {/* RECURRING FIELDS */}
        {!isProduct && showField("allowed_payment_plan") && (
          <SelectInput
            source="allowed_payment_plan"
            label="Allowed Payment Schedule"
            choices={paymentPlanOptions}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("allowed_payment_plan")}
            translateChoice={false}
            resettable
          />
        )}
        {!isProduct && (
          <DefaultAction
            source="allowed_payment_plan_action"
            {...defaultsProps}
          />
        )}

        {!isProduct && showField("recurring_options") && (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!Boolean(formData.allowed_payment_plan)) return <></>;
              return (
                <Fragment>
                  <SelectArrayInput
                    {...rest}
                    translateChoice={false}
                    validate={requiredInput}
                    label="Payment Schedule Options"
                    source="recurring_options"
                    choices={recurringMonthOptions}
                    formClassName={forDefaults ? classes.inlineBlock : null}
                    disabled={disableField("recurring_options")}
                  />
                  <DefaultAction
                    source="recurring_options_action"
                    {...defaultsProps}
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>
        )}
        {/* END RECURRING FIELDS */}

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const {
              allow_donations,
              donation_group_id, // CREATE donation campaign using donation-group
              campaign_donation_group_id, // EDIT donation campaign using donation-group
            } = formData;
            return (
              <Fragment>
                {!isRaffle &&
                  !donation_group_id &&
                  !campaign_donation_group_id &&
                  (!isProduct || (isProduct && allow_donations)) &&
                  showField("suggested_amounts") && (
                    <div>
                      <ArrayInput
                        {...rest}
                        fullWidth={true}
                        source="suggested_amounts"
                        label="Donation Amount Suggestions"
                        disabled={disableField("suggested_amounts")}
                      >
                        <SimpleFormIterator TransitionProps={{ enter: false }}>
                          <NumberInput
                            source="amount"
                            label="Amount"
                            validate={requiredInput}
                          />
                          <TextInput
                            source="text"
                            label="Optional Text"
                            multiline
                          />
                        </SimpleFormIterator>
                      </ArrayInput>

                      <div>
                        <DefaultAction
                          source="suggested_amounts_action"
                          {...defaultsProps}
                        />
                      </div>
                    </div>
                  )}
              </Fragment>
            );
          }}
        </FormDataConsumer>

        {/* SPONSOR FIELDS */}
        {showField("sponsors_header") && (
          <TextInput
            source="sponsors_header"
            disabled={disableField("sponsors_header")}
            formClassName={forDefaults ? classes.inlineBlock : null}
          />
        )}
        <DefaultAction source="sponsors_header_action" {...defaultsProps} />
        {!forDefaults && showField("sponsors") && (
          <ArrayInput source="sponsors">
            <SimpleFormIterator>
              <FormDataConsumer className={classes.inlineBlock}>
                {({ getSource, scopedFormData: _rm, formData, ...rest }) => {
                  return (
                    <div className={classes.flex}>
                      <TextInput
                        {...rest}
                        source={getSource("name")}
                        label="Name"
                      />
                      <TextInput
                        {...rest}
                        source={getSource("message")}
                        label="Message"
                      />
                      {isEdit && (
                        <Labeled
                          {...rest}
                          record={formData}
                          label="Current Logo"
                        >
                          <ImageField
                            source={getSource("logoUrl")}
                            src="logoUrl"
                            {...rest}
                            record={formData}
                            classes={{ image: classes.image }}
                          />
                        </Labeled>
                      )}
                      <ImageInput
                        {...rest}
                        source={getSource("sponsor_logoBase64")}
                        label="Logo"
                        accept="image/*"
                        multiple={false}
                        fullWidth={false}
                        className={classNames(
                          classes.fitContent,
                          classes.spaceRight,
                        )}
                      >
                        <ImageField
                          source="src"
                          title="title"
                          classes={{ image: classes.image }}
                          formClassName={classes.inlineBlock}
                        />
                      </ImageInput>
                      <TextInput
                        {...rest}
                        source={getSource("logo_link")}
                        label="Logo Link"
                      />
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        )}
        {!isProduct && forDefaults && (
          <Labeled
            label="Sponsors (use to remove this feature)"
            className={classes.fitContent}
          >
            <DefaultAction source="sponsors_action" {...defaultsProps} />
          </Labeled>
        )}
        {/* END SPONSOR FIELDS */}

        {/* PROCESSOR FIELDS */}
        {isOwnerOrGivvr && !forDefaults && (
          <FunctionField
            label="Campaign specific Processor (override Org Processor)"
            render={() => ""}
          />
        )}
        {isOwnerOrGivvr && !forDefaults && (
          <ReferenceInput
            label="Gateway Provider"
            source="gateway_provider_id"
            reference="gateway_providers"
            formClassName={classes.inlineBlock}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        {isOwnerOrGivvr && !forDefaults && (
          <TextInput
            source="gateway_login_key"
            formClassName={classes.inlineBlock}
          />
        )}
        {isOwnerOrGivvr && isEdit && (
          <FunctionField
            label="Current Gateway transaction Key"
            render={({ masked_trans_key }) => {
              return masked_trans_key ? `xxxx${masked_trans_key}` : "N/A";
            }}
            formClassName={classes.maskedKey}
          />
        )}
        {isOwnerOrGivvr && !forDefaults && (
          <TextInput
            label="Gateway transaction Key"
            source="gateway_trans_key"
            formClassName={classes.inlineBlock}
          />
        )}
        {isOwnerOrGivvr && !forDefaults && (
          <TextInput
            source="gateway_public_key"
            formClassName={classes.inlineBlock}
          />
        )}

        {showField("canadian_currency") && (
          <BooleanInput
            label="Canadian Currency"
            source="canadian_currency"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("canadian_currency")}
          />
        )}
        <DefaultAction source="canadian_currency_action" {...defaultsProps} />

        {/* END PROCESSOR FIELDS */}

        {showField("volunteer_pages_only") && (
          <BooleanInput
            label="Student Pages Only"
            source="volunteer_pages_only"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("volunteer_pages_only")}
          />
        )}
        <DefaultAction
          source="volunteer_pages_only_action"
          {...defaultsProps}
        />

        {!isProduct && showField("allow_frontend_pledges") && (
          <BooleanInput
            label="Allow Frontend Pledges"
            source="allow_frontend_pledges"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("allow_frontend_pledges")}
          />
        )}
        {!isProduct && (
          <DefaultAction
            source="allow_frontend_pledges_action"
            {...defaultsProps}
          />
        )}

        {!isProduct && showField("has_showcase") && (
          <BooleanInput
            label="Has Showcase"
            source="has_showcase"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("has_showcase")}
          />
        )}
        {!isProduct && (
          <DefaultAction source="has_showcase_action" {...defaultsProps} />
        )}

        {isGivvr && isDonation && !forDefaults && (
          <FormDataConsumer>
            {({ formData }) => {
              const { has_journal } = formData;
              return (
                <Fragment>
                  <BooleanInput label="Has Journal" source="has_journal" />
                  {has_journal && (
                    <TextInput
                      source="journal_instructions"
                      label="Journal Instructions"
                      fullWidth
                      multiline
                    />
                  )}
                </Fragment>
              );
            }}
          </FormDataConsumer>
        )}

        {isGivvr && isDonation && !forDefaults && (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { has_reservations } = formData;
              return (
                <Fragment>
                  <BooleanInput
                    label="Has Dinner Reservations"
                    source="has_reservations"
                  />
                  {has_reservations && (
                    <div className={classes.flex}>
                      <NumberInput
                        {...rest}
                        source="one_reservation_minimum"
                        className={classes.reservation}
                        validate={requiredInput}
                      />
                      <NumberInput
                        {...rest}
                        source="two_reservations_minimum"
                        className={classes.reservation}
                        validate={requiredInput}
                      />
                    </div>
                  )}
                </Fragment>
              );
            }}
          </FormDataConsumer>
        )}

        {isProduct && showField("show_profit_based_progress") && (
          <BooleanInput
            className={classes.fitContent}
            label="Display Profit Based Progress"
            source="show_profit_based_progress"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("show_profit_based_progress")}
          />
        )}
        {isProduct && (
          <DefaultAction
            source="show_profit_based_progress_action"
            {...defaultsProps}
          />
        )}

        {isProduct && showField("show_volunteer_item_progress") && (
          <BooleanInput
            className={classes.fitContent}
            label="Display Student Item Progress"
            source="show_volunteer_item_progress"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("show_volunteer_item_progress")}
          />
        )}
        {isProduct && (
          <DefaultAction
            source="show_volunteer_item_progress_action"
            {...defaultsProps}
          />
        )}

        {showField("hide_stats_component") && (
          <BooleanInput
            label="Hide Campaign Stats (progress bar, countdown)"
            source="hide_stats_component"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("hide_stats_component")}
          />
        )}
        <DefaultAction
          source="hide_stats_component_action"
          {...defaultsProps}
        />

        {showField("hide_campaign_stats_on_volunteer_pages") && (
          <BooleanInput
            label="Hide Campaign Stats On Student Pages"
            source="hide_campaign_stats_on_volunteer_pages"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("hide_campaign_stats_on_volunteer_pages")}
          />
        )}
        <DefaultAction
          source="hide_campaign_stats_on_volunteer_pages_action"
          {...defaultsProps}
        />

        {showField("hide_countdown") && (
          <BooleanInput
            label="Hide Countdown (only show progress bar)"
            source="hide_countdown"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("hide_countdown")}
          />
        )}
        <DefaultAction source="hide_countdown_action" {...defaultsProps} />

        {showField("hide_donations") && (
          <BooleanInput
            label="Hide Recent Sales/Donation List"
            source="hide_donations"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("hide_donations")}
          />
        )}
        <DefaultAction source="hide_donations_action" {...defaultsProps} />

        {showField("show_volunteer_donations") && (
          <BooleanInput
            label="Show Student Donations (when `hide donations` is selected)"
            source="show_volunteer_donations"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("show_volunteer_donations")}
          />
        )}
        <DefaultAction
          source="show_volunteer_donations_action"
          {...defaultsProps}
        />

        {showField("hide_volunteers") && (
          <BooleanInput
            label="Hide Student List"
            source="hide_volunteers"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("hide_volunteers")}
          />
        )}
        <DefaultAction source="hide_volunteers_action" {...defaultsProps} />

        {!forDefaults && (
          <BooleanInput
            label="Remove Student Picture"
            source="no_volunteer_pictures"
          />
        )}
        {!forDefaults && (
          <BooleanInput
            label="Hide Register Button"
            source="no_volunteer_registration"
          />
        )}
        {!forDefaults && (
          <BooleanInput
            label="Remove Leaderboard From Student Dashboard"
            source="no_student_leaderboard"
            className={classes.fitContent}
          />
        )}

        {showField("hide_teams") && (
          <BooleanInput
            label="Hide Team List"
            source="hide_teams"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("hide_teams")}
          />
        )}
        <DefaultAction source="hide_teams_action" {...defaultsProps} />

        {showField("allow_family_accounts") && (
          <BooleanInput
            label="Allow Family Accounts"
            source="allow_family_accounts"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("allow_family_accounts")}
          />
        )}
        <DefaultAction
          source="allow_family_accounts_action"
          {...defaultsProps}
        />

        {showField("remove_solicited_by") && (
          <BooleanInput
            label="Remove Solicited By Input"
            source="remove_solicited_by"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("remove_solicited_by")}
          />
        )}
        <DefaultAction source="remove_solicited_by_action" {...defaultsProps} />

        {showField("add_solicited_by_class") && (
          <BooleanInput
            label="Add `Solicited By Class` Dropdown On Campaign Payment Form"
            source="add_solicited_by_class"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("add_solicited_by_class")}
          />
        )}
        <DefaultAction
          source="add_solicited_by_class_action"
          {...defaultsProps}
        />

        {!isProduct && (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { use_tips, tip_percent_options } = formData;
              const disableAdd =
                Array.isArray(tip_percent_options) &&
                tip_percent_options.length >= 3;
              return (
                <Fragment>
                  <div className={classes.flex}>
                    {showField("use_tips") && (
                      <BooleanInput
                        {...rest}
                        label="Use Tips"
                        source="use_tips"
                        disabled={disableField("use_tips")}
                      />
                    )}
                    <DefaultAction
                      source="use_tips_action"
                      {...defaultsProps}
                    />
                  </div>
                  {use_tips && (
                    <Fragment>
                      <div className={classes.flex}>
                        {showField("tip_title") && (
                          <TextInput
                            {...rest}
                            source="tip_title"
                            label="Tip Title"
                            validate={requiredInput}
                            disabled={disableField("tip_title")}
                          />
                        )}
                        <DefaultAction
                          source="tip_title_action"
                          {...defaultsProps}
                        />
                      </div>
                      <div className={classes.flex}>
                        {showField("tip_paragraph") && (
                          <TextInput
                            fullWidth
                            source="tip_paragraph"
                            label="Tip Paragraph"
                            multiline
                            validate={requiredInput}
                            disabled={disableField("tip_paragraph")}
                          />
                        )}
                        <DefaultAction
                          source="tip_paragraph_action"
                          {...defaultsProps}
                        />
                      </div>
                      <div className={classes.flex}>
                        {showField("tip_percent_options") && (
                          <ArrayInput
                            {...rest}
                            validate={requiredInput}
                            source="tip_percent_options"
                            label="Tip Percent Options"
                            className={classes.tipPercents}
                            fullWidth={false}
                            disabled={disableField("tip_percent_options")}
                          >
                            <SimpleFormIterator disableAdd={disableAdd}>
                              <FormDataConsumer>
                                {({
                                  scopedFormData = {},
                                  getSource,
                                  id: _rm,
                                  formData: { tip_percent_options: TPO } = {},
                                  ...rest
                                }) => {
                                  let disableDefault = false;
                                  const isDefault =
                                    scopedFormData.defaultOption || false;
                                  if (!isDefault && Array.isArray(TPO)) {
                                    const hasDefault = TPO.find(option =>
                                      Boolean(option && option.defaultOption),
                                    );
                                    if (hasDefault) disableDefault = true;
                                  }
                                  return (
                                    <Fragment>
                                      <SelectInput
                                        {...rest}
                                        source={getSource("percent")}
                                        label="Tip Percent"
                                        validate={requiredInput}
                                        choices={tipPercentOptions}
                                        translateChoice={false}
                                      />
                                      <BooleanInput
                                        {...rest}
                                        label="Default Option"
                                        source={getSource("defaultOption")}
                                        disabled={disableDefault}
                                      />
                                    </Fragment>
                                  );
                                }}
                              </FormDataConsumer>
                            </SimpleFormIterator>
                          </ArrayInput>
                        )}
                        <DefaultAction
                          source="tip_percent_options_action"
                          {...defaultsProps}
                        />
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              );
            }}
          </FormDataConsumer>
        )}

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { use_processing_fee } = formData;
            return (
              <Fragment>
                <div className={classes.flex}>
                  {showField("use_processing_fee") && (
                    <BooleanInput
                      {...rest}
                      className={classes.fitContent}
                      label="Use Donation Processing Fee"
                      source="use_processing_fee"
                      disabled={disableField("use_processing_fee")}
                    />
                  )}
                  <DefaultAction
                    source="use_processing_fee_action"
                    {...defaultsProps}
                  />
                </div>
                {use_processing_fee && (
                  <Fragment>
                    <div className={classes.flex}>
                      {showField("processing_fee_title") && (
                        <TextInput
                          {...rest}
                          source="processing_fee_title"
                          label="Processing Fee Title"
                          validate={requiredInput}
                          disabled={disableField("processing_fee_title")}
                        />
                      )}
                      <DefaultAction
                        source="processing_fee_title_action"
                        {...defaultsProps}
                      />
                    </div>
                    <div className={classes.flex}>
                      {showField("processing_fee_paragraph") && (
                        <TextInput
                          source="processing_fee_paragraph"
                          label="Processing Fee Paragraph"
                          multiline
                          fullWidth
                          validate={requiredInput}
                          disabled={disableField("processing_fee_paragraph")}
                        />
                      )}
                      <DefaultAction
                        source="processing_fee_paragraph_action"
                        {...defaultsProps}
                      />
                    </div>
                    <div className={classes.flex}>
                      {showField("processing_fee_percent") && (
                        <TextInput
                          {...rest}
                          source="processing_fee_percent"
                          type="number"
                          label="Processing Fee Percent"
                          validate={requiredValid2Decimals}
                          disabled={disableField("processing_fee_percent")}
                        />
                      )}
                      <DefaultAction
                        source="processing_fee_percent_action"
                        {...defaultsProps}
                      />
                    </div>
                    <div className={classes.flex}>
                      {showField("processing_fee_mandatory") && (
                        <BooleanInput
                          {...rest}
                          className={classes.fitContent}
                          label="Processing Fee Is Mandatory"
                          source="processing_fee_mandatory"
                          disabled={disableField("processing_fee_mandatory")}
                        />
                      )}
                      <DefaultAction
                        source="processing_fee_mandatory_action"
                        {...defaultsProps}
                      />
                    </div>
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </FormDataConsumer>

        {showField("volunteer_label") && (
          <SelectInput
            source="volunteer_label"
            label="Student Label"
            choices={volunteerLabelOptions}
            translateChoice={false}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("volunteer_label")}
          />
        )}
        <DefaultAction source="volunteer_label_action" {...defaultsProps} />

        {showField("volunteer_header_suffix") && (
          <TextInput
            fullWidth
            label="Student Page Header Suffix (displayed after their name, leave a space if first word is not connected to their name EX: if you are not doing `'s`)"
            source="volunteer_header_suffix"
            disabled={disableField("volunteer_header_suffix")}
          />
        )}
        <DefaultAction
          source="volunteer_header_suffix_action"
          {...defaultsProps}
        />

        {isOwner && !forDefaults && (
          <TextInput
            label="Google Analytics Key"
            source="google_analytics_key"
          />
        )}
        {isOwner && !forDefaults && (
          <TextInput
            label="Google Tag Manager Key"
            source="google_tag_manager_key"
          />
        )}
        {isOwner && !forDefaults && (
          <TextInput label="Facebook Pixel Id" source="facebook_pixel_id" />
        )}

        <FormDataConsumer>
          {({ formData }) => {
            const {
              banner,
              secondary_banner,
              small_banner,
              secondary_small_banner,
              social_media_banner,
              picture,
            } = formData;
            const labelPrefix = isEdit ? "Current" : "Default";
            return (
              <Fragment>
                {showField("banner") && (
                  <div className={classes.flexStart}>
                    {banner && (
                      <Labeled
                        label={`${labelPrefix} Desktop Banner`}
                        classes={{ value: classes.imageContainer }}
                      >
                        <ImageField
                          source="banner"
                          record={formData}
                          classes={{ image: classes.image }}
                        />
                      </Labeled>
                    )}
                    {banner && (
                      <BooleanInput source="remove_banner" label="Remove" />
                    )}
                    <ImageInput
                      source="bannerBase64"
                      label={`${banner ? "Change " : ""}Desktop Banner`}
                      accept="image/*"
                      multiple={false}
                      disabled={disableField("banner")}
                      classes={{ root: classes.imageContainer }}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        classes={{ image: classes.image }}
                      />
                    </ImageInput>
                    <DefaultAction source="banner_action" {...defaultsProps} />
                  </div>
                )}

                {showField("small_banner") && (
                  <div className={classes.flexStart}>
                    {small_banner && (
                      <Labeled
                        label={`${labelPrefix} Mobile Banner`}
                        classes={{ value: classes.imageContainer }}
                      >
                        <ImageField
                          source="small_banner"
                          record={formData}
                          classes={{ image: classes.image }}
                        />
                      </Labeled>
                    )}
                    {small_banner && (
                      <BooleanInput
                        source="remove_small_banner"
                        label="Remove"
                      />
                    )}
                    <ImageInput
                      source="small_bannerBase64"
                      label={`${small_banner ? "Change " : ""}Mobile Banner`}
                      accept="image/*"
                      multiple={false}
                      disabled={disableField("small_banner")}
                      classes={{ root: classes.imageContainer }}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        classes={{ image: classes.image }}
                      />
                    </ImageInput>
                    <DefaultAction
                      source="small_banner_action"
                      {...defaultsProps}
                    />
                  </div>
                )}

                {showField("secondary_banner") && (
                  <div className={classes.flexStart}>
                    {secondary_banner && (
                      <Labeled
                        label={`${labelPrefix} Student Page Custom Banner`}
                        classes={{ value: classes.imageContainer }}
                      >
                        <ImageField
                          source="secondary_banner"
                          record={formData}
                          classes={{ image: classes.image }}
                        />
                      </Labeled>
                    )}
                    {secondary_banner && (
                      <BooleanInput
                        source="remove_secondary_banner"
                        label="Remove"
                      />
                    )}
                    <ImageInput
                      source="secondary_bannerBase64"
                      label={`${
                        secondary_banner ? "Change " : ""
                      }Student Page Custom Banner`}
                      accept="image/*"
                      multiple={false}
                      disabled={disableField("secondary_banner")}
                      classes={{ root: classes.imageContainer }}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        classes={{ image: classes.image }}
                      />
                    </ImageInput>
                    <DefaultAction
                      source="secondary_banner_action"
                      {...defaultsProps}
                    />
                  </div>
                )}

                {showField("secondary_small_banner") && (
                  <div className={classes.flexStart}>
                    {secondary_small_banner && (
                      <Labeled
                        label={`${labelPrefix} Student Page Custom Mobile Banner`}
                        classes={{ value: classes.imageContainer }}
                      >
                        <ImageField
                          source="secondary_small_banner"
                          record={formData}
                          classes={{ image: classes.image }}
                        />
                      </Labeled>
                    )}
                    {secondary_small_banner && (
                      <BooleanInput
                        source="remove_secondary_small_banner"
                        label="Remove"
                      />
                    )}
                    <ImageInput
                      source="secondary_small_bannerBase64"
                      label={`${
                        secondary_small_banner ? "Change " : ""
                      }Student Page Custom Mobile Banner`}
                      accept="image/*"
                      multiple={false}
                      disabled={disableField("secondary_small_banner")}
                      classes={{ root: classes.imageContainer }}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        classes={{ image: classes.image }}
                      />
                    </ImageInput>
                    <DefaultAction
                      source="secondary_small_banner_action"
                      {...defaultsProps}
                    />
                  </div>
                )}

                {showField("social_media_banner") && (
                  <div className={classes.flexStart}>
                    {social_media_banner && (
                      <Labeled
                        label={`${labelPrefix} Social Media Banner`}
                        classes={{ value: classes.imageContainer }}
                      >
                        <ImageField
                          source="social_media_banner"
                          record={formData}
                          classes={{ image: classes.image }}
                        />
                      </Labeled>
                    )}
                    {social_media_banner && (
                      <BooleanInput
                        source="remove_social_media_banner"
                        label="Remove"
                      />
                    )}
                    <ImageInput
                      source="social_media_bannerBase64"
                      label={`${
                        social_media_banner ? "Change " : ""
                      }Social Media Banner`}
                      accept="image/*"
                      multiple={false}
                      disabled={disableField("social_media_banner")}
                      classes={{ root: classes.imageContainer }}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        classes={{ image: classes.image }}
                      />
                    </ImageInput>
                    <DefaultAction
                      source="social_media_banner_action"
                      {...defaultsProps}
                    />
                  </div>
                )}

                {showField("picture") && (
                  <div className={classes.flexStart}>
                    {picture && (
                      <Labeled
                        label={`${labelPrefix} Display Picture`}
                        classes={{ value: classes.imageContainer }}
                      >
                        <ImageField
                          source="picture"
                          record={formData}
                          classes={{ image: classes.image }}
                        />
                      </Labeled>
                    )}
                    {picture && (
                      <BooleanInput source="remove_picture" label="Remove" />
                    )}
                    <ImageInput
                      source="pictureBase64"
                      label={`${picture ? "Change " : ""}Display Picture`}
                      accept="image/*"
                      multiple={false}
                      disabled={disableField("picture")}
                      classes={{ root: classes.imageContainer }}
                    >
                      <ImageField
                        source="src"
                        title="title"
                        classes={{ image: classes.image }}
                      />
                    </ImageInput>
                    <DefaultAction source="picture_action" {...defaultsProps} />
                  </div>
                )}
              </Fragment>
            );
          }}
        </FormDataConsumer>

        {!forDefaults && (
          <TextInput
            label="Under Banner Text"
            source="under_banner_text"
            fullWidth
          />
        )}
        {!forDefaults && (
          <TextInput
            label="Under Banner Link"
            source="under_banner_link"
            fullWidth
          />
        )}

        {showField("text_html") && (
          <CustomRichText
            label="Display Text"
            source="text_html"
            disabled={disableField("text_html")}
          />
        )}
        <DefaultAction source="text_html_action" {...defaultsProps} />

        {showField("default_volunteer_display_html") && (
          <CustomRichText
            label="Default Student Display Text"
            source="default_volunteer_display_html"
            disabled={disableField("default_volunteer_display_html")}
          />
        )}
        <DefaultAction
          source="default_volunteer_display_html_action"
          {...defaultsProps}
        />

        {showField("no_volunteer_display_html_edits") && (
          <BooleanInput
            label="Disallow Student Display Text Edits"
            source="no_volunteer_display_html_edits"
            className={classes.fitContent}
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("no_volunteer_display_html_edits")}
          />
        )}
        <DefaultAction
          source="no_volunteer_display_html_edits_action"
          {...defaultsProps}
        />

        {!forDefaults && (
          <TextInput
            label="Main Banner Youtube/Vimeo Link"
            source="main_banner_video_link"
            fullWidth
          />
        )}
        {!forDefaults && (
          <TextInput
            label="Display Picture Youtube/Vimeo Link"
            source="video_link"
            fullWidth
          />
        )}

        <FormDataConsumer>
          {props => <ThemeColorPickers {...props} />}
        </FormDataConsumer>

        {isRaffle && showField("rules_and_regulations_html") && (
          <CustomRichText
            label="Rules and Regulations Text"
            source="rules_and_regulations_html"
            disabled={disableField("rules_and_regulations_html")}
          />
        )}
        {isRaffle && (
          <DefaultAction
            source="rules_and_regulations_html_action"
            {...defaultsProps}
          />
        )}

        {showField("disclaimer_html") && (
          <CustomRichText
            label="Disclaimer Text"
            source="disclaimer_html"
            disabled={disableField("disclaimer_html")}
          />
        )}
        <DefaultAction source="disclaimer_html_action" {...defaultsProps} />

        {!forDefaults && isOwnerOrGivvr && isEdit && (
          <FunctionField
            label="Current List (for now using for journal honorees)"
            render={({ json_list }) => {
              if (Array.isArray(json_list)) {
                return <div>{json_list.join(", ")}</div>;
              }
              return "NONE";
            }}
          />
        )}
        {!forDefaults && isOwnerOrGivvr && (
          <FileInput
            source="jsonListBase64"
            label="List Upload (1 column CSV without a header) (for now using for journal honorees)"
            accept={"application/vnd.ms-excel, text/csv, text/plain"}
            multiple={false}
            fullWidth
          >
            <FileField source="src" title="title" />
          </FileInput>
        )}
        {showField("notes") && (
          <TextInput
            multiline
            source="notes"
            fullWidth
            disabled={disableField("notes")}
            formClassName={forDefaults ? classes.inlineBlock : null}
          />
        )}
        <DefaultAction source="notes_action" {...defaultsProps} />
        {isProduct && showField("is_gift_card_site") && (
          <BooleanInput
            label="This Is A Gift Card Site"
            source="is_gift_card_site"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("is_gift_card_site")}
          />
        )}
        {isProduct && (
          <DefaultAction source="is_gift_card_site_action" {...defaultsProps} />
        )}
        {showField("informational_site_only") && (
          <BooleanInput
            label="This Site is Informational ONLY (no shopping/donating)"
            source="informational_site_only"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("informational_site_only")}
            className={classes.fitContent}
          />
        )}
        <DefaultAction
          source="informational_site_only_action"
          {...defaultsProps}
        />
        {showField("profit_paid_via_ach") && (
          <BooleanInput
            label="Org Profit Paid via ACH"
            source="profit_paid_via_ach"
            formClassName={forDefaults ? classes.inlineBlock : null}
            disabled={disableField("profit_paid_via_ach")}
          />
        )}
        <DefaultAction source="profit_paid_via_ach_action" {...defaultsProps} />
      </SimpleForm>
    </ActionTag>
  );
}
