type EventState = { placeholder?: boolean };

const initialState: EventState = {};

export const eventReducers = (
  state = initialState,
  action: any,
): EventState => {
  switch (action.type) {
    default:
      return state;
  }
};
