import {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  MutableRefObject,
  Fragment,
} from "react";
import {
  makeStyles,
  Button,
  IconButton,
  TextField as MuiTextField,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Form, Field, FormSpy } from "react-final-form";
import { TextField } from "final-form-material-ui";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import {
  APIAuthClient,
  convertFileToB64,
  emailValidation,
  formatMoney,
  twoDecimalsOnly,
} from "../../lib";
import { APIRes, Base64File, GenericObject, ToastTypes } from "../../types";
import { setToast } from "../../state";
import { RootState } from "../../types/state";
import { getIsVolunteer } from "../../../customState";
import { ResponsiveModal } from "../ui/ResponsiveModal";
import { MultiFileDropzoneField } from "../ui/MultiFileDropzoneField";
import { useIsMobile } from "../../hooks/ui";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddOrderForm({ onClose, refreshList }: Props) {
  const { campaignId, allow_donations } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const isVolunteer = useSelector(getIsVolunteer);
  const { volunteerId: tokenVolunteerId, orgId } = useSelector(
    ({ user }: RootState) => user,
  );
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const studentRef = useRef<undefined | HTMLInputElement>();
  const homeroomRef = useRef<undefined | HTMLInputElement>();
  const emailRef = useRef<undefined | HTMLInputElement>();
  const phoneRef = useRef<undefined | HTMLInputElement>();
  const productRef = useRef<undefined | HTMLInputElement>();
  const qtyRef = useRef<undefined | HTMLInputElement>();
  const addAnother = useRef(false);
  const [grandTotal, setGrandTotal] = useState<0 | string>(0);
  const [itemTotal, setItemTotal] = useState(0);
  const [volunteerName, setVolunteerName] = useState("");
  const [volunteerId, setVolunteerId] = useState(null);
  const [homeroom, setHomeroom] = useState("");
  const [currStudents, setCurrStudents] = useState<GenericObject[]>([]);
  const [currHomerooms, setCurrHomerooms] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState<null | GenericObject>(
    null,
  );
  const [activeProductQty, setActiveProductQty] = useState<string | number>("");
  const [initialValues, setInitialValues] = useState({ products: [] });

  useEffect(() => {
    if (!isVolunteer) {
      fetchVolunteers();
      fetchHomerooms();
    }
    fetchCampaignProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVolunteer]);

  useEffect(() => {
    if (isVolunteer && tokenVolunteerId) setVolunteerId(tokenVolunteerId);
  }, [isVolunteer, tokenVolunteerId]);

  useEffect(() => {
    if (activeProduct && qtyRef.current) {
      setActiveProductQty(1);
      setTimeout(() => {
        qtyRef.current && qtyRef.current.select();
      }, 20);
    }
  }, [activeProduct]);

  const resetForm = () => {
    fetchVolunteers();
    fetchHomerooms();
    setGrandTotal(0);
    setItemTotal(0);
    setVolunteerName("");
    setVolunteerId(null);
    setHomeroom("");
    setActiveProduct(null);
    setActiveProductQty("");
    setInitialValues({ products: [] });
    studentRef.current && studentRef.current.focus();
  };

  const fetchCampaignProducts = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      `/campaign_products/campaigns/${campaignId}/autocomplete`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setProducts(data);
  };

  const fetchVolunteers = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      `/volunteers/autocomplete?org_id=${orgId}&campaign_id=${campaignId}`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setCurrStudents(data);
  };

  const fetchHomerooms = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      `/orgs/${orgId}/classrooms`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setCurrHomerooms(data);
  };

  const isValidForm = (values: any) => {
    let isValid = true;
    const min2Words = /(\w+\s+[^-])+\S+/;
    if (!volunteerId && !min2Words.test(volunteerName.trim())) {
      dispatch(setToast("Student must have a first and last name"));
      isValid = false;
    } else if (!homeroom && !isVolunteer) {
      dispatch(setToast("Please select/add a Homeroom"));
      isValid = false;
    } else if (
      !values.products.length &&
      Number(values.donation_amount || 0) <= 0
    ) {
      dispatch(setToast("There are no items OR donation added"));
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = async (values: any, _: any, complete: any) => {
    if (!isValidForm(values)) return complete();
    const { products, brochures, ...rest } = values;
    const SP: GenericObject[] = [];
    products.forEach((p: GenericObject) => {
      const { id: product_id, qty } = p;
      const existingIndex = SP.findIndex(e => e.product_id === product_id);
      if (existingIndex !== -1) {
        SP[existingIndex].qty = (SP[existingIndex].qty as number) + Number(qty);
      } else {
        SP.push({ product_id, qty: Number(qty) });
      }
    });
    const postObj = {
      campaignId: Number(campaignId),
      volunteerId,
      volunteerName: volunteerId ? null : volunteerName.trim(),
      class_name: homeroom,
      ...rest,
      selected_products: SP,
    };
    if (Array.isArray(brochures) && brochures.length) {
      const convertedBrochures: Base64File[] = [];
      for (const i in brochures) {
        const converted = await convertFileToB64(brochures[i]);
        if (converted) convertedBrochures.push(converted);
      }
      postObj.brochures = convertedBrochures;
    }

    const res = await APIAuthClient.post<any, APIRes>(
      "/donations/brochure_upload",
      postObj,
    );

    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Order form uploaded", ToastTypes.success));
    if (!addAnother.current) handleCloseModal();
    else resetForm();
  };

  const onStudentChange = (_: any, newValue: any) => {
    switch (true) {
      case newValue === null || newValue === "":
        setVolunteerName("");
        setVolunteerId(null);
        setHomeroom("");
        break;
      case typeof newValue === "string":
        setVolunteerName(newValue);
        setVolunteerId(null);
        setHomeroom("");
        break;
      case typeof newValue === "object":
        prefillClass(newValue.id);
        setVolunteerName(newValue.name);
        setVolunteerId(newValue.id);
        break;
      default:
        setVolunteerName("");
        setVolunteerId(null);
        setHomeroom("");
        break;
    }
  };

  const prefillClass = (id: any) => {
    const student = currStudents.find(s => s.id === Number(id));
    if (student && student.class_name)
      setHomeroom(student.class_name as string);
    else setHomeroom("");
  };

  const onHomeroomChange = (_event: any, newValue: any) => {
    setHomeroom(newValue);
  };

  const handleQtyChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    input?: any,
  ) => {
    const value = e.target.value;
    if ((!input && !value) || (value && /^[1-9][0-9]*$/.test(value))) {
      // if they are editing an added product there is an input, if it's a new product then set activeProductQty
      if (input) input.onChange(value);
      else setActiveProductQty(value);
    }
  };

  const handleQtyEnter = (e: any, fields: any) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && activeProduct && activeProductQty) handleAddItem(fields);
  };

  const handleEnter = (
    e: any,
    nextRef: MutableRefObject<HTMLInputElement | undefined>,
  ) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && nextRef.current) nextRef.current.focus();
  };

  const handleAddItem = (fields: any) => {
    fields.push({
      ...activeProduct,
      qty: activeProductQty,
    });
    setActiveProduct(null);
    setActiveProductQty("");
    productRef.current && productRef.current.focus();
  };

  const handleCloseModal = () => {
    refreshList();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={true} onClose={onClose}>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.mobileStickyTop}>
            <IconButton size="small" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.top}>
          <div>Enter order form</div>
          {!isMobile && (
            <IconButton size="small" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form
                className={classes.form}
                onSubmit={event => {
                  const promise = handleSubmit(event);
                  promise && promise.then(() => form.reset());
                  return promise;
                }}
              >
                <FormSpy
                  onChange={({ values, dirtyFieldsSinceLastSubmit }) => {
                    if (Object.keys(dirtyFieldsSinceLastSubmit).length) {
                      let ttl = 0;
                      let items = 0;
                      values.products.forEach(({ price, qty }) => {
                        ttl = ttl + Number(price) * Number(qty);
                        items = items + Number(qty);
                      });
                      setGrandTotal(formatMoney(ttl));
                      setItemTotal(items);
                    }
                  }}
                />

                {!isVolunteer && (
                  <Fragment>
                    <div className={classes.inputGroup}>
                      <Autocomplete
                        className={classes.input}
                        inputValue={volunteerName}
                        freeSolo
                        selectOnFocus
                        onInputChange={onStudentChange}
                        onChange={onStudentChange}
                        options={currStudents}
                        autoHighlight
                        getOptionLabel={(option: any) => option.name || option}
                        renderInput={params => (
                          <MuiTextField
                            {...params}
                            placeholder="Student*"
                            required
                            autoFocus
                            inputRef={studentRef}
                            onKeyDown={e => handleEnter(e, homeroomRef)}
                          />
                        )}
                        renderOption={(
                          option: GenericObject,
                          { inputValue },
                        ) => {
                          const name = option.name as string;
                          const matches = match(name, inputValue, {
                            insideWords: true,
                          });
                          const parts = parse(name, matches);

                          return (
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? "bold" : 400,
                                    backgroundColor: part.highlight
                                      ? "#66e319"
                                      : "unset",
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          );
                        }}
                      />
                      <Autocomplete
                        className={classes.input}
                        inputValue={homeroom}
                        freeSolo
                        selectOnFocus
                        onInputChange={onHomeroomChange}
                        onChange={onHomeroomChange}
                        options={currHomerooms}
                        autoHighlight
                        getOptionLabel={option => option}
                        renderInput={params => (
                          <MuiTextField
                            {...params}
                            placeholder="Homeroom*"
                            required
                            inputRef={homeroomRef}
                            onKeyDown={e => handleEnter(e, emailRef)}
                          />
                        )}
                      />
                    </div>

                    <div className={classes.inputGroup}>
                      <Field
                        name="email"
                        placeholder="Email (optional)"
                        component={TextField}
                        validate={emailValidation}
                        className={classes.input}
                        inputRef={emailRef}
                        onKeyDown={(e: any) => handleEnter(e, phoneRef)}
                      />
                      <Field
                        name="phone"
                        placeholder="Phone (optional)"
                        component={TextField}
                        className={classes.input}
                        inputRef={phoneRef}
                        onKeyDown={(e: any) => handleEnter(e, productRef)}
                      />
                    </div>
                  </Fragment>
                )}

                <div className={classes.subheader}>Products</div>
                <FieldArray name="products">
                  {({ fields }) => {
                    const itemsAdded = Boolean(fields.length);
                    return (
                      <Fragment>
                        {(itemsAdded || !isMobile) && (
                          <div className={classes.products}>
                            {!isMobile && (
                              <div
                                className={classnames(
                                  classes.row,
                                  classes.headerRow,
                                )}
                              >
                                <div>Image</div>
                                <div>Sku</div>
                                <div>Name</div>
                                <div>Price</div>
                                <div>Quantity</div>
                                <div>Total</div>
                                <div />
                              </div>
                            )}

                            {fields.map((row, index) => {
                              const values = fields.value[index];
                              const length = fields.length ? fields.length : 0;
                              const lastRow = index === length - 1;
                              const {
                                sku,
                                product_name,
                                price,
                                thumbnail,
                                qty,
                              } = values;
                              return (
                                <div key={index} className={classes.row}>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Image
                                      </div>
                                    )}
                                    <div className={classes.imgAndDelete}>
                                      <img
                                        className={classes.thumbnail}
                                        alt="product"
                                        src={thumbnail}
                                      />
                                      {isMobile && (
                                        <IconButton
                                          onClick={() => fields.remove(index)}
                                          size="small"
                                        >
                                          <DeleteIcon color="primary" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Sku
                                      </div>
                                    )}
                                    <div>{sku}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Name
                                      </div>
                                    )}
                                    <div>{product_name}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Price
                                      </div>
                                    )}
                                    <div>{`$${price}`}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Quantity
                                      </div>
                                    )}
                                    <div>
                                      <Field
                                        variant="standard"
                                        type="number"
                                        name={`${row}.qty`}
                                        render={({
                                          input,
                                          meta: _rm,
                                          ...rest
                                        }) => {
                                          return (
                                            <MuiTextField
                                              {...rest}
                                              {...input}
                                              value={input.value}
                                              onFocus={e => e.target.select()}
                                              onChange={e =>
                                                handleQtyChange(e, input)
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={classnames(
                                      classes.productLineItem,
                                      classes.lastLineItem,
                                      lastRow && classes.lastRow,
                                    )}
                                  >
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Total
                                      </div>
                                    )}
                                    <div>{formatMoney(price * qty)}</div>
                                  </div>

                                  {!isMobile && (
                                    <div>
                                      <IconButton
                                        onClick={() => fields.remove(index)}
                                        size="small"
                                      >
                                        <DeleteIcon color="primary" />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                              );
                            })}

                            {!isMobile && itemsAdded && (
                              <div
                                className={classnames(
                                  classes.row,
                                  classes.totalsRow,
                                )}
                              >
                                <div>Totals</div>
                                <div />
                                <div />
                                <div />
                                <div>{itemTotal}</div>
                                <div>{grandTotal}</div>
                                <div />
                              </div>
                            )}
                            {!isMobile && !itemsAdded && (
                              <div className={classes.noItems}>
                                No products added yet. Add products below.
                              </div>
                            )}
                          </div>
                        )}

                        {isMobile && itemsAdded && (
                          <Fragment>
                            <div className={classes.ttlRow}>
                              <div>Number of items</div>
                              <div>{itemTotal}</div>
                            </div>
                            <div className={classes.ttlRow}>
                              <div>Total</div>
                              <div>{grandTotal}</div>
                            </div>
                          </Fragment>
                        )}

                        <div className={classes.selectorContainer}>
                          <Autocomplete
                            className={classes.selector}
                            value={activeProduct}
                            onChange={(_, newValue) =>
                              setActiveProduct(newValue)
                            }
                            getOptionLabel={(option: GenericObject) =>
                              `${option.sku} | ${option.product_name}`
                            }
                            options={products}
                            autoHighlight
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            filterOptions={(options, { inputValue }) => {
                              if (!inputValue) return options;
                              return options.filter(
                                ({ sku = "", product_name = "" }) =>
                                  sku === inputValue ||
                                  (product_name as string).includes(inputValue),
                              );
                            }}
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                label=""
                                placeholder="Enter or select a product to add to order"
                                inputRef={productRef}
                              />
                            )}
                          />

                          <div className={classes.qtyAndAdd}>
                            <MuiTextField
                              className={classes.qtyInput}
                              placeholder="Qty"
                              value={activeProductQty}
                              onChange={e => handleQtyChange(e)}
                              onKeyDown={e => handleQtyEnter(e, fields)}
                              type="number"
                              inputProps={{ step: 0.5 }}
                              inputRef={qtyRef}
                            />

                            <Button
                              color="primary"
                              disabled={!activeProduct || !activeProductQty}
                              onClick={() => handleAddItem(fields)}
                              className={classes.add}
                              size="large"
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </Fragment>
                    );
                  }}
                </FieldArray>

                <div className={classes.subheader}>Upload Order Forms(s)</div>
                <MultiFileDropzoneField fieldName="brochures" />
                <div className={classes.bottomInputs}>
                  {!isVolunteer && allow_donations && (
                    <Field
                      name="donation_amount"
                      placeholder="Donation amount"
                      component={TextField}
                      className={classes.donation}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      type="number"
                      validate={twoDecimalsOnly}
                    />
                  )}
                  <Field
                    name="brochure_student_entered_amount"
                    placeholder="Student entered amount collected"
                    component={TextField}
                    className={classes.studentEntered}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    type="number"
                    validate={twoDecimalsOnly}
                  />
                </div>

                <div className={classes.saveButtons}>
                  <Button
                    type="button"
                    disabled={submitting}
                    className={classes.btn}
                    color="primary"
                    onClick={() => {
                      addAnother.current = false;
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                  {!isVolunteer && (
                    <Button
                      type="button"
                      disabled={submitting}
                      className={classes.startNewBtn}
                      color="secondary"
                      onClick={() => {
                        addAnother.current = true;
                        form.submit();
                      }}
                    >
                      {`Save & start new`}
                    </Button>
                  )}
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 800,
    maxWidth: "100%",
    position: "relative",
  },
  top: {
    borderBottom: "1px solid #DBDEEE",
    height: 64,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 12,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      height: 36,
      alignItems: "flex-start",
      paddingLeft: 16,
    },
  },
  mobileStickyTop: {
    position: "sticky",
    top: 0,
    height: 48,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 16,
  },
  form: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
    },
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  subheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    color: theme.palette.primary.main,
    marginTop: 6,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  products: {
    width: "100%",
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "68px .4fr 1fr 92px 90px 92px 54px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0 8px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    borderBottom: "1px solid #C9CDDE",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18px",
      height: "unset",
      display: "block",
      padding: "8px 0 0 0",
      borderBottom: "none",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  headerRow: {
    minHeight: 56,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  totalsRow: {
    borderBottom: "none",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  productLineItem: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      minHeight: 32,
      padding: "8px 16px",
      borderBottom: "1px solid #EAEBF3",
    },
  },
  lastLineItem: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 16,
      borderBottom: "1px solid #C9CDDE",
    },
  },
  lineItemLabel: {
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.15,
    width: 85,
    minWidth: 85,
  },
  imgAndDelete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  ttlRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  lastRow: {
    borderBottom: "none !important",
  },
  selectorContainer: {
    width: "100%",
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
      paddingBottom: 16,
      paddingTop: 8,
    },
  },
  selector: {
    width: "calc(100% - 202px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  qtyAndAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  add: {
    width: 90,
    height: 40,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
    },
  },
  qtyInput: {
    width: 82,
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 8px)",
      margin: 0,
    },
  },
  thumbnail: {
    maxHeight: 36,
    maxWidth: 36,
  },
  noItems: {
    height: 52,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    color: theme.palette.text.secondary2,
  },
  bottomInputs: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  donation: {
    width: 180,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 16,
    },
  },
  studentEntered: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 16,
    },
  },
  saveButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  btn: {
    minWidth: 120,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  startNewBtn: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 16,
      width: "100%",
    },
  },
}));
