import {
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Button,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import {
  APIRes,
  GenericObject,
  ToastTypes,
  VolunteerDetailModes,
} from "../../../types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteerLabel, setToast } from "../../../state";
import { RootState } from "../../../types/state";
import { isOrgEmployee } from "../../../../lib";
import classNames from "classnames";
import { RedButton } from "../../../components/ui/RedButton";
import { APIAuthClient } from "../../../lib";
enum Options {
  REMOVE = "REMOVE",
  DISABLE = "DISABLE",
  MASK = "MASK",
}

type Props = {
  onClose: () => void;
  refreshList: () => void;
  refreshData: () => void;
  setMode: React.Dispatch<React.SetStateAction<VolunteerDetailModes>>;
  data: GenericObject;
};
export function DeleteVolunteer({
  onClose: _onClose,
  refreshList,
  data,
  setMode,
  refreshData,
}: Props) {
  const classes = styles();
  const {
    id,
    supporters,
    volunteerCampaignId,
    webpage_enabled,
    first_name,
    last_name,
  } = data;
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [option, setOption] = useState<null | Options>(null);
  const [submitting, setSubmitting] = useState(false);
  const { volunteerLabelLCSingular } = useSelector(getVolunteerLabel);
  const isOrgEmp = useSelector(({ user }: RootState) =>
    isOrgEmployee(user.role),
  );
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const disableRemove = Boolean(supporters);
  const disableDisableWebpage = !webpage_enabled;
  const disableClear = isOrgEmp;

  const onConfirm = async () => {
    setSubmitting(true);
    let res: APIRes = { data: {} };
    let toast = "";
    let action: () => void = () => {
      setMode(VolunteerDetailModes.view);
      refreshData();
      onClose();
    };
    switch (option) {
      case Options.DISABLE:
        toast = "Webpage disabled";
        res = await APIAuthClient.put<any, APIRes>(
          `/volunteer_campaigns/${volunteerCampaignId}/disable_webpage`,
        );
        break;
      case Options.MASK:
        toast = "Identifying information removed";
        res = await APIAuthClient.put<any, APIRes>(
          `/volunteers/${id}/remove_identifying_data`,
        );
        break;
      case Options.REMOVE:
        toast = `${first_name} ${last_name} was removed from this campaign`;
        action = refreshList;
        res = await APIAuthClient.delete<any, APIRes>(
          `/volunteer_campaigns/${volunteerCampaignId}`,
        );
        break;
    }
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setSubmitting(false);
      return;
    }
    dispatch(setToast(toast, ToastTypes.success));
    action();
    setSubmitting(false);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        <div className={classes.title}>Select a deletion option</div>

        <RadioGroup
          value={option}
          onChange={({ target }) => setOption(target.value as Options)}
        >
          <FormControlLabel
            value={Options.REMOVE}
            disabled={disableRemove}
            control={<Radio className={classes.radio} />}
            label={
              <div>
                Remove {volunteerLabelLCSingular} from this campaign only
                <div
                  className={classNames(
                    classes.radioSubText,
                    disableRemove && classes.disabledSubTxt,
                  )}
                >
                  The {volunteerLabelLCSingular} will be removed from this
                  campaign. Account data will remain unaffected.
                </div>
                {disableRemove && (
                  <div className={classes.disableWrapper}>
                    <ErrorIcon className={classes.errorIcon} /> {first_name}{" "}
                    {last_name} already generated sales.
                  </div>
                )}
              </div>
            }
            classes={{ label: classes.radioLabel, root: classes.radioRoot }}
          />
          <FormControlLabel
            value={Options.DISABLE}
            disabled={disableDisableWebpage}
            control={<Radio className={classes.radio} />}
            label={
              <div>
                Disable {volunteerLabelLCSingular}'s webpage for this campaign
                only
                <div
                  className={classNames(
                    classes.radioSubText,
                    disableDisableWebpage && classes.disabledSubTxt,
                  )}
                >
                  The {volunteerLabelLCSingular}'s link will not be accessible
                  online.
                </div>
                {disableDisableWebpage && (
                  <div className={classes.disableWrapper}>
                    <ErrorIcon className={classes.errorIcon} /> The webpage is
                    already disabled.
                  </div>
                )}
              </div>
            }
            classes={{ label: classes.radioLabel, root: classes.radioRoot }}
          />
          <FormControlLabel
            value={Options.MASK}
            disabled={disableClear}
            control={<Radio className={classes.radio} />}
            label={
              <div>
                Delete identifying information for this{" "}
                {volunteerLabelLCSingular} account
                <div
                  className={classNames(
                    classes.radioSubText,
                    disableClear && classes.disabledSubTxt,
                  )}
                >
                  All personal details will be cleared including account
                  holder's name, email address, phone number and campaign page
                  links for ALL campaigns. The account will be retained as an
                  anonymous profile, and access will be revoked for the account
                  holder.
                </div>
                <div className={classes.radioSubSubText}>
                  When should I choose this option?
                  <Tooltip
                    title={
                      <div>
                        Select this option if you need to completely erase all
                        identifying information associated with the account
                        holder.{" "}
                        <div>
                          This option should be chosen in situations where there
                          are legal requirements or internal policies mandating
                          the removal of all traces of the account holder's
                          presence from our systems.
                        </div>
                      </div>
                    }
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      onClick={e => e.preventDefault()}
                      className={classes.infoWrapper}
                    >
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        onClick={e => e.preventDefault()}
                      />
                    </div>
                  </Tooltip>
                </div>
                {disableClear && (
                  <div className={classes.disableWrapper}>
                    <ErrorIcon className={classes.errorIcon} /> This can only be
                    done by the primary group account holder.
                  </div>
                )}
              </div>
            }
            classes={{ label: classes.radioLabel, root: classes.radioRoot }}
          />
        </RadioGroup>

        <div className={classes.actions}>
          <Button
            variant={isMobile ? "contained" : "text"}
            color={isMobile ? "secondary" : undefined}
            className={classes.cancel}
            disabled={submitting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <RedButton
            variant={isMobile ? "contained" : "text"}
            className={classes.confirm}
            disabled={submitting}
            onClick={onConfirm}
          >
            Confirm
            <ButtonSpinner show={submitting} color="inherit" />
          </RedButton>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: "100%",
    overflowY: "auto",
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    marginBottom: 16,
  },
  radioLabel: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  radioRoot: {
    alignItems: "flex-start",
    marginBottom: 16,
    marginRight: 0,
  },
  radio: {
    marginTop: -8,
  },
  radioSubText: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.13,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginTop: 2,
  },
  radioSubSubText: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginTop: 4,
    display: "flex",
    alignItems: "center",
  },
  disabledSubTxt: {
    color: theme.palette.text.secondary2,
  },
  infoWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    color: theme.palette.text.secondary2,
    fontWeight: 400,
    marginLeft: 8,
  },
  tooltip: {
    borderRadius: 4,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "15px",
    width: 220,
    padding: 8,
  },
  disableWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.main,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    marginTop: 8,
    overflowWrap: "anywhere",
    fontWeight: 400,
  },
  errorIcon: {
    marginRight: 8,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 18,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  cancel: {
    marginRight: 8,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 16,
      textTransform: "unset",
    },
  },
  confirm: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
