import { ToastTypes } from "../../types";
import { RootState } from "../../types/state";

const uiTypes = {
  UI_TOAST_SET: "UI_TOAST_SET",
  UI_TOAST_RESET: "UI_TOAST_RESET",
  SIDEBAR_TOGGLE: "SIDEBAR_TOGGLE",
  TOP_BAR_TITLE_COMPONENT_SET: "TOP_BAR_TITLE_COMPONENT_SET",
  LOADING_DOWNLOAD_TOGGLE: "LOADING_DOWNLOAD_TOGGLE",
  AFFILIATE_ORIGIN_SET: "AFFILIATE_ORIGIN_SET",
  MODAL_IDENTIFIER_ADD: "MODAL_IDENTIFIER_ADD",
  MODAL_IDENTIFIER_REMOVE_LAST: "MODAL_IDENTIFIER_REMOVE_LAST",
};
const uiActions = {
  setToast(toast: string = "", toastType?: ToastTypes) {
    return { type: uiTypes.UI_TOAST_SET, payload: { toast, toastType } };
  },
  resetToast() {
    return { type: uiTypes.UI_TOAST_RESET };
  },
  toggleSidebar() {
    return { type: uiTypes.SIDEBAR_TOGGLE };
  },
  setTopBarTitleComponent(component: JSX.Element | undefined) {
    return { type: uiTypes.TOP_BAR_TITLE_COMPONENT_SET, payload: component };
  },
  toggleLoadingDownload(isLoading: boolean) {
    return { type: uiTypes.LOADING_DOWNLOAD_TOGGLE, isLoading };
  },
  setAffiliateOrigin(affiliateOrigin: string) {
    return { type: uiTypes.AFFILIATE_ORIGIN_SET, affiliateOrigin };
  },
  addModalIdentifier(identifier: number) {
    return {
      type: uiTypes.MODAL_IDENTIFIER_ADD,
      modalIdentifier: identifier,
    };
  },
  removeLastModalIdentifier() {
    return {
      type: uiTypes.MODAL_IDENTIFIER_REMOVE_LAST,
    };
  },
};

const setToast = uiActions.setToast;
const toggleSidebar = uiActions.toggleSidebar;
const setTopBarTitleComponent = uiActions.setTopBarTitleComponent;
const toggleLoadingDownload = uiActions.toggleLoadingDownload;

function getIsLoadingDownload(state: RootState) {
  return state.ui.loadingDownload;
}

export {
  uiTypes,
  uiActions,
  setToast,
  toggleSidebar,
  setTopBarTitleComponent,
  toggleLoadingDownload,
  getIsLoadingDownload,
};
