import { Fragment, useEffect, useState } from "react";
import {
  AppBar,
  makeStyles,
  Avatar,
  useScrollTrigger,
  Theme,
  Button,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Account } from "./Account";
import {
  TOP_BAR_HEIGHT_DESKTOP,
  TOP_BAR_HEIGHT_MOBILE,
  WHITE_BACKGROUND_PATHS,
} from "../types";
import { RootState } from "../types/state";
import {
  useImpersonationFields,
  useIsDesktop,
  useIsMobile,
  useLeaveImpersonation,
} from "../hooks/ui";
import { getCompanyFavicon32Href, getIsImpersonating } from "../lib";
import { CampaignSelector } from "./CampaignSelector";
import classNames from "classnames";
import { getUserNameInitials } from "../state";

export function Header() {
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const isImpersonating = getIsImpersonating();
  const leaveImpersonation = useLeaveImpersonation();
  const companyId = useSelector((state: any) => state.user.companyId);
  const companyFavicon32Href = getCompanyFavicon32Href(companyId);
  const { path } = useRouteMatch();
  const [whiteBG, setWhiteBG] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [accountAnchorEl, setAccountAnchorEl] = useState<undefined | Element>(
    undefined,
  );
  const topBarTitleComponent = useSelector(
    (state: RootState) => state.ui.topBarTitleComponent,
  );
  const { impersonationLabel, impersonationName } = useImpersonationFields();
  const userInitials = useSelector(getUserNameInitials);

  useEffect(() => {
    if (WHITE_BACKGROUND_PATHS[path]) setWhiteBG(true);
    else setWhiteBG(false);
  }, [path]);

  const classes = styles({ scrolled, whiteBG });

  const handleAccountClick = (e: any) => {
    setAccountAnchorEl(e.currentTarget);
    setShowAccount(true);
  };

  const Impersonation = () => (
    <div className={classes.impContainer}>
      <div className={classes.impTxtContainer}>
        <span className={classes.impLabel}>
          Impersonating {impersonationLabel}
        </span>{" "}
        <span className={classes.impName}>{impersonationName}</span>
      </div>
      <Button
        size="small"
        className={classes.leave}
        onClick={leaveImpersonation}
        classes={{ label: classes.leaveBtnLabel }}
      >
        Leave
      </Button>
    </div>
  );

  return (
    <Fragment>
      <AppBar
        position="sticky"
        className={classNames(classes.appBar, whiteBG && classes.whiteBG)}
      >
        {isImpersonating && isMobile && <Impersonation />}
        <div className={classes.mainContent}>
          {isDesktop && (
            <div className={classes.titleWrapper}>
              {topBarTitleComponent ? topBarTitleComponent : ""}
            </div>
          )}
          {isMobile && (
            <img
              alt="logo"
              src={companyFavicon32Href}
              className={classes.favicon}
            />
          )}

          {isImpersonating && isDesktop && <Impersonation />}
          {isMobile && <CampaignSelector />}

          <Avatar onClick={handleAccountClick} className={classes.appBarAvatar}>
            {userInitials}
          </Avatar>
        </div>
      </AppBar>

      <Account
        isOpen={showAccount}
        onClose={() => setShowAccount(false)}
        anchorEl={accountAnchorEl}
      />
    </Fragment>
  );
}

type StyleProps = {
  scrolled: boolean;
  whiteBG: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  appBar: {
    backgroundColor: ({ whiteBG, scrolled }) =>
      whiteBG || scrolled ? "#FFFFFF" : "#F7F7F7",
    height: TOP_BAR_HEIGHT_DESKTOP,
    color: "unset",
    boxShadow: "none",
    borderBottom: ({ scrolled }) => (scrolled ? "1px solid #DBDEEE" : "none"),
    [theme.breakpoints.down("sm")]: {
      height: TOP_BAR_HEIGHT_MOBILE,
      backgroundColor: () => "#FFFFFF",
      borderBottom: () => "1px solid #DBDEEE",
    },
  },
  whiteBG: {
    backgroundColor: () => "#FFFFFF",
  },
  titleWrapper: {
    overflow: "hidden",
    lineHeight: "normal",
  },
  mainContent: {
    position: "relative",
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  appBarAvatar: {
    backgroundColor: theme.palette.secondary2.main,
    color: "#FFFFFF",
    cursor: "pointer",
    height: 32,
    width: 32,
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0,
  },
  favicon: {
    maxWidth: 32,
    maxHeight: 32,
  },
  impContainer: {
    width: 400,
    maxWidth: "40%",
    height: 48,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 16px",
    color: "#FFFFFF",
    paddingLeft: 16,
    paddingRight: 12,
    boxShadow:
      "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: 24,
      borderRadius: 0,
      margin: 0,
      paddingLeft: 16,
      paddingRight: 16,
      boxShadow: "none",
    },
  },
  impTxtContainer: {
    maxWidth: "calc(100% - 58px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100% - 56px)",
    },
  },
  impLabel: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      letterSpacing: 0.1,
      lineHeight: "15px",
    },
  },
  impName: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      letterSpacing: 0.1,
      lineHeight: "15px",
    },
  },
  leave: {
    height: 30,
    width: 53,
    borderRadius: 15,
    minWidth: "unset",
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    marginLeft: 16,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#FFFFFF",
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 16,
      maxHeight: 16,
      overflow: "hidden",
      borderRadius: 15,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 12,
    },
  },
  leaveBtnLabel: {
    [theme.breakpoints.down("sm")]: {
      height: 16,
    },
  },
}));
