import { Fragment, useState } from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles, IconButton } from "@material-ui/core";
import { GenericObject, ListProps, SeatingMapLevels } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { EditSeatingMapSection } from "./EditSeatingMapSection";
const MIN_WIDTH = 900;

type Props = {
  listProps: ListProps;
  level: SeatingMapLevels;
};
export function SeatingMapSectionRows({ listProps, level }: Props) {
  const { rows, params, setParams, nextPage, paginationProps } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Section ID"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Section name"
                fieldName="section_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Seats"
                fieldName="section_seat_count"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Order"
                fieldName="order_number"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Open seating"
                fieldName="allow_open_seating"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Assigned seating"
                fieldName="allow_assigned_seating"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map(row => {
          return (
            <Row
              key={row.id as number}
              row={row}
              listProps={listProps}
              level={level}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Sections per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  listProps: ListProps;
  level: SeatingMapLevels;
};
function Row({ row, listProps, level }: RowProps) {
  const {
    id,
    section_name,
    order_number,
    allow_open_seating,
    allow_assigned_seating,
    section_seat_count,
  } = row;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);

  return (
    <ListRowContainer>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{section_name}</div>
            <div>{section_seat_count}</div>
            <div>{order_number}</div>
            <div>
              {allow_open_seating ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>
              {allow_assigned_seating ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileContent}>
              <div>{`#${id}`}</div>
              <div className={classes.sectionName}>{section_name}</div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EditSeatingMapSection
          onClose={() => setShowEdit(false)}
          refreshList={listProps.refreshList}
          id={id}
          level={level}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "130px 1fr 92px 92px 138px 164px 48px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: 16,
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileContent: {
    overflow: "hidden",
    paddingRight: 12,
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  sectionName: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
      lineHeight: "22px",
    },
  },
}));
