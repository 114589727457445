import { useTheme } from "@material-ui/core";

export function ImpersonateSVG({ disabled }: { disabled?: boolean }) {
  const theme = useTheme();
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-0, -0)"
          fill={disabled ? theme.palette.text.disabled : "#248BEF"}
        >
          <g transform="translate(0, 0)">
            <path d="M2,4.00000002 L-1.8189894e-12,4.00000002 L-1.8189894e-12,18 C-1.8189894e-12,19.1000001 0.900000004,20 2,20 L16,20 L16,18 L2,18 L2,4.00000002 Z M12,9.00000001 C13.6600001,9.00000001 15,7.66000003 15,6.00000001 C15,4.34000002 13.6600001,3.00000001 12,3.00000001 C10.34,3.00000001 9.00000004,4.34000002 9.00000004,6.00000001 C9.00000004,7.66000003 10.34,9.00000001 12,9.00000001 Z M12,5.00000002 C12.5500001,5.00000002 13,5.45000002 13,6.00000002 C13,6.55000003 12.5500001,7.00000002 12,7.00000002 C11.45,7.00000002 11,6.55000003 11,6.00000002 C11,5.45000002 11.45,5.00000002 12,5.00000002 Z M18,0 L6.00000002,0 C4.90000002,0 4.00000002,0.900000004 4.00000002,2 L4.00000002,14 C4.00000002,15.1000001 4.90000002,16 6.00000002,16 L18,16 C19.1000001,16 20,15.1000001 20,14 L20,2 C20,0.900000004 19.1000001,0 18,0 Z M8.69000004,14.0000001 C9.64000004,13.3700001 10.78,13.0000001 12,13.0000001 C13.2200001,13.0000001 14.3600001,13.3700001 15.3100001,14.0000001 L8.69000004,14.0000001 Z M18,13.7300001 C16.5300001,12.0600001 14.4000001,11 12,11 C9.60000004,11 7.47000003,12.0600001 6.00000002,13.7300001 L6.00000002,2.00000001 L18,2.00000001 L18,13.7300001 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
