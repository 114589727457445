import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  minLength,
  NullableBooleanInput,
  BooleanInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { getRoleBooleans } from "../../customState";
import { styles } from "./Rep.styles";

const requiredInput = [required()];
const validateName = [required(), minLength(2)];
const validateEmail = [required(), email()];

function RepEdit(props) {
  const classes = styles();
  const { isCompanyOrCompanyAdmin } = useSelector(state =>
    getRoleBooleans(state),
  );
  const {
    hasRepHierarchy,
    campaignManagementEnabled,
    enableDiscountCards,
  } = useSelector(({ user }) => user);

  const RepTitle = ({ record }) => {
    return (
      <span>
        Editing - {record ? `${record.first_name} ${record.last_name}` : ""}
      </span>
    );
  };

  return (
    <Edit
      title={<RepTitle />}
      {...props}
      redirect="show"
      undoable={false}
      successMessage="Rep updated successfully"
    >
      <SimpleForm redirect="show">
        <TextInput source="id" disabled={true} />
        <TextInput source="first_name" validate={validateName} />
        <TextInput source="last_name" validate={requiredInput} />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="phone" />
        {hasRepHierarchy && (
          <BooleanInput
            source="allow_rep_hierarchy"
            label="Allow Rep Hierarchy"
          />
        )}
        {isCompanyOrCompanyAdmin && (
          <NullableBooleanInput
            label="Rep Can Manage - Override (keep default to use company setting)"
            source="rep_can_manage_override"
            className={classes.manageOverride}
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
          />
        )}
        <TextInput source="association" />
        {isCompanyOrCompanyAdmin && enableDiscountCards && (
          <BooleanInput
            source="allow_discount_cards"
            label="Allowed to Use Discount Cards"
            className={classes.fitContent}
          />
        )}
        {isCompanyOrCompanyAdmin && (
          <TextInput
            source="general_notes"
            multiline
            fullWidth
            helperText="Will only display in this Rep profile"
          />
        )}
        {isCompanyOrCompanyAdmin && campaignManagementEnabled && (
          <TextInput
            source="org_profit_notes"
            multiline
            fullWidth
            helperText="Will display in Company view of Org Profit tab"
          />
        )}
        {isCompanyOrCompanyAdmin && campaignManagementEnabled && (
          <TextInput
            source="rep_commission_notes"
            multiline
            fullWidth
            helperText="Will display in Company view of Rep Commission tab"
          />
        )}
      </SimpleForm>
    </Edit>
  );
}
export default RepEdit;
