import { Theme, makeStyles } from "@material-ui/core";
import { ListProps, TOP_BAR_HEIGHT_DESKTOP } from "../../types";
import { ReactNode } from "react";
import classNames from "classnames";
import { SkeletonLoader } from "../ui/SkeletonLoader";
import { MobileListLoadingMore } from "../ui/MobileListLoadingMore";
import { useIsMobile } from "../../hooks/ui";

type DefaultProps = {
  children: ReactNode | ReactNode[];
};

type ListContainerProps = {
  children: ReactNode | ReactNode[];
  listProps: ListProps;
  topOffset?: number;
  noStyling?: boolean;
  noPadding?: boolean;
};
export function ListContainer({
  children,
  listProps,
  topOffset,
  noStyling,
  noPadding,
}: ListContainerProps) {
  const { params, loading } = listProps;
  const { page } = params;
  const isMobile = useIsMobile();
  const classes = styles({ topOffset, noStyling, noPadding });

  return (
    <div className={classes.listContainer}>
      {children}
      <SkeletonLoader show={loading && (!isMobile || page === 0)} />
      <MobileListLoadingMore show={loading && page > 0} />
    </div>
  );
}

export function ListRowsWrapper({ children }: DefaultProps) {
  const classes = styles({});
  return <div className={classes.rowsWrapper}>{children}</div>;
}

type ListRowsProps = {
  children: ReactNode | ReactNode[];
  minWidth: number;
};
export function ListRows({ children, minWidth }: ListRowsProps) {
  const classes = styles({ minWidth });
  return <div className={classes.rows}>{children}</div>;
}

type ListRowContainerProps = {
  children: ReactNode | ReactNode[];
  clickable?: boolean;
  onClick?: () => void;
  lastRow?: boolean;
  noBorderLastRowDT?: boolean;
};
export function ListRowContainer({
  children,
  clickable,
  lastRow,
  noBorderLastRowDT,
  onClick,
}: ListRowContainerProps) {
  const classes = styles({ clickable, lastRow, noBorderLastRowDT });
  return (
    <div
      className={classes.rowContainer}
      onClick={e => {
        e.stopPropagation();
        typeof onClick !== "undefined" && onClick();
      }}
    >
      {children}
    </div>
  );
}

export function ListHeaderRowContainer({ children }: DefaultProps) {
  const classes = styles({});
  return <div className={classes.headerRowContainer}>{children}</div>;
}

type ListRowProps = {
  children: ReactNode | ReactNode[];
  columnsAndCustomStylesClassName: "string";
};
export function ListRow({
  children,
  columnsAndCustomStylesClassName,
}: ListRowProps) {
  const classes = styles({});
  return (
    <div className={classNames(classes.row, columnsAndCustomStylesClassName)}>
      {children}
    </div>
  );
}

type StyleProps = {
  minWidth?: number;
  clickable?: boolean;
  lastRow?: boolean;
  topOffset?: number;
  noStyling?: boolean;
  noPadding?: boolean;
  noBorderLastRowDT?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  listContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    maxHeight: ({ topOffset }) =>
      `calc(100vh - ${topOffset ? topOffset : TOP_BAR_HEIGHT_DESKTOP}px)`,
    // maxHeight: `calc(100vh - ${TOP_BAR_HEIGHT_DESKTOP}px)`,
    overflowY: "auto",
    padding: ({ noStyling, noPadding }) =>
      noStyling || noPadding ? 0 : "0 32px 18px 32px",
    backgroundColor: ({ noStyling }) => (noStyling ? "#FFFFFF" : "#F7F7F7"),
    [theme.breakpoints.down("sm")]: {
      padding: ({ noStyling, noPadding }) =>
        noStyling || noPadding ? 0 : "0 16px 16px 16px",
      maxHeight: () => "unset",
    },
  },
  rowsWrapper: {
    width: "100%",
    height: "fit-content",
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    overflowY: "auto",
  },
  rows: {
    minWidth: ({ minWidth }) => (minWidth ? minWidth : "100%"),
    [theme.breakpoints.down("sm")]: {
      minWidth: () => "100%",
      maxWidth: () => "100%",
    },
  },
  rowContainer: {
    position: "relative",
    borderBottom: ({ lastRow, noBorderLastRowDT }) =>
      lastRow && noBorderLastRowDT ? "none" : "1px solid #C9CDDE",
    cursor: ({ clickable }) => (clickable ? "pointer" : "unset"),
    "&:hover": {
      backgroundColor: ({ clickable }) =>
        clickable ? "rgba(234, 235, 243, .33)" : "unset",
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: ({ lastRow }) => (lastRow ? "none" : "1px solid #EAEBF3"),
      cursor: () => "unset",
      "&:hover": {
        backgroundColor: () => "unset",
      },
    },
  },
  headerRowContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    borderBottom: "1px solid #C9CDDE",
    "& > *": {
      minHeight: 56,
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.15,
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      padding: "16px 0 16px 16px",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
}));
