import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import arrayMutators from "final-form-arrays";
import RemoveIcon from "@material-ui/icons/Close";
import { FormApi } from "final-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, makeStyles, IconButton, TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { APIAuthClient, getErrorFromMeta, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { getCampaignAutocomplete, setToast } from "../../../state";
import { ButtonSpinner, CircularLoader } from "../../../components";
import classNames from "classnames";

type Props = { id: any };

export function EventCampaigns({ id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const campaignAC = useSelector(getCampaignAutocomplete);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [campaignACIdMap, setCampaignACIdMap] = useState<GenericObject>({});

  useEffect(() => {
    const fetch = async () => {
      const url = `/event_group_events/${id}/campaigns`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data: campaignIds } = response;
      if (error) return dispatch(setToast(errorMessage));
      setInitialValues({ campaignIds });
      setLoading(false);
    };
    if (id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const idMap = {};
    campaignAC.forEach(e => (idMap[e.id] = e));
    setCampaignACIdMap(idMap);
  }, [campaignAC]);

  const onSubmit = async (values: any, form: FormApi, complete: any) => {
    const { campaignIds } = values;
    const update = { campaign_ids: campaignIds || [] };
    const url = `/event_group_events/${id}/campaigns`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    form.restart({ campaignIds: data });
  };

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularLoader show />
        </div>
      )}

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, form, pristine }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className={classNames(
                classes.form,
                loading && classes.hiddenForm,
              )}
            >
              <div className={classes.mainContent}>
                <FieldArray name="campaignIds">
                  {({ fields }) => (
                    <div className={classes.arrayField}>
                      {fields.map((row, index) => {
                        return (
                          <div key={index}>
                            <div className={classes.arrayRow}>
                              <Field
                                name={row}
                                fullWidth
                                validate={requiredField}
                                render={({ input, meta, ...rest }) => {
                                  const {
                                    showError,
                                    helperText,
                                  } = getErrorFromMeta(meta);
                                  return (
                                    <Autocomplete
                                      {...rest}
                                      {...input}
                                      onChange={(_, value: any) => {
                                        input.onChange(
                                          value ? value.campaign_id : null,
                                        );
                                      }}
                                      value={
                                        campaignACIdMap[input.value] || null
                                      }
                                      options={campaignAC}
                                      getOptionLabel={o => (o ? o.name : o)}
                                      getOptionSelected={(o, v) =>
                                        o.campaign_id === v.campaign_id
                                      }
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          placeholder="Select campaign"
                                          helperText={helperText}
                                          error={showError}
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                              <div className={classes.removeWrapper}>
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                >
                                  <RemoveIcon className={classes.remove} />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <Button
                        color="primary"
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={() => fields.push("")}
                      >
                        CAMPAIGN
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className={classes.bottom}>
                <Button
                  variant="text"
                  disabled={submitting}
                  onClick={() => form.restart()}
                >
                  CANCEL
                </Button>

                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting || pristine}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  loadingWrapper: {
    paddingTop: 50,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100vw",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  hiddenForm: {
    display: "none",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    padding: "16px 24px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  arrayField: {
    marginBottom: 24,
  },
  arrayRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    width: 500,
    maxWidth: "100%",
  },
  removeWrapper: {
    marginLeft: 16,
    width: "fit-content",
    maxWidth: "fit-content",
    color: theme.palette.error.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
  remove: {
    color: theme.palette.error.main,
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
      marginBottom: 8,
    },
  },
}));
