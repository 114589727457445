import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FormApi } from "final-form";
import { Button, makeStyles } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, convertFileToB64, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  CircularLoader,
  DateTimeField,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { ClickTooltip } from "../../../components/ui/ClickTooltip";
import { NotificationSchedule } from "../components/NotificationSchedule";

type Props = {
  groupId: any;
  refreshOnCloseRef: React.MutableRefObject<boolean>;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
};

export function EditEventGroup({
  groupId,
  refreshOnCloseRef,
  setGroupName,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GenericObject>({});

  useEffect(() => {
    const fetch = async () => {
      const url = `/event_groups/${groupId}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setInitialValues(data);
      setLoading(false);
    };
    if (groupId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const onSubmit = async (values: any, form: FormApi, complete: any) => {
    refreshOnCloseRef.current = true;
    const {
      imageFile,
      group_name,
      active,
      active_start_date,
      active_end_date,
      event_group_notifications: EGN,
    } = values;
    const event_group_notifications = !EGN
      ? []
      : EGN.map((e: GenericObject) => {
          const { id, days_before_event } = e;
          return {
            id: id ? id : null,
            days_before_event: Number(days_before_event),
          };
        });
    const update: GenericObject = {
      group_name,
      active,
      active_start_date,
      active_end_date,
      event_group_notifications,
    };
    if (imageFile) update.group_logoBase64 = await convertFileToB64(imageFile);
    const url = `/event_groups/${groupId}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    form.restart(data);
    setGroupName(data.group_name);
  };

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularLoader show />
        </div>
      )}

      {!loading && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form, values, pristine }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.mainContent}>
                  <Field
                    component={TextFieldWrapper}
                    name="group_name"
                    label="Group name"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <div className={classes.inputGroup}>
                    <DateTimeField
                      name="active_start_date"
                      label="Active from"
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                    <DateTimeField
                      name="active_end_date"
                      label="Active until"
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                  </div>
                  <div className={classes.activeContainer}>
                    <SwitchField fieldName="active" label="Active" />
                    <div className={classes.infoIcon} />
                    <ClickTooltip content="Toggle to deactivate this group during the active dates." />
                  </div>

                  <div className={classes.logoHeader}>Group logo</div>
                  <div className={classes.logoWrapper}>
                    <ImageDropzoneField
                      fieldName="imageFile"
                      noDelete
                      currImage={values.group_logo}
                    />
                  </div>

                  <div className={classes.notificationsHeader}>
                    Event notifications
                  </div>

                  <div className={classes.notificationSubheader}>
                    Schedule email notifications for participants who have opted
                    in to have this group's event notifications sent on their
                    behalf.
                  </div>

                  <NotificationSchedule fieldName="event_group_notifications" />
                </div>

                <div className={classes.bottom}>
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={() => form.restart()}
                  >
                    CANCEL
                  </Button>

                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting || pristine}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  loadingWrapper: {
    paddingTop: 50,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100vw",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    padding: 24,
    paddingBottom: 16,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  inputGroup: {
    display: "flex",
    width: 500,
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: 500,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      width: "100%",
    },
  },
  activeContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 32,
    position: "relative",
  },
  infoIcon: {
    marginLeft: 8,
  },
  logoHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 16,
  },
  logoWrapper: {
    width: 500,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  notificationsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
  },
  notificationSubheader: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
    },
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
      marginBottom: 8,
    },
  },
}));
