import { Button, makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { nonEmptyArray } from "../../../../lib";
type Images = { name: string; link: string }[] | null;

type Props = { donation: GenericObject };
export function ImagesCard({ donation }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const { images } = donation as { images: Images };

  if (!nonEmptyArray(images)) return <></>;
  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Attachments</div>

      {images.map(({ name, link }) => (
        <div key={name} className={classes.imageBox}>
          <div className={classes.name}>{name}</div>
          <Button
            variant="text"
            color="primary"
            className={classes.open}
            endIcon={<OpenInNewIcon />}
            href={link}
            target="_blank"
          >
            VIEW
          </Button>
        </div>
      ))}

      <div className={classes.bottom} />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  imageBox: {
    width: "100%",
    minHeight: 64,
    borderRadius: 8,
    border: "1px solid #eaebf3",
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    paddingRight: 8,
  },
  name: {
    overflowWrap: "anywhere",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.15,
  },
  open: {
    minWidth: "fit-content",
    marginLeft: 12,
  },
  bottom: {
    height: 8,
  },
}));
