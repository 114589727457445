import { useState, useEffect, useCallback, Fragment } from "react";
import debounce from "lodash.debounce";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { RootState } from "../../../types/state";
import { useSelector } from "react-redux";
import { Contact } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import classNames from "classnames";
import { DeleteContact, EditContact } from "../../../components";

export function ContactList() {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const [displayContacts, setDisplayContacts] = useState<Contact[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (Array.isArray(contacts)) setDisplayContacts(contacts);
  }, [contacts]);

  const handleSearch = (newVal: string) => {
    if (!newVal) return setDisplayContacts(contacts);
    if (Array.isArray(contacts)) {
      const filtered = contacts.filter(({ name }) =>
        name.toLowerCase().includes(newVal.toLowerCase()),
      );
      setDisplayContacts(filtered);
    }
  };

  const onChange = ({ target }) => {
    setSearch(target.value);
    debouncedHandleSearch(target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), []);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <h2 className={classes.header}>My fundraising contacts</h2>
        <TextField
          placeholder="Search contacts"
          value={search}
          size="small"
          onChange={onChange}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <SearchIcon fontSize="small" className={classes.searchIcon} />
            ),
          }}
        />
      </div>

      <div className={classes.rowsWrapper}>
        <div className={classes.rows}>
          {isDesktop && (
            <div className={classes.rowContainer}>
              <div className={classNames(classes.row, classes.headerRow)}>
                <div>Name</div>
                <div>Phone</div>
                <div>Email</div>
                <div />
              </div>
            </div>
          )}

          {displayContacts.map((contact, index) => {
            const lastRow = index === displayContacts.length - 1;
            return <Row key={index} contact={contact} lastRow={lastRow} />;
          })}
        </div>
      </div>
    </div>
  );
}

type RowProps = {
  contact: Contact;
  lastRow: boolean;
};
function Row({ contact, lastRow }: RowProps) {
  const classes = styles();
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { name, phone, email } = contact;

  return (
    <Fragment>
      <div className={classNames(!lastRow && classes.rowContainer)}>
        <div className={classes.row}>
          {isDesktop && (
            <Fragment>
              <div className={classes.name}>{name}</div>
              <div>{phone}</div>
              <div>{email}</div>
            </Fragment>
          )}
          {isMobile && (
            <div>
              <div className={classes.name}>{name}</div>
              {phone && <div className={classes.emailAndPhone}>{phone}</div>}
              {email && <div className={classes.emailAndPhone}>{email}</div>}
            </div>
          )}
          <div className={classes.rightActions}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => setShowEdit(true)}
              className={classes.edit}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => setShowDelete(true)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <EditContact
        isOpen={showEdit}
        onClose={() => setShowEdit(false)}
        contact={contact}
      />
      <DeleteContact
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
        contact={contact}
      />
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    backgroundColor: "#F7F7F7",
    padding: "32px 32px 24px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 24px 16px",
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 12,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 19,
    },
  },
  header: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: 0.15,
      lineHeight: "32px",
      marginBottom: 16,
    },
  },
  input: {
    width: 350,
    borderRadius: 4,
    backgroundColor: "#EAEBF3",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 32,
      "& .MuiInputBase-root": {
        height: 32,
      },
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary2,
  },
  rowsWrapper: {
    width: "100%",
    height: "fit-content",
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    overflowY: "hidden",
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  rows: {
    minWidth: 800,
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  rowContainer: {
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: ".6fr .4fr .8fr 1fr",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 20,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 58,
      paddingTop: 8.5,
      paddingBottom: 8.5,
      gridTemplateColumns: "1fr 86px",
      "& > *": {
        paddingRight: 8,
      },
    },
  },
  headerRow: {
    minHeight: 56,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: 0.1,
    },
  },
  emailAndPhone: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: "14px",
      paddingTop: 4,
      color: theme.palette.text.secondary,
    },
  },
  edit: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 12,
    },
  },
  rightActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 8,
    },
  },
}));
