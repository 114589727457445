import { FC, useEffect } from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import { Login as _Login } from "../views";
import { Layout } from "./layout/Layout";
import { Contacts } from "./pages/contacts/Contacts";
import { VolunteerGuidedSetup } from "./pages/volunteerGuidedSetup/VolunteerGuidedSetup";
import { DashboardController } from "./pages/dashboard/DashboardController";
import { paths } from "./types";
import { VolunteerSSO } from "./pages/VolunteerSSO";
import { VolunteerEZShare } from "./pages/VolunteerEZShare";
import { Profile } from "./pages/profile/Profile";
import { Donations } from "./pages/donations/Donations";
import { OrgCampaigns } from "./pages/OrgCampaigns";
import { MobileMenu } from "./pages/MobileMenu";
import { useDispatch, useSelector } from "react-redux";
import { validJWTCheck } from "./lib";
import { setToast } from "./state";
import { Toast } from "./components";
import { isNewAppUser } from "../lib";
import { AddAssociatedVolunteerAccount } from "./pages/AddAssociatedVolunteerAccount";
import { Leaderboard } from "./pages/leaderboard/Leaderboard";
import { Teams } from "./pages/teams/Teams";
import { Volunteers } from "./pages/volunteers/Volunteers";
import { Submissions } from "./pages/submissions/Submissions";
import { OrgCampaignSettings } from "./pages/orgCampaignSettings/OrgCampaignSettings";
import { OrgEmployees } from "./pages/orgEmployees/OrgEmployees";
import { EventGroups } from "./pages/events/EventGroups";
import { EventTickets } from "./pages/eventTickets/EventTickets";
import { MediaCenter } from "./pages/MediaCenter";
import { Giveaways } from "./pages/giveaways/Giveaways";
import { SeatingMaps } from "./pages/seatingMaps/SeatingMaps";

const Login = _Login as FC<any>;
export function NewAppRoutes() {
  return (
    <Switch>
      <Route path={paths.LOGIN} exact render={props => <Login {...props} />} />
      <Route
        path={paths.VOLUNTEER_SSO}
        exact
        render={props => <VolunteerSSO {...props} />}
      />

      <Route
        path={[
          paths.HOME,
          paths.VOLUNTEER_GUIDED_SETUP,
          paths.CONTACTS,
          paths.VOLUNTEER_EZ_SHARE,
          paths.PROFILE,
          paths.DONATIONS,
          paths.ORG_CAMPAIGNS,
          paths.MOBILE_MENU,
          paths.ADD_ASSOC_VOLUNTEER_ACCOUNT,
          paths.LEADERBOARD,
          paths.TEAMS,
          paths.VOLUNTEERS,
          paths.SUBMISSIONS,
          paths.ORG_CAMPAIGN_SETTINGS,
          paths.ORG_EMPLOYEES,
          paths.EVENT_SEATING,
          paths.EVENTS,
          paths.EVENT_TICKETS,
          paths.MEDIA_CENTER,
          paths.GIVEAWAYS,
        ]}
        exact
      >
        <Layout>
          <Switch>
            <Route
              path={paths.HOME}
              exact
              render={(props: any) => <DashboardController {...props} />}
            />
            <Route
              path={paths.VOLUNTEER_GUIDED_SETUP}
              exact
              render={(props: any) => <VolunteerGuidedSetup {...props} />}
            />
            <Route
              path={paths.CONTACTS}
              exact
              render={(props: any) => <Contacts {...props} />}
            />
            <Route
              path={paths.VOLUNTEER_EZ_SHARE}
              exact
              render={(props: any) => <VolunteerEZShare {...props} />}
            />
            <Route
              path={paths.PROFILE}
              exact
              render={(props: any) => <Profile {...props} />}
            />
            <Route path={paths.DONATIONS} exact render={() => <Donations />} />
            <Route
              path={paths.ORG_CAMPAIGNS}
              exact
              render={(props: any) => <OrgCampaigns {...props} />}
            />
            <Route
              path={paths.MOBILE_MENU}
              exact
              render={() => <MobileMenu />}
            />
            <Route
              path={paths.ADD_ASSOC_VOLUNTEER_ACCOUNT}
              exact
              component={AddAssociatedVolunteerAccount}
            />
            <Route path={paths.LEADERBOARD} exact component={Leaderboard} />
            <Route path={paths.TEAMS} exact component={Teams} />
            <Route path={paths.VOLUNTEERS} exact component={Volunteers} />
            <Route path={paths.SUBMISSIONS} exact component={Submissions} />
            <Route
              path={paths.ORG_CAMPAIGN_SETTINGS}
              exact
              component={OrgCampaignSettings}
            />
            <Route path={paths.ORG_EMPLOYEES} exact component={OrgEmployees} />
            <Route path={paths.EVENTS} exact component={EventGroups} />
            <Route path={paths.EVENT_TICKETS} exact component={EventTickets} />
            <Route path={paths.EVENT_SEATING} exact component={SeatingMaps} />
            <Route path={paths.MEDIA_CENTER} exact component={MediaCenter} />
            <Route path={paths.GIVEAWAYS} exact component={Giveaways} />
          </Switch>
        </Layout>
      </Route>
      {/* TODO: Add Not-Found Page - for now redirecting to Home page  */}
      <Route path="/*" component={CatchAll} />
    </Switch>
  );
}

function CatchAll({ location, history }: RouteComponentProps) {
  const role = useSelector((state: any) => state.user.role);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!validJWTCheck()) {
      dispatch(
        setToast("Your session has expired, please log back in to continue."),
      );
      history.replace(paths.LOGIN);
    } else if (isNewAppUser(role)) {
      dispatch(setToast("We can't find the page you are looking for."));
      history.replace(paths.HOME);
    } else {
      history.replace(`admin${location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return <Toast />;
}
