import { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Button,
  Fade,
  IconButton,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import CloseIcon from "@material-ui/icons/Close";
import {
  affiliateLinkWithTS,
  facebookLink,
  linkedinLink,
  textMessageLink,
  twitterLink,
  whatsappLink,
} from "../lib";
import { BOTTOM_BAR_HEIGHT, Contact, paths } from "../types";
import { useSelector } from "react-redux";
import { contactActions } from "../state";
import { EZShareSVG } from "../assets/EZShareSVG";
import { RootState, useAppDispatch } from "../types/state";
import { ButtonSpinner, SkeletonLoader } from "../components";
import { FacebookSVG } from "../assets/FacebookSVG";
import { TwitterSVG } from "../assets/TwitterSVG";
import { LinkedinSVG } from "../assets/LinkedinSVG";
import { WhatsappSVG } from "../assets/WhatsappSVG";
import { LightbulbSVG } from "../assets/LightbulbSVG";
import { InstagramSVG } from "../assets";
import { InstagramDrawer } from "../components/general/InstagramDrawer";
import { useAddInstagram } from "../hooks/misc";
import { AFFILIATES, CONTACT_ID_PARAM } from "../../lib";

export function VolunteerEZShare() {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const addInstagram = useAddInstagram();
  const {
    campaignInfoFetched,
    campaignId,
    orgName,
    webpageLink,
    volunteerName,
  } = useSelector((state: RootState) => state.campaign);
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const [phoneContacts, setPhoneContacts] = useState<Contact[]>([]);
  const [hasPhoneContacts, setHasPhoneContacts] = useState(false);
  const link = affiliateLinkWithTS(webpageLink, AFFILIATES.EZ_SHARING);
  const [loading, setLoading] = useState(true);
  const [contactsLoading, setContactsLoading] = useState(true);
  const [showInstagram, setShowInstagram] = useState(false);
  const [enableSpanish, setEnableSpanish] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!campaignId) return;
      const success = await dispatch(contactActions.fetchContacts());
      if (success) setContactsLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (!contactsLoading && campaignInfoFetched) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfoFetched, contactsLoading]);

  useEffect(() => {
    if (Array.isArray(contacts)) {
      const _PC = contacts.filter(({ phone }) => Boolean(phone));
      setPhoneContacts(_PC);
      setHasPhoneContacts(Boolean(_PC.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <EZShareSVG />
        <h1 className={classes.header}>
          Welcome to your <span className={classes.ezSharing}>EZ-Sharing</span>{" "}
          space
        </h1>

        <SkeletonLoader show={loading} />

        {!loading && hasPhoneContacts && (
          <Fragment>
            <div className={classes.subheader}>
              Tap the TEXT button to launch your messaging app with a prewritten
              message containing a link to your page.
            </div>
            <FormControlLabel
              className={classes.spanishSwitch}
              control={
                <Switch
                  checked={enableSpanish}
                  onChange={({ target }) => setEnableSpanish(target.checked)}
                />
              }
              label="Enable Spanish messaging"
            />
            {phoneContacts.map((contact, index) => (
              <Row
                key={index}
                contact={contact}
                link={link}
                orgName={orgName}
                volunteerName={volunteerName}
                campaignId={campaignId}
                enableSpanish={enableSpanish}
              />
            ))}
          </Fragment>
        )}

        {!loading && !hasPhoneContacts && (
          <Fragment>
            <div className={classes.subheader}>
              There are no fundraising contacts with phone numbers. Visit the{" "}
              <span
                className={classes.contactsLink}
                onClick={() => history.push(paths.CONTACTS)}
              >
                Contacts
              </span>{" "}
              section and add phone numbers for easy text message fundraising.
            </div>
            <div>
              <Button
                href={textMessageLink({
                  link,
                  orgName,
                  volunteerName,
                  isVolunteer: true,
                })}
                className={classes.noContactsButton}
                color="primary"
                startIcon={<SmsOutlinedIcon />}
              >
                Send a text
              </Button>
              <Tooltip text="Click to share a link to your page via text message" />
            </div>
          </Fragment>
        )}
      </div>

      <div className={classes.socialMediaBar}>
        <div className={classes.smInstructions}>
          Tap on an icon to share your campaign on social media
        </div>
        <div className={classes.socialMediaLinks}>
          <IconButton
            size="small"
            href={facebookLink(link)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <FacebookSVG />
          </IconButton>
          <IconButton
            size="small"
            href={twitterLink(link, orgName, true)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <TwitterSVG />
          </IconButton>
          <IconButton
            size="small"
            href={linkedinLink(link)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <LinkedinSVG />
          </IconButton>
          <IconButton
            size="small"
            href={whatsappLink(link, orgName, true)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <WhatsappSVG />
          </IconButton>
          {addInstagram && (
            <IconButton
              size="small"
              onClick={() => setShowInstagram(true)}
              disableFocusRipple
              disableRipple
              classes={{ root: classes.iconButton }}
            >
              <InstagramSVG style={{ height: 32, width: 32 }} />
            </IconButton>
          )}
        </div>
      </div>
      {showInstagram && (
        <InstagramDrawer onClose={() => setShowInstagram(false)} link={link} />
      )}
    </div>
  );
}

type RowProps = {
  contact: Contact;
  orgName: string;
  volunteerName: string;
  link: string;
  campaignId?: number;
  enableSpanish: boolean;
};
enum Modes {
  text = "text",
  supported = "supported",
  conf = "conf",
  confSuccess = "confSuccess",
  texted = "texted",
}
function Row({
  contact,
  orgName,
  volunteerName,
  link: _link,
  campaignId,
  enableSpanish,
}: RowProps) {
  const dispatch = useAppDispatch();
  const classes = styles();
  const { id, name, phone, alreadySupported, lastManualText } = contact;
  const link = `${_link}&${CONTACT_ID_PARAM}=${id}`;
  const [mode, setMode] = useState<Modes>(Modes.text);
  const [submitting, setSubmitting] = useState(false);
  const [language, setLanguage] = useState("EN");
  const isSpanish = enableSpanish && language === "SP";

  useEffect(() => {
    if (alreadySupported) setMode(Modes.supported);
  }, [alreadySupported]);

  const markSent = async () => {
    setSubmitting(true);
    const sent = await dispatch(
      contactActions.createManualTextSent({
        contact_id: id,
        campaign_id: campaignId,
      }),
    );
    if (sent) {
      setMode(Modes.confSuccess);
      setTimeout(closeConf, 2500);
    }
    setSubmitting(false);
  };

  const closeConf = () => setMode(Modes.texted);

  return (
    <div className={classes.rowWrapper}>
      <Fade
        in={mode === Modes.conf || mode === Modes.confSuccess}
        timeout={{ appear: 100, exit: 800 }}
      >
        <div className={classes.confirm}>
          <div className={classes.confTxt}>
            {mode === Modes.confSuccess && (
              <div>Well done! Keep reaching out and making a difference.</div>
            )}
            {mode === Modes.conf && (
              <Fragment>
                <div className={classes.confName}>{name}</div>
                <div>Did you text this contact?</div>
              </Fragment>
            )}
          </div>
          {mode === Modes.conf && (
            <div className={classes.confButtons}>
              <Button
                size="small"
                className={classes.confYes}
                onClick={markSent}
                disabled={submitting}
              >
                Yes
                <ButtonSpinner show={submitting} customColor="#5FEA6B" />
              </Button>
              <Button
                size="small"
                className={classes.confNo}
                onClick={() => setMode(Modes.text)}
                disabled={submitting}
              >
                No
              </Button>
            </div>
          )}
          {mode === Modes.confSuccess && (
            <IconButton onClick={closeConf} className={classes.closeConf}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </Fade>
      <div className={classes.row}>
        <div className={classes.nameAndNum}>
          <div className={classes.name}>{name}</div>
          <div className={classes.number}>{phone}</div>
          {lastManualText && (
            <div className={classes.lastTxt}>
              You texted on{" "}
              {format(new Date(lastManualText.created_at), "MMM d")}
            </div>
          )}
        </div>

        {mode === Modes.text && (
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              variant="text"
              size="small"
              startIcon={<SmsOutlinedIcon />}
              className={classes.button}
              href={textMessageLink({
                link,
                phone,
                orgName,
                volunteerName,
                contactName: name,
                isVolunteer: true,
                isSpanish,
              })}
              onClick={() => setTimeout(() => setMode(Modes.conf), 2000)}
            >
              TEXT
            </Button>
            {enableSpanish && (
              <Fragment>
                <div className={classes.splitBtn}></div>
                <TextField
                  select
                  size="small"
                  value={language}
                  className={classes.langSelect}
                  onChange={({ target }) => setLanguage(target.value)}
                  SelectProps={{
                    renderValue: selected => selected,
                    classes: {
                      root: classes.langSelectRoot,
                      icon: classes.selectIcon,
                    },
                  }}
                >
                  <MenuItem value="EN">English</MenuItem>
                  <MenuItem value="SP">Spanish</MenuItem>
                </TextField>
              </Fragment>
            )}
          </div>
        )}
        {mode !== Modes.text && (
          <div className={classes.checkContainer}>
            <CheckCircleIcon className={classes.checkIcon} />
            {mode === Modes.texted ? "Texted" : "Already supported"}
          </div>
        )}
      </div>
    </div>
  );
}

function Tooltip({ text }: { text: string }) {
  const classes = styles();
  const [dismissed, setDismissed] = useState(false);

  if (dismissed) return <></>;
  return (
    <Fade in={true} timeout={500} style={{ transitionDelay: "1000ms" }}>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltip}>
          <div className={classes.lightbulb}>
            <LightbulbSVG />
          </div>
          <div className={classes.ttText}>{text}</div>
          <IconButton
            size="small"
            className={classes.ttIconButton}
            onClick={() => setDismissed(true)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Fade>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 24,
    backgroundColor: "#FFFFFF",
  },
  container: {
    width: 500,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 16,
  },
  header: {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "32px",
    textAlign: "center",
    paddingTop: 32,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  ezSharing: {
    textWrap: "nowrap",
  },

  subheader: {
    fontSize: 16,
    letterSpacing: 0.12,
    lineHeight: "24px",
    paddingBottom: 32,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 24,
    },
  },
  spanishSwitch: {
    marginRight: 0,
    marginBottom: 12,
    marginTop: -16,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 6,
    },
  },
  rowWrapper: {
    width: "100%",
    maxWidth: "100%",
    position: "relative",
    borderBottom: "1px solid #EAEBF3",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 10,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  nameAndNum: {
    paddingRight: 12,
    overflow: "hidden",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "22px",
    marginBottom: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  number: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  lastTxt: {
    fontSize: 12,
    letterSpacing: 0.4,
    paddingTop: 6,
    color: theme.palette.secondary2.main,
  },
  buttonWrapper: {
    width: "fit-content",
    minWidth: "fit-content",
    display: "flex",
    alignItems: "center",
  },
  button: {
    width: 68,
    textWrap: "nowrap",
  },
  splitBtn: {
    height: 30,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    marginLeft: 4,
  },
  langSelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    color: theme.palette.primary.main,
    marginRight: -10,
  },
  langSelectRoot: {
    paddingTop: 5.5,
    paddingBottom: 5.5,
    paddingLeft: 8,
    color: theme.palette.primary.main,
    fontSize: 13,
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    letterSpacing: 0.15,
    textWrap: "nowrap",
  },
  checkIcon: {
    color: "#1AC846",
    marginRight: 8,
  },
  confirm: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#FFF4F4",
    zIndex: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
  },
  confTxt: {
    overflow: "hidden",
    marginRight: 16,
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "22px",
  },
  confName: {
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginRight: 0,
  },
  confButtons: {
    minWidth: 104,
    display: "flex",
    justifyContent: "flex-end",
  },
  confYes: {
    minWidth: 48,
    maxWidth: 48,
    backgroundColor: "#5FEA6B",
    marginRight: 8,
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: "#5FEA6B",
    },
  },
  confNo: {
    minWidth: 48,
    maxWidth: 48,
    backgroundColor: theme.palette.error.main,
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: theme.palette.error.main,
    },
  },
  closeConf: {
    marginRight: -8,
  },
  socialMediaBar: {
    width: "100%",
    padding: 16,
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    zIndex: 200,
    [theme.breakpoints.down("sm")]: {
      bottom: BOTTOM_BAR_HEIGHT,
    },
  },
  smInstructions: {
    fontSize: 16,
    letterSpacing: 0.12,
    lineHeight: "24px",
    textAlign: "center",
    paddingBottom: 16,
  },
  socialMediaLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100% + 24px)",
    marginLeft: -12,
    marginRight: -12,
  },
  iconButton: {
    marginRight: 12,
    marginLeft: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  noContactsButton: {
    width: 300,
  },
  contactsLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  tooltipContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 8,
  },
  tooltip: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    top: 12,
    width: 320,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    padding: "6px 8px 15px 12px",
    borderRadius: 8,
    "&:before": {
      content: '""',
      position: "absolute",
      right: 152,
      top: "-7px",
      borderTop: "none",
      borderRight: "8px solid transparent",
      borderLeft: "8px solid transparent",
      borderBottom: "8px solid #1B47AA",
    },
  },
  ttText: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "20px",
    width: 236,
    marginTop: 12,
  },
  ttIconButton: {
    height: "fit-content",
    color: "#FFFFFF",
  },
  lightbulb: {
    marginTop: 12,
  },
}));
