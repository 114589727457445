import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Form } from "react-final-form";
import { APIAuthClient } from "../../../lib";
import {
  APIRes,
  DonationDetailModes,
  GenericObject,
  ToastTypes,
} from "../../../types";
import {
  campaignActions,
  getIsProductCampaign,
  getIsRaffleCampaign,
  setToast,
} from "../../../state";
import { ResponsiveModal } from "../../../components";
import { DonorInfoCard } from "./donationDetailCards/DonorInfoCard";
import { DonationCard } from "./donationDetailCards/DonationCard";
import { MessageCard } from "./donationDetailCards/MessageCard";
import { OrderFeesCard } from "./donationDetailCards/OrderFeesCard";
import { ProductsCard } from "./donationDetailCards/ProductsCard";
import { CircularLoader } from "../../../components/ui/CircularLoader";
import { getIsVolunteer } from "../../../../customState";
import { Top } from "./donationDetailCards/Top";
import { Bottom } from "./donationDetailCards/Bottom";
import { AdditionalInfoCard } from "./donationDetailCards/AdditionalInfoCard";
import { VolunteerCard } from "./donationDetailCards/VolunteerCard";
import { useDrawerTransition } from "../../../hooks/ui";
import { useAppDispatch } from "../../../types/state";
import { DeleteDonation } from "./DeleteDonation";
import { ImagesCard } from "./donationDetailCards/ImagesCard";
import { EventTicketsCard } from "./donationDetailCards/EventTicketsCard";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  id: number;
  refreshParentRef?: React.MutableRefObject<boolean>;
};

export function DonationDetails({
  id,
  onClose: _onClose,
  refreshList,
  refreshParentRef,
}: Props) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const shouldRefreshListRef = useRef(false);
  const { isOpen, onClose: onCloseFunc } = useDrawerTransition(_onClose);
  const isVolunteer = useSelector(getIsVolunteer);
  const isProduct = useSelector(getIsProductCampaign);
  const isRaffle = useSelector(getIsRaffleCampaign);
  const containerRef = useRef<null | HTMLFormElement>(null);
  const [donation, setDonation] = useState<GenericObject>({});
  const [loaded, setLoaded] = useState(false);
  const [mode, setMode] = useState<DonationDetailModes>(
    DonationDetailModes.view,
  );
  const [showDelete, setShowDelete] = useState(false);
  const [initialValues, setInitialValues] = useState<GenericObject>({});

  useEffect(() => {
    if (id) fetchDonation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [mode]);

  useEffect(() => {
    if (isRaffle) {
      dispatch(campaignActions.getCampaignTicketPackages());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, isRaffle]);

  const onClose = () => {
    if (shouldRefreshListRef.current) {
      if (refreshParentRef) refreshParentRef.current = true;
      refreshList();
    }
    onCloseFunc();
  };

  const fetchDonation = async () => {
    const url = `/donations/${id}/details`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return onClose();
    }
    setDonation(data);
    setInitialValues(data);
    setLoaded(true);
  };

  const onSubmit = async (values: any, _: any, complete: any) => {
    shouldRefreshListRef.current = true;
    if (refreshParentRef) refreshParentRef.current = true;
    const url = `/donations/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, values);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className={classes.form}
              ref={containerRef}
            >
              <Top
                onClose={onClose}
                loaded={loaded}
                donation={donation}
                setShowDelete={setShowDelete}
                mode={mode}
                setMode={setMode}
                refreshData={fetchDonation}
                shouldRefreshListRef={shouldRefreshListRef}
              />

              {!loaded && (
                <div className={classes.loadingWrapper}>
                  <CircularLoader show={!loaded} />
                </div>
              )}

              {loaded && (
                <div className={classes.bodyContainer}>
                  <div className={classes.column}>
                    <VolunteerCard
                      donation={donation}
                      mode={mode}
                      shouldRefreshListRef={shouldRefreshListRef}
                      refreshData={fetchDonation}
                    />
                    <DonorInfoCard
                      donation={donation}
                      mode={mode}
                      formValues={values}
                    />
                    {isProduct && <OrderFeesCard donation={donation} />}
                    <ImagesCard donation={donation} />
                  </div>
                  <div className={classes.column}>
                    {isProduct && <ProductsCard donation={donation} />}
                    {!isProduct && (
                      <DonationCard
                        donation={donation}
                        mode={mode}
                        formValues={values}
                      />
                    )}
                    <EventTicketsCard donation={donation} />
                    <MessageCard donation={donation} mode={mode} />
                    {!isVolunteer && (
                      <AdditionalInfoCard donation={donation} mode={mode} />
                    )}
                  </div>
                </div>
              )}
              {mode === DonationDetailModes.edit && (
                <Bottom
                  submitting={submitting}
                  setShowDelete={setShowDelete}
                  setMode={setMode}
                />
              )}
            </form>
          );
        }}
      />

      <DeleteDonation
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
        onCloseParent={onClose}
        donationId={id}
        refreshList={refreshList}
        refreshParentRef={refreshParentRef}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  loadingWrapper: {
    minHeight: 300,
    display: "flex",
  },
  form: {
    width: 960,
    maxWidth: "100%",
  },
  bodyContainer: {
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    overflowY: "clip",
    paddingBottom: 0,
    backgroundColor: "#F7F7F7",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      flexDirection: "column",
      justifyContent: "unset",
      backgroundColor: "#FFFFFF",
    },
  },
  column: {
    height: "fit-content",
    width: "calc(50% - 12px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
