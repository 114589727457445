import { useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import AddIcon from "@material-ui/icons/Add";
import { useIsDesktop, useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { NoResultsSVG } from "../../../assets";
import { SeatingMapSectionRows } from "./SeatingMapSectionRows";
import {
  SeatingMapLevels,
  seatingMapSectionSearchFields,
  seatingMapSectionSortFields,
} from "../../../types";
import { AddSeatingMapSection } from "./AddSeatingMapSection";

type Props = {
  seatingMapId: any;
  level: SeatingMapLevels;
  event_id?: any;
};
export function SeatingMapSections({ seatingMapId, level, event_id }: Props) {
  const classes = styles();
  const [showSort, setShowSort] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  useScrollToTop();

  const listProps = useList({
    baseUrl: "/seating_map_sections",
    isReady: true,
    initialState: {
      sort: "order_number",
      order: "asc",
      seating_map_id: seatingMapId,
      seating_map_level: level,
      event_id,
    },
  });
  const { listIsFiltered, total, noResults, showRows, refreshList } = listProps;
  const isOrg = level === SeatingMapLevels.Org;

  return (
    <ListContainer listProps={listProps} noStyling>
      {isMobile && (
        <div className={classes.topSection}>
          <div className={classes.count}>
            {total} Section
            {total !== 1 ? "s" : ""}
          </div>

          <div>
            <IconButton onClick={() => setShowSort(true)}>
              <SwapVertIcon />
            </IconButton>
            {isOrg && (
              <IconButton
                onClick={() => setShowAdd(true)}
                className={classes.add}
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <MultiSearch
          listProps={listProps}
          fields={seatingMapSectionSearchFields}
        />
        {isDeskTop && isOrg && (
          <div>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
            >
              Section
            </Button>
          </div>
        )}
      </div>

      {noResults && (
        <ListNoResults
          SVG={NoResultsSVG}
          listIsFiltered={listIsFiltered}
          label="sections"
        />
      )}

      {showRows && (
        <SeatingMapSectionRows listProps={listProps} level={level} />
      )}

      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={seatingMapSectionSortFields}
        />
      )}
      {showAdd && isOrg && (
        <AddSeatingMapSection
          seating_map_id={seatingMapId}
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary,
  },
  add: {
    marginLeft: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 16px 16px",
    },
  },
}));
