import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import { FlatTopDrawer } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { IS_RN_WEBVIEW } from "../../../../lib";
import { AutoTextLargeSVG } from "../../../assets/AutoTextLargeSVG";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setShowAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEmailSelector: React.Dispatch<React.SetStateAction<boolean>>;
  setShowImport: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAutoTexts: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ActionsDrawer({
  isOpen,
  onClose,
  setShowAdd,
  setShowEmailSelector,
  setShowImport,
  setShowAutoTexts,
}: Props) {
  const classes = styles();
  const contacts = useSelector(({ contacts }: RootState) => contacts.contacts);
  const hasContacts = Boolean(Array.isArray(contacts) && contacts.length);
  const hasContactAutoTexting = useSelector(
    (state: RootState) => state.campaign.hasContactAutoTexting,
  );

  const onClick = (truthyAction: any) => {
    truthyAction(true);
    setTimeout(onClose, 500);
  };

  return (
    <FlatTopDrawer isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <List className={classes.list}>
          <ListItem button onClick={() => onClick(setShowAdd)}>
            <ListItemIcon>
              <AddIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Add contact" />
          </ListItem>
          {IS_RN_WEBVIEW && (
            <ListItem button onClick={() => onClick(setShowImport)}>
              <ListItemIcon>
                <MobileScreenShareIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Import phone contacts" />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => onClick(setShowEmailSelector)}
            disabled={!hasContacts}
          >
            <ListItemIcon>
              <MailOutlineIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Send a mass email" />
          </ListItem>
          {hasContactAutoTexting && (
            <ListItem button onClick={() => onClick(setShowAutoTexts)}>
              <ListItemIcon>
                <AutoTextLargeSVG color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Manage auto-texts" />
            </ListItem>
          )}
        </List>
      </div>
    </FlatTopDrawer>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: "100%",
    margin: "1px 0",
  },
  list: {
    width: "100%",
  },
}));
