import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExportIcon from "@material-ui/icons/GetAppOutlined";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsDesktop, useIsMobile, useScrollToTop } from "../../hooks/ui";
import { campaignActions, getCampaignId } from "../../state";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../components";
import { useSetTopBarTitle } from "../../hooks/ui";
import { useList } from "../../hooks/list";
import { EventTicketRows } from "./components/EventTicketRows";
import { Filters } from "./components/Filters";
import { NoEvents } from "../../assets";
import {
  EventTicketFilterFields,
  eventTicketSearchFields,
  eventTicketSortFields,
} from "../../types/eventTickets";
import { ScanTickets } from "./components/ScanTickets";
import { TicketExport } from "./components/TicketExport";

export function EventTickets() {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showScan, setShowScan] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  useScrollToTop();

  useEffect(() => {
    if (campaign_id) {
      setIsReady(true);
      dispatch(campaignActions.fetchCampaignEventAutocomplete(campaign_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const listProps = useList({
    baseUrl: "/event_tickets",
    isReady,
    initialState: {
      sort: "created_at",
      order: "desc",
      campaign_id,
    },
    filterFields: EventTicketFilterFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    filterDrawerActive,
    showRows,
    refreshList,
  } = listProps;

  useSetTopBarTitle(<h1 className={classes.title}>Event tickets</h1>);

  return (
    <ListContainer listProps={listProps}>
      {isMobile && (
        <div className={classes.topSection}>
          <div>
            <h1 className={classes.title}>Event tickets</h1>
            <div className={classes.count}>
              {total} Ticket
              {total !== 1 ? "s" : ""}
            </div>
          </div>
          <div>
            <Button
              size="small"
              color="secondary"
              onClick={() => setShowScan(true)}
              className={classes.button}
            >
              Scan
            </Button>
            <IconButton
              onClick={() => setShowExport(true)}
              className={classes.export}
            >
              <ExportIcon />
            </IconButton>
            <IconButton
              onClick={() => setShowSort(true)}
              className={classes.sort}
            >
              <SwapVertIcon />
            </IconButton>
            <IconButton onClick={() => setShowFilter(true)}>
              <FilterListIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <MultiSearch listProps={listProps} fields={eventTicketSearchFields} />
        {isDeskTop && (
          <div>
            <Button
              color="secondary"
              onClick={() => setShowScan(true)}
              className={classes.button}
            >
              Scan tickets
            </Button>
            <Button
              color="secondary"
              onClick={() => setShowExport(true)}
              className={classes.button}
              startIcon={<ExportIcon />}
            >
              Export
            </Button>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
          </div>
        )}
      </div>

      <FilterChips listProps={listProps} />

      {noResults && (
        <ListNoResults
          SVG={NoEvents}
          listIsFiltered={listIsFiltered}
          label="tickets"
        />
      )}

      {showRows && <EventTicketRows listProps={listProps} />}

      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={eventTicketSortFields}
        />
      )}
      {showFilter && (
        <Filters onClose={() => setShowFilter(false)} listProps={listProps} />
      )}
      {showScan && (
        <ScanTickets
          onClose={() => setShowScan(false)}
          refreshList={refreshList}
        />
      )}
      {showExport && <TicketExport onClose={() => setShowExport(false)} />}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 8px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  sort: {
    marginRight: 6,
    marginLeft: 6,
  },
  export: {
    marginLeft: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  button: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "fit-content",
      minWidth: "fit-content",
    },
  },
}));
