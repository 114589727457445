import { useRef, useEffect, useCallback, Fragment, useState } from "react";
import Quill from "quill";
import InlineBlot from "quill/blots/inline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import debounce from "lodash.debounce";
import { useField } from "react-final-form";
import { requiredField, sanitizeQuillHtml } from "../../lib";
import {
  makeStyles,
  MenuItem,
  Button,
  Menu,
  ListItemText,
} from "@material-ui/core";
import { CommunicationMergeFields } from "../../types/communications";
import { useSelector } from "react-redux";
import { RootState } from "../../types/state";

class MergeFieldBlot extends InlineBlot {
  static blotName = "mergeField";
  static tagName = "span";
  static className = "merge-field";
  static create(value: string): HTMLElement {
    const node = super.create() as HTMLElement;
    node.setAttribute("data-field", value);
    node.innerHTML = `{{${value}}}`;
    node.setAttribute("contenteditable", "false");
    return node;
  }
  static deleteAt() {
    return false;
  }
  static formats(node: HTMLElement): string | null {
    return node.getAttribute("data-field");
  }
  format(name: string, value: string | null): void {
    if (name === "mergeField" && value) {
      this.domNode.setAttribute("data-field", value);
      this.domNode.innerHTML = `{{${value}}}`;
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(MergeFieldBlot);

type Props = {
  header: string;
  fieldName: string;
  currentValue?: string | null;
  mergeFields: CommunicationMergeFields;
  addMaterialMerge?: boolean;
  forText?: boolean;
  disabled?: boolean;
};
export function CommunicationWYSIWYGField({
  header,
  fieldName,
  currentValue,
  mergeFields,
  addMaterialMerge,
  forText,
  disabled,
}: Props) {
  const classes = styles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const quillDivRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const mergeFieldRef = useRef<HTMLSpanElement | null>(null);
  const materialMergeFieldRef = useRef<HTMLSpanElement | null>(null);
  const [mergeEl, setMergeEl] = useState<HTMLButtonElement | null>(null);
  const [materialEl, setMaterialEl] = useState<HTMLButtonElement | null>(null);
  const materialAutocomplete = useSelector(
    ({ material }: RootState) => material.materialAutocomplete,
  );

  const {
    input,
    meta: { touched, error, submitError, dirtySinceLastSubmit },
  } = useField(fieldName, { validate: requiredField });
  const showError =
    ((submitError && !dirtySinceLastSubmit) || error) && touched;

  useEffect(() => {
    if (quillDivRef.current) {
      let toolbar: any = [["clean"]];
      if (!forText) {
        toolbar = [
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          ["link"],
          ["clean"],
        ];
      }
      quillRef.current = new Quill(quillDivRef.current, {
        modules: { toolbar },
        theme: "snow",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;
    const toolbarElement = containerRef.current.querySelector(".ql-toolbar");
    if (toolbarElement) {
      if (mergeFieldRef.current) {
        toolbarElement.appendChild(mergeFieldRef.current);
      }
      if (materialMergeFieldRef.current) {
        toolbarElement.appendChild(materialMergeFieldRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (!quillDivRef.current) return;
    const childElement = quillDivRef.current.querySelector(".ql-editor");
    if (childElement) {
      (childElement as HTMLElement).style.setProperty(
        "border",
        showError ? "1px solid red" : "1px solid #c9cddd",
        "important",
      );
    }
  }, [showError]);

  const updateInput = () => {
    if (quillRef.current) {
      const value = sanitizeQuillHtml(quillRef.current.root.innerHTML);
      input.onChange(value);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(debounce(updateInput, 300), []);

  // this must be after Quill initialization
  useEffect(() => {
    if (quillRef.current) {
      if (currentValue) {
        quillRef.current.root.innerHTML = currentValue;
      }
      quillRef.current.on("text-change", onChange);
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off("text-change", onChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insertMergeField = (fieldName: string): void => {
    if (!quillRef.current) return;
    const range = quillRef.current.getSelection();
    if (range) {
      quillRef.current.insertEmbed(range.index, "mergeField", fieldName);
    }
  };

  return (
    <Fragment>
      <div className={classes.header}>{header}</div>

      {/* merge fields */}
      <span className="ql-formats" ref={mergeFieldRef}>
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={e => setMergeEl(e.currentTarget)}
          endIcon={<ExpandMoreIcon />}
          className="merge-field-button" // don't change the class name - used in quill css
        >
          Merge field
        </Button>
      </span>
      <Menu
        anchorEl={mergeEl}
        keepMounted
        open={Boolean(mergeEl)}
        onClose={() => setMergeEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClick={() => setMergeEl(null)}
      >
        {mergeFields.map(({ label, value }) => {
          return (
            <MenuItem key={value} onClick={() => insertMergeField(value)}>
              <ListItemText primary={label} />
            </MenuItem>
          );
        })}
      </Menu>

      {/* material merge fields */}
      {addMaterialMerge && (
        <span className="ql-formats" ref={materialMergeFieldRef}>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={e => setMaterialEl(e.currentTarget)}
            endIcon={<ExpandMoreIcon />}
            className="merge-field-button" // don't change the class name - used in quill css
          >
            Material link
          </Button>
        </span>
      )}
      <Menu
        anchorEl={materialEl}
        keepMounted
        open={Boolean(materialEl)}
        onClose={() => setMaterialEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClick={() => setMaterialEl(null)}
      >
        {materialAutocomplete.map(({ id, name }) => {
          return (
            <MenuItem
              key={id}
              onClick={() => insertMergeField(`material_${id}_${name}`)}
            >
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Menu>

      <div
        className="quill"
        ref={containerRef}
        style={{ position: "relative" }}
      >
        <div ref={quillDivRef} />
        {disabled && <div className={classes.disabled} />}
      </div>

      {showError && <div className={classes.error}>{error || submitError}</div>}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
    paddingTop: 8,
  },
  error: {
    fontSize: 12,
    letterSpacing: 0.4,
    paddingTop: 6,
    paddingLeft: 6,
    color: theme.palette.error.main,
    lineHeight: "15px",
  },
  disabled: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 2,
    backgroundColor: "#FFFFFF",
    opacity: 0.7,
    top: 0,
    left: 0,
  },
}));
