import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  makeStyles,
  Button,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField as MuiTextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useSelector } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Form, Field, FormSpy, useField } from "react-final-form";
import { TextField } from "final-form-material-ui";
import CloseIcon from "@material-ui/icons/Close";
import {
  Notification,
  useNotify,
  ImageInput,
  ImageField,
  useRefresh,
} from "react-admin";
import {
  APIClient,
  generateAuthHeader,
  convertFileToBase64,
  email as validEmail,
  twoDecimalsOnly,
  isVolunteer as getIsVolunteer,
  required,
} from "../../lib";
import classnames from "classnames";
import { Personalization } from "./Personalization";
import { VolunteerPrizesDialog } from "../../components";

export function BrochureUploadModal(props) {
  const {
    setShowModal,
    campaignId,
    orgId,
    allowFamilyAccounts,
    allowDonations,
  } = props;
  const isVolunteer = useSelector(state => getIsVolunteer(state.user.role));
  const tokenVolunteerId = useSelector(state => state.user.volunteerId);
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const studentRef = useRef();
  const homeroomRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const productRef = useRef();
  const qtyRef = useRef();
  const addAnother = useRef(false);
  const addPrizes = useRef(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [itemTotal, setItemTotal] = useState(0);
  const [volunteerName, setVolunteerName] = useState("");
  const [volunteerId, setVolunteerId] = useState(null);
  const [homeroom, setHomeroom] = useState("");
  const [currStudents, setCurrStudents] = useState([]);
  const [currHomerooms, setCurrHomerooms] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeProductQty, setActiveProductQty] = useState("");
  const [showPrizeDialog, setShowPrizeDialog] = useState(false);
  const [returnedVolunteerId, setReturnedVolunteerId] = useState(null);
  const [addFamilyAccount, setAddFamilyAccount] = useState(false);
  const [initialValues, setInitialValues] = useState({ products: [] });

  useEffect(() => {
    if (!isVolunteer) {
      fetchVolunteers();
      fetchHomerooms();
    }
    fetchCampaignProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVolunteer && tokenVolunteerId) setVolunteerId(tokenVolunteerId);
  }, [isVolunteer, tokenVolunteerId]);

  useEffect(() => {
    if (activeProduct && qtyRef.current) {
      setActiveProductQty(1);
      setTimeout(() => {
        qtyRef.current.select();
      }, 20);
    }
  }, [activeProduct]);

  const resetForm = () => {
    fetchVolunteers();
    fetchHomerooms();
    setGrandTotal(0);
    setItemTotal(0);
    setVolunteerName("");
    setVolunteerId(null);
    setHomeroom("");
    setActiveProduct(null);
    setActiveProductQty("");
    setInitialValues({ products: [] });
    studentRef.current && studentRef.current.focus();
  };

  const fetchCampaignProducts = async () => {
    const res = await APIClient.get(
      `/campaign_products/campaigns/${campaignId}/autocomplete`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProducts(data);
  };

  const fetchVolunteers = async () => {
    const res = await APIClient.get(
      `/volunteers/autocomplete?org_id=${orgId}&campaign_id=${campaignId}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setCurrStudents(data);
  };

  const fetchHomerooms = async () => {
    const res = await APIClient.get(`/orgs/${orgId}/classrooms`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setCurrHomerooms(data);
  };

  const isValidForm = values => {
    let isValid = true;
    const min2Words = /(\w+\s+[^-])+\S+/;
    if (!volunteerId && !min2Words.test(volunteerName.trim())) {
      notify("Student must have a first and last name", "warning");
      isValid = false;
    } else if (!homeroom && !isVolunteer) {
      notify("Please select/add a Homeroom", "warning");
      isValid = false;
    } else if (
      !values.products.length &&
      Number(values.donation_amount || 0) <= 0
    ) {
      notify("There are no items OR donation added", "warning");
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = async (values, _, complete) => {
    if (!isValidForm(values)) return complete();
    const {
      brochures,
      products,
      family_member_first_name,
      family_member_last_name,
      family_member_class_name,
      ...rest
    } = values;
    const SP = [];
    products.forEach(p => {
      const { id: product_id, qty, personalization: p13n } = p;
      const existingIndex = SP.findIndex(e => e.product_id === product_id);
      if (existingIndex !== -1) {
        SP[existingIndex].qty = SP[existingIndex].qty + Number(qty);
        if (Array.isArray(p13n)) {
          const currP13n = Array.isArray(SP[existingIndex].personalization)
            ? SP[existingIndex].personalization
            : [];

          SP[existingIndex].personalization = [...currP13n, ...p13n];
        }
      } else {
        const personalization = Array.isArray(p13n) ? p13n : null;
        SP.push({ product_id, qty: Number(qty), personalization });
      }
    });
    const postObj = {
      campaignId: Number(campaignId),
      volunteerId,
      volunteerName: volunteerId ? null : volunteerName.trim(),
      class_name: homeroom,
      ...rest,
      selected_products: SP,
    };
    if (brochures) {
      try {
        const convertJobs = [];
        brochures.forEach(b => {
          convertJobs.push(convertFileToBase64(b, undefined, true));
        });
        const convertedBrochures = await Promise.all(convertJobs);
        postObj.brochures = convertedBrochures;
      } catch (error) {
        return notify(
          "There was an error with your attachment, please try again",
          "warning",
        );
      }
    }
    if (volunteerId && addFamilyAccount) {
      postObj.family_member_first_name = family_member_first_name;
      postObj.family_member_last_name = family_member_last_name;
      postObj.family_member_class_name = family_member_class_name;
    }

    const res = await APIClient.post("/donations/brochure_upload", postObj, {
      headers: generateAuthHeader(),
    });

    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setReturnedVolunteerId(data.volunteer_id);

    notify("Your Order form has been successfully uploaded", { type: "info" });
    if (addPrizes.current) setShowPrizeDialog(true);
    else if (!addAnother.current) handleCloseModal();
    else resetForm();
  };

  const onStudentChange = (_event, newValue) => {
    switch (true) {
      case newValue === null || newValue === "":
        setVolunteerName("");
        setVolunteerId(null);
        setHomeroom("");
        break;
      case typeof newValue === "string":
        setVolunteerName(newValue);
        setVolunteerId(null);
        setHomeroom("");
        break;
      case typeof newValue === "object":
        prefillClass(newValue.id);
        setVolunteerName(newValue.name);
        setVolunteerId(newValue.id);
        break;
      default:
        setVolunteerName("");
        setVolunteerId(null);
        setHomeroom("");
        break;
    }
  };

  const prefillClass = id => {
    const student = currStudents.find(e => e.id === Number(id));
    if (student && student.class_name) setHomeroom(student.class_name);
    else setHomeroom("");
  };

  const onHomeroomChange = (_event, newValue) => {
    setHomeroom(newValue);
  };

  const handleQtyChange = (e, input) => {
    const value = e.target.value;
    if ((!input && !value) || (value && /^[1-9][0-9]*$/.test(value))) {
      // if they are editing an added product there is an input, if it's a new product then set activeProductQty
      if (input) input.onChange(value);
      else setActiveProductQty(value);
    }
  };

  const handleQtyEnter = (e, fields) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && activeProduct && activeProductQty) handleAddItem(fields);
  };

  const handleEnter = (e, nextRef) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && nextRef.current) nextRef.current.focus();
  };

  const handleAddItem = fields => {
    fields.push({
      ...activeProduct,
      qty: activeProductQty,
      openPersonalization: activeProduct.can_personalize,
    });
    setActiveProduct(null);
    setActiveProductQty("");
    productRef.current && productRef.current.focus();
  };

  const handleCloseModal = () => {
    refresh();
    setShowModal(false);
  };

  const closePrizeModal = openBrochure => {
    if (openBrochure) {
      resetForm();
      setShowPrizeDialog(false);
      addPrizes.current = false;
    } else {
      handleCloseModal();
    }
  };

  const EditPersonalization = ({ rowName }) => {
    const openField = useField(`${rowName}.openPersonalization`);
    return (
      <IconButton
        className={classes.editIcon}
        onClick={() => openField.input.onChange(true)}
        size="small"
      >
        <EditIcon />
      </IconButton>
    );
  };

  if (showPrizeDialog) {
    return (
      <VolunteerPrizesDialog
        volunteer_id={returnedVolunteerId}
        campaign_id={campaignId}
        brochureCB={closePrizeModal}
        fromBrochure
      />
    );
  }

  return (
    <Dialog open={true} maxWidth={false}>
      <div className={classes.dialog}>
        <div className={classes.dialogHeader}>
          <div>Enter Order Form</div>
          <IconButton className={classes.closeIcon} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form
                onSubmit={event => {
                  const promise = handleSubmit(event);
                  promise && promise.then(() => form.reset());
                  return promise;
                }}
                className={classes.form}
              >
                <FormSpy
                  onChange={({ values, dirtyFieldsSinceLastSubmit }) => {
                    if (Object.keys(dirtyFieldsSinceLastSubmit).length) {
                      let ttl = 0;
                      let items = 0;
                      values.products.forEach(({ price, qty }) => {
                        ttl = ttl + Number(price) * Number(qty);
                        items = items + Number(qty);
                      });
                      setGrandTotal(`$${ttl.toFixed(2)}`);
                      setItemTotal(items);
                    }
                  }}
                />

                {!isVolunteer && (
                  <div className={classes.studentInputs}>
                    <Autocomplete
                      className={classes.input}
                      inputValue={volunteerName}
                      freeSolo
                      selectOnFocus
                      onInputChange={onStudentChange}
                      onChange={onStudentChange}
                      options={currStudents}
                      autoHighlight
                      getOptionLabel={option => option.name || option}
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          label="Student"
                          variant="outlined"
                          required
                          autoFocus
                          inputRef={studentRef}
                          onKeyDown={e => handleEnter(e, homeroomRef)}
                        />
                      )}
                      renderOption={(option, { inputValue }) => {
                        const matches = match(option.name, inputValue, {
                          insideWords: true,
                        });
                        const parts = parse(option.name, matches);

                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? "bold" : 400,
                                  backgroundColor: part.highlight
                                    ? "#66e319"
                                    : "unset",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                    />
                    <Autocomplete
                      className={classes.input}
                      inputValue={homeroom}
                      freeSolo
                      selectOnFocus
                      onInputChange={onHomeroomChange}
                      onChange={onHomeroomChange}
                      options={currHomerooms}
                      autoHighlight
                      getOptionLabel={option => option}
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          label="Homeroom"
                          variant="outlined"
                          required
                          inputRef={homeroomRef}
                          onKeyDown={e => handleEnter(e, emailRef)}
                        />
                      )}
                    />
                    <Field
                      name="email"
                      variant="outlined"
                      label="Email (optional)"
                      component={TextField}
                      validate={validEmail}
                      className={classes.input}
                      inputRef={emailRef}
                      onKeyDown={e => handleEnter(e, phoneRef)}
                    />
                    <Field
                      name="phone"
                      variant="outlined"
                      label="Phone (optional)"
                      component={TextField}
                      className={classes.input}
                      inputRef={phoneRef}
                      onKeyDown={e => handleEnter(e, productRef)}
                    />
                    {allowFamilyAccounts && volunteerId && (
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.fmButton}
                          onClick={() => setAddFamilyAccount(curr => !curr)}
                        >
                          {addFamilyAccount ? "remove" : "add"} family member
                        </Button>
                      </div>
                    )}
                    {volunteerId && addFamilyAccount && (
                      <div className={classes.familyInputs}>
                        <Field
                          name="family_member_first_name"
                          variant="standard"
                          label="Family Member First Name"
                          component={TextField}
                          validate={required}
                          className={classes.familyInput}
                        />
                        <Field
                          name="family_member_last_name"
                          variant="standard"
                          label="Family Member Last Name"
                          component={TextField}
                          validate={required}
                          className={classes.familyInput}
                        />
                        <Field
                          select
                          name="family_member_class_name"
                          variant="standard"
                          label="Select Family Member Class"
                          component={TextField}
                          validate={required}
                          className={classes.familyInput}
                        >
                          {currHomerooms.map((homeroom, index) => (
                            <MenuItem key={index} value={homeroom}>
                              {homeroom}
                            </MenuItem>
                          ))}
                        </Field>
                      </div>
                    )}
                  </div>
                )}

                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Thumbnail</TableCell>
                        <TableCell>Sku</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>price</TableCell>
                        <TableCell className={classes.qtyInput}>Qty</TableCell>
                        <TableCell className={classes.ttlCol}>Total</TableCell>
                        <TableCell className={classes.iconCol} />
                      </TableRow>
                    </TableHead>

                    <FieldArray name="products">
                      {({ fields }) => {
                        return (
                          <TableBody>
                            {fields.map((row, index) => {
                              const values = fields.value[index];
                              const {
                                sku,
                                product_name,
                                price,
                                thumbnail,
                                qty,
                                can_personalize,
                                personalize_max_chars,
                              } = values;

                              const total = Number(price * qty).toFixed(2);
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <img
                                      className={classes.thumbnail}
                                      alt="product"
                                      src={thumbnail}
                                    />
                                  </TableCell>
                                  <TableCell>{sku}</TableCell>
                                  <TableCell>{product_name}</TableCell>
                                  <TableCell>{`$${price}`}</TableCell>
                                  <TableCell>
                                    <Field
                                      variant="standard"
                                      type="number"
                                      name={`${row}.qty`}
                                      render={({
                                        input,
                                        meta: _rm,
                                        ...rest
                                      }) => {
                                        return (
                                          <MuiTextField
                                            {...rest}
                                            {...input}
                                            value={input.value}
                                            onFocus={e => e.target.select()}
                                            onChange={e =>
                                              handleQtyChange(e, input)
                                            }
                                          />
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell className={classes.ttlCol}>
                                    {`$${total}`}
                                  </TableCell>
                                  <TableCell>
                                    <div className={classes.icons}>
                                      {can_personalize && (
                                        <EditPersonalization rowName={row} />
                                      )}
                                      {can_personalize && (
                                        <Personalization
                                          rowName={row}
                                          maxChars={personalize_max_chars}
                                        />
                                      )}
                                      <IconButton
                                        onClick={() => fields.remove(index)}
                                        size="small"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            <TableRow>
                              <TableCell
                                align="left"
                                colSpan={4}
                                className={classes.gTtl}
                              >
                                TOTALS:
                              </TableCell>
                              <TableCell>{itemTotal}</TableCell>
                              <TableCell colSpan={2}>{grandTotal}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Autocomplete
                                  value={activeProduct}
                                  onChange={(_, newValue) =>
                                    setActiveProduct(newValue)
                                  }
                                  getOptionLabel={option =>
                                    `${option.sku} | ${option.product_name}`
                                  }
                                  options={products}
                                  autoHighlight
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  filterOptions={(options, { inputValue }) => {
                                    if (!inputValue) return options;
                                    return options.filter(
                                      ({ sku = "", product_name = "" }) =>
                                        sku === inputValue ||
                                        product_name.includes(inputValue),
                                    );
                                  }}
                                  renderInput={params => (
                                    <MuiTextField
                                      {...params}
                                      label=""
                                      placeholder="Enter or select an item to add to order"
                                      inputRef={productRef}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <MuiTextField
                                  className={classes.qtyInput}
                                  label="Qty"
                                  value={activeProductQty}
                                  onChange={e => handleQtyChange(e)}
                                  onKeyDown={e => handleQtyEnter(e, fields)}
                                  type="number"
                                  step="1"
                                  inputRef={qtyRef}
                                />
                              </TableCell>
                              <TableCell className={classes.iconCol}>
                                <IconButton
                                  color="primary"
                                  disabled={!activeProduct || !activeProductQty}
                                  onClick={() => handleAddItem(fields)}
                                >
                                  <AddIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      }}
                    </FieldArray>
                  </Table>
                </TableContainer>

                <div className={classes.subHeader}>Upload Order Forms(s)</div>
                <ImageInput
                  className={classes.image}
                  source="brochures"
                  label=""
                  accept="image/*, application/pdf"
                  multiple
                >
                  <ImageField
                    source="src"
                    title="title"
                    className={classes.image}
                  />
                </ImageInput>
                <div className={classes.bottomInputs}>
                  {allowDonations && (
                    <Field
                      name="donation_amount"
                      variant="standard"
                      label="Donation amount"
                      component={TextField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      type="number"
                      validate={twoDecimalsOnly}
                    />
                  )}

                  <Field
                    name="brochure_student_entered_amount"
                    variant="standard"
                    label="Student entered amount collected"
                    component={TextField}
                    className={classes.studentEntered}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    type="number"
                    validate={twoDecimalsOnly}
                  />
                </div>

                <Button
                  type="button"
                  disabled={submitting}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    addAnother.current = false;
                    addPrizes.current = false;
                    form.submit();
                  }}
                >
                  save
                </Button>
                {!isVolunteer && (
                  <Button
                    type="button"
                    disabled={submitting}
                    className={classnames(classes.button, classes.middleButton)}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addAnother.current = true;
                      addPrizes.current = false;
                      form.submit();
                    }}
                  >
                    {`save & new`}
                  </Button>
                )}
                {!isVolunteer && (
                  <Button
                    type="button"
                    disabled={submitting}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addPrizes.current = true;
                      form.submit();
                    }}
                  >
                    {`save & add prizes`}
                  </Button>
                )}
              </form>
            );
          }}
        />
      </div>
      <Notification />
    </Dialog>
  );
}

const styles = makeStyles(
  _theme => ({
    dialog: {
      width: 1000,
      padding: 40,
    },
    dialogHeader: {
      fontSize: "22px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      paddingBottom: "24px",
    },
    closeIcon: {
      position: "absolute",
      right: 0,
    },
    studentInputs: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    input: {
      width: 440,
      marginBottom: "24px",
    },
    fmButton: {
      marginBottom: 24,
    },
    familyInputs: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 24,
    },
    familyInput: {
      width: "30%",
    },
    subHeader: {
      fontSize: 17,
      letterSpacing: 0.08,
      lineHeight: "20px",
      paddingBottom: "8px",
    },
    table: {
      marginBottom: 24,
    },
    button: {
      color: "#FFFFFF",
      minWidth: 94,
      fontSize: 18,
    },
    middleButton: {
      margin: "0 24px",
    },
    thumbnail: {
      maxHeight: 32,
    },
    ttlCol: {
      width: 82,
    },
    qtyInput: {
      width: 82,
    },
    iconCol: {
      width: 48,
    },
    icons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editIcon: {
      marginRight: 16,
    },
    gTtl: {
      fontWeight: 600,
      fontSize: 16,
    },
    bottomInputs: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: 28,
    },
    studentEntered: {
      width: 268,
    },
  }),
  { classNamePrefix: "brochureUpload" },
);
