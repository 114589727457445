import { theme } from "../theme/theme";

type Props = {
  color: "primary" | "secondary" | "white";
};
export function AutoTextLargeSVG({ color }: Props) {
  const fill =
    color === "white"
      ? "#FFFFFF"
      : color === "primary"
      ? theme.palette.primary.main
      : theme.palette.secondary.main;

  return (
    <svg width="24" height="19">
      <path
        fill={fill}
        transform="translate(0 4.53008)"
        d="M5.400085 11.400084C4.2800169 10.280017 3.5053034 8.9953327 3.0750954 7.5451846C2.6448877 6.095036 2.5549426 4.6448874 2.8052609 3.1947391L1.1998303 4.8001699L0 3.6003394L3.6003394 0L7.1998301 3.6003394L6 4.8001699L4.5150614 3.3152313C4.2749257 4.5150614 4.3275352 5.7276196 4.6728892 6.9529061C5.017395 8.1773443 5.6597371 9.2600765 6.599915 10.200254C7.3500214 10.95036 8.1951637 11.510394 9.1353416 11.880357C10.07552 12.250319 11.030123 12.435299 12 12.435299C12.969877 12.435299 13.925329 12.250319 14.865507 11.880357C15.804836 11.510394 16.649979 10.95036 17.400085 10.200254L18.599915 11.400084C17.690285 12.309715 16.662706 12.992787 15.518031 13.447603C14.372507 13.902418 13.19983 14.129826 12 14.129826C10.80017 14.129826 9.6274929 13.902418 8.4828167 13.447603C7.3372931 12.992787 6.3097157 12.309715 5.400085 11.400084"
        fillRule="evenodd"
      />
      <path
        fill={fill}
        transform="translate(5.40009 0.000255585)"
        d="M0 2.7297411C0.90963089 1.8201103 1.9372083 1.1370386 3.0827322 0.68222314C4.2274079 0.22740772 5.400085 0 6.599915 0C7.7997456 0 8.9724226 0.22740772 10.117947 0.68222314C11.262622 1.1370386 12.290199 1.8201103 13.19983 2.7297411C14.319899 3.8498092 15.09546 5.1344929 15.524819 6.5846415C15.955028 8.03479 16.044973 9.4849386 15.795503 10.935087L17.400085 9.3296566L18.599915 10.529487L15.000424 14.129826L11.400084 10.529487L12.599916 9.3296566L14.084853 10.814595C14.324989 9.6147642 14.27238 8.4022064 13.927875 7.1769199C13.58252 5.9524817 12.940178 4.8697495 12 3.9295714C11.249894 3.1794655 10.404752 2.6194315 9.4654217 2.2494698C8.5252438 1.8795079 7.5697923 1.6945269 6.599915 1.6945269C5.6300383 1.6945269 4.6754351 1.8795079 3.7352567 2.2494698C2.7950785 2.6194315 1.9499364 3.1794655 1.1998303 3.9295714L0 2.7297411Z"
        fillRule="evenodd"
      />
      <path
        fill={fill}
        transform="translate(7.84302 5.17268)"
        d="M1.5307595 5.2761984L6.7840476 5.2761984L6.7840476 1.5307595L1.5307595 1.5307595L1.5307595 5.2761984ZM7.9796352 0.33517182C7.7598643 0.11540093 7.4815445 0 7.172677 0L1.1412812 0C0.83241409 0 0.5540942 0.11540093 0.33432329 0.33517182C0.11455239 0.5540942 0 0.83326262 0 1.1421298L0 8.8646584L2.0560033 6.8078065L7.172677 6.8078065C7.4815445 6.8078065 7.7598643 6.6924057 7.9796352 6.4726348C8.1994057 6.2528639 8.3139582 5.9736953 8.3139582 5.6648283L8.3139582 1.1421298C8.3139582 0.83326262 8.1994057 0.5540942 7.9796352 0.33517182L7.9796352 0.33517182Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
