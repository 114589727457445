import { useEffect, useState } from "react";
import { makeStyles, Button, IconButton } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LinkIcon from "@material-ui/icons/Link";
import { useSelector } from "react-redux";
import { sharedStyles } from "../shared.styles";
import { GuidedSetupScreen } from "../../../types";
import { AFFILIATES } from "../../../../lib";
import {
  affiliateLinkWithTS,
  copyLinkWithSubAffiliate,
  emailLink,
  facebookLink,
  linkedinLink,
  textMessageLink,
  twitterLink,
  whatsappLink,
} from "../../../lib";
import { SharingSVG } from "../SVGs/SharingSVG";
import { FacebookSVG } from "../../../assets/FacebookSVG";
import { WhatsappSVG } from "../../../assets/WhatsappSVG";
import { TwitterSVG } from "../../../assets/TwitterSVG";
import { LinkedinSVG } from "../../../assets/LinkedinSVG";
import { RootState } from "../../../types/state";
import { CopyLink } from "../../../components";
import { InstagramSVG } from "../../../assets";
import { InstagramDrawer } from "../../../components/general/InstagramDrawer";
import { useAddInstagram } from "../../../hooks/misc";

export function Sharing(props: GuidedSetupScreen) {
  const {
    activeScreen,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setDisableNext,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const addInstagram = useAddInstagram();
  const { orgName, first_name, last_name, webpageLink } = useSelector(
    (state: RootState) => state.volunteerSetup,
  );
  const [showInstagram, setShowInstagram] = useState(false);

  const link = affiliateLinkWithTS(
    webpageLink,
    AFFILIATES.VOLUNTEER_SETUP_SHARING_LINKS,
  );

  useEffect(() => {
    if (activeScreen === "SOCIAL") {
      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  useEffect(() => {
    if (nextTriggeredBy === "SOCIAL") {
      handleNext();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  if (activeScreen !== "SOCIAL") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <SharingSVG />
      <h1 className={sharedClasses.screenHeader}>
        Create a buzz by sharing with family & friends
      </h1>

      <div className={classes.socialMediaLinks}>
        <IconButton
          size="small"
          href={facebookLink(link)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <FacebookSVG />
        </IconButton>
        <IconButton
          size="small"
          href={twitterLink(link, orgName, true)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <TwitterSVG />
        </IconButton>
        <IconButton
          size="small"
          href={linkedinLink(link)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <LinkedinSVG />
        </IconButton>
        <IconButton
          size="small"
          href={whatsappLink(link, orgName, true)}
          target="_blank"
          disableFocusRipple
          disableRipple
          classes={{ root: classes.iconButton }}
        >
          <WhatsappSVG />
        </IconButton>
        {addInstagram && (
          <IconButton
            size="small"
            onClick={() => setShowInstagram(true)}
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <InstagramSVG style={{ height: 32, width: 32 }} />
          </IconButton>
        )}
      </div>

      <Button
        color="primary"
        className={classes.button}
        startIcon={<ChatBubbleOutlineIcon />}
        href={textMessageLink({
          link,
          orgName,
          volunteerName: `${first_name} ${last_name}`,
          isVolunteer: true,
        })}
      >
        Send a text
      </Button>
      <Button
        color="primary"
        className={classes.button}
        startIcon={<EmailOutlinedIcon />}
        href={emailLink(link, orgName, true, `${first_name} ${last_name}`)}
      >
        Send an email
      </Button>
      <CopyLink link={copyLinkWithSubAffiliate(link)}>
        <Button
          color="primary"
          className={classes.button}
          startIcon={<LinkIcon />}
        >
          Copy page link
        </Button>
      </CopyLink>
      {showInstagram && (
        <InstagramDrawer onClose={() => setShowInstagram(false)} link={link} />
      )}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  socialMediaLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100% + 24px)",
    marginLeft: -12,
    marginRight: -12,
    paddingTop: 28,
    paddingBottom: 28,
  },
  button: {
    marginTop: 16,
    width: 300,
  },
  iconButton: {
    marginRight: 12,
    marginLeft: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
