import { isSchoolathonCompany } from "../../../lib";
import { RootState } from "../../types/state";
import { companyTypes } from "./actions";

type CompanyState = {
  volunteerTextingEnabled?: boolean;
  contactTextingEnabled?: boolean;
};

const initialState: CompanyState = {};

export const companyReducers = (
  state = initialState,
  action: any,
): CompanyState => {
  switch (action.type) {
    case companyTypes.COMPANY_CONFIGS_SET:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export function getIsSchoolathon(state: RootState) {
  const {
    user: { companyId },
  } = state;
  return isSchoolathonCompany(companyId);
}
