import { makeStyles, Menu } from "@material-ui/core";
import { Fragment, useState } from "react";
import { EmailVolunteers } from "./actionDialogs/EmailVolunteers";
import { BulkActionUpdates } from "./actionDialogs/BulkActionUpdates";
import { ListProps } from "../../../types";
import { useSelector } from "react-redux";
import { getIsOrgOrEmp } from "../../../../customState";
const MENU_WIDTH = 246;
const BUTTON_WIDTH = 146;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
  refreshData: () => void;
  listProps: ListProps<any>;
};
export function BulkActions(props: Props) {
  const { isOpen, onClose, anchorEl, listProps } = props;
  const classes = styles();
  const isOrgOrOrgEmployee = useSelector(getIsOrgOrEmp);
  const [showEmail, setShowEmail] = useState(false);

  return (
    <Fragment>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        className={classes.menu}
        open={isOpen}
        onClose={onClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: MENU_WIDTH - BUTTON_WIDTH,
        }}
      >
        <div className={classes.container}>
          <BulkActionUpdates
            listProps={listProps}
            className={classes.row}
            onClose={onClose}
          />
          {isOrgOrOrgEmployee && (
            <div
              className={classes.row}
              onClick={() => {
                setShowEmail(true);
                onClose();
              }}
            >
              Send emails
            </div>
          )}
        </div>
      </Menu>

      {showEmail && (
        <EmailVolunteers
          onClose={() => setShowEmail(false)}
          listProps={listProps}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  menu: {
    padding: 0,
  },
  menuPaper: {
    borderRadius: 8,
  },
  container: {
    width: MENU_WIDTH,
    position: "relative",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      width: "100%",
    },
  },
  row: {
    width: "100%",
    height: 36,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 16,
    letterSpacing: 0.15,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
}));
